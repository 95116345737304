import React from 'react'

const Info = ({ children, border }) => {
  return (
    <section
      className={`text-[11px] py-3 tracking-normal lg:y-3 ${
        border ? 'border-t' : ''
      }`}
    >
      <h6 className="text-primary pb-1">Informasi Penting</h6>
      {children}
    </section>
  )
}

export default Info
