import location from 'images/menu/location.svg'
import order from 'images/menu/min-order.svg'
import shiping from 'images/menu/shipping.svg'
import React from 'react'
import { currency } from 'utils/formatNumber'

import Tooltip from '../../../../components/layouts/fragments/Tooltip'

const FeaturedItem = ({
  click,
  name,
  distance,
  unit,
  shipping,
  cost,
  cost_to,
  minOrder,
  type,
}) => {
  return (
    <div className="flex flex-col gap-1 md:gap-2">
      <h3 onClick={click} className="lg:text-xl cursor-pointer text-base">
        {name}
      </h3>
      <div className="flex flex-col w-[max-content] md:flex-row gap-1 md:gap-4 text-gray-400">
        <div className="md:flex gap-2 items-center hidden">
          <img src={location} alt="location" width={14} height={14} />
          <p className="md:text-xs lg:text-sm text-[10px]">
            {distance} {unit}
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <img src={shiping} alt="shipping" width={20} height={20} />
          <div className="flex">
            <p className="md:text-xs lg:text-sm text-[10px]">
              {shipping && type == 2
                ? 'Gratis Ongkir'
                : cost_to == 0
                ? 'Gratis Ongkir'
                : `Estimasi Ongkir ${currency(cost)} - ${currency(cost_to)}`}
            </p>
            {cost_to !== 0 && cost == 0 && (
              <Tooltip>
                Bisa gratis ongkir jika <br /> pesan lebih banyak
              </Tooltip>
            )}
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <img src={order} alt="order" width={20} height={20} />
          <div className="flex">
            <p className="md:text-xs lg:text-sm text-[10px]">{minOrder}</p>
            {type === 2 && (
              <Tooltip>
                Bisa digabungkan dengan <br />
                menu yang lain selama
                <br /> satu pengiriman dalam
                <br /> restoran yang sama
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedItem
