import React from 'react'
import { AiOutlineLoading } from 'react-icons/ai'

const Loading = ({ className = '' }) => {
  return (
    <div
      className={`h-52 w-full flex items-center justify-center ${className}`}
    >
      <AiOutlineLoading className="text-primary animate-spin" size={48} />
    </div>
  )
}

export default Loading
