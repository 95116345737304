import order from 'images/menu/min-order.svg'
import shiping from 'images/menu/shipping.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { currency } from 'utils/formatNumber'

import Tooltip from '../../../../components/layouts/fragments/Tooltip'

const RecommendItem = ({
  mitra,
  name,
  id,
  img,
  minCost,
  cost,
  type,
  minOrder,
  unit,
  orderInfo,
}) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => navigate(`/detail-mitra/${id}`)}
      className="shadow-none flex-shrink-0 py-4 md:py-0 border-b md:border-none md:shadow-search-explore md:bg-white bg-transparent gap-2 cursor-pointer mx-1 md:my-4 md:w-[356px] flex rounded-none md:rounded-xl"
    >
      <img
        src={img}
        alt={name}
        className="rounded-xl h-20 md:h-[140px] object-cover max-w-[80px] md:max-w-[140px]"
      />
      <div className="flex flex-col gap-1 justify-between py-0 md:py-4 pr-2">
        <div className="flex flex-col gap-1 md:gap-0">
          <div className="text-xs text-primary">{mitra}</div>
          <div className="text-lg line-clamp-2">{name} </div>
        </div>
        <div className="flex gap-2">
          <div className="flex gap-1 items-center">
            <img src={shiping} alt="shiping" className="h-4 w-4" />
            <p className="text-[10px] flex whitespace-nowrap items-center">
              {cost == 0 ? 'Gratis Ongkir' : currency(cost)}
              {cost !== 0 && minCost == 0 && (
                <Tooltip>
                  Bisa gratis ongkir jika <br /> pesan lebih banyak
                </Tooltip>
              )}
            </p>
          </div>
          <div className="flex gap-1 items-center">
            <img src={order} alt="order" className="h-4 w-4" />
            {type == 2 && (
              <p className="text-[10px] flex items-center truncate">
                {orderInfo.replace('order per pengiriman', '')}
                <Tooltip>
                  Bisa digabungkan dengan <br />
                  menu yang lain selama
                  <br /> satu pengiriman dalam
                  <br /> restoran yang sama
                </Tooltip>
              </p>
            )}
            {type == 1 && (
              <p className="text-[10px] flex items-center truncate">
                {minOrder} {unit}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecommendItem
