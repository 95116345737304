import React from 'react'
import { DAY_NAMES, MONTH_NAMES } from 'utils/date'
import { currency } from 'utils/formatNumber'

const Cart = ({ data }) => {
  return (
    <React.Fragment>
      {/* MOBILE */}
      <div className="md:hidden block py-2 border-b last:border-b-0">
        <div className="py-4 border-b">
          <p className="text-sm font-semibold">{`${
            DAY_NAMES[new Date(data.date).getDay()]
          }, ${new Date(data.date).getDate()} ${
            MONTH_NAMES[new Date(data.date).getMonth()]
          } ${new Date(data.date).getFullYear()}`}</p>
          <p className="text-sm text-primary">
            Waktu Tiba {data.shipping_time}
          </p>
        </div>
        <div className="flex justify-between py-2 gap-2 text-sm text-gray-400">
          <div className="">Nama</div>
          <div className="text-end ">Harga</div>
        </div>
        <div className="flex flex-col py-2 gap-2">
          {data.menus.map((menu, key) => (
            <div className="flex justify-between gap-3" key={key}>
              <div className=" text-sm flex flex-col font-medium">
                <h6 className="capitalize">{menu.name}</h6>
                <p>{`${currency(menu.price)} x ${menu.qty} ${
                  menu.qty_unit_name
                }`}</p>
              </div>
              <div className="whitespace-nowrap text-end text-sm font-semibold">
                {currency(menu.sub_total)}
              </div>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-4 py-2 gap-2 text-sm font-medium">
          <div className="col-span-3">Ongkos Kirim</div>
          <div className="col-span-1 text-end ">
            {currency(data.shipping_cost)}
          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <section className="border-b hidden md:block">
        <div className="py-3 flex flex-col border-b pt-6 gap-1">
          <p className="md:text-base font-semibold">{`${
            DAY_NAMES[new Date(data.date).getDay()]
          }, ${new Date(data.date).getDate()} ${
            MONTH_NAMES[new Date(data.date).getMonth()]
          } ${new Date(data.date).getFullYear()}`}</p>
          <p className="text-primary text-sm">
            Waktu Tiba {data.shipping_time.replace('.', ':')}
          </p>
        </div>
        <div className="flex flex-col pt-4 gap-4">
          <div className="grid grid-cols-6 gap-1 pr-1 text-gray-400 text-sm">
            <div className="col-span-3">Nama</div>
            <div className="col-span-1">Harga</div>
            <div className="col-span-1">Jumlah</div>
            <div className="col-span-1 text-end">Pembayaran</div>
          </div>
          {data.menus.map((menu, key) => (
            <div
              key={key}
              className="grid grid-cols-6 gap-1 font-medium text-sm"
            >
              <div className="col-span-3">{menu.name}</div>
              <div className="col-span-1">{currency(menu.price)}</div>
              <div className="col-span-1">{menu.qty}</div>
              <div className="col-span-1 text-end">
                {currency(menu.sub_total)}
              </div>
            </div>
          ))}
          <div className="grid grid-cols-6 gap-1 pb-7 font-medium text-sm">
            <div className="col-span-3">Ongkos Kirim</div>
            <div className="col-span-1"> </div>
            <div className="col-span-1"> </div>
            <div className="col-span-1 text-end">
              {currency(data.shipping_cost)}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Cart
