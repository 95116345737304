import React from 'react'
import { IoCloseSharp } from 'react-icons/io5'

const SearchMenu = ({
  searchValue,
  searchChange,
  placeholder,
  focus,
  clearValue,
  submit,
}) => {
  return (
    <div className="w-full px-4">
      <form onSubmit={submit} className="mx-auto w-full max-w-3xl">
        <label
          htmlFor="menu-search"
          className="mb-2 text-sm font-medium text-white sr-only"
        >
          Search
        </label>
        <div className="relative block shadow-search-explore-responsive md:shadow-search-explore isolate p-3 md:p-6 pl-12 md:pl-12 pr-14 md:pr-20 w-full text-sm md:text-lg text-black bg-white rounded-full border border-white focus:outline-none">
          <input
            ref={focus}
            type="text"
            id="menu-search"
            className="focus:outline-none min-w-full"
            placeholder={`Cari Menu ${placeholder}`}
            value={searchValue}
            onChange={searchChange}
          />
          {searchValue !== '' && (
            <IoCloseSharp
              onClick={clearValue}
              className="absolute top-4 right-12 w-5 md:w-6 md:right-20 text-gray-400 cursor-pointer md:top-5 z-30 md:h-10"
            />
          )}
          <button
            name="menu-search"
            type="submit"
            className="text-white absolute right-[4px] md:right-[8px] top-[4px] md:top-[8px] bg-primary hover:bg-red-800 focus:outline-none focus:ring-0 font-medium rounded-full text-sm px-3 md:px-5 py-3 md:py-5"
          >
            <svg
              aria-hidden="true"
              className="w-3 md:w-5 h-3 md:h-5 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  )
}

export default SearchMenu
