import emptyCart from 'images/icons/empty-cart.svg'
import React from 'react'

const EmptyCart = () => {
  return (
    <div className="flex justify-center h-full min-h-[450px] lg:h-[500px] overflow-y-scroll no-scrollbar items-center">
      <div className="flex items-center gap-5 flex-col">
        <img src={emptyCart} alt="empty-cart" className="w-[120px] h-[120px]" />
        <div className="flex flex-col gap-1 items-center justify-center">
          <h5 className="font-semibold text-center text-lg">
            Keranjang pesananmu <br /> masih kosong
          </h5>
          <p className="text-sm text-center">
            Set tanggal dan waktu, <br /> lalu pilih menu sesuai kebutuhanmu
          </p>
        </div>
      </div>
    </div>
  )
}

export default EmptyCart
