/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const PemesananMakanan = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="pemesanan-makanan" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        D. Pemesanan Makanan
      </div>
      <List>
        <ListItem>
          Pemesan memilih harga dan paket makanan serta jumlah order melalui{' '}
          <em>website</em> dan/atau aplikasi KendiXpress.
        </ListItem>
        <ListItem>
          Pemesan menyampaikan permintaan tertentu terkait makanan dan/atau
          minuman yang akan disediakan oleh <em>Vendor</em> apabila ada
          kondisi-kondisi khusus terkait medis ataupun lainnya dari Pemesan yang
          disampaikan di dalam Pesanan (“<strong>Permintaan Khusus</strong>”).
        </ListItem>
        <ListItem>
          Pemesan menentukan jadwal dan waktu pengantaran Pesanan.
        </ListItem>
        <ListItem>
          Pemesan menyampaikan alamat pengantaran secara jelas, detail dan
          akurat.
        </ListItem>
        <ListItem>
          Pemesan melakukan pembayaran hanya melalui Rekening Pembayaran yang
          diinformasikan melalui <em>website</em> dan/atau aplikasi KendiXpress
          selambat-lambatnya 1 (satu) hari kerja sebelum jadwal dan waktu
          pengantaran Pesanan.
        </ListItem>
        <ListItem>
          Pesanan Pemesan akan diteruskan ke Vendor dalam waktu 1 (satu) hari
          sebelum jadwal dan waktu pengantaran apabila pembayaran Pesanan
          diterima dan tidak ada pembatalan Pesanan.
        </ListItem>
        <ListItem>
          Apabila Pemesan tidak melakukan pembayaran sesuai dengan jangka waktu
          diatas, maka secara otomatis Pesanan akan menjadi batal.
        </ListItem>
        <ListItem>
          Dalam hal ketidaksesuaian Pesanan yang diterima oleh Pemesan, Pemesan
          harus menyampaikan bukti-bukti termasuk foto dan surat jalan yang
          telah ditandatangani selambat-lambatnya 3 (tiga) jam setelah Pemesan
          menerima Pesanan. Kendixpress akan melakukan verifikasi kepada Pemesan
          dan akan meneruskan informasi tersebut kepada Vendor dalam jangka
          waktu selambat-lambatnya 3 (tiga) jam setelah keluhan dari Pemesan
          diterima oleh Kami.
        </ListItem>
      </List>
    </TabContent>
  )
})

export default PemesananMakanan
