import CartNotification from 'components/CartNotification'
import WhatsappButton from 'components/WhatsappButton'
import { useCartSystem } from 'modules/custom/useCartSystem'
import React, { useEffect, useState } from 'react'

import Footer from './fragments/Footer'
import NavAddress from './fragments/Navbar/NavAddress'

const ExploreLayout = ({ children }) => {
  const { cart } = useCartSystem()
  const [condition, setCondition] = useState()
  useEffect(() => {
    cart.length > 0 ? setCondition(true) : setCondition(false)
  }, [])
  return (
    <React.Fragment>
      <NavAddress />
      {children}
      <Footer />
      {cart.length > 0 && <div className="h-10 md:h-16"></div>}
      <WhatsappButton cart={condition} />
      {cart.length > 0 && <CartNotification />}
    </React.Fragment>
  )
}

export default ExploreLayout
