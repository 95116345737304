/* eslint-disable no-unused-vars */
import UserIcon from 'images/icons/user.svg'
import React, { useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdArrowBackIos } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'

import DropdownNavbarResponsive from './DropdownNavbarResponsive'

const NavbarUser = ({ menuNameResponsive = '' }) => {
  const navigate = useNavigate()
  const [location] = useState(() => {
    const saved = localStorage.getItem('dataAddress')
    const initialValue = JSON.parse(saved)
    return initialValue || ''
  })

  return (
    <div className="lg:container px-4">
      <div className="w-full p-0 py-5 bg-white flex justify-center items-center">
        <div className="mx-auto flex w-full md:justify-between items-center font-medium">
          <div className="w-auto md:block hidden h-auto relative">
            <Link to="/">
              <img
                src="/images/kendixpress.svg"
                className="w-44 cursor-pointer"
                alt="Logo"
              />
            </Link>
          </div>
          <div
            onClick={() => window.history.back()}
            className="block cursor-pointer md:hidden"
          >
            <MdArrowBackIos size={24} />
          </div>
          <div className="block md:hidden ml-2">
            <h4 className="text-lg text-black font-semibold">
              {menuNameResponsive}
            </h4>
          </div>
          <div className="md:flex hidden">
            <Link to="/user/profile" className="mr-5">
              <img src={UserIcon} className="w-9 h-9" />
            </Link>
            <button
              onClick={() => navigate('/user/order')}
              className="px-2 md:px-3 py-[6px] text-sm rounded-full bg-primary border-2 border-primary text-white w-28 hover:bg-red-800 hover:border-red-800"
            >
              Pesanan
            </button>
          </div>
          <DropdownNavbarResponsive classButton={`ml-auto`} />
        </div>
      </div>
    </div>
  )
}

export default NavbarUser
