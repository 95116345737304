import CustomForm from 'components/forms/CustomForm'
import { useCartSystem } from 'modules/custom/useCartSystem'
import { useOrder } from 'modules/custom/useOrder'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { currency } from 'utils/formatNumber'

import Cart from './fragments/Cart'
import InfoDetail from './fragments/InfoDetail'

const OrderDetail = () => {
  const { cart, grandTotal } = useCartSystem()
  const token = localStorage.getItem('token')
  const { dataUser } = useOrder()
  const navigate = useNavigate()
  const tax = Math.ceil((grandTotal + dataUser.payment_unique_code) * (2 / 100))

  return (
    <CustomForm title="Detail Pesanan">
      <div className="flex flex-col pb-7">
        <InfoDetail title="Nama Mitra" value={dataUser.partner_name} border />
        <div className="flex flex-col">
          {cart.map((data, key) => (
            <Cart key={key} data={data} total={grandTotal} />
          ))}
          {dataUser.payment_type == 2 && (
            <div className="flex justify-between py-2 md:py-4 border-b text-base font-semibold">
              <h5>Kode Unik</h5>
              <p>{currency(dataUser.payment_unique_code)}</p>
            </div>
          )}
          {dataUser?.company_name?.length > 0 && dataUser.tax == true && (
            <div className="flex justify-between py-2 md:py-4 border-b text-base text-primary font-semibold">
              <h5>Pajak Penghasilan</h5>
              <p>- {currency(tax)}</p>
            </div>
          )}
          <div className="flex justify-between py-6 text-base font-semibold">
            <h5>Total Pembayaran</h5>
            {dataUser?.company_name?.length > 0 && dataUser.tax == true ? (
              <p>
                {currency(
                  parseInt(grandTotal + dataUser.payment_unique_code - tax)
                )}
              </p>
            ) : (
              <p>
                {currency(parseInt(grandTotal + dataUser.payment_unique_code))}
              </p>
            )}
          </div>
          <button
            onClick={() => navigate(`/microsite?token=${token}`)}
            className="border-2 border-primary rounded-full justify-center text-[10px] font-semibold py-2 hover:bg-primary hover:text-white active:opacity-80 flex w-full max-w-[160px] text-primary"
          >
            Ubah Pesanan
          </button>
        </div>
      </div>
    </CustomForm>
  )
}

export default OrderDetail
