import Logout from 'components/modal/Logout'
import LocationIcon from 'images/icons/location.svg'
import LogoutIcon from 'images/icons/logout.svg'
import OrderIcon from 'images/icons/order.svg'
import ProfileIcon from 'images/icons/profile.svg'
import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import Card from './Card'

function Sidebar({ name, isLoading }) {
  const [showLogout, setShowLogout] = useState(false)

  return (
    <>
      <Card className={`py-10 px-6`}>
        <div>
          {/* USERNAME */}
          <div className="mb-[42px]">
            {!isLoading && (
              <h1 className="text-[26px] leading-9 text-black font-semibold">
                {name}
              </h1>
            )}
            {isLoading && (
              <div role="status" className="space-y-2.5 animate-pulse w-full">
                <div className="h-9 bg-gray-300 rounded-xl w-full"></div>
              </div>
            )}
          </div>
          {/* MENU SIDEBAR */}
          <div className="flex flex-col justify-between">
            <div className="flex items-center py-4">
              <img src={ProfileIcon} className="w-6 h-6 mr-4" />
              <Link
                to="/user/profile"
                className={`${
                  location.pathname === '/user/profile'
                    ? 'text-primary'
                    : 'text-black'
                } text-lg hover:text-primary`}
              >
                Profil
              </Link>
            </div>
            <div className="flex items-center py-4">
              <img src={OrderIcon} className="w-6 h-6 mr-4" />
              <Link
                to="/user/order"
                className={`${
                  location.pathname === '/user/order'
                    ? 'text-primary'
                    : 'text-black'
                } text-lg hover:text-primary`}
              >
                Pesanan
              </Link>
            </div>
            <div className="flex items-center py-4">
              <img src={LocationIcon} className="w-6 h-6 mr-4" />
              <Link
                to="/user/address"
                className={`${
                  location.pathname === '/user/address'
                    ? 'text-primary'
                    : 'text-black'
                } text-lg hover:text-primary`}
              >
                Alamat
              </Link>
            </div>
            <div className="flex items-center py-4">
              <img src={LogoutIcon} className="w-6 h-6 mr-4" />
              <div
                className="text-black text-lg hover:text-primary cursor-pointer"
                onClick={() => setShowLogout(true)}
              >
                Keluar
              </div>
            </div>
          </div>
          {/* END MENU SIDEBAR */}
        </div>
      </Card>
      {/* MODAL LOGOUT */}
      <Logout showLogout={showLogout} setShowLogout={setShowLogout} />
    </>
  )
}

export default Sidebar
