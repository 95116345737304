import Modal from './Index'

function TokenExpiredModal({ showModal, setShowModal, title }) {
  const resetDataModal = (isModalOpen) => {
    if (isModalOpen === true) {
      setShowModal && setShowModal(true)
    }
    if (isModalOpen === false) {
      setShowModal && setShowModal(false)
    }
  }

  const handleWhatsAppContact = () => {
    const whatsappUrl =
      'https://api.whatsapp.com/send/?phone=6281119279900&text=Halo&type=phone_number&app_absent=0'
    window.open(whatsappUrl, '_blank')
  }

  return (
    <Modal
      showModal={showModal}
      setShowModal={resetDataModal}
      isModalResponsiveFullPage={false}
      isModalResponsiveRounded={true}
    >
      <div className="text-center pb-6">
        <h4 className="text-lg text-black font-semibold mb-1">Ups! {title}</h4>
        <p className="mt-6 mb-8">
          Silakan kembali ke WhatsApp untuk memulai sesi baru.
        </p>
        <button
          onClick={() => handleWhatsAppContact()}
          className="bg-primary text-white hover:opacity-80 text-sm font-semibold p-3 rounded-full"
        >
          Hubungi WhatsApp KendiXpress
        </button>
      </div>
    </Modal>
  )
}

export default TokenExpiredModal
