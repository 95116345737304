/* eslint-disable no-unused-vars */
import AuthModal from 'components/modal/Auth'
import UserIcon from 'images/icons/user.svg'
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import NavbarItem from './NavbarItem'

function Navbar() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  const [showModalAuth, setShowModalAuth] = useState(false)

  const scrollSelectedItem = (id) => {
    const el = document.getElementById(id)
    el.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  return (
    <div className="container">
      <div className="w-full p-0 py-5 bg-white flex justify-center items-center">
        <div className="mx-auto flex w-full justify-start md:justify-center items-center font-medium">
          <div className="w-auto h-auto relative">
            <Link to="/">
              <img src="/images/kendixpress.svg" className="w-44" alt="Logo" />
            </Link>
          </div>
          <NavbarItem
            classLink="ml-auto"
            onClick={() => scrollSelectedItem('our-service')}
          >
            Layanan Kami
          </NavbarItem>
          <NavbarItem onClick={() => scrollSelectedItem('partner')}>
            Mitra Kami
          </NavbarItem>
          <NavbarItem onClick={() => scrollSelectedItem('workflow')}>
            Cara Kerja
          </NavbarItem>
          <NavbarItem onClick={() => scrollSelectedItem('testimonial')}>
            Kata Mereka
          </NavbarItem>
          {!token && (
            <>
              <button
                className="ml-4 px-2 py-[6px] text-sm rounded-full bg-white border-2 border-primary text-primary w-32 hover:bg-primary hover:text-white hidden md:block font-semibold"
                onClick={() => setShowModalAuth(true)}
              >
                Daftar
              </button>
              <button
                className="ml-auto md:ml-4 px-2 md:px-3 py-[6px] text-sm rounded-full bg-primary border-2 border-primary text-white w-32 hover:bg-red-800 hover:border-red-800"
                onClick={() => setShowModalAuth(true)}
              >
                Masuk
              </button>
            </>
          )}
          {token && (
            <div className="flex ml-auto md:ml-4">
              <Link to="/user/profile" className="md:mr-5">
                <img src={UserIcon} className="w-9 h-9" />
              </Link>
              <button
                onClick={() => navigate('/user/order')}
                className="px-2 md:px-3 py-[6px] text-sm rounded-full bg-primary border-2 border-primary text-white w-28 hover:bg-red-800 hover:border-red-800 hidden md:block"
              >
                Pesanan
              </button>
            </div>
          )}
        </div>
      </div>
      {/* MODAL AUTH */}
      <AuthModal showModal={showModalAuth} setShowModal={setShowModalAuth} />
    </div>
  )
}

export default Navbar
