/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from 'components/button/LoadingButton'
import Form from 'components/forms/Form'
import Input from 'components/forms/Input'
import EditIcon from 'images/icons/edit-red.svg'
import { useLoginUser } from 'modules/Auth/hook'
import { useFirebaseHook } from 'modules/custom/useFirebaseHook'
import { useFetchUserProfileMutation } from 'modules/User/hook'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

const schema = yup.object().shape({
  password: yup.string().required('Wajib diisi'),
})

function LoginContent({ phoneNumber, setStep, setScenario, setShowModal }) {
  const [showPassword, setShowPassword] = useState(false)

  const mutation = useLoginUser()
  const mutationProfile = useFetchUserProfileMutation()

  const { redirectSuccessAuth } = useFirebaseHook()

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
    },
  })

  const handleSubmitForm = (data) => {
    const replaceCountryCode = phoneNumber.replace(/^0/, '+62')
    mutation.mutate(
      { phone: replaceCountryCode, password: data.password },
      {
        onSuccess: (result) => {
          if (result.length === 0 || Object.keys(result).length === 0) {
            setError('password', {
              type: 'server',
              message: 'Kata Sandi Salah',
            })
          } else {
            if (result.token === 0 || Object.keys(result.token).length === 0) {
              setError('password', {
                type: 'server',
                message: 'Kata Sandi Salah',
              })
            } else {
              localStorage.setItem('token', result.token)
              mutationProfile.mutate(
                {},
                {
                  onSuccess: (results) => {
                    if (results.lat !== 0 && results.lon !== 0) {
                      let tmpObj = {
                        lat: results.lat,
                        lng: results.lon,
                        formattedAddress: results.delivery_address,
                        detailLocation: results.detail_location,
                        city: results.city,
                        locationPin: results.location_pin,
                      }
                      if (
                        window.location.pathname.includes('/detail-mitra') ||
                        window.location.pathname.includes('/cart')
                      ) {
                        undefined
                      } else {
                        localStorage.setItem(
                          'dataAddress',
                          JSON.stringify(tmpObj)
                        )
                      }
                    }
                    setShowModal && setShowModal(false)
                    redirectSuccessAuth()
                  },
                }
              )
            }
          }
        },
      }
    )
  }

  return (
    <div className="flex flex-col w-full">
      <h4 className="text-lg text-black font-semibold mb-1">Masuk/Daftar</h4>
      <span className="text-sm text-black leading-6">
        Rasakan Cita Rasa Istimewa
        <br />
        mulai dari sini
      </span>

      <Form>
        <div className="mt-12 mb-9">
          <div className="flex justify-between items-center my-5">
            <div className="w-full">
              <span className="text-xs text-[#9D9D9D]">Nomor Handphone</span>
              <div className="text-sm text-black font-medium">
                {phoneNumber}
              </div>
            </div>
            <div className="cursor-pointer" onClick={() => setStep('phone')}>
              <img src={EditIcon} className="w-6 h-6" />
            </div>
          </div>
        </div>

        <div className="mt-12 mb-10">
          <div className="hidden">
            <Input type="text" name="username" defaultValue={phoneNumber} />
          </div>
          <Controller
            name="password"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                {...field}
                type={!showPassword ? 'password' : 'text'}
                label="Kata Sandi"
                name="katasandi"
                autoComplete="off"
                iconAppend={
                  <div
                    className="cursor-pointer"
                    onClick={() => setShowPassword((prevValue) => !prevValue)}
                  >
                    {!showPassword ? (
                      <IoEyeOffOutline className="w-full h-full text-[#9D9D9D]" />
                    ) : (
                      <IoEyeOutline className="w-full h-full text-[#9D9D9D]" />
                    )}
                  </div>
                }
                contentBottom={
                  <span
                    className="text-primary text-[10px] float-left mt-1 cursor-pointer"
                    onClick={() => {
                      setStep('forgot-password')
                      setScenario('forgot-password')
                    }}
                  >
                    Lupa sandi?
                  </span>
                }
                error={errors.password && errors.password.message}
              />
            )}
          />
        </div>

        <LoadingButton
          className="!px-2 md:!px-3 !py-3 font-semibold !w-full"
          isRounded={true}
          isLoading={mutation.isLoading}
          onClick={handleSubmit(handleSubmitForm)}
        >
          Selanjutnya
        </LoadingButton>
      </Form>

      <div className="mt-7 text-center">
        <div className="text-[10px] leading-3 text-black">
          Dengan masuk atau daftar, Kamu menyetujui
        </div>
        <div className="text-[10px] leading-3 text-black mt-1">
          <Link to="/tnc" className="text-primary">
            Ketentuan Layanan
          </Link>{' '}
          dan{' '}
          <Link to="/privacy-policy" className="text-primary">
            Kebijakan Privasi
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoginContent
