import React from 'react'
import { FaChevronRight } from 'react-icons/fa'

function WorkflowItem({ imgSrc, title, subtitle }) {
  return (
    <div className="w-3/12 relative">
      <div className="flex justify-center items-center flex-col">
        <div className="w-[142px] h-[101px]">
          <img src={imgSrc} className="m-auto" />
        </div>
        <h5 className="text-lg font-semibold text-center mt-12">{title}</h5>
        <p className="text-center mt-6">{subtitle}</p>
      </div>
      {title != 'Pengiriman' && (
        <FaChevronRight className="absolute top-1/2 -right-[6px] -translate-y-2/4 text-primary w-6 h-6 font-bold" />
      )}
    </div>
  )
}

export default WorkflowItem
