import React, { useState } from 'react'
import { BiMinus, BiPlus, BiTrash } from 'react-icons/bi'
import { currency } from 'utils/formatNumber'

const MenuList = ({
  qty,
  key,
  price,
  name,
  qty_unit_name,
  free_shipping_cost,
  available,
  min_order,
  quantity,
  setQuantity,
  addProduct,
  updateQuantity,
  removeProduct,
  fulfilled,
}) => {
  const [editQty, setEditQty] = useState(false)

  const editQuantity = () => {
    setQuantity(qty)
    setEditQty(true)
  }

  const sendQuantity = () => {
    updateQuantity()
    setEditQty(false)
  }
  return (
    <React.Fragment>
      <div key={key} className="flex flex-col gap-3 py-2">
        <div className="flex justify-between gap-3">
          <div className="col-span-3 flex flex-col">
            <div className="flex items-center flex-wrap gap-x-2 w-40">
              <h4 className="text-sm truncate">{name}</h4>
              <p className="text-[10px] text-gray-400 truncate">
                (min {min_order})
              </p>
            </div>
            <p className="text-[11px]">
              {currency(price)}{' '}
              <span className="text-gray-400">per {qty_unit_name}</span>
              {free_shipping_cost > 0 && available && !fulfilled && (
                <span className="text-gray-400 ml-1">
                  (Gratis Ongkir, {free_shipping_cost} {qty_unit_name})
                </span>
              )}
            </p>
            {!available && (
              <p className="text-[10px] text-primary flex-shrink-0 tracking-normal">
                Menu tidak tersedia pada tanggal ini
              </p>
            )}
          </div>
          <div className="flex justify-between items-center w-20 gap-2">
            <button
              onClick={removeProduct}
              className={
                qty !== min_order
                  ? `text-center border-2 h-5 rounded-full ${
                      !available
                        ? 'text-gray-400 border-gray-400 pointer-events-none'
                        : 'text-primary  border-primary'
                    }`
                  : 'text-primary border-primary'
              }
            >
              {qty === min_order ? (
                <BiTrash className="w-6 h-6" />
              ) : (
                <BiMinus className="w-4 h-3" />
              )}
            </button>
            {editQty ? (
              <input
                autoFocus
                value={quantity}
                type="number"
                onChange={(e) => setQuantity(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendQuantity()}
                onBlur={sendQuantity}
                className="w-full h-auto align-top flex text-center text-sm font-semibold outline-none"
              />
            ) : (
              <p
                onClick={() => editQuantity()}
                className="text-sm font-semibold"
              >
                {qty}
              </p>
            )}
            <button
              onClick={addProduct}
              className={`border-2 h-5 rounded-full ${
                !available
                  ? 'text-gray-400 border-gray-400 pointer-events-none'
                  : 'text-primary  border-primary'
              }`}
            >
              <BiPlus className="w-4 h-3" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MenuList
