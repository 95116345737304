/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Tentang = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="tentang">
      <div className="text-lg md:text-xl font-semibold text-center leading-6 my-6">
        Kebijakan Privasi KendiXpress<sup>i</sup>
      </div>
      <div className="text-sm leading-6 my-2">
        <div>
          Kebijakan Privasi adalah metode proses pengumpulan, penggunaan,
          penyimpanan, penyingkapan, pengolahan dan perlindungan terhadap
          informasi pribadi yang Pemesan berikan sehubungan dengan layanan
          KendiXpress melalui situs web kendiXpress.com (“<strong>Situs</strong>
          ”). Informasi pribadi tersebut merupakan data tentang seorang individu
          yang dapat diidentifikasi dari data tersebut, atau dari data dan
          informasi lainnya yang aksesnya dimiliki atau mungkin dimiliki
          organisasi (“<strong>Data Pribadi</strong>”). Dengan mengunjungi atau
          menggunakan Situs, Pemesan sepakat dan menyetujui Kebijakan Privasi
          ini.
        </div>
      </div>
    </TabContent>
  )
})

export default Tentang
