import React, { useEffect, useState } from 'react'

import LoadMore from './fragments/LoadMore'
import MenuItem from './fragments/MenuItem'
import MenuWrapper from './MenuWrapper'
import PopupMenu from './PopupMenu'

const ListKatalog = ({
  menus,
  addProduct,
  removeProduct,
  id,
  cart,
  setCart,
  date1,
  updateQuantity,
  time,
}) => {
  const [loadMore, setLoadMore] = useState(10)
  const [isButton, setIsButton] = useState(false)
  const [open, setOpen] = useState(false)
  const [popup, setPopup] = useState('')

  const handlePopup = (menu) => {
    setPopup(menu)
    setOpen(true)
  }

  const handleMore = () => {
    setLoadMore(loadMore + 10)
  }

  // check condition menus length
  useEffect(() => {
    if (menus) {
      menus.length > loadMore ? setIsButton(true) : setIsButton(false)
    }
  }, [loadMore, menus])
  return (
    <MenuWrapper id={id} menu={menus}>
      <div className="flex flex-col gap-6">
        {menus.map((menu, key) => (
          <MenuItem
            key={key}
            menu={menu}
            cart={cart}
            setCart={setCart}
            addProduct={addProduct}
            updateQuantity={updateQuantity}
            removeProduct={removeProduct}
            // changeMitra={changeMitra}
            date1={date1}
            time={time}
            popup={handlePopup}
          />
        ))}
      </div>
      {isButton && <LoadMore click={handleMore} />}
      <PopupMenu show={open} setShow={setOpen} menu={popup} />
    </MenuWrapper>
  )
}

export default ListKatalog
