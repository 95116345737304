import { useMutation, useQuery } from 'react-query'

import { fetchLatestPrice, fetchListMitra, fetchMenuRecommend } from './api'

export const useFetchListMitra = (lat, long, type, search) => {
  return useQuery(['list-mitra', lat, long, type, search], () =>
    fetchListMitra(lat, long, type, search)
  )
}

export const useListMitraMutation = () => {
  return useMutation(({ lat, long, type, search }) =>
    fetchListMitra(lat, long, type, search)
  )
}

export const useFetchLatestPrice = () => {
  return useQuery(['latest-price'], () => {
    fetchLatestPrice()
  })
}

export const useFetchMenuRecommend = (lat, long, price) => {
  return useQuery(['menu-recommend', lat, long, price], () =>
    fetchMenuRecommend(lat, long, price)
  )
}
