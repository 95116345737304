import md5 from 'blueimp-md5'
import $axios from 'utils/axios'

export const fetchPhoneExist = async (params) => {
  const result = await $axios.get('/user/phone/exists', { params })
  return result.data.result
}

export const registerUser = async (data) => {
  const result = await $axios.post('/user/register', data)
  return result.data.result
}

export const validateManualUser = async (params) => {
  const result = await $axios.get('/user/manual-validate', { params })
  return result.data.result
}

export const loginUser = async (data) => {
  const result = await $axios.post('/user/login', data)
  return result.data.result
}

export const createPasswordUser = async (data) => {
  const hashMd5 = md5(
    data.phone
      .concat(data.password)
      .concat(process.env.REACT_APP_PWD_SECRET_TOKEN)
  )
  const result = await $axios.put('/user/password', data, {
    headers: { 'x-api-key': hashMd5 },
  })
  return result.data.result
}
