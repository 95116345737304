import React, { useRef, useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { setIdTimePicker } from 'utils/date'

const DesktopVersion = ({ times, setShippingTime, shippingTime }) => {
  const scrl = useRef(null)
  const [scrollY, setscrollY] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)

  const [id, setId] = useState(sessionStorage.getItem('scroll') || '09.00')

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollTop += shift
    setscrollY(scrollY + shift)

    if (
      Math.floor(scrl.current.scrollHeight - scrl.current.scrollTop) <=
      scrl.current.offsetHeight
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  // check scroll
  const scrollCheck = () => {
    setscrollY(scrl.current.scrollTop)
    if (
      Math.floor(scrl.current.scrollHeight - scrl.current.scrollTop) <=
      scrl.current.offsetHeight
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  React.useEffect(() => {
    const el = document.getElementById(id)
    el.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }, [])

  return (
    <div className="hidden md:block">
      <div className="w-fit flex-col flex m-auto">
        <div className="border-t border-b text-base font-medium p-4 px-10">
          Waktu Tiba
        </div>
        <div className="flex justify-center gap-4 items-center py-2 flex-row md:flex-col">
          <button
            onClick={() => slide(-100)}
            className={`${
              scrollY === 0
                ? 'text-gray-400 pointer-events-none cursor-not-allowed'
                : 'text-primary'
            }`}
          >
            <IoIosArrowUp size={30} />
          </button>
          <div
            ref={scrl}
            onScroll={scrollCheck}
            className="h-60 overflow-y-scroll scroll-smooth no-scrollbar flex flex-col gap-1"
          >
            {times.map((time, index) => (
              <div
                id={time}
                key={index}
                onClick={() => {
                  sessionStorage.setItem('scroll', setIdTimePicker(time))
                  setId(setIdTimePicker(time))
                  setShippingTime(time)
                }}
                className={`py-3 rounded-lg px-6 flex items-center justify-center cursor-pointer text-sm ${
                  shippingTime == time
                    ? 'bg-primary text-white'
                    : 'opacity-80 text-black'
                }`}
              >
                {time}
              </div>
            ))}
          </div>
          <button
            onClick={() => slide(+100)}
            className={`${
              scrolEnd ? 'text-gray-400 pointer-events-none' : 'text-primary'
            }`}
          >
            <IoIosArrowDown size={30} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default DesktopVersion
