/* eslint-disable no-unused-vars */
import Loading from 'components/layouts/fragments/Loading'
import MicrositeLayout from 'components/layouts/MicrositeLayout'
import { useMicrositeCartSystem } from 'modules/custom/microsite/useCartSystem'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import {
  useFetchMenuCategory,
  useFetchPermanentMenuList,
} from 'modules/Microsite/hook'
import React, { useEffect, useRef, useState } from 'react'

import Cart from './components/Cart'
import DateTime from './components/DateTime'
import OverlayCart from './components/fragments/OverlayCart'
import TabItem from './components/fragments/TabItem'
import ListKatalog from './components/ListKatalog'

const Catalog = () => {
  const [active, setActive] = useState(0)
  const menuCategory = useFetchMenuCategory()
  const [selectedCategoryId, setSelectedCategoryId] = useState()
  const tabContainerRef = useRef(null)

  useEffect(() => {
    if (menuCategory.data?.data?.length > 0) {
      setSelectedCategoryId(menuCategory.data.data[0].secure_id)
    }
  }, [menuCategory.data])

  const { loading } = useGoogleMapHook()
  const userData = JSON.parse(localStorage.getItem('data-user'))
  const menuList = useFetchPermanentMenuList(
    selectedCategoryId,
    userData.lat,
    userData.long
  )

  const {
    cart,
    setCart,
    dateParams,
    setDateParams,
    datePick,
    grandTotal,
    setDatePick,
    dateTime,
    time,
    setTime,
    addProduct,
    updateQuantity,
    removeProduct,
    removeByDate,
    changeDateCart,
    changeTimeCart,
    loadingCart,
  } = useMicrositeCartSystem()

  const handleClick = (id, index) => {
    setSelectedCategoryId(id)
    setActive(index)
  }

  const handleScroll = (direction) => {
    if (tabContainerRef.current) {
      const container = tabContainerRef.current
      const scrollAmount =
        direction === 'right'
          ? container.scrollLeft + container.clientWidth
          : container.scrollLeft - container.clientWidth

      container.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  return (
    <React.Fragment>
      {menuCategory.isLoading ||
      menuList.isLoading ||
      loading ||
      loadingCart ? (
        <div className="w-screen flex items-center h-screen">
          <Loading />
        </div>
      ) : (
        <MicrositeLayout>
          <div className="grid grid-cols-1 lg:grid-cols-8 gap-16 md:container">
            <div className="lg:col-span-5">
              <div className="top-0 bg-white">
                <DateTime
                  defaultDate={datePick}
                  setDateParams={setDateParams}
                  date={datePick}
                  setDate={setDatePick}
                  time={time}
                  setTime={setTime}
                />
                <div className="relative w-full border-b lg:flex">
                  <button
                    onClick={() => handleScroll('left')}
                    className="bg-white py-3 px-5 hidden lg:block"
                  >
                    ←
                  </button>
                  <div
                    ref={tabContainerRef}
                    className="flex justify-start text-xs md:text-sm w-full text-gray-400 font-normal no-scrollbar overflow-x-auto"
                  >
                    {menuCategory.data.data.length > 0 &&
                      menuCategory.data.data?.map((category, key) => (
                        <TabItem
                          id={() => handleClick(category.secure_id, key)}
                          active={active}
                          setActive={setActive}
                          index={key}
                          key={key}
                        >
                          {category.category_name}
                        </TabItem>
                      ))}
                  </div>
                  <button
                    onClick={() => handleScroll('right')}
                    className="bg-white py-3 px-5 hidden lg:block"
                  >
                    →
                  </button>
                  {/* </div> */}
                </div>
              </div>
              {/* list katalog */}
              {menuList?.data?.length > 0 ? (
                <ListKatalog
                  menus={menuList?.data}
                  addProduct={addProduct}
                  updateQuantity={updateQuantity}
                  removeProduct={removeProduct}
                  cart={cart}
                  setCart={setCart}
                  date1={dateTime}
                  time={time}
                />
              ) : (
                <section className="flex justify-center align-middle p-10">
                  Menu belum tersedia
                </section>
              )}
            </div>
            <div className="lg:col-span-3 hidden lg:block">
              <Cart
                cart={cart}
                defaultDate={datePick}
                setDatePick={setDatePick}
                dateParams={dateParams}
                setDateParam={setDateParams}
                updateQuantity={updateQuantity}
                time={time}
                setTime={setTime}
                addProduct={addProduct}
                removeProduct={removeProduct}
                removeByDate={removeByDate}
                changeDateCart={changeDateCart}
                changeTimeCart={changeTimeCart}
                grandTotal={grandTotal}
              />
            </div>
          </div>
          {cart.length > 0 && (
            <OverlayCart cart={cart} grandTotal={grandTotal} />
          )}
        </MicrositeLayout>
      )}
      {cart.length > 0 && <div className="h-10 md:h-0"></div>}
    </React.Fragment>
  )
}

export default Catalog
