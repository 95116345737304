import { filterNested } from 'array-nested-filter'
import { fetchMenuExist } from 'modules/Mitra/api'
import {
  useMenuAndalanMutations,
  useMenuHarianMutations,
  useMenuTambahanMutations,
} from 'modules/Mitra/hooks'
import { useEffect, useState } from 'react'
import { useImmer } from 'use-immer'

export const useCartSystem = () => {
  const [loadingCart, setLoadingCart] = useState(false)
  const [cart, setCart] = useImmer(
    JSON.parse(localStorage.getItem('cart')) || []
  )
  const [time, setTime] = useState(
    JSON.parse(sessionStorage.getItem('time')) || '10.00'
  )
  const [partner, setPartner] = useState(
    JSON.parse(localStorage.getItem('partner')) || null
  )
  const [minOrderMitra, setMinOrderMitra] = useState('')
  const [typeMitra, setTypeMitra] = useState('')

  let defaultParam = new Date()
  if (new Date().getHours() >= 14 && new Date().getMinutes() >= 1) {
    defaultParam.setDate(defaultParam.getDate() + 2)
  } else {
    defaultParam.setDate(defaultParam.getDate() + 1)
  }
  const [dateParams, setDateParams] = useState(
    JSON.parse(sessionStorage.getItem('dateparams')) || defaultParam
  )

  let defaultDate = new Date().toDateString()
  defaultDate = new Date(defaultDate)
  if (new Date().getHours() >= 14 && new Date().getMinutes() >= 1) {
    defaultDate.setDate(defaultDate.getDate() + 2)
  } else {
    defaultDate.setDate(defaultDate.getDate() + 1)
  }

  defaultDate = Date.parse(defaultDate)
  const [datePick, setDatePick] = useState(
    JSON.parse(sessionStorage.getItem('date')) || defaultDate
  )
  const dateConvert = new Date(datePick).toDateString()
  const dateTime = Date.parse(dateConvert)

  const [grandTotal, setGrandTotal] = useState(
    JSON.parse(localStorage.getItem('price')) || 0
  )
  const [dateChange, setDateChange] = useState(0)
  const [dateCheck, setDateCheck] = useState([])

  useEffect(() => {
    sessionStorage.setItem('time', JSON.stringify('10.00'))
  }, [])

  const shippingDate = (data) => {
    const map = data.map((menu) => menu.shipping_cost)
    return Math.min(...map)
  }

  // calculate grandtotal
  const calculateTotal = (cart) => {
    const subtotal = cart.map((date) =>
      date.menus.map((menu) => menu.sub_total)
    )
    const subtotalflat = subtotal.flatMap((item) => item)
    const shipping = cart
      .map((data) => data.shipping_cost)
      .reduce((total, a) => total + a, 0)
    const total = subtotalflat.reduce((total, b) => total + b, 0)
    const totalOrder = total + shipping
    setGrandTotal(totalOrder)
    localStorage.setItem('price', totalOrder)
    localStorage.setItem('ongkir', shipping)
  }

  // subtotal per date
  const sumPerDate = (cart) => {
    const subtotal = cart.menus.map((menu) => menu.sub_total)
    const total = subtotal.reduce((total, a) => total + a, 0)
    return total
  }

  // qty per date
  const qtyPerDate = (cart) => {
    const subtotal = cart.menus.map((menu) => menu.qty)
    const total = subtotal.reduce((total, a) => total + a, 0)
    return total
  }
  // add product
  const addProduct = (product, partner, dateMenu, timeMenu) => {
    const partnerActive = {
      partner_id: partner.partner_id,
      name: partner.name,
      partner_type_id: partner.partner_type_id,
      info_min_order: partner.info_min_order,
    }
    setPartner(partnerActive)
    localStorage.setItem('partner', JSON.stringify(partnerActive))
    setCart((draftCart) => {
      const dateIndex = cart.findIndex(
        (data) => data.date === dateMenu && data.shipping_time === timeMenu
      )
      if (dateIndex !== -1) {
        const findMenu = draftCart[dateIndex]?.menus.findIndex(
          (item) => item.menu_id === product.menu_id
        )
        if (findMenu >= 0) {
          draftCart[dateIndex].menus.map((item) => {
            if (item.menu_id === product.menu_id) {
              item.qty += 1
              item.shipping_cost =
                item.shipping_cost_array.length === 0
                  ? 0
                  : item.qty >= item.free_shipping_cost &&
                    item.free_shipping_cost !== 0
                  ? 0
                  : item.shipping_cost_array.length == 1
                  ? item.shipping_cost_array[0].cost
                  : item.shipping_cost_array.filter((data) => {
                      if (data.qty_fr <= item.qty && item.qty <= data.qty_to) {
                        return data.cost
                      }
                    })[0].cost
              item.disable_up =
                item.shipping_cost_array.length === 0
                  ? undefined
                  : item.shipping_cost_array.filter((data) => {
                      if (
                        data.qty_fr <= item.qty + 1 &&
                        1 + item.qty <= data.qty_to
                      ) {
                        return data
                      }
                    })
            }
            item.sub_total = item.qty * item.price
          })
        } else {
          // append menu
          const newMenu = {
            available: true,
            menu_id: product.menu_id,
            type: product.type,
            is_main_menu: product.is_main_menu,
            name: product.name,
            price: product.price,
            free_shipping_cost: product.free_shipping_cost,
            shipping_cost_array: product.shipping_cost,
            disable_up: null,
            shipping_cost:
              product.shipping_cost.length > 0
                ? product.shipping_cost[0].cost
                : 0,
            min_order: product.min_order,
            max_order: product.max_order,
            qty: product.min_order == 0 ? 1 : product.min_order,
            qty_unit_id: product.qty_unit_id,
            qty_unit_name: product.qty_unit_name,
            sub_total:
              product.min_order !== 0
                ? product.min_order * product.price
                : product.price * 1,
          }
          draftCart[dateIndex].menus.push(newMenu)
        }
        draftCart[dateIndex].sub_total = sumPerDate(draftCart[dateIndex])
        draftCart[dateIndex].qty_total = qtyPerDate(draftCart[dateIndex])
        draftCart[dateIndex].shipping_cost = shippingDate(
          draftCart[dateIndex].menus
        )
        calculateTotal(draftCart)
        localStorage.setItem('cart', JSON.stringify(draftCart))
      } else {
        // add cart
        const newDate = {
          date: dateMenu,
          sub_total:
            product.min_order !== 0
              ? product.min_order * product.price
              : product.price * 1,
          qty_total: product.min_order == 0 ? 1 : product.min_order,
          shipping_time: timeMenu,
          shipping_cost:
            product.shipping_cost.length > 0
              ? product.shipping_cost[0].cost
              : 0,
          menus: [
            {
              available: true,
              menu_id: product.menu_id,
              type: product.type,
              is_main_menu: product.is_main_menu,
              name: product.name,
              price: product.price,
              free_shipping_cost: product.free_shipping_cost,
              min_order: product.min_order,
              max_order: product.max_order,
              shipping_cost_array: product.shipping_cost,
              disable_up: null,
              shipping_cost:
                product.shipping_cost.length > 0
                  ? product.shipping_cost[0].cost
                  : 0,
              qty: product.min_order == 0 ? 1 : product.min_order,
              qty_unit_id: product.qty_unit_id,
              qty_unit_name: product.qty_unit_name,
              sub_total:
                product.min_order !== 0
                  ? product.min_order * product.price
                  : product.price * 1,
            },
          ],
        }
        draftCart.push(newDate)
        calculateTotal(draftCart)
        localStorage.setItem('cart', JSON.stringify(draftCart))
      }
    })
  }

  // change Mitra
  const changeMitra = (product, partner, dateMenu, timeMenu) => {
    const partnerActive = {
      partner_id: partner.partner_id,
      name: partner.name,
      partner_type_id: partner.partner_type_id,
      info_min_order: partner.info_min_order,
    }
    setPartner(partnerActive)
    localStorage.setItem('partner', JSON.stringify(partnerActive))
    setCart((draftCart) => {
      const newDate = {
        date: dateMenu,
        sub_total:
          product.min_order !== 0
            ? product.min_order * product.price
            : product.price * 1,
        qty_total: product.min_order == 0 ? 1 : product.min_order,
        shipping_time: timeMenu,
        shipping_cost:
          product.shipping_cost.length > 0 ? product.shipping_cost[0].cost : 0,
        menus: [
          {
            available: true,
            menu_id: product.menu_id,
            type: product.type,
            is_main_menu: product.is_main_menu,
            name: product.name,
            price: product.price,
            free_shipping_cost: product.free_shipping_cost,
            min_order: product.min_order,
            max_order: product.max_order,
            shipping_cost_array: product.shipping_cost,
            disable_up: null,
            shipping_cost:
              product.shipping_cost.length > 0
                ? product.shipping_cost[0].cost
                : 0,
            qty: product.min_order == 0 ? 1 : product.min_order,
            qty_unit_id: product.qty_unit_id,
            qty_unit_name: product.qty_unit_name,
            sub_total:
              product.min_order !== 0
                ? product.min_order * product.price
                : product.price * 1,
          },
        ],
      }
      draftCart.push(newDate)
      localStorage.setItem('cart', JSON.stringify(draftCart))
      calculateTotal(draftCart)
    })
  }

  // add manual quantity
  const updateQuantity = (product, dateMenu, timeMenu, value) => {
    setCart((draftCart) => {
      const dateIndex = cart.findIndex(
        (data) => data.date === dateMenu && data.shipping_time === timeMenu
      )
      if (dateIndex !== -1) {
        const findMenu = draftCart[dateIndex]?.menus.findIndex(
          (item) => item.menu_id === product.menu_id
        )
        if (findMenu !== -1) {
          draftCart[dateIndex].menus.map((item) => {
            if (item.menu_id === product.menu_id) {
              const checkavail =
                item.shipping_cost_array.length == 0
                  ? undefined
                  : item.shipping_cost_array.findIndex(
                      (data) => data.qty_fr <= value && value <= data.qty_to
                    )
              if (
                value >= product.min_order &&
                value <= product.max_order &&
                checkavail !== -1 &&
                value != 0 &&
                value.length > 0
              ) {
                item.qty = Number(value)
              } else {
                item.qty
              }
              item.shipping_cost =
                item.shipping_cost_array.length === 0
                  ? 0
                  : item.qty >= item.free_shipping_cost &&
                    item.free_shipping_cost !== 0
                  ? 0
                  : item.shipping_cost_array.length == 1
                  ? item.shipping_cost_array[0].cost
                  : item.shipping_cost_array.filter((data) => {
                      if (data.qty_fr <= item.qty && item.qty <= data.qty_to) {
                        return data.cost
                      }
                    })[0]?.cost
              item.disable_up =
                item.shipping_cost_array.length === 0
                  ? undefined
                  : item.shipping_cost_array.filter((data) => {
                      if (
                        data.qty_fr <= item.qty + 1 &&
                        1 + item.qty <= data.qty_to
                      ) {
                        return data
                      }
                    })
            }
            item.sub_total = item.qty * item.price
          })
        }
      }
      draftCart[dateIndex].sub_total = sumPerDate(draftCart[dateIndex])
      draftCart[dateIndex].qty_total = qtyPerDate(draftCart[dateIndex])
      draftCart[dateIndex].shipping_cost = shippingDate(
        draftCart[dateIndex].menus
      )
      calculateTotal(draftCart)
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
  }

  // delete product in cart
  const removeProduct = (product, dateMenu, timeMenu) => {
    setDateCheck([])
    setCart((draftCart) => {
      const dateIndex = cart.findIndex(
        (data) => data.date === dateMenu && data.shipping_time === timeMenu
      )
      if (dateIndex !== -1) {
        const findMenu = draftCart[dateIndex].menus.findIndex(
          (x) => x.menu_id === product.menu_id
        )
        if (findMenu !== -1) {
          draftCart[dateIndex].menus.map((item) => {
            if (item.menu_id === product.menu_id) {
              if (item.qty > product.min_order && item.qty > 1) {
                item.qty -= 1
                item.shipping_cost =
                  item.shipping_cost_array.length === 0
                    ? 0
                    : item.qty >= item.free_shipping_cost &&
                      item.free_shipping_cost !== 0
                    ? 0
                    : item.shipping_cost_array.length == 1
                    ? item.shipping_cost_array[0].cost
                    : item.shipping_cost_array.filter((data) => {
                        if (
                          data.qty_fr <= item.qty &&
                          item.qty <= data.qty_to
                        ) {
                          return data.cost
                        }
                      }) !== null
                    ? item.shipping_cost_array.filter((data) => {
                        if (
                          data.qty_fr <= item.qty &&
                          item.qty <= data.qty_to
                        ) {
                          return data.cost
                        }
                      })[0]?.cost
                    : item.shipping_cost_array[
                        item.shipping_cost_array.length - 1
                      ].cost

                item.disable_up =
                  item.shipping_cost_array.length === 0
                    ? undefined
                    : item.shipping_cost_array.filter((data) => {
                        if (
                          data.qty_fr <= item.qty + 1 &&
                          1 + item.qty <= data.qty_to
                        ) {
                          return data
                        }
                      })
              } else {
                if (cart[dateIndex].menus.length === 1) {
                  draftCart.splice(dateIndex, 1)
                } else {
                  draftCart[dateIndex].menus.splice(findMenu, 1)
                }
              }
              item.sub_total = item.qty * item.price
            }
          })
          if (draftCart.length > 0) {
            if (draftCart[dateIndex]) {
              draftCart[dateIndex].sub_total = sumPerDate(draftCart[dateIndex])
              draftCart[dateIndex].qty_total = qtyPerDate(draftCart[dateIndex])
              draftCart[dateIndex].shipping_cost = shippingDate(
                draftCart[dateIndex].menus
              )
            }
          }
        }
      }
      calculateTotal(draftCart)
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
  }

  // remove by Date
  const removeByDate = (date, time) => {
    setDateCheck([])
    setCart((draftCart) => {
      const dateIndex = draftCart.findIndex(
        (data) => data.date === date && data.shipping_time === time
      )
      draftCart.splice(dateIndex, 1)
      calculateTotal(draftCart)
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
  }

  // change date cart
  const changeDateCart = (date, time, value) => {
    setLoadingCart(true)
    if (date === value) return setLoadingCart(false)
    const dateGMT = new Date(value).setHours(new Date(value).getHours() + 7)
    setCart((draftCart) => {
      const dateIndex = draftCart.findIndex(
        (data) => data.date === date && data.shipping_time === time
      )
      const dateValue = draftCart.findIndex(
        (data) => data.date === value && data.shipping_time === time
      )
      draftCart[dateIndex].date = value

      let item = []
      for (let i = 0; i < draftCart[dateIndex].menus.length; i++) {
        fetchMenuExist(
          draftCart[dateIndex].menus[i].menu_id,
          dateGMT / 1000
        ).then((result) => {
          item.push({
            index: cart[dateIndex].menus[i].menu_id,
            avail: result.result.data,
          })
        })
      }
      setDateCheck(item)

      if (dateValue !== -1) {
        let newMenu = cart[dateIndex].menus
        for (let i = 0; i < cart[dateValue].menus.length; i++) {
          for (let a = 0; a < newMenu.length; a++) {
            if (cart[dateValue].menus[i].menu_id === newMenu[a].menu_id) {
              if (
                draftCart[dateValue].menus[i].qty + newMenu[a].qty <=
                draftCart[dateValue].menus[i].max_order
              ) {
                draftCart[dateValue].menus[i].qty += newMenu[a].qty
              } else {
                draftCart[dateValue].menus[i].qty = newMenu[a].max_order
              }
              const deleteMenu = newMenu[a]
              newMenu = newMenu.filter(
                (item) => item.menu_id !== deleteMenu.menu_id
              )
            }
          }
        }
        draftCart[dateValue].menus.push(...newMenu)
        draftCart.splice(dateIndex, 1)
      }
      setDateChange(value)
    })
    setTimeout(() => {
      setLoadingCart(false)
    }, 500)
  }

  const handlePayment = (cart) => {
    const filteredCart = filterNested({
      array: cart,
      search: true,
      searchAttribute: 'available',
      childrenPath: 'menus',
    })
    localStorage.setItem('cart', JSON.stringify(filteredCart))
  }

  useEffect(() => {
    setTimeout(() => {
      if (dateCheck.length > 0) {
        setCart((draftCart) => {
          let dateChanges = cart.findIndex((data) => data.date === dateChange)
          for (let i = 0; i < draftCart[dateChanges].menus.length; i++) {
            for (let a = 0; a < dateCheck.length; a++) {
              if (
                dateCheck[a].index === draftCart[dateChanges].menus[i].menu_id
              ) {
                draftCart[dateChanges].menus[i].available = dateCheck[a].avail
              }
            }
          }
          localStorage.setItem('cart', JSON.stringify(draftCart))
        })
      }
    }, 800)

    setTimeout(() => {
      setDateCheck([])
    }, 800)
  }, [cart, dateChange])

  // change shipping_time cart
  const changeTimeCart = (date, time, value) => {
    setCart((draftCart) => {
      const dateIndex = draftCart.findIndex(
        (data) => data.date === date && data.shipping_time === time
      )
      draftCart[dateIndex].shipping_time = value
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
  }

  // change address and shipping cost
  const andalan = useMenuAndalanMutations()
  const harian = useMenuHarianMutations()
  const tambahan = useMenuTambahanMutations()

  const changeAddress = (lat, lon) => {
    andalan.mutate(
      { lat, lon, partner: partner.partner_id, date: dateParams / 1000 },
      {
        onSuccess: (data) => {
          sessionStorage.setItem('andalanTmp', JSON.stringify(data.result.data))
        },
      }
    )
    harian.mutate(
      { lat, lon, partner: partner.partner_id, date: dateParams / 1000 },
      {
        onSuccess: (data) => {
          sessionStorage.setItem('harianTmp', JSON.stringify(data.result.data))
        },
      }
    )
    tambahan.mutate(
      { lat, lon, partner: partner.partner_id, date: dateParams / 1000 },
      {
        onSuccess: (data) => {
          sessionStorage.setItem(
            'tambahanTmp',
            JSON.stringify(data.result.data)
          )
        },
      }
    )
  }

  const adjustShippingCost = (data) => {
    setCart((draftCart) => {
      for (let index = 0; index < cart.length; index++) {
        for (let i = 0; i < cart[index].menus.length; i++) {
          for (let a = 0; a < data.length; a++) {
            if (cart[index].menus[i].menu_id === data[a].menu_id) {
              if (cart[index].menus[i].qty < data[a].min_order) {
                draftCart[index].menus[i].qty = data[a].min_order
                draftCart[index].menus[i].min_order = data[a].min_order
              }
              draftCart[index].menus[i].free_shipping_cost =
                data[a].free_shipping_cost
              draftCart[index].menus[i].shipping_cost_array =
                data[a].shipping_cost
              draftCart[index].menus[i].shipping_cost =
                data[a].shipping_cost.length === 0
                  ? 0
                  : draftCart[index].menus[i].qty >=
                      data[a].free_shipping_cost &&
                    data[a].free_shipping_cost !== 0
                  ? 0
                  : data[a].shipping_cost.length == 1
                  ? data[a].shipping_cost[0].cost
                  : data[a].shipping_cost.filter((item) => {
                      if (
                        item.qty_fr <= draftCart[index].menus[i].qty &&
                        draftCart[index].menus[i].qty <= item.qty_to
                      ) {
                        return item.cost
                      }
                    })[0]?.cost
            }
          }
        }
        draftCart[index].shipping_cost = shippingDate(draftCart[index].menus)
      }
      calculateTotal(draftCart)
      localStorage.setItem('cart', JSON.stringify(draftCart))
    })
  }

  return {
    partner,
    setPartner,
    minOrderMitra,
    setMinOrderMitra,
    typeMitra,
    setTypeMitra,
    dateParams,
    setDateParams,
    cart,
    setCart,
    datePick,
    setDatePick,
    dateTime,
    time,
    setTime,
    addProduct,
    updateQuantity,
    removeProduct,
    removeByDate,
    changeMitra,
    handlePayment,
    grandTotal,
    changeDateCart,
    changeTimeCart,
    calculateTotal,
    changeAddress,
    loadingCart,
    adjustShippingCost,
  }
}
