/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Definisi = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="definisi" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        A. Definisi
      </div>
      <div className="my-2">
        <List>
          <ListItem>
            <strong>
              “<em>Vendor</em>”
            </strong>{' '}
            berarti suatu badan usaha Indonesia maupun perorangan, yang
            melakukan Penjualan Makanan dan telah terdaftar untuk menyediakan
            makanan dan/atau minuman kepada Pemesan melalui KendiXpress.
          </ListItem>
          <ListItem>
            <strong>“Pemesan”</strong> atau <strong>“Anda”</strong>, berarti
            suatu individu dan/atau badan usaha Indonesia, baik berbentuk badan
            hukum maupun bukan, yang telah melakukan pemesanan makanan melalui
            KendiXpress.
          </ListItem>
          <ListItem>
            <strong>
              “<em>Force Majeure</em>”
            </strong>{' '}
            berarti keadaan yang berada di luar kontrol, tidak dapat dihindari
            meskipun dengan perencanaan yang baik dan tidak dapat diatasi dengan
            upaya yang wajar. Kejadian-kejadian yang dikategorikan sebagai
            keadaan memaksa.
          </ListItem>
          <ListItem>
            <strong>“KendiXpress”</strong> berarti program aplikasi tambahan
            yang dikembangkan oleh RAM yang bernama KendiXpress atau nama lain
            yang ditetapkan oleh RAM sewaktu-waktu, baik dalam bentuk{' '}
            <em>website</em> yaitu www.kendixpress.com atau aplikasi mobile
            yaitu KendiXPress berikut semua informasi, data, artikel, perangkat
            atau fitur yang terkandung atau dapat diakses di dalamnya untuk (i)
            mempertemukan
            <em>Vendor</em> dengan Pemesan, (ii) memfasilitasi Penjualan Makanan
            oleh <em>Vendor</em> kepada Pemesan dan/atau pemesanan makanan
            secara daring (dalam jaringan atau <em>online</em>).
          </ListItem>
          <ListItem>
            <strong>“Kerja Sama”</strong> berarti kerja sama antara RAM, Pemesan
            dan <em>Vendor</em> dalam hal penggunaan KendiXPress dan Penjualan
            Makanan sebagaimana diatur dalam Syarat dan Ketentuan KendiXpress.
          </ListItem>
          <ListItem>
            <strong>“Kualitas”</strong> berarti tingkat yang menunjukkan
            serangkaian karakteristik yang melekat dan memenuhi ukuran tertentu,
            termasuk namun tidak terbatas pada tingkat baik buruknya sesuatu,
            derajat atau taraf mutu.
          </ListItem>
          <ListItem>
            <strong>“Para Pihak”</strong> berarti RAM, Pemesan dan{' '}
            <em>Vendor</em> secara bersama-sama dan masing-masing disebut
            “Pihak”.
          </ListItem>
          <ListItem>
            <strong>“Penjualan Makanan”</strong> berarti penjualan makanan
            dan/atau minuman dengan atau tanpa peralatan, yang dilakukan oleh
            Vendor kepada Pemesan berdasarkan KendiXpress dan sesuai dengan
            Pesanan.
          </ListItem>
          <ListItem>
            <strong>“Pesanan”</strong> berarti makanan dan/atau minuman yang
            dipesan oleh Pemesan melalui KendiXpress yang menyatakan antara lain
            makanan dan/atau minuman yang dipesan, jumlah, tempat, dan tanggal
            pengantaran.
          </ListItem>
          <ListItem>
            <strong>“RAM”</strong> atau <strong>“Kami”</strong>, adalah PT
            Redkendi Andalan Mitra, perusahaan teknologi yang memiliki,
            mengelola, menyelenggarakan, dan menyediakan suatu portal termasuk
            diantaranya Redkendi dan KendiXPress.
          </ListItem>
          <ListItem>
            <strong>“Redkendi”</strong> berarti suatu layanan yang dimiliki oleh
            RAM, baik dalam bentuk <em>website</em> yaitu www.redkendi.com atau
            aplikasi <em>mobile</em> yaitu Redkendi dan/atau aplikasi apapun
            yang dirancang, dikembangkan, dan dikelola oleh RAM, berikut semua
            informasi, data, artikel, perangkat atau fitur yang terkandung atau
            dapat diakses di dalamnya termasuk <em>Online Catering System</em>{' '}
            untuk (i) mempertemukan <em>Caterer</em> dengan Pemesan, (ii)
            memfasilitasi Pelayanan Katering oleh <em>Caterer</em> kepada
            Pemesan dan/atau Pemesanan Katering secara daring (dalam jaringan
            atau <em>online</em>).
          </ListItem>
          <ListItem>
            <strong>“Rekening Pemesan”</strong> berarti rekening bank milik
            Pemesan yang tercatat pada saat melakukan pendaftaran (registrasi)
            dan digunakan sepenuhnya untuk keperluan transaksi Penjualan
            Makanan.
          </ListItem>
          <ListItem>
            <strong>“Rekening Pembayaran”</strong> berarti rekening bank{' '}
            <em>virtual account</em> dalam mata uang Rupiah yang dibuat oleh RAM
            atas nama Pemesan antara lain untuk (i) menerima pembayaran Tagihan
            (ii) melakukan pembayaran kepada <em>Vendor</em>, (iii) melakukan
            pembayaran <em>fee</em> KendiXpress, (iv) melakukan pembayaran
            lainnya kepada pihak-pihak sehubungan dengan Penjualan Makanan, atau
            (v) untuk kepentingan lainnya terkait Pesanan atau layanan-layanan
            lain terkait fitur-fitur yang tersedia atau akan disediakan oleh RAM
            dan/atau Afiliasi RAM di KendiXpress.
          </ListItem>
          <ListItem>
            <strong>
              “Tagihan <em>Vendor</em>”
            </strong>{' '}
            adalah tagihan tertulis yang diterbitkan oleh <em>Vendor</em>{' '}
            melalui KendiXpress kepada Pemesan sehubungan dengan Penjualan
            Makanan, yang memuat informasi antara lain mengenai jumlah biaya
            yang harus dibayar oleh Pemesan kepada <em>Vendor</em> dan Rekening
            Pembayaran sebagai satu-satunya rekening tujuan pembayaran. Tagihan{' '}
            <em>Vendor</em> terdiri dari harga makanan dan/atau minuman, dan
            biaya-biaya lainnya terkait Penjualan Makanan.
          </ListItem>
        </List>
      </div>
    </TabContent>
  )
})

export default Definisi
