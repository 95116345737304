/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Tautan = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="tautan">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        5. Tautan
      </div>
      <div className="my-2">
        Kami tidak memiliki kendali atas semua tautan yang menghubungkan dengan
        situs lain, serta tidak bertanggung jawab atas praktik privasi di situs
        tersebut.
      </div>
    </TabContent>
  )
})

export default Tautan
