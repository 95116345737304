import { useCartSystem } from 'modules/custom/useCartSystem'
import { useUniqueCodeMutations } from 'modules/Order/hook'
import React from 'react'

import CustomForm from '../../../components/forms/CustomForm'
import Dropdown from './fragments/Dropdown'
import Info from './fragments/Info'

const PaymentDetail = ({ user, setUser, errorPayment }) => {
  const { grandTotal } = useCartSystem()
  const uniqueMutations = useUniqueCodeMutations()

  const paymentTransfer = () => {
    uniqueMutations.mutate(
      {},
      {
        onSuccess: (data) => {
          setUser({
            ...user,
            payment_unique_code: data,
            payment_type: 2,
            payment_bank: 'BCA',
          })
        },
      }
    )
  }

  return (
    <CustomForm title="Detail Pembayaran" sideRequired={errorPayment}>
      <div className="flex flex-col gap-3">
        <div
          onClick={() => paymentTransfer()}
          className={`flex flex-col cursor-pointer gap-3 rounded-xl ${
            user.payment_type == 2 ? 'border-primary' : ''
          } border py-5 px-6`}
        >
          <div className="flex gap-3">
            <input
              type="radio"
              name="payment"
              value={user.payment_type}
              checked={user.payment_type == 2}
              onChange={() => paymentTransfer()}
              className="accent-primary cursor-pointer outline-primary w-5"
            />
            <div className="flex  flex-col">
              <div className="py-2 lg:py-3">
                <h4 className="text-sm font-semibold">Bank Transfer</h4>
                <p className="text-[10px]">Bank BCA</p>
              </div>
            </div>
          </div>
          {user.payment_bank !== '' && user.payment_type == 2 && (
            <Info border>
              <p>
                - Nomor rekening BCA akan diberi tau setelah Kamu melalui tahap
                konfirmasi pesanan
              </p>
              <p>
                - Pastikan nominal transfer sesuai hingga 3 digit angka terakhir
              </p>
              <p>
                - Pastikan kembali nominal pembayaran sesuai dengan pemesanan
                yang Kamu lakukan
              </p>
            </Info>
          )}
        </div>
        <div
          className={`flex flex-col gap-3 rounded-xl ${
            grandTotal < 375000
              ? 'pointer-events-none bg-gray-100 bg-opacity-60 border-gray-300'
              : ''
          } ${user.payment_type == 1 ? 'border-primary' : ''} border py-5 px-6`}
        >
          <div
            onClick={() =>
              setUser({
                ...user,
                payment_unique_code: 0,
                payment_type: 1,
                payment_bank: 'no account',
              })
            }
            className="flex cursor-pointer gap-3"
          >
            <input
              type="radio"
              name="payment"
              value={user.payment_type}
              checked={user.payment_type == 1}
              disabled={grandTotal < 375000 ? true : false}
              onChange={() =>
                setUser({
                  ...user,
                  payment_unique_code: 0,
                  payment_type: 1,
                  payment_bank: 'no account',
                })
              }
              className="accent-primary cursor-pointer border-primary outline-primary w-5"
            />
            <div
              className={`flex flex-col ${
                grandTotal < 375000 ? 'text-gray-400' : ''
              }`}
            >
              <div className="py-2 lg:py-3">
                <h4 className="text-sm font-semibold">Virtual Account</h4>
                <p className="text-[10px]">BCA, BNI, Mandiri, BRI</p>
              </div>
            </div>
          </div>

          {user.payment_type == 1 && (
            <div className="rounded-lg border py-2 flex- w-full">
              <Dropdown user={user} setUser={setUser} />
            </div>
          )}

          {user.payment_bank !== 'no account' && user.payment_type == 1 && (
            <Info>
              <p>
                - Nomor Virtual Account {user.payment_bank} akan diberi tau
                setelah Kamu melalui tahap konfirmasi pesanan
              </p>
              <p>
                - Pembayaran hanya berlaku dengan menggunakan akun{' '}
                {user.payment_bank}
              </p>
              <p>
                - Pastikan nomor Virtual Account Kamu sesuai dengan instruksi
                pembayaran
              </p>
              <p>
                - Pastikan kembali nominal pembayaran sesuai dengan pemesanan
                yang Kamu lakukan{' '}
              </p>
            </Info>
          )}
        </div>
        {grandTotal < 375000 && (
          <p className="text-[10px] text-primary">
            Metode Virtual Account tidak dapat dipilih karena total pembayaran
            kurang dari Rp 375.000
          </p>
        )}
      </div>
    </CustomForm>
  )
}

export default PaymentDetail
