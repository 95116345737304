import React from 'react'
import { Navigate } from 'react-router-dom'

const RedirectCartRoute = ({ children }) => {
  const partnerCart = localStorage.getItem('partner')
  const token = localStorage.getItem('token')
  if (!partnerCart || !token) {
    return <Navigate to="/" />
  }
  return children
}

export default RedirectCartRoute
