import React, { useRef, useState } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import RecommendItem from './RecommendItem'

const DesktopMenuRecommend = ({ menuRecommend }) => {
  const scrl = useRef(null)
  const lengthData = menuRecommend.data.result.map((x) =>
    x.menus.map((item) => item)
  )
  const length = lengthData.flatMap((item) => item)
  const [scrollX, setscrollX] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift
    setscrollX(scrollX + shift)

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  // check scroll
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft)
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  return (
    <div className="relative md:block container hidden">
      {scrollX !== 0 && length.length > 3 && (
        <button
          className="text-primary absolute -left-8 top-[40%]"
          onClick={() => slide(-372)}
        >
          <IoIosArrowBack size={36} />
        </button>
      )}
      <div
        ref={scrl}
        onScroll={scrollCheck}
        className={`flex gap-2 overflow-x-scroll scroll-smooth no-scrollbar ${
          length.length == 1 ? 'justify-center' : 'justify-start'
        } flex-nowrap w-full`}
      >
        {menuRecommend.data.result.map((item, index) => (
          <React.Fragment key={index}>
            {item.menus.map((menu, index) => (
              <RecommendItem
                key={index}
                id={item.partner_id}
                mitra={item.name}
                name={menu.name}
                img={menu.photo}
                minCost={menu.shipping_cost_fr}
                cost={menu.shipping_cost_to}
                type={item.partner_type_id}
                minOrder={menu.min_order}
                unit={menu.qty_unit_name}
                orderInfo={item.info_min_order}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
      {!scrolEnd && length.length > 3 && (
        <button
          className="text-primary absolute -right-8 top-[40%]"
          onClick={() => slide(+372)}
        >
          <IoIosArrowForward size={36} />
        </button>
      )}
    </div>
  )
}

export default DesktopMenuRecommend
