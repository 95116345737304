import React from 'react'

function Index({
  type = 'decimal',
  position = 'outside',
  className = '',
  children,
}) {
  return (
    <ul
      className={`${
        type == 'decimal'
          ? 'list-decimal'
          : type == 'disc'
          ? 'list-disc'
          : type == 'none'
          ? 'list-none'
          : ''
      }
    ${
      position == 'inside'
        ? 'list-inside'
        : position == 'outside'
        ? 'list-outside'
        : ''
    } ${className}`}
    >
      {children}
    </ul>
  )
}

export default Index
