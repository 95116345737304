/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const KetentuanPenagihan = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="ketentuan-penagihan" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        G. Ketentuan Penagihan Dan Pembayaran
      </div>
      <List>
        <ListItem>
          Atas Penjualan Makanan yang dilakukan, <em>Vendor</em> akan
          menerbitkan Tagihan <em>Vendor</em> kepada Pemesan hanya melalui
          KendiXpress sesuai dengan data Pesanan sebagaimana tercatat di dalam
          KendiXPress.
        </ListItem>
        <ListItem>
          Pembayaran Pesanan oleh Pemesan WAJIB dilakukan melalui Rekening
          Pembayaran sesuai instruksi yang tertera dalam KendiXpress
          selambat-lambatnya 1 (satu) hari sebelum pengiriman Pesanan.
        </ListItem>
        <ListItem>
          Pesanan oleh Pemesan akan dikonfirmasi oleh RAM apabila pembayaran
          Pesanan telah masuk di Rekening Pembayaran.
        </ListItem>
        <ListItem>
          Pengembalian dana kepada Pemesan karena alasan apapun akan dilakukan
          oleh RAM ke Rekening Pemesan.
        </ListItem>
      </List>
    </TabContent>
  )
})

export default KetentuanPenagihan
