import { useMutation, useQuery } from 'react-query'

import { fetchPartnerList } from './api'

export const useFetchPartnerList = (params) => {
  return useQuery(['partners-list', params], () => fetchPartnerList(params))
}

export const useFetchPartnerListMutation = () => {
  return useMutation((params) => fetchPartnerList(params))
}
