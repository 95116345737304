import Image from 'images/menu/menu-utama.svg'
import React from 'react'

const MenuImage = ({ menu, className, onClick }) => {
  const DefaultImage = () => {
    return (
      <img
        src={Image}
        alt="default-image"
        className="h-full max-h-[194px] transition-transform duration-200 ease-in hover:scale-[1.2]"
      />
    )
  }
  return (
    <div
      onClick={onClick}
      className={`md:max-w-[276px] md:max-h-[194px] flex justify-center ${
        menu ? '' : 'bg-primary'
      } w-full h-full overflow-hidden cursor-pointer ${className}`}
    >
      {menu ? (
        <img
          src={menu?.photo}
          alt={menu?.name}
          className="md:w-[276px] h-[194px] md:bg-center cursor-pointer w-full object-cover transition-transform duration-200 ease-in hover:scale-[1.2]"
        />
      ) : (
        <DefaultImage />
      )}
    </div>
  )
}

export default MenuImage
