import React from 'react'

const InfoWrapper = ({ title, value, border }) => {
  return (
    <div className={`flex flex-col ${border ? 'border-b pb-7' : ''}`}>
      <div className="text-xs text-gray-400">{title}</div>
      <div className="text-sm font-medium">{value}</div>
    </div>
  )
}

export default InfoWrapper
