/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const KetentuanLainnya = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="ketentuan-lainnya" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        L. Ketentuan Lainnya
      </div>
      <List>
        <ListItem>
          Syarat dan Ketentuan Pemesan KendiXpress, Kebijakan Privasi, formulir
          Pesanan serta instruksi pembayaran yang disediakan untuk Pemesan
          merupakan sebuah perjanjian yang sah antara RAM dan Pemesan.
        </ListItem>
        <ListItem>
          RAM berhak dan berwenang untuk melakukan pembaruan terhadap Syarat dan
          Ketentuan Pemesan KendiXpress ini dari waktu ke waktu tanpa
          pemberitahuan sebelumnya. Dengan tetap mengakses dan menggunakan
          KendiXpress maka Anda dianggap menyetujui perubahan-perubahan dalam
          Ketentuan ini.
        </ListItem>
        <ListItem>
          Dalam hal salah satu atau lebih dalam Syarat dan Ketentuan Pemesan
          KendiXpress ini menjadi batal, tidak berlaku, tidak sah, atau tidak
          dapat diberlakukan, baik seluruhnya atau sebagian berdasarkan
          peraturan perundang-undangan yang berlaku, hal ini tidak akan
          mempengaruhi keabsahan atau berlakunya ketentuan lain Syarat dan
          Ketentuan Pemesan KendiXpress ini.
        </ListItem>
      </List>
    </TabContent>
  )
})

export default KetentuanLainnya
