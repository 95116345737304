/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Pernyataan = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="pernyataan" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        B. Pernyataan Dan Jaminan
      </div>
      <div className="my-6">
        Pemesan dengan ini menyatakan dan menyetujui bahwa:
      </div>
      <List>
        <ListItem>
          RAM bukan penyedia layanan jasa boga serta tidak mempekerjakan Vendor
          oleh karena itu Penjualan Makanan dan upaya pemenuhan seluruh Syarat
          dan Ketentuan KendiXpress akan sepenuhnya dilakukan oleh{' '}
          <em>Vendor</em> berdasarkan Pesanan;
        </ListItem>
        <ListItem>Pemesan merupakan konsumen akhir dari Pesanan;</ListItem>
        <ListItem>
          Pemesan akan selalu melindungi, merahasiakan dan tidak membocorkan
          kepada pihak lain <em>Username</em> dan <em>Password</em> milik
          Pemesan. Tanggung jawab atas perlindungan dan kerahasiaan{' '}
          <em>Username</em> dan <em>Password</em> merupakan dan akan selalu
          berada di tangan Pemesan. RAM tidak dapat diminta untuk
          bertanggungjawab terhadap segala bentuk kerugian yang diderita oleh
          Pemesan akibat kegagalan Pemesan dalam melindungi kerahasiaan{' '}
          <em>Username</em> atau <em>Password</em> miliknya;
        </ListItem>
        <ListItem>
          Seluruh informasi dan data yang dimasukkan Pemesan pada saat melakukan
          pendaftaran keanggotaan atau pada saat melakukan pemesanan Pesanan
          adalah benar, detail, lengkap dan akurat;
        </ListItem>
        <ListItem>
          Alamat surel (surat elektronik atau e-mail) dan nomor telepon yang
          diberikan atau didaftarkan melalui KendiXpress adalah benar milik
          Pemesan dan Pemesan telah memastikan bahwa alamat surel dan nomor
          telephone tersebut dapat diakses oleh Pemesan;
        </ListItem>
        <ListItem>
          Pemesan tidak akan melakukan pembayaran secara tunai langsung kepada
          <em>Vendor</em>
        </ListItem>
        <ListItem>
          Pemesan akan selalu menjaga dan melindungi hak kekayaan intelektual
          RAM dan <em>Vendor</em>, termasuk namun tidak terbatas dengan cara
          tidak menggunakan konten dan foto makanan yang ditampilkan pada
          KendiXpress tanpa izin tertulis dari RAM.
        </ListItem>
        <ListItem>
          RAM akan melakukan langkah pengamanan dengan upaya terbaiknya dalam
          mencegah penyalahgunaan informasi dan terhadap data yang Anda masukkan
          dalam KendiXpress, RAM tidak dapat diminta untuk bertanggungjawab atas
          segala bentuk kerugian yang Pemesan derita apabila pihak ketiga
          melakukan pengaksesan secara ilegal terhadap informasi dan data
          tersebut.
        </ListItem>
      </List>
    </TabContent>
  )
})

export default Pernyataan
