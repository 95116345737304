import React from 'react'

import CustomForm from '../../../components/forms/CustomForm'
import Dropdown from './fragments/Dropdown'
import Info from './fragments/Info'

const PaymentDetail = ({ user, setUser, errorPayment }) => {
  return (
    <CustomForm title="Detail Pembayaran" sideRequired={errorPayment}>
      <div className="flex flex-col gap-3">
        <div
          className={`flex flex-col gap-3 rounded-xl ${
            user.payment_type == 1 ? 'border-primary' : ''
          } border py-5 px-6`}
        >
          <div
            onClick={() =>
              setUser({
                ...user,
                payment_unique_code: 0,
                payment_type: 1,
                payment_bank: 'no account',
              })
            }
            className="flex cursor-pointer gap-3"
          >
            <input
              type="radio"
              name="payment"
              value={user.payment_type}
              checked={user.payment_type == 1}
              onChange={() =>
                setUser({
                  ...user,
                  payment_unique_code: 0,
                  payment_type: 1,
                  payment_bank: 'no account',
                })
              }
              className="accent-primary cursor-pointer border-primary outline-primary w-5"
            />
            <div className="flex flex-col">
              <div className="py-2 lg:py-3">
                <h4 className="text-sm font-semibold">Virtual Account</h4>
                <p className="text-[10px]">BCA, BNI, Mandiri, BRI</p>
              </div>
            </div>
          </div>

          {user.payment_type == 1 && (
            <div className="rounded-lg border py-2 flex- w-full">
              <Dropdown user={user} setUser={setUser} />
            </div>
          )}

          {user.payment_bank !== 'no account' && user.payment_type == 1 && (
            <Info>
              <p>
                - Nomor Virtual Account {user.payment_bank} akan diberi tau
                setelah Kamu melalui tahap konfirmasi pesanan
              </p>
              <p>
                - Pembayaran hanya berlaku dengan menggunakan akun{' '}
                {user.payment_bank}
              </p>
              <p>
                - Pastikan nomor Virtual Account Kamu sesuai dengan instruksi
                pembayaran
              </p>
              <p>
                - Pastikan kembali nominal pembayaran sesuai dengan pemesanan
                yang Kamu lakukan{' '}
              </p>
            </Info>
          )}
        </div>
      </div>
    </CustomForm>
  )
}

export default PaymentDetail
