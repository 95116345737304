import React, { useState } from 'react'
import { IoChevronDown } from 'react-icons/io5'

const Accordion = ({ name, title, noBorder, description, setTitle }) => {
  const [check, setCheck] = useState('active')
  const onChangeItem = (value) => {
    setCheck((prevState) => {
      if (prevState == value) {
        return ''
      } else {
        return value
      }
    })
  }
  return (
    <div
      className={`relative overflow-hidden ${
        noBorder ? '' : 'border-b'
      } py-3 h-auto`}
    >
      <input
        type="checkbox"
        name={name}
        checked={check == 'active'}
        onClick={setTitle}
        onChange={() => onChangeItem('active')}
        className="peer absolute top-0 inset-x-0 w-full h-full md:h-12 opacity-0 z-10 cursor-pointer"
      />
      <div className="h-auto md:h-12 w-full flex items-start md:items-center">
        <div className="text-sm md:text-lg w-11/12 md:auto">{title}</div>
      </div>
      <div className="absolute top-[10px] right-0 md:top-6 md:right-0 transition-transform duration-[1500ms] rotate-0 peer-checked:-rotate-180">
        <IoChevronDown className="text-primary w-5 h-5" />
      </div>
      <div className="overflow-hidden bg-transparent transition-all duration-1000 max-h-[0px] peer-checked:max-h-[1096px]">
        <div className="w-full md:w-11/12 pt-4 text-sm">{description}</div>
      </div>
    </div>
  )
}

export default Accordion
