import React from 'react'
import { Link } from 'react-router-dom'

function NavMicrosite() {
  return (
    <div className="container">
      <div className="w-full p-0 py-5 bg-white flex items-center">
        <div className="mx-auto flex w-full justify-start md:justify-between  items-center font-medium">
          <div className="w-auto h-auto relative">
            <Link to="/">
              <img src="/images/kendixpress.svg" className="w-44" alt="Logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavMicrosite
