import Loading from 'components/layouts/fragments/Loading'
import UserLayout from 'components/layouts/UserLayout'
import { useFetchDetailOrder } from 'modules/Order/hook'
import { useFetchUserProfile } from 'modules/User/hook'
import React from 'react'
import { useEffect } from 'react'
import { HiDownload } from 'react-icons/hi'
import { MdArrowBackIosNew } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { MONTH_NAMES } from 'utils/date'

import Card from './components/Card'
import DetailCart from './components/DetailCart'
import DataTop from './components/fragments/DataTop'
import RecipientDetail from './components/RecipientDetail'
import Sidebar from './components/Sidebar'

const OrderDetail = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dataHook = useFetchUserProfile()
  const detail = useFetchDetailOrder(params.id)

  useEffect(() => {
    sessionStorage.setItem('detail-order', JSON.stringify('active'))
  }, [])

  if (dataHook.isLoading || detail.isLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
    )
  }
  return (
    <UserLayout>
      <div className="grid md:grid-cols-10 gap-11 py-2 bg- md:py-10">
        {/* DESKTOP */}
        <div className="col-span-3 hidden md:block">
          {/* SIDEBAR DESKTOP */}
          <Sidebar
            name={dataHook?.data && dataHook?.data.name}
            isLoading={dataHook.isFetching}
          />
          {/* END SIDEBAR DESKTOP */}
        </div>

        <div className="col-span-7 w-full flex flex-col gap-5">
          <div
            onClick={() => navigate(-1)}
            className="md:flex gap-3 hidden cursor-pointer w-fit items-center text-sm"
          >
            <MdArrowBackIosNew className="text-primary" />
            <p>Kembali ke Daftar Pesanan</p>
          </div>

          <Card
            className={`md:py-10 flex flex-col md:flex-row gap-3 justify-between md:px-12`}
          >
            <div className="flex md:gap-2 flex-col">
              <p className="text-sm">
                Dipesan:{' '}
                {new Date(
                  detail?.data?.result.transaction_date * 1000
                ).getDate()}{' '}
                {
                  MONTH_NAMES[
                    new Date(
                      detail?.data?.result.transaction_date * 1000
                    ).getMonth()
                  ]
                }{' '}
                {new Date(
                  detail?.data?.result.transaction_date * 1000
                ).getFullYear()}
              </p>
              <h3 className="text-lg lg:text-3xl font-semibold">
                Order ID: {detail?.data?.result.order_number}
              </h3>
            </div>
            {(detail?.data?.result.invoice_status == 'U' ||
              detail?.data?.result.invoice_status == 'P') && (
              <div className="flex items-center">
                <a
                  href={detail?.data?.result.invoice_url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary items-center flex gap-1 text-sm md:text-lg font-semibold"
                >
                  <HiDownload /> Unduh Invoice
                </a>
              </div>
            )}
          </Card>
          <div className="border-b md:hidden"></div>
          <DetailCart data={detail?.data?.result} />
          <RecipientDetail data={detail?.data?.result} />

          <DataTop
            item={detail?.data?.result}
            className={`${
              (detail?.data?.result?.status == 12 ||
                detail?.data?.result?.status == 22 ||
                detail?.data?.result?.status == 24) &&
              'pb-5 md:pb-10 bg-white rounded-2xl'
            }`}
          />
        </div>
      </div>
    </UserLayout>
  )
}

export default OrderDetail
