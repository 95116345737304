/* eslint-disable no-unused-vars */
import { fetchMenuExist } from 'modules/Mitra/api'
import { useOrderMutation } from 'modules/Order/hook'
import { useFetchUserProfile } from 'modules/User/hook'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCartSystem } from './useCartSystem'

export const useOrder = () => {
  const navigate = useNavigate()
  const { cart, setCart, partner } = useCartSystem()
  const userProfile = useFetchUserProfile()
  const [loading, setLoading] = useState(false)
  const [menuAvail, setMenuAvail] = useState([])
  const [location] = useState(JSON.parse(localStorage.getItem('dataAddress')))
  const [dataUser, setDataUser] = useState(
    JSON.parse(localStorage.getItem('data-user')) || {
      notes: '',
      payment_type: '',
      payment_bank: '',
      order_detail: '',
      partner_id: partner.partner_id || '',
      partner_type_id: partner.partner_type_id || '',
      partner_name: partner.name || '',
      payment_unique_code: '',
      delivery_to: '',
      delivery_address: location.formattedAddress || '',
      detail_location: location.detailLocation || '',
      lat: location.lat || '',
      long: location.lng || '',
      pic_email: '',
      company_name: '',
      recipient_name: '',
      recipient_phone: '',
    }
  )

  const [nama, setNama] = useState(localStorage.getItem('nama') || '')
  const [phone, setPhone] = useState(localStorage.getItem('phone') || '')
  const [deliver, setDeliver] = useState(localStorage.getItem('deliver') || '')

  const [lateOrder, setLateOrder] = useState(false)
  const [dateLate, setDateLate] = useState('')
  const [alertAvail, setAlertAvail] = useState(false)

  const sendOrder = useOrderMutation()

  // CONVERT TANGGAL KE GMT
  const handleDateGMT = (date) => {
    let dateConvert = new Date(date)
    dateConvert.setHours(dateConvert.getHours() + 7)
    return Date.parse(dateConvert)
  }

  // SELISIH TANGGAL
  const differentDate = (date) => {
    const dateConver = date - Date.now()
    return Math.ceil(dateConver / (1000 * 3600 * 24))
  }

  // ORDER
  const handleOrder = () => {
    let order = dataUser
    let newCart = cart.map(({ sub_total, qty_total, ...item }) => item)
    const newMenus = cart.map((data) =>
      data.menus.map(
        ({
          available,
          disable_up,
          free_shipping_cost,
          is_main_menu,
          min_order,
          max_order,
          shipping_cost,
          shipping_cost_array,
          sub_total,
          type,
          ...item
        }) => item
      )
    )

    for (let i = 0; i < newCart.length; i++) {
      newCart[i].menus = newMenus[i]
      newCart[i].date = handleDateGMT(newCart[i].date) / 1000
    }
    order = { ...order, order_detail: newCart }

    sendOrder.mutate(
      order,
      {
        onSuccess: (data) => {
          if (data.status.status_code !== 20) {
            setLoading(false)
            return alert(data.status.message_client)
          }
          setLoading(false)
          localStorage.removeItem('cart')
          localStorage.removeItem('partner')
          localStorage.removeItem('ongkir')
          localStorage.removeItem('price')
          localStorage.removeItem('data-user')
          localStorage.removeItem('cart-available')
          localStorage.setItem('tool', JSON.stringify(true))
          sessionStorage.clear()
          sessionStorage.setItem('order', JSON.stringify(true))
          navigate('/user/order')
        },
      },
      { onError: (error) => alert(error) }
    )
  }

  const handleSubmit = () => {
    // SETELAH JAM 14.00
    if (new Date().getHours() >= 14 && new Date().getMinutes() >= 1) {
      const findDate = cart.find((data) => differentDate(data.date) < 2)
      if (findDate) {
        setDateLate(findDate.date)
        setLateOrder(true)
        return setLoading(false)
      }
    }
    // SEBELUM JAM 14.01
    else {
      const findDate = cart.find((data) => differentDate(data.date) < 1)
      if (findDate) {
        setDateLate(findDate.date)
        setLateOrder(true)
        return setLoading(false)
      }
    }

    // CEK MENU AVAILABLE
    const findAvail = menuAvail.findIndex((menu) => menu.avail == false)
    if (findAvail >= 0) {
      setCart((dataObj) => {
        for (let i = 0; i < dataObj.length; i++) {
          for (let a = 0; a < dataObj[i].menus.length; a++) {
            for (let b = 0; b < menuAvail.length; b++) {
              dataObj[i].menus[a].available = menuAvail[b].avail
            }
          }
        }
      })
      setAlertAvail(true)
      return setLoading(false)
    }
    // ORDER
    handleOrder()
  }

  // CEK AVAILABLE ON LOAD
  useEffect(() => {
    let item = []
    for (let a = 0; a < cart.length; a++) {
      for (let i = 0; i < cart[a].menus.length; i++) {
        fetchMenuExist(
          cart[a].menus[i].menu_id,
          handleDateGMT(cart[a].date) / 1000
        ).then((result) => {
          item.push({ avail: result.result.data })
        })
      }
    }
    setMenuAvail(item)
  }, [])

  return {
    loading,
    setLoading,
    userProfile,
    dataUser,
    setDataUser,
    menuAvail,
    setMenuAvail,
    handleSubmit,
    handleOrder,
    lateOrder,
    dateLate,
    alertAvail,
    differentDate,
    nama,
    setNama,
    phone,
    setPhone,
    deliver,
    setDeliver,
  }
}
