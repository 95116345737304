import React from 'react'

const CustomForm = ({
  title,
  enabled,
  setSameValue,
  titleSide,
  sideRequired,
  classContainer,
  children,
}) => {
  return (
    <div
      className={`rounded-3xl py-4 lg:py-10 ${
        classContainer ? classContainer : 'px-0 md:px-4 lg:px-10'
      } bg-white`}
    >
      <div
        className={`flex ${
          sideRequired ? 'flex-row justify-between items-center' : 'flex-col'
        } gap-3 md:flex-row md:justify-between w-full pb-4 md:pb-8 `}
      >
        <h3 className={`text-xl font-semibold`}>{title}</h3>
        {titleSide && (
          <div className="flex gap-2 md:flex-row-reverse items-center flex-row">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={enabled}
                readOnly
              />
              <div
                onClick={setSameValue}
                className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-white  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"
              ></div>
            </label>
            <p className="text-xs">Sama seperti pemesan</p>
          </div>
        )}
        {sideRequired && (
          <p className="text-primary text-[10px]">Wajib diisi</p>
        )}
      </div>
      {children}
    </div>
  )
}

export default CustomForm
