import doneGray from 'images/icons/done-gray.svg'
import doneRed from 'images/icons/done-red.svg'
import processGray from 'images/icons/process-gray.svg'
import processRed from 'images/icons/process-red.svg'
import waitingGray from 'images/icons/waiting-gray.svg'
import waitingRed from 'images/icons/waiting-red.svg'
import React, { useState } from 'react'
import { MONTH_NAMES } from 'utils/date'

const StatusOrder = ({ data }) => {
  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState('')
  const [firstImg, setFirstImg] = useState('')
  const [secondImg, setSecondImg] = useState('')
  const [thirdImg, setThirdImg] = useState('')
  const [dueDate, setDueDate] = useState('')

  // CONVERT TANGGAL KE GMT
  const handleDateGMT = (date) => {
    let dateConvert = new Date(date * 1000)
    dateConvert.setHours(dateConvert.getHours() - 7)
    return Date.parse(dateConvert)
  }

  React.useEffect(() => {
    switch (data?.status) {
      case 2:
        setTitle('Pesanan Dibatalkan')
        setDesc('')
        break

      case 11:
        setTitle('Pesanan Terbuat')
        setDesc('Harap Menunggu Konfirmasi Dapur')
        setFirstImg(waitingGray)
        setSecondImg(processGray)
        setThirdImg(doneGray)
        break

      case 12:
        setTitle('Menunggu Pembayaran')
        setDesc('Silahkan lakukan pembayaran, agar pesanan dapat diproses')
        setFirstImg(waitingRed)
        setSecondImg(processGray)
        setThirdImg(doneGray)
        break

      case 13:
        setTitle('Pesanan Diproses')
        setDesc('Kami mempersiapkan pesananmu')
        setFirstImg(waitingRed)
        setSecondImg(processRed)
        setThirdImg(doneGray)
        break

      case 14:
        setTitle('Pesanan Selesai')
        setDesc('Pesanan sudah dibayar dan selesai')
        setFirstImg(waitingRed)
        setSecondImg(processRed)
        setThirdImg(doneRed)
        break

      case 21:
        setTitle('Pesanan Terbuat')
        setDesc('Harap Menunggu Konfirmasi')
        setFirstImg(processRed)
        setSecondImg(waitingGray)
        setThirdImg(doneGray)
        break

      case 22:
        setTitle('Menunggu Pembayaran')
        setDesc('Pesanan belum dibayar, Jatuh Tempo ')
        setFirstImg(processRed)
        setSecondImg(waitingRed)
        setThirdImg(doneGray)
        setDueDate(data?.due_date)
        break

      case 23:
        setTitle('Pesanan Selesai')
        setDesc('Pesanan sudah dibayar dan selesai')
        setFirstImg(processRed)
        setSecondImg(waitingRed)
        setThirdImg(doneRed)
        break

      case 24:
        setTitle('Pembayaran Sudah Melebihi Jatuh Tempo')
        setDesc('Pesanan belum dibayar, Jatuh Tempo ')
        setFirstImg(processRed)
        setSecondImg(waitingRed)
        setThirdImg(doneGray)
        setDueDate(data?.due_date)
        break

      case 25:
        setTitle('Menunggu Konfirmasi Catering')
        setDesc('Pesananmu sedang diteruskan ke catering. Ditunggu ya!')
        setFirstImg(processRed)
        setSecondImg(waitingRed)
        setThirdImg(doneGray)
        break

      default:
        break
    }
  }, [])

  return (
    <div className="bg-[#FFF6F6] flex justify-between p-4 md:py-6 md:px-12 rounded-t-xl md:rounded-t-2xl gap-3">
      <div className="flex flex-col gap-1">
        <h4
          className={`font-semibold text-lg ${
            data?.status == 2 || data?.status == 24
              ? 'text-primary'
              : 'text-black'
          }`}
        >
          {title}
        </h4>
        <p className="text-xs md:text-sm">
          {desc}{' '}
          {dueDate !== '' && (
            <span>
              {new Date(handleDateGMT(dueDate)).getDate()}{' '}
              {MONTH_NAMES[new Date(handleDateGMT(dueDate)).getMonth()]}{' '}
              {new Date(handleDateGMT(dueDate)).getFullYear()}
            </span>
          )}
        </p>
      </div>
      <div className="hidden flex-shrink-0 lg:flex items-start">
        {data?.status !== 2 ? (
          <div className="flex items-center">
            <div
              className={`p-2 border rounded-full ${
                firstImg == processGray ||
                firstImg == waitingGray ||
                firstImg == doneGray
                  ? 'border-[#9D9D9D]'
                  : 'border-primary'
              }`}
            >
              <img src={firstImg} alt={title} className="w-5 h-5" />
            </div>
            <div
              className={`border-t w-5 h-1 ${
                secondImg == processGray ||
                secondImg == waitingGray ||
                secondImg == doneGray
                  ? 'border-[#9D9D9D]'
                  : 'border-primary'
              }`}
            ></div>
            <div
              className={`p-2 border rounded-full ${
                secondImg == processGray ||
                secondImg == waitingGray ||
                secondImg == doneGray
                  ? 'border-[#9D9D9D]'
                  : 'border-primary'
              }`}
            >
              <img src={secondImg} alt={title} className="w-5 h-5" />
            </div>
            <div
              className={`border-t w-5 h-1 ${
                thirdImg == processGray ||
                thirdImg == waitingGray ||
                thirdImg == doneGray
                  ? 'border-[#9D9D9D]'
                  : 'border-primary'
              }`}
            ></div>
            <div
              className={`p-2 border rounded-full ${
                thirdImg == processGray ||
                thirdImg == waitingGray ||
                thirdImg == doneGray
                  ? 'border-[#9D9D9D]'
                  : 'border-primary'
              }`}
            >
              <img src={thirdImg} alt={title} className="w-5 h-5" />
            </div>
          </div>
        ) : (
          <div className="flex gap-1 text-[10px] mt-1.5">
            Apakah terdapat masalah pada pesananmu?{' '}
            <a
              href="https://wa.me/6281119279900"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              Hubungi Kami
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default StatusOrder
