import empty from 'images/belum_ada_pesanan.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Card from './Card'

const EmptyOrder = () => {
  const navigate = useNavigate()
  return (
    <Card className={`flex py-10 items-center justify-center flex-col gap-1`}>
      <img src={empty} alt="pesanan kosong" className="w-60 mb-3" />
      <h6 className="text-lg font-semibold text-center">
        Ayo Buat Pesananmu <br /> Sekarang Juga!
      </h6>
      <p className="text-ms text-center">
        Rasakan cita rasa istimewa <br />
        untuk setiap momenmu
      </p>
      <button
        onClick={() => navigate('/explore')}
        className="bg-primary text-sm font-semibold py-3 mt-5 rounded-full px-10 text-white"
      >
        Buat Pesanan
      </button>
    </Card>
  )
}

export default EmptyOrder
