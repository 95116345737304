import AuthModal from 'components/modal/Auth'
import ModalMaps from 'components/modal/ModalMaps'
import UserIcon from 'images/icons/user.svg'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import React, { useState } from 'react'
import { BiMap } from 'react-icons/bi'
import { MdArrowBackIos } from 'react-icons/md'
import { Link, useNavigate, useParams } from 'react-router-dom'

import DropdownNavbarResponsive from './DropdownNavbarResponsive'

const NavAddress = ({ children }) => {
  const params = useParams()
  const navigate = useNavigate()
  const [location] = useState(
    JSON.parse(localStorage.getItem('dataAddress')) || ''
  )
  const {
    showModal,
    setShowModal,
    setLatLong,
    setChange,
    place,
    setPartnerId,
    setPlace,
    setRegion,
    setURL,
    isPartnerExist,
    setIsPartnerExist,
    handleSubmitForm,
    saveLocationMutation,
    andalan,
    harian,
    tambahan,
  } = useGoogleMapHook()

  const handleChange = () => {
    if (window.location.pathname.search('/detail-mitra') !== -1) {
      setPartnerId(params.id)
    }
    sessionStorage.removeItem('listData')
    setChange(true)
    setShowModal(true)
  }

  const token = localStorage.getItem('token')

  const [showModalAuth, setShowModalAuth] = useState(false)
  return (
    <div className="lg:container px-4">
      <div className="w-full p-0 py-5 bg-white flex justify-center items-center">
        <div className="mx-auto flex w-full justify-between md:justify-start gap-1 items-center font-medium">
          <div className="w-auto md:block hidden h-auto relative">
            <Link to="/">
              <img
                src="/images/kendixpress.svg"
                className="w-44 md:w-36 cursor-pointer lg:w-[11rem]"
                alt="Logo"
              />
            </Link>
          </div>
          <button
            onClick={() => navigate(-1)}
            className="block cursor-pointer md:hidden"
          >
            <MdArrowBackIos size={24} />
          </button>
          {!children ? (
            <div
              onClick={() => handleChange()}
              className="md:border-2 items-center max-w-md flex flex-row border truncate cursor-pointer px-3 md:px-5 py-3 text-xs md:text-sm rounded-full md:ml-auto"
            >
              <div className="pr-1 md:pr-2">
                <BiMap className="h-4 w-4 md:h-5 md:w-5" color="#9D2121" />
              </div>
              <p className="truncate">
                Dikirim ke{' '}
                <span className="font-semibold capitalize">
                  {location !== ''
                    ? location.formattedAddress
                    : 'isi alamat pengiriman dan detail lokasi'}
                </span>
              </p>
            </div>
          ) : (
            <React.Fragment>{children}</React.Fragment>
          )}
          {!token && (
            <button
              className="px-2 md:px-3 py-[6px] text-sm md:block hidden rounded-full bg-primary border-2 border-primary text-white w-44 hover:bg-red-800 hover:border-red-800 md:ml-auto"
              onClick={() => setShowModalAuth(true)}
            >
              Masuk / Daftar
            </button>
          )}
          {token && (
            <div className="hidden md:flex md:ml-auto">
              <Link to="/user/profile" className="md:mr-5">
                <img src={UserIcon} className="w-9 h-9" />
              </Link>
              <button
                onClick={() => navigate('/user/order')}
                className="px-2 md:px-3 py-[6px] text-sm rounded-full bg-primary border-2 border-primary text-white w-28 hover:bg-red-800 hover:border-red-800"
              >
                Pesanan
              </button>
            </div>
          )}
          <DropdownNavbarResponsive onClick={() => setShowModalAuth(true)} />
        </div>
      </div>
      {/* MODAL AUTH */}
      <AuthModal showModal={showModalAuth} setShowModal={setShowModalAuth} />
      {/* POP UP MAP */}
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        place={place}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={
          saveLocationMutation.isLoading ||
          andalan.isLoading ||
          harian.isLoading ||
          tambahan.isLoading
        }
        setURL={setURL}
        setRegion={setRegion}
      />
    </div>
  )
}

export default NavAddress
