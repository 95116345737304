import React from 'react'

function NavbarItem({ children, classLink = '', onClick }) {
  return (
    <span
      onClick={onClick}
      className={`${classLink} cursor-pointer mx-4 hidden md:block relative no-underline text-sm
              after:content-[''] after:absolute after:w-4/5 after:h-1 after:-bottom-1 after:left-0 
              after:bg-primary after:origin-bottom-right after:transition-transform after:ease-out after:duration-200 after:scale-x-0 
              hover:after:scale-x-100 hover:after:origin-bottom-left`}
    >
      {children}
    </span>
  )
}

export default NavbarItem
