import React from 'react'

const InfoDetail = ({ title, value, border }) => {
  return (
    <div className={`flex flex-col ${border ? 'border-b pb-7' : ''}`}>
      <div className="text-xs text-gray-400">{title}</div>
      <p className="text-sm font-medium">{value}</p>
    </div>
  )
}

export default InfoDetail
