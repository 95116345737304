import * as React from 'react'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import { DAY_NAMES, MONTH_NAMES } from 'utils/date'

const OLD_YEAR = 1970
const MAX_YEAR = new Date().getFullYear() + 3

const DatePicker = ({
  isOpen: showCalendar,
  dayNames,
  monthNames,
  defaultValue,
  minDate = new Date(OLD_YEAR, 0, 1),
  maxDate = new Date(MAX_YEAR, 11, 31),
  onChange,
}) => {
  const [isOpen, setIsOpen] = React.useState(showCalendar)
  const [calendar, setCalendar] = React.useState([])
  const [days] = React.useState(dayNames?.length === 7 ? dayNames : DAY_NAMES)
  const [months] = React.useState(
    monthNames?.length === 12 ? monthNames : MONTH_NAMES
  )
  const [month, setMonth] = React.useState(0)
  const [year, setYear] = React.useState(defaultValue.getFullYear())
  const [selectedDate, setSelectedDate] = React.useState(defaultValue)
  const lbRef = React.useRef(null)
  const changeMonth = (inc) => {
    let curMonth = month + inc
    let curYear = year

    if (curMonth === 12) {
      curMonth = 0
      curYear++
    } else if (curMonth === -1) {
      curMonth = 11
      curYear--
    }

    setMonth(curMonth)
    setYear(curYear)
  }

  const selectDate = (day) => {
    setMonth(day.getMonth())
    setYear(day.getFullYear())
    setSelectedDate(day)
    onChange(day)
  }

  React.useEffect(() => {
    const firstDayThisMonth = new Date(year, month, 1).getDay()
    const temp = []

    for (let i = 0; i < 42; i++) {
      const date = new Date(year, month, i - firstDayThisMonth + 1)
      temp.push(date)
    }

    setCalendar(temp)
  }, [month, year])

  React.useEffect(() => {
    if (defaultValue) {
      if (defaultValue.getTime() < minDate.getTime()) {
        setMonth(minDate.getMonth())
        setSelectedDate(minDate)
      } else {
        setMonth(defaultValue.getMonth())
      }
    }
  }, [])

  React.useEffect(() => {
    setIsOpen(showCalendar)
  }, [showCalendar])

  if (!isOpen) {
    return null
  }
  return (
    <div className="max-w-md bg-white overflow-hidden" ref={lbRef}>
      <div className="flex flex-row w-full border-b border-t justify-between p-2 md:p-4 px-5 items-center">
        <button
          disabled={
            minDate.getFullYear() === year && minDate.getMonth() === month
          }
          className="p-0 ml-2 bg-none border-none cursor-pointer disabled:text-gray-400 text-primary"
          onClick={() => changeMonth(-1)}
        >
          <MdArrowBackIos size={24} />
        </button>
        <div className="">
          {months[month]} {year}
        </div>
        <button
          disabled={
            maxDate.getFullYear() === year && maxDate.getMonth() === month
          }
          className="p-0 bg-none border-none cursor-pointer disabled:text-gray-400 text-primary"
          onClick={() => changeMonth(+1)}
        >
          <MdArrowForwardIos size={24} />
        </button>
      </div>

      <div className="">
        <div className="pt-3 pr-4 md:pr-4 pl-3 md:pl-4 flex justify-around items-center font-semibold text-sm">
          {days.map((day) => (
            <div className="" key={day}>
              {day.substring(0, 1)}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 p-4">
          {calendar.map((day, index) => (
            <div
              className={`h-full text-sm flex justify-end items-center ${
                day.getMonth() === month ? 'font-medium' : 'opacity-50'
              }`}
              key={index}
            >
              <button
                className={`${
                  selectedDate?.getTime() === day.getTime()
                    ? 'bg-primary text-white'
                    : 'bg-white text-black'
                } md:w-12 md:h-12 w-8 h-8 cursor-pointer bg-none border-none disabled:text-gray-400 rounded-full`}
                onClick={() => selectDate(day)}
                disabled={
                  day.getTime() < minDate.getTime() ||
                  day.getTime() > maxDate.getTime()
                }
              >
                {day.getDate()}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default React.memo(DatePicker)
