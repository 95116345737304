import React from 'react'
import { IoChevronDown } from 'react-icons/io5'

function Accordion({ title, description, number, value, onChange, checked }) {
  return (
    <div className="relative overflow-hidden py-2 h-auto">
      <input
        type="checkbox"
        name={value}
        checked={checked == value}
        onChange={() => onChange(value)}
        className="peer absolute top-0 inset-x-0 w-full h-full md:h-12 opacity-0 z-10 cursor-pointer"
      />
      <div className="h-auto md:h-12 w-full flex items-start md:items-center">
        <h1 className="text-sm md:text-lg pr-4 w-1/12 md:w-auto">{number}.</h1>
        <h1 className="text-sm md:text-lg w-9/12 md:auto">{title}</h1>
      </div>
      <div className="absolute top-[10px] right-0 md:top-3 md:right-3 transition-transform duration-500 rotate-0 peer-checked:-rotate-180">
        <IoChevronDown className="text-primary w-6 h-6" />
      </div>
      <div className="overflow-hidden bg-transparent transition-all duration-500 max-h-0 peer-checked:max-h-40">
        <div className="pl-7 pr-5 pt-4 pb-7 border-b text-sm">
          {description}
        </div>
      </div>
    </div>
  )
}

export default Accordion
