import { useCartSystem } from 'modules/custom/useCartSystem'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { currency } from 'utils/formatNumber'

const CartNotification = () => {
  const { cart, partner, grandTotal } = useCartSystem()
  const data = localStorage.getItem('cart-available')
  const [cartAvailable, setCartAvailable] = useState('')
  const navigate = useNavigate()
  const menus = cart.map((date) => date.menus.map((menu) => menu.menu_id))
  const menuCount = menus.flatMap((item) => item)

  useEffect(() => {
    setCartAvailable(data)
  }, [data])

  return (
    <div className="fixed bottom-0 py-2 lg:py-5 w-screen bg-white shadow-top-only">
      <div className="container flex items-center justify-between">
        <h3 className=" text-xs md:text-sm md:inline-flex">
          <span className="hidden mr-1 lg:block">{partner.name}, </span>{' '}
          {menuCount.length} Menu Terpilih{' '}
          <strong className="ml-1">{currency(grandTotal)}</strong>
        </h3>
        {cartAvailable == -1 ? (
          <p className=" text-xs md:text-sm flex flex-col md:flex-row gap-0 justify-center md:gap-1 text-primary">
            <strong className="text-center">Mohon Maaf</strong>{' '}
            <span>Mitra diluar jangkauan</span>
          </p>
        ) : (
          <button
            onClick={() => navigate(`/detail-mitra/${partner.partner_id}`)}
            className="bg-primary text-white px-5 py-2 font-semibold rounded-full text-xs"
          >
            Cek Pesanan
          </button>
        )}
      </div>
    </div>
  )
}

export default CartNotification
