import React from 'react'

const MoreButton = ({ click, disabled }) => {
  return (
    <div className={`flex justify-center items-center`}>
      <div
        className={`flex justify-center mt-5 md:mt-10 ${
          disabled ? 'hidden' : ''
        }`}
      >
        <button
          name="load more"
          disabled={disabled}
          onClick={click}
          className="py-3 rounded-full font-semibold text-sm text-primary border-primary px-16 hover:bg-primary hover:text-white border-2"
        >
          Lihat Lebih Banyak
        </button>
      </div>
    </div>
  )
}

export default MoreButton
