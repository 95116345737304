import React from 'react'

import DataTransfer from './DataTransfer'
import StatusOrder from './StatusOrder'

const DataTop = ({ item, className }) => {
  return (
    <div className={`${className}`}>
      <StatusOrder data={item} />
      {(item?.status == 12 || item?.status == 22 || item?.status == 24) && (
        <DataTransfer data={item} />
      )}
    </div>
  )
}

export default DataTop
