import copy from 'copy-to-clipboard'
import successIcon from 'images/success-icon.svg'
import paymentIcon from 'images/symbol_tidak_ada_harga.svg'
import { useCheckPayment } from 'modules/Order/hook'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DAY_NAMES, MONTH_NAMES } from 'utils/date'
import { dataTransfer } from 'utils/transferData'

import MethodModal from '../MethodModal'
import ModalPopup from '../ModalPopup'

const SuccessCopied = () => {
  return (
    <div className="bg-black rounded-md font-normal absolute z-20 -top-8 -left-4 p-1 px-2 whitespace-nowrap text-[10px] text-white">
      Berhasil disalin
    </div>
  )
}

const DataTransfer = ({ data }) => {
  const {
    due_date,
    bank_account_bank,
    bank_account_name,
    bank_account_norek,
    payment_type,
    payment_description,
    secure_id,
    status,
  } = data

  const navigate = useNavigate()
  const checkPayment = useCheckPayment()
  const [verif, setVerif] = useState(false)
  const [done, setDone] = useState(false)
  const [dataTutor, setDataTutor] = useState([])
  const [showMethod, setShowMethod] = useState(false)
  const [copied, setCopied] = useState()

  const handleCheckTutor = async (bank, number) => {
    const data = await dataTransfer(bank, number)
    setDataTutor(data)
    setShowMethod(true)
  }

  // copy clipboard
  const copyToClipboard = async (text) => {
    try {
      copy(text)
      setCopied(true)
    } catch (error) {
      alert(error)
    }
  }

  // check payment status
  const checkStatus = (data) => {
    checkPayment.mutate(
      {
        order_id: data,
      },
      {
        onSuccess: (res) => {
          res.result.data == true ? setDone(true) : setVerif(true)
        },
      },
      { onError: (err) => alert(err) }
    )
  }

  // CONVERT TANGGAL KE GMT
  const handleDateGMT = (date) => {
    let dateConvert = new Date(date * 1000)
    dateConvert.setHours(dateConvert.getHours() - 7)
    return Date.parse(dateConvert)
  }

  useEffect(() => {
    let body = document.querySelector('body')
    let modal = document.querySelector('.modal')

    if (modal) {
      body.classList.add('overflow-hidden')
    } else {
      body.classList.remove('overflow-hidden')
    }
  }, [showMethod])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  }, [copied])

  return (
    <React.Fragment>
      {status == 12 && (
        <div className="bg-[#FF796C] w-full py-2 px-4 md:px-12 text-white tracking-tight md:tracking-normal text-[10px]">
          Batas Akhir Pembayaran{' '}
          <strong className="font-semibold">
            {DAY_NAMES[new Date(handleDateGMT(due_date)).getDay()]},{' '}
            {new Date(handleDateGMT(due_date)).getDate()}{' '}
            {MONTH_NAMES[new Date(handleDateGMT(due_date)).getMonth()]}{' '}
            {new Date(handleDateGMT(due_date)).getFullYear()} | 17.00 WIB
          </strong>
        </div>
      )}
      <div className="px-4 md:px-12 bg-white">
        <div className="py-8 grid gap-2 lg:gap-3 lg:grid-cols-2 w-full">
          {payment_type !== 3 ? (
            <div className="col-span-1 flex flex-col gap-2">
              <p className="text-sm font-semibold text-primary">
                1.{' '}
                {payment_type == 2
                  ? 'Transfer ke Rekening BCA pada:'
                  : 'Silahkan Transfer ke:'}
              </p>
              <div className="flex flex-wrap gap-2 md:gap-0 justify-between">
                <div className="flex flex-col">
                  <p className="text-xs text-gray-400">{bank_account_name}</p>
                  <p className="text-[13px] font-medium">
                    No {payment_type == 2 ? 'Rek' : 'VA'} {bank_account_bank}
                    <strong> {bank_account_norek}</strong>{' '}
                    <input
                      type="text"
                      value={bank_account_norek}
                      className="hidden"
                      readOnly
                    />
                    <button
                      onClick={() => copyToClipboard(bank_account_norek)}
                      className="text-primary relative font-semibold"
                    >
                      {copied ? 'Disalin' : 'Salin'}
                      {copied && <SuccessCopied />}
                    </button>
                  </p>
                </div>
                {payment_type == 1 && (
                  <div className="flex items-end">
                    <button
                      onClick={() =>
                        handleCheckTutor(bank_account_bank, bank_account_norek)
                      }
                      className="bg-primary text-white text-[9px] py-1.5 px-3 rounded-full font-semibold"
                    >
                      Cara Bayar VA
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="col-span-1 flex flex-col gap-2">
              <p className="text-sm font-semibold text-primary">
                1. Pembayaran Cash
              </p>
              <div className="flex flex-col">
                <p className="text-xs text-gray-400">Dibayarkan Melalui</p>
                <p className="text-sm font-medium">{payment_description}</p>
              </div>
            </div>
          )}
          <div className="col-span-1 flex flex-col gap-2">
            <p className="text-sm font-semibold text-primary">
              2. Kirimkan bukti transfer ke:
            </p>
            <div className="flex justify-between gap-2">
              <div className="flex flex-col">
                <p className="text-xs text-gray-400">WhatsApp CS KendiXpress</p>
                <p className="text-[13px] font-medium">
                  No <strong>0811 1927 9900</strong>{' '}
                </p>
              </div>
              <div className="flex items-end whitespace-nowrap">
                <a
                  href="https://wa.me/6281119279900?text=Saya%20sudah%20bayar%20dan%20ingin%20kirim%20bukti%20transfer.%20Pemesanan%20atas%20nama"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-primary flex gap-1 rounded-full font-semibold text-[10px] py-1.5 px-4"
                >
                  Kirim <span className="hidden md:block">Bukti Transfer</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t flex justify-between gap-1 md:justify-start border-b py-2 text-[10px]">
          Sudah melakukan pembayaran?{' '}
          <button
            onClick={() => checkStatus(secure_id)}
            className="text-primary font-semibold"
          >
            Cek Status Bayar
          </button>
        </div>
      </div>

      {/* MODAL PAYMENT VERIFICATION */}
      <ModalPopup
        show={verif}
        setShow={setVerif}
        image={paymentIcon}
        title="Pembayaran Belum Terverifikasi"
        contact
        desc={
          <>
            Jika Kamu sudah bayar, namun belum
            <br />
            terverifikasi, hubungi Admin kami
          </>
        }
        buttonTitle="Cek Nanti Lagi"
      />

      {/* MODAL PAYMENT DONE */}
      <ModalPopup
        show={done}
        onClick={() => {
          navigate(0)
          setDone(false)
        }}
        image={successIcon}
        title="Pembayaran Berhasil"
        desc={
          <>
            Terimakasih telah melakukan pembayaran,
            <br />
            Pesananmu siap kami proses
          </>
        }
        buttonTitle="Cek Status Pesanan"
      />

      {/* MODAL PAYMENT TUTORIAL */}
      {showMethod && (
        <MethodModal
          data={dataTutor}
          show={showMethod}
          setShow={setShowMethod}
        />
      )}
    </React.Fragment>
  )
}

export default DataTransfer
