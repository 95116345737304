import React from 'react'

function Card({ children, className, ...rest }) {
  return (
    <div className={`bg-white rounded-[20px] ${className}`} {...rest}>
      {children}
    </div>
  )
}

export default Card
