import { useMicrositeCartSystem } from 'modules/custom/microsite/useCartSystem'
import React, { useCallback, useState } from 'react'

import Modal from '../../../../components/modal/Index'

const ChangeCateringModal = ({ showModal, setShowModal, onSelectCatering }) => {
  const partnerList = JSON.parse(localStorage.getItem('partner-list'))
  const { cart, getPartnerShippingCost } = useMicrositeCartSystem()
  const [loading, setLoading] = useState(false)

  const resetDataModal = (isModalOpen) => {
    if (isModalOpen === true) {
      setShowModal && setShowModal(true)
    }
    if (isModalOpen === false) {
      setShowModal && setShowModal(false)
    }
  }

  const handleSelect = useCallback(
    (name) => {
      setLoading(true)

      const selectedPartner = partnerList.find(
        (partner) => partner.partner_id === name
      )

      if (selectedPartner) {
        localStorage.setItem('partner', JSON.stringify(selectedPartner))

        getPartnerShippingCost(cart, () => {
          try {
            onSelectCatering(selectedPartner.name)
            resetDataModal(false)
            location.reload()
          } catch (error) {
            console.error('Error in catering selection:', error)
          } finally {
            setLoading(false)
          }
        })
      } else {
        setLoading(false)
      }
    },
    [cart, partnerList, onSelectCatering, resetDataModal]
  )

  return (
    <>
      <Modal
        title="Ganti Catering"
        showModal={showModal}
        setShowModal={resetDataModal}
        isModalResponsiveFullPage={false}
        isModalResponsiveRounded={true}
        className="z-100"
        modalClass={`sm:!w-[500px] pb-10`}
        modalBodyClass={`!px-3 overflow-auto h-[300px]`}
      >
        {partnerList?.map((ptn, key) => (
          <div
            key={key}
            className={`flex flex-row gap-4 items-center py-4 border-b hover:bg-slate-100 ${
              loading ? 'cursor-wait disabled' : 'cursor-pointer'
            }`}
            onClick={() => {
              setLoading(true)
              handleSelect(ptn.partner_id)
            }}
          >
            <img
              src={ptn.logo}
              className="min-w-[50px] h-[50px] rounded-xl pl-3"
            />
            <div className="flex justify-between gap-1 flex-col">
              <p>{ptn.name}</p>
              <p className="text-xs md:text-sm w-full flex-wrap flex line-clamp-2 md:line-clamp-3 opacity-50">
                {`${ptn.distance} ${ptn.distance_unit}`}
              </p>
            </div>
          </div>
        ))}
      </Modal>
    </>
  )
}

export default ChangeCateringModal
