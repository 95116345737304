/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Informasi = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="informasi">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        1. Informasi yang kami kumpulkan
      </div>
      <div className="my-2">
        Data Pribadi yang kami kumpulkan termasuk namun tidak terbatas pada
        identitas Pemesan (dapat meliputi nama, jenis kelamin, dan negara asal
        Pemesan), foto Pemesan, nomor telepon, alamat email, riwayat transaksi,
        data pembayaran, tanggal dan waktu saat Pemesan membuat permintaan,
        serta keterangan lain yang dibutuhkan (apabila ada). Data teknis yang
        kami kumpulkan berupa data lokasi geografis, jenis sistem pengoperasian,
        kebangsaan, bahasa, dan informasi URL. Detail selengkapnya mengenai data
        teknis ada di bagian Kebijakan Cookie.
      </div>
    </TabContent>
  )
})

export default Informasi
