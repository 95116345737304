import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { times } from 'utils/date'

// eslint-disable-next-line react/display-name
const TimeChange = React.memo(
  ({ cart = [], show, setShow, date, time, action }) => {
    // Check if cart exists and find the matching date and time
    const findDate =
      cart.length > 0
        ? cart.findIndex(
            (item) => item.date === date && item.shipping_time === time
          )
        : -1

    return (
      <React.Fragment>
        {show && (
          <>
            <div
              onClick={() => setShow(false)}
              className="fixed inset-0 w-screen h-screen"
            ></div>
            <div className="overflow-y-scroll no-scrollbar gap-2 bg-white z-50 rounded-xl shadow-menu-item p-2 absolute top-[50px] right-1 h-[172px] w-[90px] flex flex-col">
              {times.map((currentTime, key) => (
                <button
                  key={key}
                  className="text-xs font-medium gap-1 flex justify-between pl-1"
                >
                  <p onClick={() => action(date, time, currentTime)}>
                    {currentTime}
                  </p>
                  {findDate !== -1 && cart[findDate] && (
                    <AiOutlineCheck
                      size={18}
                      className={`text-primary ${
                        cart[findDate].shipping_time === currentTime
                          ? 'visible'
                          : 'invisible'
                      }`}
                    />
                  )}
                </button>
              ))}
            </div>
          </>
        )}
      </React.Fragment>
    )
  }
)

export default TimeChange
