import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchDailyServices } from 'modules/Order/api'
import { MONTH_NAMES } from 'utils/date'

const DailyServiceOrders = () => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const { id, date } = useParams()

  const formatDateToIndonesian = (dateStr) => {
    const [year, month, day] = dateStr.split('-')
    return `${parseInt(day)} ${MONTH_NAMES[parseInt(month) - 1]} ${year}`
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = date || '2024-10-04'
        const response = await fetchDailyServices(id, today)
        setOrders(response.result.data)
      } catch (error) {
        console.error('Error fetching daily services:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [id, date])

  if (loading) {
    return (
      <div className="p-6">
        <div className="text-center">Memuat data...</div>
      </div>
    )
  }

  return (
    <div className="p-6">
      <div>
        <h1 className="text-2xl font-bold mb-4 text-center">
          Tabel Pengiriman {date && formatDateToIndonesian(date)}
        </h1>
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-red-800 text-white">
                <th className="border border-gray-300 px-4 py-2">
                  Tanggal Pengiriman
                </th>
                <th className="border border-gray-300 px-4 py-2">Pemesan</th>
                <th className="border border-gray-300 px-4 py-2">
                  No HP Pemesan
                </th>
                <th className="border border-gray-300 px-4 py-2">Penerima</th>
                <th className="border border-gray-300 px-4 py-2">
                  No HP Penerima
                </th>
                <th className="border border-gray-300 px-4 py-2">Harus Tiba</th>
                <th className="border border-gray-300 px-4 py-2">Alamat</th>
                {/* <th className="border border-gray-300 px-4 py-2">Pin Lokasi</th> */}
                <th className="border border-gray-300 px-4 py-2">Menu</th>
                <th className="border border-gray-300 px-4 py-2">Isi Menu</th>
                <th className="border border-gray-300 px-4 py-2">Harga</th>
                <th className="border border-gray-300 px-4 py-2">Jumlah</th>
                <th className="border border-gray-300 px-4 py-2">Kota</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? 'bg-gray-100' : ''}
                >
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.order_date}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.customer_name}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.customer_phone}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.recipient_name}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.recipient_phone}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.shipping_time}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.delivery_address}
                  </td>
                  {/* <td className="border border-gray-300 px-4 py-2">
                    <a
                      href={order?.location_pin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700 underline"
                    >
                      Buka Maps
                    </a>
                  </td> */}
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.menu_name}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.menu_description}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    Rp {order?.menu_price.toLocaleString('id-ID')}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.quantity}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {order?.region}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <style>{`
        @media print {
          body {
            background-color: #fff;
            padding: 0px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #000;
            padding: 1px;
            font-size: 7pt;
            word-wrap: break-word;
          }
          thead {
            background-color: #ddd !important;
            color: black !important;
          }
          @page {
            size: A4 landscape;
          }
          a {
            text-decoration: none;
            color: black;
          }
        }
      `}</style>
    </div>
  )
}

export default DailyServiceOrders
