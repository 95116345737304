/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const AksesDataPribadi = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="akses-data-pribadi">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        4. Akses ke Data Pribadi Pemesan
      </div>
      <div className="my-2">
        Pemesan mendapatkan kendali penuh atas Data Pribadi yang Pemesan bagikan
        kepada kami. Semua Data Pribadi tersebut diharapkan merupakan data yang
        paling terbaru dan valid. Pemesan dapat melakukan perubahan data
        sewaktu-waktu pada Situs.
      </div>
    </TabContent>
  )
})

export default AksesDataPribadi
