import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from 'components/button/LoadingButton'
import Form from 'components/forms/Form'
import Input from 'components/forms/Input'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isNumber } from 'utils/helpers'
import * as yup from 'yup'

import FooterHelp from './FooterHelp'

function ForgotPasswordPhoneContent({
  goNextStep,
  loadingButton,
  errorMessage,
  phoneNumber,
}) {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .required('Wajib diisi')
      .test('len', 'Harap cek nomor handphonemu', (val) => {
        if (val == undefined) {
          return true
        }
        return val.length == 0 || val.length >= 8
      }),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      phone: phoneNumber || '',
    },
  })

  const handleSubmitForm = (data) => {
    goNextStep && goNextStep(data)
  }

  return (
    <div className="flex flex-col w-full">
      <h4 className="text-lg text-black font-semibold mb-1">
        Lupa Kata Sandi?
      </h4>
      <span className="text-sm text-black leading-6">
        Masukkan nomor handphonemu, kami
        <br />
        akan kirimkan kode verifikasi
      </span>

      <Form>
        <div className="mt-12 mb-9">
          <Controller
            name="phone"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Nomor Handphone"
                autoComplete="off"
                name="phone"
                error={errors.phone && errors.phone.message}
                onKeyDown={(e) => isNumber(e)}
              />
            )}
          />
        </div>
        <LoadingButton
          className="!px-2 md:!px-3 !py-3 font-semibold !w-full"
          isRounded={true}
          isLoading={loadingButton}
          onClick={handleSubmit(handleSubmitForm)}
        >
          Lanjut
        </LoadingButton>
      </Form>
      <FooterHelp errorMessage={errorMessage} />
    </div>
  )
}

export default ForgotPasswordPhoneContent
