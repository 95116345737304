/* eslint-disable no-unused-vars */
import Loading from 'components/layouts/fragments/Loading'
import PaymentLayout from 'components/layouts/PaymentLayout'
import { useCartSystem } from 'modules/custom/useCartSystem'
import { useOrder } from 'modules/custom/useOrder'
import { useFetchUserProfileMutation } from 'modules/User/hook'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CartDetail from './components/CartDetail'
import CustomerDetail from './components/CustomerDetail'
import CartResponsive from './components/fragments/CartResponsive'
import PaymentDetail from './components/PaymentDetail'
import RecipientDetail from './components/RecipientDetail'
import RequestForm from './components/RequestForm'

const PaymentInfo = () => {
  const navigate = useNavigate()
  const {
    nama,
    setNama,
    phone,
    setPhone,
    deliver,
    setDeliver,
    dataUser,
    setDataUser,
    userProfile,
  } = useOrder()
  const { cart, partner, calculateTotal, adjustShippingCost } = useCartSystem()
  const [location] = useState(JSON.parse(localStorage.getItem('dataAddress')))
  const [errorMessage] = useState(<p className="text-end">Wajib diisi</p>)
  const [errorName, setErrorName] = useState(false)
  const [errorPhone, setErrorPhone] = useState(false)
  const [errorPayment, setErrorPayment] = useState(false)
  const [error, setError] = useState(false)

  const [andalanTmp, setAndalanTmp] = useState(
    JSON.parse(sessionStorage.getItem('andalanTmp')) || null
  )
  const [harianTmp, setHarianTmp] = useState(
    JSON.parse(sessionStorage.getItem('harianTmp')) || null
  )
  const [tambahanTmp, setTambahanTmp] = useState(
    JSON.parse(sessionStorage.getItem('tambahanTmp')) || null
  )

  const profileMutations = useFetchUserProfileMutation()

  // handleSubmit
  const handleSubmit = () => {
    if (dataUser.recipient_name.length == 0) {
      setErrorName(errorMessage)
    }
    if (dataUser.recipient_phone.length == 0) {
      setErrorPhone(errorMessage)
    }
    if (
      dataUser.payment_type.length == 0 ||
      dataUser.payment_bank === 'no account'
    ) {
      setErrorPayment(true)
    }
    if (
      dataUser.recipient_name.length == 0 ||
      dataUser.recipient_phone.length == 0 ||
      dataUser.payment_type.length == 0 ||
      dataUser.payment_bank === 'no account'
    ) {
      return setError(true)
    }
    navigate('/payment-confirmation')
  }

  // delete
  useEffect(() => {
    calculateTotal(cart)
    localStorage.removeItem('click')
    setDataUser({
      ...dataUser,
      partner_id: partner.partner_id,
      partner_type_id: partner.partner_type_id,
      partner_name: partner.name,
    })

    if (
      andalanTmp !== null &&
      andalanTmp.length > 0 &&
      andalanTmp !== undefined
    ) {
      adjustShippingCost(andalanTmp)
    }
    if (harianTmp !== null && harianTmp.length > 0 && harianTmp !== undefined) {
      adjustShippingCost(harianTmp)
    }
    if (
      tambahanTmp !== null &&
      tambahanTmp.length > 0 &&
      tambahanTmp !== undefined
    ) {
      adjustShippingCost(tambahanTmp)
    }
    sessionStorage.removeItem('andalanTmp')
    sessionStorage.removeItem('harianTmp')
    sessionStorage.removeItem('tambahanTmp')
    localStorage.setItem('data-user', JSON.stringify(dataUser))
  }, [])

  useEffect(() => {
    if (errorName !== false && dataUser.recipient_name.length > 0) {
      setErrorName(false)
      setError(false)
    }

    if (errorPhone !== false && dataUser.recipient_phone.length > 0) {
      setErrorPhone(false)
      setError(false)
    }

    if (
      errorPayment !== false &&
      dataUser.payment_bank.length >= 2 &&
      dataUser.payment_type !== ''
    ) {
      setErrorPayment(false)
      setError(false)
    }

    if (!errorName && !errorPhone && !errorPayment) setError(false)
    // localStorage.setItem('data-user', JSON.stringify(dataUser))
  }, [dataUser])

  console.log(dataUser)

  useEffect(() => {
    profileMutations.mutate(
      {},
      {
        onSuccess: (data) => {
          if (
            dataUser.recipient_name.length == 0 ||
            dataUser.recipient_phone.length == 0
          ) {
            setDataUser({
              ...dataUser,
              recipient_name: data.recipient_name,
              recipient_phone: data.recipient_phone,
              delivery_to: data.delivery_to,
              pic_email: data.email,
              company_name: data.company_name,
              delivery_address: location.formattedAddress,
              detail_location: location.detailLocation,
              lat: location.lat,
              long: location.lng,
              region_name: location.city,
              location_pin: location.locationPin,
            })
          }
        },
      },
      { onError: (err) => console(err) }
    )
  }, [])

  if (userProfile?.isLoading)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
    )

  if (!userProfile?.isLoading)
    return (
      <React.Fragment>
        <PaymentLayout
          header="Info Kirim & Bayar"
          headForm="Informasi Pengiriman dan Pembayaran"
          descriptionForm="Silakan isi informasi dengan lengkap"
        >
          <div className="container border-b">
            <div className="grid grid-cols-5 md:py-6 pb-10 gap-11">
              <div className="col-span-5 md:col-span-3 flex flex-col gap-6">
                <CustomerDetail
                  dataUser={userProfile}
                  user={dataUser}
                  setUser={setDataUser}
                />
                <RecipientDetail
                  errorName={errorName}
                  errorPhone={errorPhone}
                  nama={nama}
                  setNama={setNama}
                  phone={phone}
                  setPhone={setPhone}
                  deliver={deliver}
                  setDeliver={setDeliver}
                  dataUser={dataUser}
                  setDataUser={setDataUser}
                  userProfile={userProfile}
                />
                <RequestForm user={dataUser} setUser={setDataUser} />
                <PaymentDetail
                  user={dataUser}
                  setUser={setDataUser}
                  errorPayment={errorPayment}
                />
                <div className="fixed md:static block w-full left-0 px-4 md:h-28 bottom-0 shadow-top-only py-4 md:py-0 bg-white md:bg-transparent z-[53] md:shadow-none">
                  <button
                    onClick={() => handleSubmit()}
                    className="flex w-full bg-primary text-white justify-center md:mt-8 hover:opacity-80 text-sm font-semibold py-3 rounded-full"
                  >
                    Selanjutnya
                  </button>
                  {error && (
                    <p className="text-primary text-[9px] md:text-[11px] text-center pt-3">
                      Ada informasi yang belum diisi (informasi penerima atau
                      pembayaran)
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-5 md:col-span-2">
                <div className="hidden md:block">
                  <CartDetail />
                </div>
                <div className="block md:hidden">
                  <CartResponsive />
                </div>
              </div>
            </div>
          </div>
        </PaymentLayout>
      </React.Fragment>
    )
}

export default PaymentInfo
