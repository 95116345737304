import Tooltip from 'components/layouts/fragments/Tooltip'
import location from 'images/menu/location.svg'
import order from 'images/menu/min-order.svg'
import shiping from 'images/menu/shipping.svg'
import React from 'react'
import { currency } from 'utils/formatNumber'

const FeaturedMitra = ({
  distance,
  unit,
  cost_from,
  cost_to,
  minOrder,
  type,
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-1 md:gap-3">
      <div className="flex flex-row gap-2 md:gap-1 items-center text-xs md:text-sm">
        <img src={location} alt="location" className="w-4 h-4" />
        <p>
          Jarak {distance} {unit} ke lokasi pengiriman{' '}
        </p>
      </div>
      <div className="flex flex-row gap-2 md:gap-1 items-center text-xs md:text-sm">
        <img src={shiping} alt="shiping" className="w-4 h-4 md:w-5 md:h-5" />
        <div className="flex">
          <p>
            {cost_to !== 0 && cost_to !== null
              ? `Estimasi Ongkir ${currency(cost_from)} - ${currency(cost_to)}`
              : 'Gratis Ongkir'}
          </p>
          {cost_to > 0 && cost_from == 0 && (
            <Tooltip>
              Bisa gratis ongkir jika <br /> pesan lebih banyak
            </Tooltip>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2 md:gap-1 items-center text-xs md:text-sm">
        <img src={order} alt="order" className="w-4 h-4 md:w-5 md:h-5" />
        <div className="flex">
          <p>{minOrder}</p>
          {type === 2 && (
            <Tooltip>
              Bisa digabungkan dengan <br />
              menu yang lain selama
              <br /> satu pengiriman dalam
              <br /> restoran yang sama
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeaturedMitra
