import $axios from 'utils/axios'

export const verifyWhatsappCustomer = async (params) => {
  const result = await $axios.get('/order/wa/microsite-link/decrypt', {
    params: params,
  })
  return result.data
}

export const fetchMenuCategory = async () => {
  const result = await $axios.get('/partner/microsite/menu-category/list')

  return result.data.result
}

export const fetchPermanentMenu = async (category_id, lat, lon) => {
  const result = await $axios.get(
    `/partner/microsite/menu/list?category_id=${category_id}&lat=${lat}&lon=${lon}`
  )

  return result.data.result
}

export const getPartnerList = async (data) => {
  const result = await $axios.post('/partner/microsite/list', data)

  return result.data
}

export const getPartnerMenuShipping = async (data) => {
  const result = await $axios.post(
    '/partner/microsite/menu/shipping-cost/detail',
    data
  )
  return result.data
}

export const createOrder = async (data) => {
  const result = await $axios.post('/order/microsite', data)
  return result.data
}

export const getUniqueCodePayment = async () => {
  const result = await $axios.get('/order/microsite/unique-code')

  return result.data.result
}
