import React from 'react'
import { useNavigate } from 'react-router-dom'

const OtherItem = (data) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => navigate(`/detail-mitra/${data.data.partner_id}`)}
      className="rounded-2xl cursor-pointer flex-shrink-0 w-40 md:w-48 shadow-menu-item flex gap-3 flex-col"
    >
      <img
        src={data.data.menu_photo}
        alt="ytt"
        className="md:w-48 md:h-48 w-40 h-40 rounded-t-2xl"
      />
      <div className="flex flex-col px-5 pb-6 gap-6">
        <h5 className="text-sm truncate">{data.data.name || 'nama mitra'}</h5>
        <p className="text-xs text-gray-400">
          {data.data.distance || 0.5} {data.data.distance_unit || 'km'}
        </p>
      </div>
    </div>
  )
}

export default OtherItem
