import React from 'react'

const TabMenu = ({ index, setIndex, borderBottom, classWrapper }) => {
  return (
    <div className={`w-full ${borderBottom ? 'border-b' : ''}`}>
      <div
        className={`grid grid-cols-3 md:flex justify-center w-full ${classWrapper} text-center text-gray-400 font-normal`}
      >
        <div
          className={`p-2 md:p-4 text-sm md:text-base cursor-pointer ${
            index == ''
              ? 'border-b-[3px] md:border-b-4 text-black border-primary'
              : ''
          } `}
          onClick={() => (index != '' ? setIndex('') : null)}
        >
          Semua
        </div>
        <div
          className={`p-2 md:p-4 text-sm md:text-base cursor-pointer ${
            index == 1
              ? 'border-b-[3px] md:border-b-4 text-black border-primary'
              : ''
          } `}
          onClick={() => (index == 1 ? null : setIndex(1))}
        >
          Catering
        </div>
        <div
          className={`p-2 md:p-4 text-sm md:text-base cursor-pointer ${
            index == 2
              ? 'border-b-[3px] md:border-b-4 text-black border-primary'
              : ''
          } `}
          onClick={() => (index == 2 ? null : setIndex(2))}
        >
          Restoran
        </div>
      </div>
    </div>
  )
}

export default TabMenu
