import React, { useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { MONTH_NAMES } from 'utils/date'
import { currency } from 'utils/formatNumber'

import CustomForm from '../../../components/forms/CustomForm'
import Accordion from './fragments/Accordion'
import ChangeCateringModal from './fragments/ChangeCateringModal'
import DateCart from './fragments/DateCart'
import MenuList from './fragments/MenuList'

const CartDetail = ({
  addProduct,
  updateQuantity,
  removeProduct,
  partner,
  cart,
  grandTotal,
  setDateParams,
  changeDateCart,
  changeTimeCart,
}) => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [showChangeCateringModal, setShowChangeCateringModal] = useState(false)
  const [ongkir] = useState(localStorage.getItem('ongkir'))
  const token = localStorage.getItem('token')
  const [quantity, setQuantity] = useState(0)
  const [selectedCatering, setSelectedCatering] = useState('')

  // console.log(cart)
  const addMenu = (time, date) => {
    setDateParams(date)
    sessionStorage.setItem('time', JSON.stringify(time))
    sessionStorage.setItem('date', JSON.stringify(date))
    navigate(`/microsite?token=${token}`)
  }

  const handleSelectCatering = (name) => {
    setSelectedCatering(name)
  }
  return (
    <CustomForm title="Detail Pesanan" classContainer="px-6">
      <div className="flex flex-col py-3 border-t border-b">
        <div className="text-xs text-gray-400 mb-2">Nama Mitra</div>
        <h6 className="text-lg">{selectedCatering || partner?.name}</h6>
        <div className="text-gray-400 text-xs">
          Ingin ganti catering?{' '}
          <span
            className="text-primary cursor-pointer"
            onClick={() => setShowChangeCateringModal(true)}
          >
            Klik di sini
          </span>
        </div>
      </div>
      {cart.map((data, key) => {
        let fulfilled = false
        return (
          <>
            <DateCart
              cart={cart}
              date={data.date}
              time={data.shipping_time}
              qty={data.qty_total}
              shipping={data.shipping_cost}
              changeTimeCart={changeTimeCart}
              changeDateCart={changeDateCart}
            />
            <Accordion
              key={key}
              title={
                <div className="col-span-3 text-sm font-medium">
                  Rincian Pesanan
                </div>
              }
              description={data.menus.map((menu, key) => (
                <MenuList
                  key={key}
                  qty={menu.qty}
                  name={menu.name}
                  qty_unit_name={menu.qty_unit_name}
                  free_shipping_cost={menu.free_shipping_cost}
                  available={menu.available}
                  max_order={menu.max_order}
                  min_order={menu.min_order}
                  disabled_up={menu.disabled_up}
                  add={addMenu}
                  price={menu.price}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  fulfilled={fulfilled}
                  addProduct={() =>
                    addProduct(menu, data.date, data.shipping_time)
                  }
                  updateQuantity={() =>
                    updateQuantity(
                      menu,
                      data.date,
                      data.shipping_time,
                      quantity
                    )
                  }
                  removeProduct={() =>
                    removeProduct(menu, data.date, data.shipping_time)
                  }
                />
              ))}
            />
            <button
              onClick={() => addMenu(data.shipping_time, data.date)}
              className="flex w-full border-2 py-1 justify-center gap-1 items-center text-primary rounded-full border-primary font-semibold text-sm mt-4 mb-8"
            >
              <BiPlus /> Tambah Pesanan
            </button>
          </>
        )
      })}
      <Accordion
        title={
          <div className="grid grid-cols-4 gap-3 text-sm">
            <div className="col-span-3 font-medium">Ongkos Kirim</div>
            <div className="col-span-1 font-bold">
              {currency(Number(ongkir))}
            </div>
          </div>
        }
        description={
          <React.Fragment>
            <div className="grid grid-cols-4 gap-3 text-xs text-gray-400">
              <div className="col-span-3">Tanggal dan Waktu</div>
              <div className="col-span-1">Harga</div>
            </div>
            <div className="flex flex-col mt-1 pb-1 gap-1">
              {cart.map((data, key) => (
                <div
                  key={key}
                  className="grid grid-cols-4 gap-3 text-sm font-medium"
                >
                  <div className="col-span-3">{`${new Date(
                    data.date
                  ).getDate()} ${MONTH_NAMES[
                    new Date(data.date).getMonth()
                  ].slice(0, 3)} ${new Date(
                    data.date
                  ).getFullYear()}, ${data.shipping_time.replaceAll(
                    '.',
                    ':'
                  )}`}</div>
                  <div className="col-span-1">
                    {currency(data.shipping_cost)}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        }
      />
      <div className="mt-10 flex flex-col gap-1">
        <p className="text-sm">Total Pembayaran</p>
        <h4 className="text-primary font-bold text-2xl">
          {currency(grandTotal)}
        </h4>
      </div>
      <ChangeCateringModal
        showModal={showChangeCateringModal}
        setShowModal={setShowChangeCateringModal}
        onSelectCatering={handleSelectCatering}
      />
    </CustomForm>
  )
}

export default CartDetail
