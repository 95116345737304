import { Scrollspy } from '@makotot/ghostui'
import InfoLayout from 'components/layouts/InfoLayout'
import Tabs from 'components/tabs/Index'
import TabSidebarItem from 'components/tabs/TabSidebarItem'
import React from 'react'
import { useMemo, useRef, useState } from 'react'

import BatasTanggungJawab from './fragments/BatasTanggungJawab'
import Definisi from './fragments/Definisi'
import General from './fragments/General'
import Hukum from './fragments/Hukum'
import Kerahasiaan from './fragments/Kerahasiaan'
import KetentuanHarga from './fragments/KetentuanHarga'
import KetentuanLainnya from './fragments/KetentuanLainnya'
import KetentuanPenagihan from './fragments/KetentuanPenagihan'
import Pajak from './fragments/Pajak'
import PembatalanMakanan from './fragments/PembatalanMakanan'
import PemesananMakanan from './fragments/PemesananMakanan'
import Pengantaran from './fragments/Pengantaran'
import Pernyataan from './fragments/Pernyataan'

function TnC() {
  // ACTIVE TAB
  const [activeTab, selectActiveTab] = useState('snk')

  // REF FOR ALL SECTION CONTENT
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  // TABS CONTENT
  const tabsItem = useMemo(
    () => [
      {
        id: 'snk',
        title: 'Syarat dan Ketentuan',
        redirectTo: '/tnc',
      },
      {
        id: 'kp',
        title: <>Kebijakan {window.innerWidth < 378 && <br />} Privasi</>,
        redirectTo: '/privacy-policy',
      },
    ],
    []
  )

  // SIDEBAR ITEMS
  const sidebarItem = useMemo(() => [
    {
      id: 'definisi',
      title: 'Definisi',
    },
    {
      id: 'pernyataan',
      title: 'Pernyataan dan Jaminan',
    },
    {
      id: 'ketentuan-harga',
      title: 'Ketentuan Harga',
    },
    {
      id: 'pemesanan-makanan',
      title: 'Pemesanan Makanan',
    },
    {
      id: 'pembatalan-pesanan',
      title: 'Pembatalan Pesanan',
    },
    {
      id: 'pengantaran',
      title: 'Pengantaran',
    },
    {
      id: 'ketentuan-penagihan',
      title: 'Ketentuan Penagihan Dan Pembayaran',
    },
    {
      id: 'pajak',
      title: 'Pajak',
    },
    {
      id: 'batas-tanggung-jawab',
      title: 'Batas Tanggung Jawab',
    },
    {
      id: 'hukum',
      title: 'Hukum, Jurisdiksi Dan Bahasa Yang Berlaku',
    },
    {
      id: 'kerahasiaan',
      title: 'Kerahasiaan Dan Hak Kekayaan Intelektual',
    },
    {
      id: 'ketentuan-lainnya',
      title: 'Ketentuan Lainnya',
    },
  ])

  // SELECT TAB
  const selectTab = (id) => {
    selectActiveTab(id)
  }

  return (
    <InfoLayout withContainer={false} isFaq={false}>
      <div className="md:pt-[30px] md:container">
        {/* TABS */}
        <Tabs
          activeTab={activeTab}
          onClick={selectTab}
          tabsItem={tabsItem}
          classItems={`w-1/2 md:w-auto`}
          classItem={`w-full`}
        />
        {/* END TABS */}
      </div>
      {/* SCROLLSPY WRAPPER */}
      <Scrollspy sectionRefs={sectionRefs}>
        {({ currentElementIndexInViewport }) => (
          <div className="bg-white py-10">
            <div className="container px-6 md:px-4">
              <div className="w-full flex relative">
                <div className="w-3/12 sticky top-5 pl-4 h-[500px] hidden md:block">
                  {sidebarItem.map((item, index) => (
                    <TabSidebarItem
                      key={index}
                      title={`${item.title}`}
                      id={item.id}
                      active={currentElementIndexInViewport == index}
                    />
                  ))}
                </div>
                <div className="w-full md:w-9/12">
                  <General />
                  <Definisi ref={sectionRefs[0]} className="my-10" />
                  <Pernyataan ref={sectionRefs[1]} className="my-10" />
                  <KetentuanHarga ref={sectionRefs[2]} className="my-10" />
                  <PemesananMakanan ref={sectionRefs[3]} className="my-10" />
                  <PembatalanMakanan ref={sectionRefs[4]} className="my-10" />
                  <Pengantaran ref={sectionRefs[5]} className="my-10" />
                  <KetentuanPenagihan ref={sectionRefs[6]} className="my-10" />
                  <Pajak ref={sectionRefs[7]} className="my-10" />
                  <BatasTanggungJawab ref={sectionRefs[8]} className="my-10" />
                  <Hukum ref={sectionRefs[9]} className="my-10" />
                  <Kerahasiaan ref={sectionRefs[10]} className="my-10" />
                  <KetentuanLainnya ref={sectionRefs[11]} className="my-10" />
                  <div className="md:px-10 text-sm leading-6 relative after:content-[''] after:absolute after:-top-4 after:left-0 after:h-[1px] after:w-1/3 after:bg-black">
                    <sup>i</sup> Syarat dan Ketentuan KendiXpress ini terakhir
                    kali diubah pada tanggal 26 Oktober 2022.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Scrollspy>
      {/* END SCROLLSPY WRAPPER */}
    </InfoLayout>
  )
}

export default TnC
