import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'

import React, { useEffect } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import InnerImageZoom from 'react-inner-image-zoom'
import { currency } from 'utils/formatNumber'

const PopupMenu = ({ show, setShow, menu }) => {
  useEffect(() => {
    let body = document.querySelector('body')
    let modal = document.querySelector('.modal')

    if (modal) {
      body.classList.add('overflow-hidden')
    } else {
      body.classList.remove('overflow-hidden')
    }
  }, [show])

  return (
    <React.Fragment>
      {show ? (
        <div className="inset-0 z-50 p-4 md:p-10 w-screen h-screen flex fixed bg-black bg-opacity-40 modal">
          <div className="bg-white relative md:justify-between w-fit max-w-5xl mx-auto h-fit my-auto md:min-h-[70vh] md:max-h-full flex flex-col md:flex-row transition-all duration-200 gap-5 md:gap-8 p-4 md:p-7 rounded-2xl">
            <button
              onClick={() => setShow(false)}
              className="absolute block md:hidden -top-10 right-0"
            >
              <IoCloseSharp className="w-7 h-7 text-white" />
            </button>
            <div className="md:h-full flex md:hidden">
              <img
                src={menu.photo}
                alt="tumpeng"
                className=" md:min-w-[500px] min-w-[250px] w-full md:max-w-[500px] object-cover bg-center max-h-[300px] md:max-h-[500px]"
              />
            </div>
            <div className="md:h-full hidden md:flex cursor-zoom-in overflow-hidden min-w-[512px] max-w-lg items-center">
              <InnerImageZoom
                src={menu.photo}
                zoomSrc={menu.photo}
                zoomType="hover"
                hideHint={true}
              />
            </div>
            <div className=" flex flex-col gap-2 justify-center">
              <h4 className="text-sm md:text-2xl font-semibold">{menu.name}</h4>
              <p className="text-xs mb-2 md:mb-0 md:text-sm opacity-80">
                {menu.content?.replaceAll('\n', ', ')}
              </p>
              <div className="flex w-full flex-row md:flex-col">
                <p className="text-sm md:text-lg text-primary md:mt-10 font-semibold">
                  {currency(menu.price)}
                </p>
                <p className="text-xs md:text-sm hidden md:flex">
                  <span className="mr-1">per </span> {menu.qty_unit_name}{' '}
                  <span className="text-xs md:text-sm text-gray-400 ml-0 md:ml-2">
                    (min {menu.min_order})
                  </span>
                </p>
                <p className="text-xs md:text-sm md:hidden flex justify-end items-end">
                  <span className="">/</span>
                  {menu.qty_unit_name}{' '}
                  <span className="text-xs md:text-sm text-gray-400 ml-2">
                    (min {menu.min_order})
                  </span>
                </p>
              </div>
            </div>
            <div className="hidden md:flex justify-start">
              <button onClick={() => setShow(false)} className="h-fit">
                <IoCloseSharp size={28} className="text-primary font-bold" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default PopupMenu
