import { useMutation, useQuery } from 'react-query'

import {
  createPasswordUser,
  fetchPhoneExist,
  loginUser,
  registerUser,
  validateManualUser,
} from './api'

export const useFetchPhoneExist = (params) => {
  return useQuery(['phone-exists', params], () => fetchPhoneExist(params))
}

export const useFetchPhoneExistMutation = () => {
  return useMutation((params) => fetchPhoneExist(params))
}

export const useRegisterUser = () => {
  return useMutation(registerUser)
}

export const useValidateManualUser = () => {
  return useMutation((params) => validateManualUser(params))
}

export const useLoginUser = () => {
  return useMutation(loginUser)
}

export const useCreatePasswordUser = () => {
  return useMutation(createPasswordUser)
}
