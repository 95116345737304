/* eslint-disable no-unused-vars */
import { data } from 'autoprefixer'
import UserLayout from 'components/layouts/UserLayout'
import BuildingIcon from 'images/icons/building.svg'
import EmailIcon from 'images/icons/email.svg'
import GuestIcon from 'images/icons/guest.svg'
import PhoneIcon from 'images/icons/phone.svg'
import { useFetchUserProfile } from 'modules/User/hook'
import React from 'react'

import Card from './components/Card'
import InfoItems from './components/InfoItems'
import Sidebar from './components/Sidebar'

function Profile() {
  const dataHook = useFetchUserProfile()

  return (
    <UserLayout>
      <div className="flex flex-wrap py-6 md:py-10">
        {/* DESKTOP */}
        <div className="w-4/12 pr-11 hidden md:block">
          {/* SIDEBAR DESKTOP */}
          <Sidebar
            name={dataHook.data && dataHook.data.name}
            isLoading={dataHook.isFetching}
          />
          {/* END SIDEBAR DESKTOP */}
        </div>
        <div className="w-8/12 hidden md:block">
          <Card className={`py-10 px-12`}>
            {/* TITLE */}
            <div className="mb-[32px] border-b pb-6">
              <h1 className="text-[26px] leading-9 text-black font-semibold">
                Detail Profil
              </h1>
              <div className="text-sm text-black mt-1">
                Untuk mengganti profilmu{' '}
                <a
                  href="https://wa.me/6281119279900"
                  target={`_blank`}
                  className="text-primary"
                >
                  hubungi admin
                </a>
              </div>
            </div>
            {/* END TITLE */}
            {/* INFO SECTION */}
            <div className="mb-[60px]">
              <InfoItems
                title={`Nama`}
                desc={dataHook.data && dataHook.data.name}
                icon={GuestIcon}
                isLoading={dataHook.isFetching}
              />
              <InfoItems
                title={`Nomor Handphone`}
                desc={dataHook.data && dataHook.data.phone}
                icon={PhoneIcon}
                isLoading={dataHook.isFetching}
              />
              {dataHook.data && dataHook.data.email && (
                <>
                  <InfoItems
                    title={`Email`}
                    desc={dataHook.data && dataHook.data.email}
                    icon={EmailIcon}
                    isLoading={dataHook.isFetching}
                  />
                </>
              )}
              {dataHook.data && dataHook.data.company_name && (
                <>
                  <InfoItems
                    title={`Nama Perusahaan`}
                    desc={dataHook.data && dataHook.data.company_name}
                    icon={BuildingIcon}
                    isLoading={dataHook.isFetching}
                  />
                </>
              )}
            </div>
            {/* END INFO SECTION */}
          </Card>
        </div>
        {/* END DESKTOP */}
        {/* MOBILE */}
        <div className="w-full block md:hidden">
          {/* TITLE */}
          <div className="mb-6 border-b pb-6">
            <div className="text-xs text-black mt-1">
              Untuk mengganti profilmu{' '}
              <a
                href="https://wa.me/6281119279900"
                target={`_blank`}
                className="text-primary"
              >
                hubungi admin
              </a>
            </div>
          </div>
        </div>
        {/* END TITLE */}
        {/* INFO SECTION */}
        <div className="w-full block md:hidden">
          {dataHook.isFetching && (
            <div role="status" className="space-y-2.5 animate-pulse w-full">
              <div className="h-8 bg-gray-300 rounded-lg w-full"></div>
            </div>
          )}
          {!dataHook.isFetching && (
            <h3 className="text-xl text-black font-semibold">
              {dataHook.data && dataHook.data.name}
            </h3>
          )}
          <InfoItems
            title={`Nomor Handphone`}
            desc={dataHook.data && dataHook.data.phone}
            icon={PhoneIcon}
            isLoading={dataHook.isFetching}
          />
          {dataHook.data && dataHook.data.email && (
            <>
              <InfoItems
                title={`Email`}
                desc={dataHook.data && dataHook.data.email}
                icon={EmailIcon}
                isLoading={dataHook.isFetching}
              />
            </>
          )}
          {dataHook.data && dataHook.data.company_name && (
            <>
              <InfoItems
                title={`Nama Perusahaan`}
                desc={dataHook.data && dataHook.data.company_name}
                icon={BuildingIcon}
                isLoading={dataHook.isFetching}
              />
            </>
          )}
        </div>
        {/* END INFO SECTION */}
        {/* END MOBILE */}
      </div>
    </UserLayout>
  )
}

export default Profile
