/* eslint-disable no-unused-vars */
import AuthModal from 'components/modal/Auth'
import { useCartSystem } from 'modules/custom/useCartSystem'
import { useFirebaseHook } from 'modules/custom/useFirebaseHook'
import { useOrder } from 'modules/custom/useOrder'
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { dateGMT, MONTH_NAMES } from 'utils/date'
import { currency } from 'utils/formatNumber'

import DateTimePicker from './DateTimePicker'
import DateCart from './fragments/DateCart'
import MenuCart from './fragments/MenuCart'

const CartItem = ({
  defaultDate,
  time,
  cart,
  partner,
  datePick,
  setDatePick,
  setDateParam,
  setTime,
  grandTotal,
  addProduct,
  updateQuantity,
  removeByDate,
  removeProduct,
  changeDateCart,
  changeTimeCart,
}) => {
  const token = localStorage.getItem('token')
  const [paramsFrom, setParamsFrom] = useSearchParams()
  const dataparam = paramsFrom.get('ref')
  const navigate = useNavigate()

  const { handlePayment } = useCartSystem()
  const { differentDate } = useOrder()
  const { setClickPayment } = useFirebaseHook()
  const [showModalAuth, setShowModalAuth] = useState(false)

  const [alertMenuUtama, setAlertMenuUtama] = useState(false)
  const [alertMinOrder, setAlertMinOrder] = useState(false)
  const [alertMenuNotAvail, setAlertMenuNotAvail] = useState(false)
  const [lateOrder, setLateOrder] = useState(false)
  const [dateLate, setDateLate] = useState('')
  const [checkMenuAndalan, setCheckMenuAndalan] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const minOrder = partner.info_min_order
  const findMenu = cart?.flatMap((menu) => menu.menus)

  const cekData = cart?.map((data) => data.menus)

  const checkNotAvail = findMenu.findIndex((x) => x.available === false)

  const [dateMinimum, setDateMinimum] = useState(Date.now())
  const dateDay = new Date(dateMinimum).getDate()
  const dateMonth = new Date(dateMinimum).getMonth()
  const [minimumPrice, setMinimumPrice] = useState(0)

  const [dateIndex, setDateIndex] = useState(new Date(defaultDate))

  const [quantity, setQuantity] = useState(0)

  const [isOpen, setIsOpen] = useState(false)
  let maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() + 3)
  let minDate = new Date()

  const order = parseInt(
    minOrder?.replaceAll('Min order per pengiriman Rp ', '').replaceAll('.', '')
  )
  const checkTotalMinimum = cart?.filter((data) => {
    if (data.sub_total < order) {
      return data
    }
  })

  const handleCheckSubmit = () => {
    if (partner.partner_type_id === 2) {
      if (checkTotalMinimum.length == 0) {
        handlePayment(cart)
        if (!token) {
          setClickPayment(true)
          localStorage.setItem('click', true)
          setShowModalAuth(true)
        } else {
          dataparam == 'confirm'
            ? navigate('/payment-confirmation')
            : navigate(`/payment-info`)
        }
      } else {
        setMinimumPrice(parseInt(order - checkTotalMinimum[0].sub_total))
        setDateMinimum(checkTotalMinimum[0].date)
        setAlertMenuNotAvail(false)
        setAlertMinOrder(true)
      }
    } else {
      if (checkMenuAndalan) {
        setAlertMenuNotAvail(false)
        setAlertMenuUtama(true)
      } else {
        handlePayment(cart)
        if (!token) {
          setClickPayment(true)
          localStorage.setItem('click', true)
          setShowModalAuth(true)
        } else {
          dataparam == 'confirm'
            ? navigate('/payment-confirmation')
            : navigate(`/payment-info`)
        }
      }
    }
  }

  // add menu in same time & date
  const addMenu = (date, time) => {
    setDatePick(date)
    sessionStorage.setItem('dateparams', JSON.stringify(dateGMT(date)))
    setDateParam(dateGMT(date))
    setTime(time)
    sessionStorage.setItem('date', JSON.stringify(date))
    sessionStorage.setItem('time', JSON.stringify(time))
  }

  const handleSave = () => {
    sessionStorage.setItem('time', JSON.stringify(time))
    sessionStorage.setItem('dateparams', JSON.stringify(dateGMT(dateIndex)))
    setDatePick(dateIndex)
    setDateParam(dateGMT(dateIndex))
    setIsOpen(false)
  }

  let dateIdx = 0

  useEffect(() => {
    let dateHour

    const interval = setInterval(() => {
      if (new Date().getHours() >= 14 && new Date().getMinutes() >= 1) {
        minDate.setDate(minDate.getDate() + 1)
      }

      const dataLate = cart?.find((data) => {
        if (new Date().getHours() >= 14 && new Date().getMinutes() >= 1) {
          if (differentDate(data.date) < 2) return data
        } else {
          if (differentDate(data.date) < 1) return data
        }
      })

      if (dataLate) {
        setLateOrder(true)
        setDateLate(dataLate)
      } else {
        setLateOrder(false)
      }

      dateHour = new Date().getSeconds()
      dateIdx++
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [dateIdx])

  useEffect(() => {
    let dataTmp = []
    for (let a = 0; a < cekData.length; a++) {
      const cek = cekData[a].findIndex(
        (x) => (x.is_main_menu == true && x.type == 2) || x.type == 1
      )
      if (cek == -1) {
        dataTmp.push({ data: true })
      }
    }
    const cekDataTmp = dataTmp.findIndex((a) => a.data == true)
    if (cekDataTmp >= 0) {
      setCheckMenuAndalan(true)
    } else {
      setCheckMenuAndalan(false)
      setAlertMenuUtama(false)
    }

    if (cart?.length > 0) {
      setMinimumPrice(parseInt(order - checkTotalMinimum[0]?.sub_total))
    }

    // setTimeout(() => {
    const menu = cart.flatMap((data) => data.menus)
    const menuFilter = menu.filter((item) => item.available === false)
    menuFilter.length > 0
      ? setAlertMenuNotAvail(true)
      : setAlertMenuNotAvail(false)
    if (menu.length === 1 && menuFilter.length > 0) {
      setDisableButton(true)
    } else {
      setDisableButton(false)
    }
    // }, 500)

    checkTotalMinimum.length == 0 && setAlertMinOrder(false)
  }, [cart, changeDateCart, checkNotAvail, checkTotalMinimum])

  return (
    <React.Fragment>
      <div className="h-3/4 lg:h-[450px] px-4 lg:px-5 relative pb-4 overflow-y-auto pr-2 scrollbar">
        {cart?.map((data, key) => {
          // free ongkir hidden if any one is true
          let fulfilled = false
          const filterFreeOngkir = data.menus.filter(
            (menu) =>
              menu.qty >= menu.free_shipping_cost &&
              menu.free_shipping_cost !== 0
          )
          const flat = filterFreeOngkir.flatMap((data) => data)
          if (flat.length > 0) {
            fulfilled = true
          } else {
            fulfilled = false
          }
          return (
            <section key={key}>
              <div className=" pb-3">
                <DateCart
                  cart={cart}
                  date={data.date}
                  time={data.shipping_time}
                  shipping={data.shipping_cost}
                  removeByDate={removeByDate}
                  changeDateCart={changeDateCart}
                  changeTimeCart={changeTimeCart}
                />
                {data.menus.map((menu, key) => (
                  <MenuCart
                    key={key}
                    name={menu.name}
                    qty={menu.qty}
                    available={menu.available}
                    price={menu.price}
                    unit={menu.qty_unit_name}
                    min_free={menu.free_shipping_cost}
                    disabled_up={menu.disable_up}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    maxOrder={menu.qty < menu.max_order ? true : false}
                    addProduct={() =>
                      addProduct(menu, partner, data.date, data.shipping_time)
                    }
                    updateQuantity={() =>
                      updateQuantity(
                        menu,
                        data.date,
                        data.shipping_time,
                        quantity
                      )
                    }
                    removeProduct={() =>
                      removeProduct(menu, data.date, data.shipping_time)
                    }
                    fulfilled={fulfilled}
                  />
                ))}
              </div>
              {new Date(datePick || defaultDate).getDate() ==
                new Date(data.date).getDate() &&
              new Date(datePick || defaultDate).getMonth() ==
                new Date(data.date).getMonth() &&
              time == data.shipping_time ? null : (
                <div className="flex justify-end border-t text-end items-center w-full">
                  <button
                    onClick={() => addMenu(data.date, data.shipping_time)}
                    className="flex gap-1 items-center  py-3 text-primary font-semibold text-[10px]"
                  >
                    <AiOutlinePlus size={12} /> Tambah Menu
                  </button>
                </div>
              )}
              <div className="h-3 mt-3 mb-5 bg-primary opacity-5"></div>
            </section>
          )
        })}
        <button
          onClick={() => setIsOpen(true)}
          className="flex w-full border-2 py-1 justify-center text-primary rounded-full border-primary font-semibold text-[10px]"
        >
          Tambah Tanggal Pengiriman
        </button>
      </div>
      <div className="py-2 px-4 lg:px-5 flex rounded-b-2xl flex-col gap-1 w-full bg-white">
        <button
          disabled={lateOrder || disableButton}
          onClick={() => handleCheckSubmit()}
          className="py-3 w-full rounded-full z-10 text-white hover:opacity-80 disabled:bg-gray-200 disabled:text-gray-400 bg-primary text-sm font-semibold flex justify-between px-5"
        >
          <p>Pembayaran</p>
          <p>{currency(grandTotal)}</p>
        </button>
        <div className="min-h-[20px] h-fit">
          {alertMenuUtama && (
            <p className="text-[10px] text-center text-primary">
              Harap pesan menu utama, agar bisa proses selanjutnya
            </p>
          )}
          {alertMenuNotAvail && (
            <p className="text-[10px] text-center text-primary">
              Terdapat menu yang tak tersedia, harap cek menu
            </p>
          )}
          {alertMinOrder && (
            <p className="text-[10px] text-center text-primary">
              Minimal Pesanan {dateDay} {MONTH_NAMES[dateMonth].slice(0, 3)},{' '}
              {cart[0].shipping_time.replaceAll('.', ':')} Kurang{' '}
              {currency(minimumPrice)}
            </p>
          )}
          {lateOrder && (
            <p className="text-[10px] tracking-tight text-center text-primary">
              Pesanan {new Date(dateLate.date).getDate()}{' '}
              {MONTH_NAMES[new Date(dateLate.date).getMonth()].slice(0, 3)}{' '}
              {new Date(dateLate.date).getFullYear()} sudah tidak berlaku, harap
              ganti tanggal
            </p>
          )}
        </div>
      </div>

      {/* Popup Auth */}
      <AuthModal showModal={showModalAuth} setShowModal={setShowModalAuth} />

      {/* Popup DatePicker */}
      <DateTimePicker
        show={isOpen}
        setShow={setIsOpen}
        dateTmp={dateIndex}
        minDate={minDate}
        maxDate={maxDate}
        setDateTmp={setDateIndex}
        time={time}
        setTime={setTime}
        setSave={handleSave}
      />
    </React.Fragment>
  )
}

export default CartItem
