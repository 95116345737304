import TabContent from 'components/tabs/TabContent'
import React from 'react'

function General() {
  return (
    <TabContent id="general">
      <div className="text-lg md:text-xl font-semibold text-center leading-6 my-6">
        SYARAT DAN KETENTUAN PEMESAN KENDIXPRESS<sup>i</sup>
      </div>
    </TabContent>
  )
}

export default General
