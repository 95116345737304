import React from 'react'
import { useNavigate } from 'react-router-dom'
import { currency } from 'utils/formatNumber'

const OverlayCart = ({ cart, grandTotal }) => {
  // const [searchParams] = useSearchParams()
  // const dataParams = searchParams.get('ref')
  const navigate = useNavigate()
  const menus = cart.map((date) => date.menus.map((menu) => menu.name))
  const menuCount = menus.flatMap((item) => item)
  return (
    <div className="fixed block z-10 lg:hidden bottom-0 py-2 w-screen bg-white shadow-top-only">
      <div className="container flex items-center justify-between">
        <div className="flex flex-col">
          <p className="text-xs">{menuCount.length} Menu Terpilih</p>
          <p className="text-sm">
            <strong>{currency(grandTotal)}</strong>
          </p>
        </div>
        <button
          onClick={() => navigate('/cart-wa')}
          className="bg-primary text-white px-5 py-2 font-semibold rounded-full text-xs"
        >
          Cek Pesanan
        </button>
      </div>
    </div>
  )
}

export default OverlayCart
