/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const CaraMenghubungiKami = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="cara-menghubungi-kami">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        10. Cara Menghubungi Kami
      </div>
      <div className="my-2">
        Apabila ada pertanyaan tentang Kebijakan ini, harap hubungi kami dengan
        menggunakan form Hubungi Kami atau dengan mengirimkan email ke:
        legal@redkendi.com.
      </div>
    </TabContent>
  )
})

export default CaraMenghubungiKami
