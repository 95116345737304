import React from 'react'

function BannerInfo({ isFaq }) {
  return (
    <div className="bg-info-background bg-no-repeat bg-center bg-cover h-[116px] md:h-[370px] flex">
      <div className="container flex items-center">
        <div className="w-1/2 my-auto">
          {isFaq && (
            <h1 className="text-xs md:text-2xl text-white font-semibold">
              Kami selalu berusaha memberikan
              <br />
              yang terbaik untuk Anda
            </h1>
          )}
          {!isFaq && (
            <h1 className="text-xs md:text-2xl text-white font-semibold">
              Terimakasih telah berkenan
              <br />
              membaca halaman ini
            </h1>
          )}
        </div>
      </div>
    </div>
  )
}

export default BannerInfo
