/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Pengantaran = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="pengantaran" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        F. Pengantaran
      </div>
      <List>
        <ListItem>
          Pengantaran makanan akan dilakukan oleh <em>Vendor</em> dengan
          menginformasikan kepada RAM 1 (satu) jam sebelum pengantaran apabila
          pengantaran telah siap dilakukan.
        </ListItem>
        <ListItem>
          Pemesan wajib mengkonfirmasi Pesanan telah selesai diantarkan apabila
          telah diterima oleh Pemesan dengan menandatangani surat jalan yang
          diberikan oleh <em>Vendor</em>.
        </ListItem>
        <ListItem>
          Dalam hal Pesanan gagal diantar oleh <em>Vendor</em> sampai kepada
          alamat tujuan akibat Pemesan tidak memberikan informasi alamat dan
          nomor korespondensi secara detail, lengkap dan akurat termasuk namun
          tidak terbatas pada Pemesan tidak dapat dihubungi maksimal 1 (satu)
          jam setelah pesanan dikonfirmasi telah sampai di tujuan maka Pesanan
          dianggap telah selesai diterima oleh Pemesan, dan Pemesan wajib
          melunasi Pesanan dan beban-beban lain sehubungan dengan Pesanan.
        </ListItem>
        <ListItem>
          Pengantaran Pesanan dilakukan oleh <em>Vendor</em>. RAM tidak
          bertanggungjawab atas segala bentuk kerugian yang timbul sehubungan
          pengantaran Pesanan yang dilakukan oleh <em>Vendor</em>.
        </ListItem>
        <ListItem>
          Jam Pengantaran bersifat perkiraan dan hasilnya mungkin berbeda karena
          dapat dipengaruhi oleh faktor diluar kendali <em>Vendor</em>.
        </ListItem>
        <ListItem>
          RAM tidak bertanggungjawab atas segala bentuk kerugian yang timbul dan
          dialami Pemesan atau pihak ketiga pada saat/setelah Pesanan diterima
          oleh Pemesan.
        </ListItem>
      </List>
    </TabContent>
  )
})

export default Pengantaran
