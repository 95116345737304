/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const PembatalanMakanan = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="pembatalan-pesanan" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        E. Pembatalan Pesanan
      </div>
      <List>
        <ListItem>
          Dalam hal Pemesan telah melakukan pembayaran ke Rekening Pembayaran,
          Pemesan hanya dapat melakukan pembatalan selambat-lambatnya 2 (dua)
          hari kerja dari jadwal dan waktu pengantaran Pesanan.
        </ListItem>
        <ListItem>
          Pengembalian pembayaran akan diteruskan kepada Rekening Pemesan
          selambat-lambatnya 1 (satu) hari kerja setelah pembatalan dikonfirmasi
          oleh RAM.
        </ListItem>
        <ListItem>
          Apabila terdapat pengembalian pembayaran sebagaimana tersebut di atas,
          Kami akan melakukan pengembalian ke Rekening Pemesan.
        </ListItem>
      </List>
    </TabContent>
  )
})

export default PembatalanMakanan
