import $axios from 'utils/axios'

export const fetchListMitra = async (lat, long, type, search) => {
  const result = await $axios(
    `/partner/menu/list?&lat=${lat}&lon=${long}&partner_type_id=${type}&menu_keyword=${search}`
  )
  return result.data
}

export const fetchLatestPrice = async () => {
  const result = await $axios('/order/v2/customer/latest-price')
  return result.data
}

export const fetchMenuRecommend = async (lat, long, price) => {
  const result = await $axios(
    `/partner/menu/list?&lat=${lat}&lon=${long}&menu_price=${price}&is_recommendation=1`
  )
  return result.data
}
