import Tippy from '@tippyjs/react'
import React from 'react'

const Tooltip = ({ visible, setVisible, desc, children, ...rest }) => {
  return (
    <Tippy
      visible={visible}
      zIndex={10}
      className="tippy-css"
      {...rest}
      content={
        <div className="p-2 flex flex-col text-[10px]">
          {desc}
          <br />
          <button
            className="cursor-pointer flex md:justify-end mt-1 font-semibold"
            onClick={() => {
              setVisible(!visible)
              localStorage.removeItem('tool')
            }}
          >
            OKE MENGERTI
          </button>
        </div>
      }
      theme="dark"
    >
      {children}
    </Tippy>
  )
}

export default Tooltip
