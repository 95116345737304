import React from 'react'

function SectionLayout({ children, className, id }) {
  return (
    <section className={className} id={id}>
      {children}
    </section>
  )
}

export default SectionLayout
