import { Scrollspy } from '@makotot/ghostui'
import InfoLayout from 'components/layouts/InfoLayout'
import Tabs from 'components/tabs/Index'
import TabSidebarItem from 'components/tabs/TabSidebarItem'
import React, { useMemo, useRef, useState } from 'react'

import AksesDataPribadi from './fragments/AksesDataPribadi'
import CaraMenghubungiKami from './fragments/CaraMenghubungiKami'
import Disclaimer from './fragments/Disclaimer'
import Informasi from './fragments/Informasi'
import KebijakanCookie from './fragments/KebijakanCookie'
import MelindungiData from './fragments/MelindungiData'
import PengalihanBisnis from './fragments/PengalihanBisnis'
import PenyimpananData from './fragments/PenyimpananData'
import PerubahanKebijakan from './fragments/PerubahanKebijakan'
import Tautan from './fragments/Tautan'
import Tentang from './fragments/Tentang'

function PrivacyPolicy() {
  // ACTIVE TAB
  const [activeTab, setActiveTab] = useState('kp')

  // REF FOR ALL SECTION CONTENT
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  // TABS CONTENT
  const tabsItem = useMemo(
    () => [
      {
        id: 'snk',
        title: 'Syarat dan Ketentuan',
        redirectTo: '/tnc',
      },
      {
        id: 'kp',
        title: <>Kebijakan {window.innerWidth < 378 && <br />} Privasi</>,
        redirectTo: '/privacy-policy',
      },
    ],
    []
  )

  // SIDEBAR ITEMS
  const sidebarItem = useMemo(() => [
    {
      id: 'tentang',
      title: 'Tentang Kebijakan Privasi',
    },
    {
      id: 'informasi',
      title: 'Informasi yang kami kumpulkan',
    },
    {
      id: 'penyimpanan-data',
      title: 'Penyimpanan Data Pemesan',
    },
    {
      id: 'kebijakan-cookie',
      title: 'Kebijakan Cookie',
    },
    {
      id: 'akses-data-pribadi',
      title: 'Akses ke Data Pribadi Pemesan',
    },
    {
      id: 'tautan',
      title: 'Tautan',
    },
    {
      id: 'pengalihan-bisnis',
      title: 'Pengalihan Bisnis',
    },
    {
      id: 'melindungi-data',
      title: 'Melindungi Data Pribadi Pemesan',
    },
    {
      id: 'perubahan-kebijakan',
      title: 'Perubahan Kebijakan Privasi',
    },
    {
      id: 'disclaimer',
      title: 'Disclaimer',
    },
    {
      id: 'cara-menghubungi-kami',
      title: 'Cara Menghubungi Kami',
    },
  ])

  // SELECT TAB
  const selectTab = (id) => {
    setActiveTab(id)
  }

  return (
    <InfoLayout withContainer={false} isFaq={false}>
      <div className="md:pt-[30px] md:container">
        {/* TABS */}
        <Tabs
          activeTab={activeTab}
          onClick={selectTab}
          tabsItem={tabsItem}
          classItems={`w-1/2 md:w-auto h-full`}
          classItem={`w-full h-full`}
        />
        {/* END TABS */}
      </div>
      {/* SCROLLSPY WRAPPER */}
      <Scrollspy sectionRefs={sectionRefs}>
        {({ currentElementIndexInViewport }) => (
          <div className="bg-white py-10">
            <div className="container px-6 md:px-4">
              <div className="w-full flex relative">
                <div className="w-3/12 sticky top-5 pl-4 h-[500px] hidden md:block">
                  {sidebarItem.map((item, index) => (
                    <TabSidebarItem
                      key={index}
                      title={item.title}
                      id={item.id}
                      active={index == currentElementIndexInViewport}
                    />
                  ))}
                </div>
                <div className="w-full md:w-9/12">
                  <Tentang ref={sectionRefs[0]} className="my-10" />
                  <Informasi ref={sectionRefs[1]} className="my-10" />
                  <PenyimpananData ref={sectionRefs[2]} className="my-10" />
                  <KebijakanCookie ref={sectionRefs[3]} className="my-10" />
                  <AksesDataPribadi ref={sectionRefs[4]} className="my-10" />
                  <Tautan ref={sectionRefs[5]} className="my-10" />
                  <PengalihanBisnis ref={sectionRefs[6]} className="my-10" />
                  <MelindungiData ref={sectionRefs[7]} className="my-10" />
                  <PerubahanKebijakan ref={sectionRefs[8]} className="my-10" />
                  <Disclaimer ref={sectionRefs[9]} className="my-10" />
                  <CaraMenghubungiKami
                    ref={sectionRefs[10]}
                    className="my-10"
                  />
                  <div className="md:px-10 text-sm leading-6 relative after:content-[''] after:absolute after:-top-4 after:left-0 after:h-[1px] after:w-1/3 after:bg-black">
                    <sup>i</sup> Terakhir diperbaharui: 12 Oktober 2022
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Scrollspy>
      {/* END SCROLLSPY WRAPPER */}
    </InfoLayout>
  )
}

export default PrivacyPolicy
