/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useState } from 'react'

export const useGoogleMapGeocode = () => {
  const [address, setAddress] = useState('')

  const getAddress = (latLng) => {
    return new Promise((resolve, reject) => {
      axios
        .get('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            latlng: latLng,
            key: process.env.REACT_APP_API_KEY_GMAPS,
            sensor: false,
          },
        })
        .then((resp) => {
          setAddress(resp.data.results[0].formatted_address)
          resolve(resp.data.results[0])
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  return {
    address,
    getAddress,
  }
}
