import logoMitra from 'images/menu/logo-mitra.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import FeaturedItem from './fragments/FeaturedItem'
import MenuItem from './fragments/MenuItem'

const MitraItem = (props) => {
  const navigate = useNavigate()
  return (
    <div className="pt-4 md:pt-9 pb-2 container flex gap-5 flex-col">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <img
            src={props.data.logo === '' ? logoMitra : props.data.logo}
            alt={props.data.name}
            width="100%"
            className="max-w-[60px] my-auto h-[60px]"
            loading="lazy"
          />

          {/* Featured Mitra */}
          <FeaturedItem
            click={() => navigate(`/detail-mitra/${props.data.partner_id}`)}
            name={props.data.name}
            distance={props.data.distance}
            unit={props.data.distance_unit}
            shipping={props.data.is_available_free_shipping_cost}
            cost={props.data.shipping_cost_fr}
            cost_to={props.data.shipping_cost_to}
            minOrder={props.data.info_min_order}
            type={props.data.partner_type_id}
          />
        </div>
        <button
          onClick={() => navigate(`/detail-mitra/${props.data.partner_id}`)}
          name="pesan"
          className="px-10 h-9 rounded-full hidden md:block bg-primary text-xs tracking-wider text-white font-semibold"
        >
          Pesan
        </button>
      </div>

      {/* List Menu */}
      {props.data.menus.length > 0 && (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
          {(props.data.menus
            ? props.data.menus.slice(0, 3)
            : props.data.menus
          ).map((item, index) => (
            <MenuItem
              click={() => navigate(`/detail-mitra/${props.data.partner_id}`)}
              key={index}
              photo={item.photo}
              title={item.name}
              price={item.price}
              unit={item.qty_unit_name}
              type={props.data.partner_type_id}
              minOrder={item.min_order}
            />
          ))}
        </div>
      )}
      <button
        onClick={() => navigate(`/detail-mitra/${props.data.partner_id}`)}
        className="text-xs w-fit text-left text-primary"
      >
        Lihat Menu Lainnya
      </button>
      <hr />
    </div>
  )
}

export default MitraItem
