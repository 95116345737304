import { useCartSystem } from 'modules/custom/useCartSystem'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MONTH_NAMES } from 'utils/date'
import { currency } from 'utils/formatNumber'

import CustomForm from '../../../components/forms/CustomForm'
import Accordion from './fragments/Accordion'
import DateCart from './fragments/DateCart'
import MenuList from './fragments/MenuList'

const CartDetail = () => {
  const navigate = useNavigate()
  const [ongkir] = useState(localStorage.getItem('ongkir'))
  const { cart, partner, setDateParams, grandTotal } = useCartSystem()

  const addMenu = (time, date) => {
    setDateParams(date)
    sessionStorage.setItem('time', JSON.stringify(time))
    sessionStorage.setItem('date', JSON.stringify(date))
    navigate(`/detail-mitra/${partner.partner_id}`)
  }
  return (
    <CustomForm title="Detail Pesanan" classContainer="px-6">
      <div className="flex flex-col py-5 border-t border-b">
        <div className="text-xs text-gray-400">Nama Mitra</div>
        <h6 className="text-lg">{partner.name}</h6>
      </div>
      {cart.map((data, key) => (
        <Accordion
          key={key}
          title={
            <DateCart
              date={new Date(data.date)}
              time={data.shipping_time}
              qty={data.qty_total}
            />
          }
          description={
            <MenuList
              add={addMenu}
              date={data.date}
              time={data.shipping_time}
              menus={data.menus}
            />
          }
        />
      ))}
      <Accordion
        title={
          <div className="grid grid-cols-4 gap-3 text-sm">
            <div className="col-span-3 font-medium">Ongkos Kirim</div>
            <div className="col-span-1 font-bold">
              {currency(Number(ongkir))}
            </div>
          </div>
        }
        description={
          <React.Fragment>
            <div className="grid grid-cols-4 gap-3 text-xs text-gray-400">
              <div className="col-span-3">Tanggal dan Waktu</div>
              <div className="col-span-1">Harga</div>
            </div>
            <div className="flex flex-col mt-1 pb-1 gap-1">
              {cart.map((data, key) => (
                <div
                  key={key}
                  className="grid grid-cols-4 gap-3 text-sm font-medium"
                >
                  <div className="col-span-3">{`${new Date(
                    data.date
                  ).getDate()} ${MONTH_NAMES[
                    new Date(data.date).getMonth()
                  ].slice(0, 3)} ${new Date(
                    data.date
                  ).getFullYear()}, ${data.shipping_time.replaceAll(
                    '.',
                    ':'
                  )}`}</div>
                  <div className="col-span-1">
                    {currency(data.shipping_cost)}
                  </div>
                </div>
              ))}
            </div>
          </React.Fragment>
        }
      />
      <div className="mt-10 flex flex-col gap-1">
        <p className="text-sm">Total Pembayaran</p>
        <h4 className="text-primary font-bold text-2xl">
          {currency(grandTotal)}
        </h4>
      </div>
    </CustomForm>
  )
}

export default CartDetail
