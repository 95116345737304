/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const PengalihanBisnis = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="pengalihan-bisnis">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        6. Pengalihan Bisnis
      </div>
      <div className="my-2">
        Dalam hal terjadi perubahan kendali atau perubahan kepemilikan atas
        semua atau sebagian dari bisnis KendiXpress atau perusahaan, termasuk
        Situs, maka Data Pribadi Pemesan dapat menjadi bagian dari pengalihan
        aset tersebut.
      </div>
    </TabContent>
  )
})

export default PengalihanBisnis
