import HomeLayout from 'components/layouts/HomeLayout'

import Banner from './components/Banner'
import Feature from './components/Feature'
import OurServices from './components/OurServices'
import Partner from './components/Partner'
import Testimonial from './components/Testimonial'
import Workflow from './components/Workflow'

function Home() {
  return (
    <>
      <HomeLayout
        bottomContent={
          <>
            {/* CARA KERJA KAMI */}
            <Workflow />
            <div className="container">
              {/* TESTIMONIAL */}
              <Testimonial />
            </div>
          </>
        }
      >
        {/* SLIDER BANNER */}
        <Banner />
        {/* FITUR APLIKASI */}
        <Feature />
        {/* LAYANAN KAMI */}
        <OurServices />
        {/* PARTNER RESTO & CATERING */}
        <Partner />
      </HomeLayout>
    </>
  )
}

export default Home
