/* eslint-disable no-unused-vars */
import Loading from 'components/layouts/fragments/Loading'
import MitraLayout from 'components/layouts/MitraLayout'
import WhatsappButton from 'components/WhatsappButton'
import { useCartSystem } from 'modules/custom/useCartSystem'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import {
  useFetchDetailMitra,
  useFetchListMenuAndalan,
  useFetchListMenuHarian,
  useFetchListMenuTambahan,
  useFetchNearMitra,
} from 'modules/Mitra/hooks'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Cart from './components/Cart'
import DateTime from './components/DateTime'
import OverlayCart from './components/fragments/OverlayCart'
import TabMenu from './components/fragments/TabMenu'
import ListAndalan from './components/ListAndalan'
import ListHarian from './components/ListHarian'
import ListTambahan from './components/ListTambahan'
import NearMitra from './components/NearMitra'
import TopSection from './components/TopSection'

const DetailMitra = () => {
  const idPartner = useParams()
  const [location, setLocation] = useState(
    JSON.parse(localStorage.getItem('dataAddress')) || ''
  )
  const [condition, setCondition] = useState()
  const { loading, partnerId, setPartnerId } = useGoogleMapHook()

  const {
    cart,
    setCart,
    partner,
    setPartner,
    dateParams,
    setDateParams,
    datePick,
    grandTotal,
    setDatePick,
    dateTime,
    time,
    setTime,
    addProduct,
    updateQuantity,
    removeProduct,
    removeByDate,
    changeMitra,
    changeDateCart,
    changeTimeCart,
    loadingCart,
    adjustShippingCost,
  } = useCartSystem()

  const [andalanTmp, setAndalanTmp] = useState(
    JSON.parse(sessionStorage.getItem('andalanTmp')) || null
  )
  const [harianTmp, setHarianTmp] = useState(
    JSON.parse(sessionStorage.getItem('harianTmp')) || null
  )
  const [tambahanTmp, setTambahanTmp] = useState(
    JSON.parse(sessionStorage.getItem('tambahanTmp')) || null
  )

  const dataNear = useFetchNearMitra(location.lat, location.lng, idPartner.id)
  const detailMitra = useFetchDetailMitra(
    location.lat,
    location.lng,
    idPartner.id
  )
  const listMenuAndalan = useFetchListMenuAndalan(
    location.lat,
    location.lng,
    idPartner.id,
    parseInt(dateParams / 1000)
  )
  const listMenuHarian = useFetchListMenuHarian(
    location.lat,
    location.lng,
    idPartner.id,
    parseInt(dateParams / 1000)
  )
  const listMenuTambahan = useFetchListMenuTambahan(
    location.lat,
    location.lng,
    idPartner.id,
    parseInt(dateParams / 1000)
  )
  useEffect(() => {
    if (
      andalanTmp !== null &&
      andalanTmp.length > 0 &&
      andalanTmp !== undefined
    ) {
      adjustShippingCost(andalanTmp)
    }
    if (harianTmp !== null && harianTmp.length > 0 && harianTmp !== undefined) {
      adjustShippingCost(harianTmp)
    }
    if (
      tambahanTmp !== null &&
      tambahanTmp.length > 0 &&
      tambahanTmp !== undefined
    ) {
      adjustShippingCost(tambahanTmp)
    }
    sessionStorage.removeItem('andalanTmp')
    sessionStorage.removeItem('harianTmp')
    sessionStorage.removeItem('tambahanTmp')
  }, [])

  useEffect(() => {
    if (cart.length === 0) {
      setPartner(null)
      localStorage.removeItem('partner')
      setCondition(false)
    } else {
      setCondition(true)
    }

    if (cart.length > 0) {
      let partner = JSON.parse(localStorage.getItem('partner'))
      setPartnerId(partner.partner_id)
    } else {
      setPartnerId(null)
    }
  }, [cart, changeDateCart])

  return (
    <React.Fragment>
      {detailMitra.isLoading ||
      listMenuAndalan.isLoading ||
      listMenuHarian.isLoading ||
      listMenuHarian.isFetching ||
      loading ||
      loadingCart ? (
        <div className="w-screen flex items-center h-screen">
          <Loading />
        </div>
      ) : (
        <MitraLayout>
          {detailMitra?.data?.result.length > 0 && (
            <TopSection data={detailMitra?.data?.result} />
          )}

          <div className="grid grid-cols-1 lg:grid-cols-8 gap-16 md:container">
            <div className="lg:col-span-5">
              <DateTime
                defaultDate={datePick}
                setDateParams={setDateParams}
                date={datePick}
                setDate={setDatePick}
                time={time}
                setTime={setTime}
              />
              {/* {detailMitra?.data?.result[0]?.partner_type_id === 1 && ( */}
              <TabMenu
                andalan={listMenuAndalan?.data?.result}
                harian={listMenuHarian?.data?.result}
                tambahan={listMenuTambahan?.data?.result}
              />
              {/* )} */}
              {listMenuAndalan?.data?.result?.data?.length > 0 && (
                <ListAndalan
                  menus={listMenuAndalan?.data?.result?.data}
                  addProduct={addProduct}
                  updateQuantity={updateQuantity}
                  removeProduct={removeProduct}
                  changeMitra={changeMitra}
                  partnerParam={idPartner.id}
                  cart={cart}
                  setCart={setCart}
                  date1={dateTime}
                  time={time}
                  isPartner={partner}
                  partnerActive={detailMitra?.data?.result[0]}
                  andalan={listMenuAndalan?.data?.result}
                  harian={listMenuHarian?.data?.result}
                  tambahan={listMenuTambahan?.data?.result}
                />
              )}
              {listMenuHarian?.data?.result?.data?.length > 0 && (
                <ListHarian
                  menus={listMenuHarian.data?.result?.data}
                  addProduct={addProduct}
                  updateQuantity={updateQuantity}
                  removeProduct={removeProduct}
                  partnerParam={idPartner.id}
                  time={time}
                  cart={cart}
                  setCart={setCart}
                  changeMitra={changeMitra}
                  date1={dateTime}
                  setDatePick={setDatePick}
                  setDateParams={setDateParams}
                  isPartner={partner}
                  partnerActive={detailMitra?.data?.result[0]}
                  andalan={listMenuAndalan?.data?.result}
                  harian={listMenuHarian?.data?.result}
                  tambahan={listMenuTambahan?.data?.result}
                />
              )}
              {listMenuTambahan?.data?.result?.data?.length > 0 && (
                <ListTambahan
                  menus={listMenuTambahan.data?.result?.data}
                  addProduct={addProduct}
                  updateQuantity={updateQuantity}
                  removeProduct={removeProduct}
                  partnerParam={idPartner.id}
                  time={time}
                  cart={cart}
                  setCart={setCart}
                  changeMitra={changeMitra}
                  date1={dateTime}
                  isPartner={partner}
                  partnerActive={detailMitra?.data?.result[0]}
                  andalan={listMenuAndalan?.data?.result}
                  harian={listMenuHarian?.data?.result}
                  tambahan={listMenuTambahan?.data?.result}
                />
              )}
            </div>
            <div className="lg:col-span-3 hidden lg:block sticky top-0">
              <Cart
                cart={cart}
                partnerParam={idPartner.id}
                partner={partner}
                defaultDate={datePick}
                setDatePick={setDatePick}
                dateParams={dateParams}
                setDateParam={setDateParams}
                updateQuantity={updateQuantity}
                time={time}
                setTime={setTime}
                addProduct={addProduct}
                removeProduct={removeProduct}
                removeByDate={removeByDate}
                changeDateCart={changeDateCart}
                changeTimeCart={changeTimeCart}
                grandTotal={grandTotal}
              />
            </div>
          </div>
          {dataNear?.data?.result.length > 0 && (
            <NearMitra mitra={dataNear?.data?.result} />
          )}
          <WhatsappButton cart={condition} />
          {cart.length > 0 && (
            <OverlayCart
              partner={partner}
              cart={cart}
              grandTotal={grandTotal}
            />
          )}
        </MitraLayout>
      )}
      {cart.length > 0 && <div className="h-10 md:h-0"></div>}
    </React.Fragment>
  )
}

export default DetailMitra
