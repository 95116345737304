import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Footer({ classWrapper }) {
  useEffect(() => {
    if (window.location.pathname.search('/detail-mitra') == 0) {
      undefined
    } else if (window.location.pathname.search('/explore') == 0) {
      undefined
    } else {
      sessionStorage.removeItem('listData')
    }
  }, [])
  return (
    <section className="bg-white">
      <div className={`container ${classWrapper}`}>
        <div className="w-full flex flex-wrap py-10 md:py-20">
          <div className="w-full md:w-5/12 order-2 md:order-1 mt-0 md:mt-0">
            <Link to="/">
              <img
                src="/images/kendixpress.svg"
                className="w-[150px] md:w-[186px] md:h-[36px]"
                alt="Logo"
              />
            </Link>
            <div className="mt-5">
              <p className="text-xs font-medium pr-28 md:pr-0">
                &#169; {new Date().getFullYear()} PT. Redkendi Andalan Mitra.
                All Rights Reserved.
              </p>
            </div>
          </div>
          <div className="w-full md:w-7/12 order-1 md:order-2">
            <div className="flex flex-col md:flex-row md:justify-around mt-0 md:mt-4">
              <Link
                to="/about-us"
                className="text-gray-400 hover:text-gray-500 text-xs my-1.5 md:my-0 md:mx-3"
              >
                Tentang kendiXpress
              </Link>
              <Link
                to="/tnc"
                className="text-gray-400 hover:text-gray-500 text-xs my-1.5 md:my-0 md:mx-3"
              >
                Syarat dan Ketentuan
              </Link>
              <Link
                to="/privacy-policy"
                className="text-gray-400 hover:text-gray-500 text-xs my-1.5 md:my-0 md:mx-3"
              >
                Kebijakan Privasi
              </Link>
              <Link
                to="/faq"
                className="text-gray-400 hover:text-gray-500 text-xs my-1.5 md:my-0 md:mx-3"
              >
                FAQ
              </Link>
              <a
                href="https://forms.gle/TCEKn1VBfvsRSo927"
                target="_blank"
                className="text-gray-400 hover:text-gray-500 text-xs my-1.5 md:my-0 md:mx-3"
                rel="noreferrer"
              >
                Kostumisasi pesanan
              </a>
            </div>
            <div className="mt-8 md:mt-3 md:mx-3">
              <div className="text-xs font-medium text-left md:text-right flex flex-col md:flex-row md:justify-end md:items-center">
                Hubungi kami jika memerlukan informasi &nbsp;
                <a
                  href="https://wa.me/6281119279900?text=Saya%20ingin%20pesan%20Catering%20acara%20di%20kendiXpress"
                  target="_blank"
                  className="text-primary mt-2 md:mt-0"
                  rel="noreferrer"
                >
                  0811 1927 9900
                </a>
                <div className="mt-6 md:mt-0 mb-10 md:mb-0 flex">
                  <a
                    href="https://www.instagram.com/kendixpress/"
                    target="_blank"
                    className="md:ml-3"
                    rel="noreferrer"
                  >
                    <img
                      src="/images/ig-circle-outline-red.svg"
                      width={24}
                      height={24}
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCWvL5O_DZ3rS8VPHQtuyF4g"
                    target="_blank"
                    className="ml-3"
                    rel="noreferrer"
                  >
                    <img
                      src="/images/yt-circle-outline-red.svg"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
