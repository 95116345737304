import SectionLayout from 'components/layouts/SectionLayout'
import CBDTop from 'images/feature/cbd_atau_top.svg'
import GratisOngkir from 'images/feature/gratis_ongkir.svg'
import HargaTerjangkau from 'images/feature/harga_terjangkau.svg'
import VariasiMenu from 'images/feature/variasi_menu.svg'
import React from 'react'

import FeatureItem from './fragments/FeatureItem'

function Feature() {
  return (
    <SectionLayout className="mt-[20px] md:mt-[80px]">
      <div className="w-full flex items-center flex-wrap md:flex-nowrap">
        {/* FEATURE ITEMS */}
        <FeatureItem
          imgSrc={GratisOngkir}
          title={`Gratis Ongkir`}
          subtitle={
            <>
              Minimal order terpenuhi <br /> di setiap mitra
            </>
          }
        />
        <FeatureItem
          imgSrc={CBDTop}
          title={`Bayar CBD atau TOP`}
          subtitle={
            <>
              TOP, pemesanan atas <br /> nama perusahaan
            </>
          }
        />
        <FeatureItem
          imgSrc={HargaTerjangkau}
          title={`Harga Terjangkau`}
          subtitle={
            <>
              Harga menu mulai dari <br /> Rp 15.000
            </>
          }
        />
        <FeatureItem
          imgSrc={VariasiMenu}
          title={`Variasi Menu`}
          subtitle={
            <>
              Terdapat menu catering <br /> dan resto favorit
            </>
          }
        />
        {/* END FEATURE ITEMS */}
      </div>
    </SectionLayout>
  )
}

export default Feature
