/* eslint-disable no-unused-vars */
import Input from 'components/forms/Input'
import BuildingIcon from 'images/icons/building.svg'
import EmailIcon from 'images/icons/email.svg'
import guestIcon from 'images/icons/guest.svg'
import phoneIcon from 'images/icons/phone.svg'
import React from 'react'

import CustomForm from '../../../components/forms/CustomForm'

const CustomerDetail = ({
  dataUser,
  user,
  setUser,
  errorEmail,
  setErrorEmail,
}) => {
  return (
    <CustomForm title="Detail Pemesan">
      <div className="flex flex-col">
        <div className="flex flex-col gap-5 pb-7">
          <div className="py-1 flex gap-2 items-center">
            <img src={guestIcon} alt="guest" className="h-6" />
            <div className="flex flex-col">
              <div className="text-xs text-gray-400">Nama Pemesan</div>
              <p className="text-sm font-medium">{dataUser?.name}</p>
            </div>
          </div>
          <div className="py-1 flex gap-2 items-center">
            <img src={phoneIcon} alt="phone" className="h-6" />
            <div className="flex flex-col">
              <div className="text-xs text-gray-400">
                Nomor Handphone Pemesan
              </div>
              <p className="text-sm font-medium">{dataUser?.phone}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-7">
          <div className="py-1 flex gap-2">
            <img src={EmailIcon} alt="Email" className="h-5" />
            <div className="flex w-full flex-col">
              <Input
                type="email"
                name="Email"
                label="Nama Email"
                error={errorEmail}
                value={user.pic_email || ''}
                onChange={(e) => {
                  setUser({ ...user, pic_email: e.target.value })
                }}
                contentBottom={
                  !errorEmail && (
                    <span className="text-[10px] pt-1 text-gray-400">
                      Ringkasan pesanan akan dikirim ke email ini
                    </span>
                  )
                }
              />
            </div>
          </div>
          <div className="py-1 w-full flex gap-2">
            <img src={BuildingIcon} alt="Building" className="h-5" />
            <div className="flex w-full flex-col">
              <Input
                type="text"
                name="Perusahaan"
                label="Nama Perusahaan"
                value={user.company_name || ''}
                onChange={(e) => {
                  setUser({ ...user, company_name: e.target.value })
                }}
                contentBottom={
                  <span className="text-[10px] pt-1 text-gray-400">
                    Harap diisi jika pesan atas nama perusahaan
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </CustomForm>
  )
}

export default CustomerDetail
