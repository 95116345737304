import SectionTitle from 'components/layouts/fragments/SectionTitle'
import SectionLayout from 'components/layouts/SectionLayout'
import SetLokasi from 'images/workflow/1_set_lokasi.svg'
import PilihMenu from 'images/workflow/2_pilih_menu.svg'
import Pembayaran from 'images/workflow/3_pembayaran.svg'
import Pengiriman from 'images/workflow/4_pengiriman.svg'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import WorkflowItem from './fragments/WorkflowItem'

function Workflow() {
  return (
    <SectionLayout className="py-10 md:py-20 bg-[#fffbf8]" id="workflow">
      <div className="container">
        <SectionTitle
          title={`Cara Kerja Kami`}
          subtitle={`Untukmu yang terbaik`}
        />
        {/* DESKTOP VIEW */}
        <div className="w-full hidden md:flex justify-around my-24">
          <WorkflowItem
            imgSrc={SetLokasi}
            title={`Set Lokasi`}
            subtitle={
              <>
                Tentukan alamat <br /> pengirimanmu
              </>
            }
          />
          <WorkflowItem
            imgSrc={PilihMenu}
            title={`Pilih Menu`}
            subtitle={
              <>
                Pilih menu dari <br /> catering atau restoran
              </>
            }
          />
          <WorkflowItem
            imgSrc={Pembayaran}
            title={`Pembayaran`}
            subtitle={
              <>
                Pilih metode pembayaran <br /> yang kamu inginkan
              </>
            }
          />
          <WorkflowItem
            imgSrc={Pengiriman}
            title={`Pengiriman`}
            subtitle={
              <>
                Tanggal dan waktu tiba <br /> dapat disesuaikan
              </>
            }
          />
        </div>
        {/* END DEKSTOP VIEW */}

        {/* RESPONSIVE VIEW */}
        <div className="w-full relative md:hidden my-12">
          <Carousel
            showStatus={false}
            showIndicators={false}
            showArrows={true}
            showThumbs={false}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
          >
            <div className="flex flex-col justify-center items-center">
              <div className="">
                <img src={SetLokasi} />
              </div>
              <h5 className="text-lg font-bold text-center mt-12">
                Set Lokasi
              </h5>
              <p className="text-center mt-2">
                Tentukan alamat <br /> pengirimanmu
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="">
                <img src={PilihMenu} />
              </div>
              <h5 className="text-lg font-bold text-center mt-12">
                Pilih Menu
              </h5>
              <p className="text-center mt-2">
                Pilih menu dari <br /> catering atau restoran
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="">
                <img src={Pembayaran} />
              </div>
              <h5 className="text-lg font-bold text-center mt-12">
                Pembayaran
              </h5>
              <p className="text-center mt-2">
                Pilih metode pembayaran <br /> yang kamu inginkan
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="">
                <img src={Pengiriman} />
              </div>
              <h5 className="text-lg font-bold text-center mt-12">
                Pengiriman
              </h5>
              <p className="text-center mt-2">
                Tanggal dan waktu tiba <br /> dapat disesuaikan
              </p>
            </div>
          </Carousel>
        </div>
        {/* END RESPONSIVE VIEW */}
      </div>
    </SectionLayout>
  )
}

export default Workflow
