/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useEffect } from 'react'

import TabItem from './TabItem'

const TabMenu = ({ andalan, harian, tambahan }) => {
  const [active, setActive] = useState(1)
  const scrollSelectedItem = (id) => {
    const el = document.getElementById(id)
    el.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const handleClick = (id, index) => {
    scrollSelectedItem(id)
    setActive(index)
  }

  useEffect(() => {
    if (andalan.data.length == 0) {
      if (harian.data.length > 0) {
        setActive(2)
      } else {
        setActive(3)
      }
    }
  }, [])

  return (
    <React.Fragment>
      {((andalan?.data?.length > 0 && harian?.data?.length > 0) ||
        (andalan?.data?.length > 0 && tambahan?.data?.length > 0) ||
        (harian?.data?.length > 0 && tambahan?.data?.length > 0)) && (
        <div className="w-full border-b">
          <div className="grid grid-cols-3 md:flex justify-center md:justify-start text-xs md:text-sm w-full text-gray-400 font-normal">
            {andalan?.data?.length > 0 && (
              <TabItem
                id={() => handleClick('andalan', 1)}
                active={active}
                setActive={setActive}
                index={1}
              >
                Menu Andalan
              </TabItem>
            )}
            {harian?.data?.length > 0 && (
              <TabItem
                id={() => handleClick('harian', 2)}
                active={active}
                setActive={setActive}
                index={2}
              >
                Menu Harian
              </TabItem>
            )}
            {tambahan?.data?.length > 0 && (
              <TabItem
                id={() => handleClick('tambahan', 3)}
                active={active}
                setActive={setActive}
                index={3}
              >
                Menu Tambahan
              </TabItem>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default TabMenu
