/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

import LoadMore from './fragments/LoadMore'
import MenuHarian from './fragments/MenuHarian'
import MenuWrapper from './MenuWrapper'
import PopupMenu from './PopupMenu'

const ListHarian = ({
  menus,
  addProduct,
  updateQuantity,
  removeProduct,
  partnerParam,
  cart,
  setCart,
  changeMitra,
  date1,
  setDatePick,
  setDateParams,
  time,
  isPartner,
  partnerActive,
  andalan,
  harian,
  tambahan,
}) => {
  const [loadMore, setLoadMore] = useState(10)
  const [isButton, setIsButton] = useState(false)
  const [open, setOpen] = useState(false)
  const [popup, setPopup] = useState('')

  const handlePopup = (menu) => {
    setPopup(menu)
    setOpen(true)
  }
  const handleMore = () => {
    setLoadMore(loadMore + 10)
  }

  useEffect(() => {
    if (menus) {
      menus.length > loadMore ? setIsButton(true) : setIsButton(false)
    }
  }, [loadMore, menus])
  return (
    <MenuWrapper
      isPartner={partnerActive}
      id="harian"
      menuTitle="Menu Harian"
      andalan={andalan}
      harian={harian}
      tambahan={tambahan}
    >
      <div className="grid grid-cols-2 gap-2 md:gap-5">
        {menus.slice(0, loadMore).map((menu, key) => (
          <MenuHarian
            key={key}
            menus={menu}
            addProduct={addProduct}
            removeProduct={removeProduct}
            cart={cart}
            setCart={setCart}
            updateQuantity={updateQuantity}
            changeMitra={changeMitra}
            date1={date1}
            setDatePick={setDatePick}
            setDateParams={setDateParams}
            isPartner={isPartner}
            partnerActive={partnerActive}
            partnerParam={partnerParam}
            time={time}
            popup={handlePopup}
          />
        ))}
      </div>
      {isButton && <LoadMore click={handleMore} title="Harian" />}
      <PopupMenu show={open} setShow={setOpen} menu={popup} />
    </MenuWrapper>
  )
}

export default ListHarian
