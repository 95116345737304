import $axios from 'utils/axios'

export const order = async (data) => {
  const result = await $axios.post('/order/v2/customer', data)
  return result.data
}

export const checkUniqueCode = async () => {
  const result = await $axios('/order/v2/customer/unique-code')
  return result.data.result
}

export const fetchListOrder = async (page = 0) => {
  const result = await $axios(`/order/v2/customer/list?page=${page}`)
  return result.data
}

export const fetchDetailOrder = async (id) => {
  const result = await $axios(`/order/v2/customer?id=${id}`)
  return result.data
}

export const checkPaymentStatus = async (order_id) => {
  const result = await $axios(
    `/order/v2/customer/payment/check?order_id=${order_id}`
  )
  return result.data
}

export const fetchDetailOrderConfirmPartner = async (id) => {
  const result = await $axios(`/order/partner-confirm?id=${id}`)
  return result.data
}

export const confirmOrderPartner = async (id) => {
  const result = await $axios.post('/order/partner-confirm/action/confirm', {
    id,
  })
  return result.data
}

export const rejectOrderPartner = async (id) => {
  const result = await $axios.post('/order/partner-confirm/action/reject', {
    id,
  })
  return result.data
}

export const fetchDailyServices = async (uuid, date) => {
  const result = await $axios.get(
    `/order/partner/daily-services?id=${uuid}&date=${date}`
  )
  return result.data
}
