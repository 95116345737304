import React from 'react'

import SearchMenu from './fragments/SearchMenu'
import TabMenu from './fragments/TabMenu'

const TabSearch = ({
  searchValue,
  searchChange,
  clearValue,
  placeholder,
  focus,
  submit,
  index,
  setIndex,
}) => {
  return (
    <div className="md:container">
      <div className=" flex flex-col gap-5 mb-2 items-center border-b">
        <div className="">
          <h3 className="text-primary hidden text-center mt-1 text-xs md:text-lg">
            Hai, User
          </h3>
          <h2 className="md:text-4xl sm:text-2xl text-xl px-4">
            Jelajahi Cita Rasa Istimewa
          </h2>
        </div>
        <SearchMenu
          searchValue={searchValue}
          searchChange={searchChange}
          clearValue={clearValue}
          focus={focus}
          placeholder={placeholder}
          submit={submit}
        />
        <TabMenu
          index={index}
          setIndex={setIndex}
          classWrapper="md:text-lg text-base text-center"
        />
      </div>
    </div>
  )
}

export default TabSearch
