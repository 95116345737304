import CustomForm from 'components/forms/CustomForm'
import { useOrder } from 'modules/custom/microsite/useOrder'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import InfoDetail from './fragments/InfoDetail'

const Recipient = () => {
  const navigate = useNavigate()
  const { dataUser, userProfile } = useOrder()
  const PAYMENT_TYPE = ['', 'Virtual Account', 'Bank Transfer', 'Cash']

  return (
    <CustomForm title="Detail Pemesan">
      <div className="flex flex-col gap-3 pb-8 border-b">
        <InfoDetail title="Nama Pemesan" value={userProfile?.name} />
        <InfoDetail
          title="Nomor Handphone Pemesan"
          value={userProfile?.phone}
        />
        {dataUser?.pic_email && (
          <InfoDetail title="Email Pemesan" value={dataUser?.pic_email} />
        )}
        {dataUser?.company_name && (
          <InfoDetail title="Nama Perusahaan" value={dataUser?.company_name} />
        )}
      </div>

      <div className="flex flex-col gap-5 py-6 pt-8 border-b">
        <h4 className="text-xl font-semibold">Detail Penerima</h4>
        <div className="flex flex-col gap-3">
          <InfoDetail title="Nama Penerima" value={dataUser?.recipient_name} />
          <InfoDetail
            title="Nomor Handphone Penerima"
            value={dataUser?.recipient_phone}
          />
          {dataUser?.delivery_to && (
            <InfoDetail
              title="Pemesanan dikirim ke"
              value={dataUser?.delivery_to}
            />
          )}
          <InfoDetail
            title="Alamat Pengiriman"
            value={dataUser?.delivery_address}
          />
        </div>
      </div>

      {dataUser?.notes && (
        <div className="flex flex-col gap-5 py-6 pt-8 border-b">
          <h4 className="text-xl font-semibold">Permintaan Khusus</h4>
          <div className="flex flex-col gap-3">
            <InfoDetail
              title="Catatan Pesanan atau Pengiriman"
              value={dataUser?.notes}
            />
          </div>
        </div>
      )}

      <div className="flex flex-col gap-5 py-6 pt-8">
        <h4 className="text-xl font-semibold">Detail Pembayaran</h4>
        <div className="flex flex-col gap-3">
          <InfoDetail
            title="Metode Pembayaran"
            value={`${PAYMENT_TYPE[dataUser?.payment_type]} (${
              dataUser?.payment_bank
            })`}
          />
        </div>
        <button
          onClick={() => navigate('/ms-payment-info')}
          className="border-2 mt-5 border-primary hover:bg-primary hover:text-white hover:opacity-80 active:opacity-100 rounded-full text-[10px] py-2 text-primary font-semibold max-w-[160px]"
        >
          Ubah Informasi
        </button>
      </div>
    </CustomForm>
  )
}

export default Recipient
