/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const KetentuanHarga = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="ketentuan-harga" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        C. Ketentuan Harga
      </div>
      <List>
        <ListItem>
          Minimum order dalam Pesanan adalah sebagaimana tercantum dalam
          KendiXpress yang dapat diperbaharui oleh RAM dari waktu ke waktu.
        </ListItem>
        <ListItem>
          Harga paket makanan dan minuman yang tertera dalam KendiXpress adalah
          harga yang ditetapkan oleh <em>Vendor</em> dengan menggunakan mata
          uang Rupiah.
        </ListItem>
        <ListItem>
          Semua harga yang tercantum dalam KendiXpress adalah benar pada saat
          dipublikasikan dan dilakukannya Pesanan. <em>Vendor</em> berhak untuk
          mengubah paket makanan dan minuman yang dipublikasikan di KendiXpress
          dari waktu ke waktu sebelum Pemesan membuat Pesanan..
        </ListItem>
        <ListItem>
          Kecuali secara tegas diatur sebaliknya, harga-harga yang tertera dalam
          KendiXpress sudah termasuk pajak-pajak atau beban layanan lain yang
          harus ditanggung oleh Pemesan.
        </ListItem>
      </List>
    </TabContent>
  )
})

export default KetentuanHarga
