import React from 'react'

const TabItem = ({ children, index, active, id }) => {
  return (
    <div
      className={`py-3 px-2 md:p-4 tracking-normal whitespace-nowrap text-center md:text-left cursor-pointer ${
        active == index ? 'border-b-4 text-black border-primary' : ''
      } `}
      onClick={id}
    >
      {children}
    </div>
  )
}

export default TabItem
