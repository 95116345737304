import { useMutation, useQuery } from 'react-query'

import { fetchUserProfile } from './api'

export const useFetchUserProfile = (params) => {
  return useQuery(['user-profile', params], () => fetchUserProfile(params))
}

export const useFetchUserProfileMutation = () => {
  return useMutation((params) => fetchUserProfile(params))
}
