import React from 'react'

function InfoItems({
  title,
  desc,
  descBottom,
  descBottomClass = '',
  icon,
  isLoading,
}) {
  return (
    <div className="flex justify-between items-center my-5">
      <div className="w-10/12 md:w-9/12">
        <span className="text-xs text-[#9D9D9D]">{title}</span>
        {!isLoading && (
          <div className="text-sm text-black font-medium">{desc}</div>
        )}
        {isLoading && (
          <div role="status" className="space-y-2.5 animate-pulse w-full mt-2">
            <div className="h-5 bg-gray-300 rounded-lg w-1/2"></div>
          </div>
        )}
        {descBottom && (
          <div className={`text-sm text-black font-medium ${descBottomClass}`}>
            {descBottom}
          </div>
        )}
      </div>
      <div>
        <img src={icon} className="w-6 h-6" />
      </div>
    </div>
  )
}

export default InfoItems
