import Footer from 'components/layouts/fragments/Footer'
import WhatsappButton from 'components/WhatsappButton'
import Banner from 'images/banner/banner-about-us.jpg'
import React from 'react'

import Navbar from './Navbar'

function AboutUsLayout({ children }) {
  return (
    <>
      <div className="w-full">
        <div className="relative">
          <img src={Banner} className="w-full h-[200px] md:h-full" />
          <Navbar />
          <div className="container absolute inset-0">
            <div className="translate-y-[45%] h-full">
              <div className="text-center">
                <h2 className="text-sm md:text-[26px] text-white font-semibold">
                  Kebutuhan Makan untuk Eventmu
                </h2>
                <h1 className="text-xl md:text-[40px] text-white font-merienda font-semibold md:mt-6">
                  Siap Kami Layani
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">{children}</div>
      <Footer />
      <WhatsappButton />
    </>
  )
}

export default AboutUsLayout
