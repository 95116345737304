import ModalMaps from 'components/modal/ModalMaps'
import emptyAddress from 'images/menu/empty-address.svg'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import React from 'react'

import EmptyWrapper from './fragments/EmptyWrapper'

const EmptyAddress = () => {
  const {
    showModal,
    setShowModal,
    setLatLong,
    place,
    setPlace,
    isPartnerExist,
    setIsPartnerExist,
    handleSubmitForm,
    saveLocationMutation,
  } = useGoogleMapHook()
  return (
    <>
      <EmptyWrapper
        image={emptyAddress}
        click={() => setShowModal(true)}
        text={'Masukan Alamat'}
      >
        <h3 className="text-base font-semibold">
          Silahkan isi <br />
          alamat pengirimanmu
        </h3>
        <p className="text-sm">
          Agar kamu bisa melihat menu
          <br />
          yang tersedia didaerahmu
        </p>
      </EmptyWrapper>
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        place={place}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={saveLocationMutation.isLoading}
      />
    </>
  )
}

export default EmptyAddress
