import React from 'react'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import { DAY_NAMES, MONTH_NAMES } from 'utils/date'

// eslint-disable-next-line react/display-name
const DateChange = React.memo(
  ({ show, setShow, cart, dateCart, time, minDate = new Date(), action }) => {
    const findDate =
      cart.length > 0
        ? cart.findIndex(
            (data) => data.date === dateCart && data.shipping_time === time
          )
        : -1

    if (new Date().getHours() >= 14 && new Date().getMinutes() >= 1) {
      minDate.setDate(minDate.getDate() + 1)
    }

    let maxDate = new Date()
    maxDate.setMonth(maxDate.getMonth() + 3)

    const currentDate =
      findDate !== -1 && cart[findDate]
        ? new Date(cart[findDate].date)
        : new Date()
    const [calendar, setCalendar] = React.useState([])
    const [days] = React.useState(DAY_NAMES)
    const [months] = React.useState(MONTH_NAMES)
    const [month, setMonth] = React.useState(currentDate.getMonth())
    const [year, setYear] = React.useState(currentDate.getFullYear())
    const [selectedDate, setSelectedDate] = React.useState(currentDate)
    const lbRef = React.useRef(null)

    const changeMonth = (inc) => {
      let curMonth = month + inc
      let curYear = year

      if (curMonth === 12) {
        curMonth = 0
        curYear++
      } else if (curMonth === -1) {
        curMonth = 11
        curYear--
      }

      setMonth(curMonth)
      setYear(curYear)
    }

    const selectDate = (day) => {
      setMonth(day.getMonth())
      setYear(day.getFullYear())
      setSelectedDate(day)
      action(day.getTime())
    }

    React.useEffect(() => {
      const firstDayThisMonth = new Date(year, month, 1).getDay()
      const temp = []

      for (let i = 0; i < 42; i++) {
        const date = new Date(year, month, i - firstDayThisMonth + 1)
        temp.push(date)
      }

      setCalendar(temp)
    }, [month, year])

    React.useEffect(() => {
      if (currentDate) {
        if (currentDate.getTime() < minDate.getTime()) {
          setMonth(minDate.getMonth())
          setSelectedDate(minDate)
        } else {
          setMonth(currentDate.getMonth())
        }
      }
    }, [])

    return (
      <React.Fragment>
        {show && (
          <>
            <div
              onClick={() => setShow(false)}
              className="fixed inset-0 w-screen h-screen"
            ></div>
            <div
              className=" bg-white absolute z-10 max-w-xs w-[190px] left-[2px] shadow-menu-item top-[50px] rounded-xl overflow-hidden"
              ref={lbRef}
            >
              <div className="flex flex-row w-full justify-between p-2 md:p-4 px-5 items-center">
                <button
                  disabled={
                    minDate.getFullYear() === year &&
                    minDate.getMonth() === month
                  }
                  className="p-0 ml-2 bg-none border-none cursor-pointer disabled:text-gray-400 text-primary"
                  onClick={() => changeMonth(-1)}
                >
                  <MdArrowBackIos size={12} />
                </button>
                <div className="text-xs font-semibold whitespace-nowrap">
                  {months[month].slice(0, 3)} {year}
                </div>
                <button
                  disabled={
                    maxDate.getFullYear() === year &&
                    maxDate.getMonth() === month
                  }
                  className="p-0 bg-none border-none cursor-pointer disabled:text-gray-400 text-primary"
                  onClick={() => changeMonth(+1)}
                >
                  <MdArrowForwardIos size={12} />
                </button>
              </div>

              <div className="">
                <div className="py-1 mr-4 ml-3 border-t border-b flex justify-around items-center font-semibold text-xs">
                  {days.map((day) => (
                    <div className="" key={day}>
                      {day.substring(0, 1)}
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-7 p-4">
                  {calendar.map((day, index) => (
                    <div
                      className={`h-full text-sm flex justify-end items-center ${
                        day.getMonth() === month ? 'font-medium' : 'opacity-50'
                      }`}
                      key={index}
                    >
                      <button
                        className={`${
                          selectedDate?.getTime() === day.getTime()
                            ? 'bg-primary text-white'
                            : 'bg-white text-black'
                        } w-12 h-6 cursor-pointer text-xs bg-none border-none disabled:text-gray-400 rounded-full`}
                        onClick={() => selectDate(day)}
                        disabled={
                          day.getTime() < minDate.getTime() ||
                          day.getTime() > maxDate.getTime()
                        }
                      >
                        {day.getDate()}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    )
  }
)

export default DateChange
