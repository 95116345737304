/* eslint-disable react/display-name */
import 'styles/components/input.css'

import React, { forwardRef } from 'react'

export const Input = forwardRef(
  (
    {
      type = 'text',
      label,
      name,
      error,
      onChange,
      classWrapper,
      classInput,
      iconAppend,
      contentBottom,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <div
          className={`relative border-b-[1px] border-b-[#c3c3c3] ${
            error && 'border-b-primary'
          } ${classWrapper} focus-within:border-primary text-sm bg-transparent z-[51]`}
        >
          <input
            ref={ref}
            type={type}
            name={name}
            {...rest}
            onChange={onChange}
            placeholder=" "
            className={`block w-full appearance-none focus:outline-none bg-transparent pb-3 ${
              iconAppend && '!pr-9'
            } ${classInput}`}
          />
          <label
            htmlFor={name}
            className={`peer absolute top-0 -z-1 duration-300 origin-0 text-[#9D9D9D]`}
          >
            {label}
          </label>
          {iconAppend && (
            <div className="absolute top-2/4 right-2 -translate-y-2/4 w-5 h-5">
              {iconAppend}
            </div>
          )}
        </div>
        {contentBottom}
        {error && (
          <span className="text-primary text-[10px] float-right mt-1">
            {error}
          </span>
        )}
      </>
    )
  }
)

export default Input
