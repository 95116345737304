import { useOrder } from 'modules/custom/useOrder'
import React from 'react'

const InfoCard = () => {
  const { dataUser } = useOrder()
  return (
    <div className="bg-white rounded-3xl md:py-10 md:px-6">
      <h5 className="md:text-xl text-primary md:pb-6 md:border-b font-semibold">
        Informasi Penting
      </h5>
      {dataUser.payment_type == 2 ? (
        <p className="text-sm py-6">
          Nomor rekening {dataUser.payment_bank} akan diberi tau setelah Kamu
          melalui tahap konfirmasi pesanan. <br /> <br />
          Pastikan nominal transfer sesuai hingga 3 digit angka terakhir. <br />
          <br />
          Pastikan kembali nominal pembayaran sesuai dengan pemesanan yang Kamu
          lakukan
        </p>
      ) : (
        <p className="text-sm py-6">
          Nomor Virtual Account {dataUser.payment_bank} akan diberi tau setelah
          Kamu melalui tahap konfirmasi pesanan
          <br /> <br />
          Pembayaran hanya berlaku dengan menggunakan akun{' '}
          {dataUser.payment_bank}
          <br /> <br />
          Pastikan nomor Virtual Account Kamu sesuai dengan instruksi pembayaran
          <br /> <br />
          Pastikan kembali nominal pembayaran sesuai dengan pemesanan yang Kamu
          lakukan
        </p>
      )}
    </div>
  )
}

export default InfoCard
