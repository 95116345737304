import { useMutation, useQuery } from 'react-query'

import {
  checkPaymentStatus,
  checkUniqueCode,
  fetchDetailOrder,
  fetchDetailOrderConfirmPartner,
  fetchListOrder,
  order,
  confirmOrderPartner,
  rejectOrderPartner,
} from './api'

export const useOrderMutation = () => {
  return useMutation((data) => order(data))
}

export const useUniqueCodeMutations = () => {
  return useMutation((params) => checkUniqueCode(params))
}

export const useFetchListOrder = () => {
  return useQuery(['list-order'], () => fetchListOrder())
}

export const useFetchDetailOrder = (id) => {
  return useQuery(['detail-order', id], () => fetchDetailOrder(id))
}

export const useCheckPayment = () => {
  return useMutation(({ order_id }) => checkPaymentStatus(order_id))
}

export const useFetchDetailOrderConfirmPartner = (id) => {
  return useQuery(['detail-order-confirm-partner', id], () =>
    fetchDetailOrderConfirmPartner(id)
  )
}

export const useConfirmOrderPartner = () => {
  return useMutation((id) => confirmOrderPartner(id))
}

export const useRejectOrderPartner = () => {
  return useMutation((id) => rejectOrderPartner(id))
}
