import Breadcrumb from 'components/layouts/fragments/breadcrumb'
import WhatsappButton from 'components/WhatsappButton'
import React from 'react'

import Footer from './fragments/Footer'
import NavAddress from './fragments/Navbar/NavAddress'

const PaymentLayout = ({ header, headForm, descriptionForm, children }) => {
  return (
    <React.Fragment>
      <NavAddress>
        <div className="block md:invisible w-full md:w-fit ml-2 text-lg font-semibold">
          {header}
        </div>
      </NavAddress>
      <Breadcrumb heading={headForm} description={descriptionForm} />
      <div className="md:bg-slate-50 bg-white bg-opacity-70">{children}</div>
      <Footer />
      <WhatsappButton />
    </React.Fragment>
  )
}

export default PaymentLayout
