/* eslint-disable no-unused-vars */
import SectionLayout from 'components/layouts/SectionLayout'
import ModalMaps from 'components/modal/ModalMaps'
import BannerOneResponsive from 'images/banner/banner_mobile_1.jpg'
import BannerTwoResponsive from 'images/banner/banner_mobile_2.jpg'
import BannerThreeResponsive from 'images/banner/banner_mobile_3.jpg'
import BannerOne from 'images/banner/banner_website_1.jpg'
import BannerTwo from 'images/banner/banner_website_2.jpg'
import BannerThree from 'images/banner/banner_website_3.jpg'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import SearchBanner from './fragments/SearchBanner'
function Banner() {
  const {
    showModal,
    setShowModal,
    latLong,
    setLatLong,
    place,
    setPlace,
    setURL,
    setRegion,
    isPartnerExist,
    setIsPartnerExist,
    handleSubmitForm,
    saveLocationMutation,
    redirectToExploreIfAddressFilled,
  } = useGoogleMapHook()

  return (
    <SectionLayout className={`mt-[21px]`}>
      <div className="w-full relative flex md:block flex-col md:flex-row">
        {/* CAROUSEL BANNER */}
        <Carousel
          showStatus={false}
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          stopOnHover={true}
          interval={5000}
          transitionTime={500}
          swipeable={true}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          className="slider order-2"
          onClickItem={() => redirectToExploreIfAddressFilled()}
        >
          <div className="cursor-pointer">
            <picture>
              <source media="(min-width: 768px)" srcSet={BannerOne} />
              <img src={BannerOneResponsive} />
            </picture>
          </div>
          <div className="cursor-pointer">
            <picture>
              <source media="(min-width: 768px)" srcSet={BannerTwo} />
              <img src={BannerTwoResponsive} />
            </picture>
          </div>
          <div className="cursor-pointer">
            <picture>
              <source media="(min-width: 768px)" srcSet={BannerThree} />
              <img src={BannerThreeResponsive} />
            </picture>
          </div>
        </Carousel>
        {/* END CAROUSEL BANNER */}
        {/* SLIDER INPUT */}
        <SearchBanner />
        {/* END SLIDER INPUT */}
      </div>
      {/* MODAL SET LOCATION */}
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        place={place}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={saveLocationMutation.isLoading}
        setURL={setURL}
        setRegion={setRegion}
        hideModal={() => {
          sessionStorage.setItem('searchValue', JSON.stringify(''))
          sessionStorage.setItem('partnerType', JSON.stringify(''))
        }}
      />
    </SectionLayout>
  )
}

export default Banner
