import React from 'react'
import { useState } from 'react'
import { BiMinus, BiPlus, BiTrash } from 'react-icons/bi'
import { currency } from 'utils/formatNumber'

const MenuCart = ({
  quantity,
  setQuantity,
  qty,
  name,
  available,
  min_order,
  price,
  unit,
  addProduct,
  updateQuantity,
  removeProduct,
}) => {
  const [editQty, setEditQty] = useState(false)

  const editQuantity = () => {
    setQuantity(qty)
    setEditQty(true)
  }

  const sendQuantity = () => {
    updateQuantity()
    setEditQty(false)
  }
  return (
    <div className="">
      <div className="flex flex-col gap-2">
        <div
          className={`grid grid-cols-3 mt-4 gap-1 justify-between ${
            available ? '' : 'text-gray-400'
          }`}
        >
          <div className="flex col-span-2 flex-col gap-1">
            <div className="line-clamp-1 text-sm ">{name}</div>
            <div className="flex gap-1">
              <p className="text-[10px] flex-shrink-0 tracking-normal">
                {currency(price)}{' '}
                <span className="text-gray-400">per {unit}</span>
              </p>
              {!available && (
                <p className="text-[10px] text-primary flex-shrink-0 tracking-normal">
                  Menu tidak tersedia pada tanggal ini
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center max-w-20 gap-2">
            <button
              onClick={removeProduct}
              className={
                qty !== min_order
                  ? `text-center border-2 h-5 rounded-full ${
                      !available
                        ? 'text-gray-400 border-gray-400 pointer-events-none'
                        : 'text-primary  border-primary'
                    }`
                  : 'text-primary border-primary'
              }
            >
              {qty === min_order ? (
                <BiTrash className="w-6 h-6" />
              ) : (
                <BiMinus className="w-4 h-3" />
              )}
            </button>
            {editQty ? (
              <input
                autoFocus
                value={quantity}
                type="number"
                onChange={(e) => setQuantity(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendQuantity()}
                onBlur={sendQuantity}
                className="w-full h-auto align-top flex text-center text-sm font-semibold outline-none"
              />
            ) : (
              <p
                onClick={() => editQuantity()}
                className="text-sm font-semibold"
              >
                {qty}
              </p>
            )}
            <button
              onClick={addProduct}
              className={`border-2 h-5 rounded-full ${
                !available
                  ? 'text-gray-400 border-gray-400 pointer-events-none'
                  : 'text-primary  border-primary'
              }`}
            >
              <BiPlus className="w-4 h-3" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuCart
