/* eslint-disable no-unused-vars */
import ImageUtama from 'images/menu/menu-utama.svg'
import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel'

import FeaturedMitra from './fragments/FeaturedMitra'
import MenuImage from './fragments/MenuImage'
import PopupMenu from './PopupMenu'

const DefaultImage = () => {
  return (
    <img
      src={ImageUtama}
      alt="default-image"
      className="h-[396px] w-full cursor-pointer transition-transform bg-primary duration-200 ease-in hover:scale-[1.2]"
    />
  )
}

const TopSection = ({ data }) => {
  const [popup, setPopup] = useState(false)
  const [show, setShow] = useState('')

  const handlePopup = (props) => {
    setPopup(props)
    setShow(true)
  }
  return (
    <React.Fragment>
      <div className="flex flex-col-reverse md:flex-col md:px-4 mt-1 isolate md:mt-10 gap-5 lg:container">
        <div className="flex flex-col gap-1 pb-7 px-4 md:pb-0 border-b md:border-b-0">
          <h1 className="text-[1.625rem] font-semibold">{data[0]?.name}</h1>
          <FeaturedMitra
            distance={data[0]?.distance}
            unit={data[0]?.distance_unit}
            cost_from={data[0]?.shipping_cost_fr}
            cost_to={data[0]?.shipping_cost_to}
            minOrder={data[0]?.info_min_order}
            type={data[0]?.partner_type_id}
          />
        </div>
        {/* Desktop mode */}
        <div className="hidden md:grid grid-rows-1 grid-cols-2 gap-2">
          <div
            onClick={
              data[0]?.menus[0]
                ? () => handlePopup(data[0].menus[0])
                : undefined
            }
            className="max-h-[396px] rounded-l-2xl  overflow-hidden"
          >
            {data[0]?.menus[0] ? (
              <img
                src={data[0]?.menus[0]?.photo}
                alt={data[0]?.menus[0]?.name}
                className="object-cover cursor-pointer w-full h-[396px] transition-transform duration-200 ease-in hover:scale-[1.2] bg-center"
              />
            ) : (
              <DefaultImage />
            )}
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-2">
            <MenuImage
              menu={data[0]?.menus[1]}
              onClick={
                data[0]?.menus[1]
                  ? () => handlePopup(data[0].menus[1])
                  : () => console.log('hello')
              }
            />
            <MenuImage
              onClick={
                data[0]?.menus[2]
                  ? () => handlePopup(data[0].menus[2])
                  : undefined
              }
              menu={data[0]?.menus[2]}
              className="rounded-tr-2xl"
            />
            <MenuImage
              onClick={
                data[0]?.menus[3]
                  ? () => handlePopup(data[0].menus[3])
                  : undefined
              }
              menu={data[0]?.menus[3]}
            />
            <MenuImage
              onClick={
                data[0]?.menus[4]
                  ? () => handlePopup(data[0].menus[4])
                  : undefined
              }
              menu={data[0]?.menus[4]}
              className="rounded-br-2xl"
            />
          </div>
        </div>
        {/* Mobile mode */}
        <div className="block md:hidden">
          <Carousel
            showArrows={false}
            showStatus={false}
            swipeable={true}
            width="100% "
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={true}
            interval={3000}
            transitionTime={500}
          >
            <div
              onClick={
                data[0]?.menus[0]
                  ? () => handlePopup(data[0]?.menus[0])
                  : undefined
              }
              className="cursor-pointer"
            >
              <MenuImage menu={data[0]?.menus[0]} />
            </div>
            <div
              onClick={
                data[0]?.menus[1]
                  ? () => handlePopup(data[0]?.menus[1])
                  : undefined
              }
              className="cursor-pointer"
            >
              <MenuImage menu={data[0]?.menus[1]} />
            </div>
            <div
              onClick={
                data[0]?.menus[2]
                  ? () => handlePopup(data[0]?.menus[2])
                  : undefined
              }
              className="cursor-pointer"
            >
              <MenuImage menu={data[0]?.menus[2]} />
            </div>
            <div
              onClick={
                data[0]?.menus[3]
                  ? () => handlePopup(data[0]?.menus[3])
                  : undefined
              }
              className="cursor-pointer"
            >
              <MenuImage menu={data[0]?.menus[3]} />
            </div>
            <div
              onClick={
                data[0]?.menus[4]
                  ? () => handlePopup(data[0]?.menus[4])
                  : undefined
              }
              className="cursor-pointer"
            >
              <MenuImage menu={data[0]?.menus[4]} />
            </div>
          </Carousel>
        </div>
      </div>
      {/* Pop up Menu */}
      <PopupMenu show={show} setShow={setShow} menu={popup} />
    </React.Fragment>
  )
}

export default TopSection
