import emptyMenu from 'images/menu/empty-menu.svg'
import React from 'react'
import { useState } from 'react'

import EmptyWrapper from './fragments/EmptyWrapper'

const EmptySearchMenu = () => {
  const [searchMenu] = useState(
    JSON.parse(sessionStorage.getItem('searchValue'))
  )
  return (
    <EmptyWrapper
      image={emptyMenu}
      link={`https://wa.me/6281119279900?text=Saya%20ingin%20pesan%20menu%20${searchMenu}`}
      text={'Hubungi Admin'}
    >
      <h3 className="text-base capitalize font-semibold">
        Mohon Maaf, <br />
        Pencarian Menu {searchMenu} Belum Tersedia
      </h3>
      <p className="text-sm">
        Kamu menginginkan menu ini? <br />
        Silahkan hubungi admin kendiXpress
      </p>
    </EmptyWrapper>
  )
}

export default EmptySearchMenu
