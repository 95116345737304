import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'

function Dropdown({
  classButton = '',
  buttonContent,
  classDropdown = '',
  dropdownContent,
}) {
  const [open, setOpen] = useState(false)
  const drop = useRef(null)

  const handleClick = (e) => {
    if (!e.target.closest(`.${drop.current.className}`) && open) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  return (
    <div
      ref={drop}
      className="dropdown"
      style={{ position: 'relative', display: 'inline-block' }}
    >
      <div
        className={`cursor-pointer ${classButton}`}
        onClick={() => setOpen((open) => !open)}
      >
        {buttonContent}
      </div>
      {open && (
        <div
          className={`shadow bg-white absolute top-8 right-0 bottom-auto left-auto z-10 ${classDropdown}`}
        >
          {dropdownContent}
        </div>
      )}
    </div>
  )
}

export default Dropdown
