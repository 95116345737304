import React from 'react'

function TabSidebarItem({ title, id, active, ...rest }) {
  const scrollSelectedItem = (id) => {
    const el = document.getElementById(id)
    el.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  return (
    <div
      {...rest}
      className={`cursor-pointer text-sm my-3 hover:text-primary ${
        active ? 'text-primary font-semibold' : 'text-[#9D9D9D]'
      }`}
      onClick={() => scrollSelectedItem(id)}
    >
      {title}
    </div>
  )
}

export default TabSidebarItem
