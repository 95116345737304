import { useMutation, useQuery } from 'react-query'

import {
  createOrder,
  fetchMenuCategory,
  fetchPermanentMenu,
  getPartnerList,
  getPartnerMenuShipping,
  getUniqueCodePayment,
} from './api'

export const useFetchMenuCategory = () => {
  return useQuery('menu-category', () => fetchMenuCategory())
}

export const useFetchPermanentMenuList = (category_id, lat, long) => {
  return useQuery(['menu-list', category_id, lat, long], () =>
    fetchPermanentMenu(category_id, lat, long)
  )
}

export const usePartnerListMutation = () => {
  return useMutation((data) => getPartnerList(data))
}

export const usePartnerShippingCostMutation = () => {
  return useMutation((data) => getPartnerMenuShipping(data))
}

export const useOrderMutation = () => {
  return useMutation((data) => createOrder(data))
}

export const useGenerateUniqueCode = () => {
  return useQuery('unique-code', () => getUniqueCodePayment())
}
