import Tippy from '@tippyjs/react'
import React from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const Tooltip = ({ children }) => {
  return (
    <Tippy content={<div className="p-2 text-xs">{children}</div>} theme="dark">
      <button>
        <AiOutlineInfoCircle className="text-primary w-3 h-3 ml-1 outline-none cursor-pointer" />
      </button>
    </Tippy>
  )
}

export default Tooltip
