import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'

const Breadcrumb = ({ heading, description }) => {
  return (
    <React.Fragment>
      {/* Breadcrumb */}
      <div className="container py-5 hidden md:block">
        <ul className="flex gap-2 text-xs items-center">
          <li className="text-primary">Pesanan</li>
          <li className="text-primary">
            <IoIosArrowForward />
          </li>
          <li
            className={` ${
              window.location.pathname == '/payment-info'
                ? 'text-black'
                : 'text-primary'
            }`}
          >
            Informasi pengiriman dan pembayaran
          </li>
          <li
            className={` ${
              window.location.pathname == '/payment-info'
                ? 'text-gray-400'
                : 'text-primary'
            }`}
          >
            <IoIosArrowForward />
          </li>
          <li
            className={` ${
              window.location.pathname == '/payment-info'
                ? 'text-gray-400  '
                : 'text-black'
            }`}
          >
            Konfirmasi pesanan
          </li>
        </ul>
      </div>

      {/* Header Form */}
      <div className="flex container py-4 md:py-5 md:pb-10 flex-col gap-2">
        <h2
          className={`font-semibold md:text-[26px] ${
            location.pathname == '/payment-confirmation'
              ? 'hidden md:block'
              : 'block'
          }`}
        >
          {heading}
        </h2>
        <p className="text-sm">{description}</p>
      </div>
    </React.Fragment>
  )
}

export default Breadcrumb
