import changeCart from 'images/icons/change-cart.svg'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ChangeCart = () => {
  // eslint-disable-next-line no-unused-vars
  const [partnerID, setPartnerID] = useState(
    JSON.parse(localStorage.getItem('partner'))
  )
  const cartAvailable = localStorage.getItem('cart-available')
  return (
    <div className="flex justify-center h-[500px] overflow-y-scroll no-scrollbar items-center">
      <div className="flex items-center gap-5 flex-col">
        <img
          src={changeCart}
          alt="change-cart"
          className="w-[120px] h-[120px]"
        />
        <div className="flex flex-col gap-1 items-center justify-center">
          <h5 className="font-semibold text-center text-lg">
            Ingin Ganti Pesanan dari <br />
            Catering atau Resto lain?
          </h5>
          <p className="text-sm text-center">
            Pesanan yang terpilih sebelumnya
            <br /> akan terhapus jika kamu pilih
            <br />
            menu dari Catering atau Resto lain
          </p>
          {cartAvailable !== -1 ? (
            <Link to={`/detail-mitra/${partnerID.partner_id}`}>
              <p className="bg-primary text-white mt-3 text-xs font-semibold rounded-full px-7 py-2">
                Cek Pesanan
              </p>
            </Link>
          ) : (
            <p className="text-xs mt-5 text-primary text-center">
              <strong>
                Mohon Maaf <br />
              </strong>{' '}
              Mitra yang terpilih sebelumnya <br />
              diluar jangkauan
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChangeCart
