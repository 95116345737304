/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from 'components/button/LoadingButton'
import Form from 'components/forms/Form'
import Input from 'components/forms/Input'
import { useFetchPhoneExistMutation } from 'modules/Auth/hook'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { isNumber } from 'utils/helpers'
import * as yup from 'yup'

function PhoneValidatorContent({ goNextStep, phoneNumber, setPhoneNumber }) {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .required('Wajib diisi')
      .test('len', 'Harap cek nomor handphonemu', (val) => {
        if (val == undefined) {
          return true
        }
        return val.length == 0 || (val.length >= 8 && val.length <= 15)
      }),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      phone: phoneNumber || '',
    },
  })

  const mutation = useFetchPhoneExistMutation()

  const handleSubmitForm = async (data) => {
    let phoneNumber = null
    if (/^62/.test(data.phone)) {
      phoneNumber = data.phone.replace(/^62/, '0')
    } else {
      phoneNumber = data.phone
    }
    mutation.mutate(
      {
        phone: phoneNumber,
      },
      {
        onSuccess: (result) => {
          setPhoneNumber && setPhoneNumber(phoneNumber)
          goNextStep && goNextStep(result)
        },
      }
    )
  }

  return (
    <div className="flex flex-col w-full">
      <h4 className="text-lg text-black font-semibold mb-1">Masuk/Daftar</h4>
      <span className="text-sm text-black leading-6">
        Rasakan Cita Rasa Istimewa
        <br />
        mulai dari sini
      </span>

      <Form>
        <div className="mt-12 mb-9">
          <Controller
            name="phone"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                autoComplete="off"
                label="Nomor Handphone"
                name="phone"
                error={errors.phone && errors.phone.message}
                onInput={(e) => isNumber(e)}
              />
            )}
          />
        </div>
        <LoadingButton
          className="!px-2 md:!px-3 !py-3 font-semibold !w-full"
          isRounded={true}
          isLoading={mutation.isLoading}
          onClick={handleSubmit(handleSubmitForm)}
          id="next-button"
        >
          Selanjutnya
        </LoadingButton>
      </Form>
      <div className="mt-7 text-center">
        <div className="text-[10px] leading-3 text-black">
          Dengan masuk atau daftar, Kamu menyetujui
        </div>
        <div className="text-[10px] leading-3 text-black mt-1">
          <Link to="/tnc" className="text-primary">
            Ketentuan Layanan
          </Link>{' '}
          dan{' '}
          <Link to="/privacy-policy" className="text-primary">
            Kebijakan Privasi
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PhoneValidatorContent
