/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const PerubahanKebijakan = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="perubahan-kebijakan">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        8. Perubahan Kebijakan Privasi
      </div>
      <div className="my-2">
        Dari waktu ke waktu, KendiXpress dapat mengubah Kebijakan Privasi ini.
        Saat kami melakukan perubahan pada Kebijakan Privasi ini, kami akan
        memberikan Pemesan pemberitahuan penting yang sesuai dengan keadaan.
      </div>
    </TabContent>
  )
})

export default PerubahanKebijakan
