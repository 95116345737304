import { useFetchMenuRecommend } from 'modules/Explore/hook'
import React from 'react'
import { useState } from 'react'

import DesktopMenuRecommend from './fragments/DesktopMenuRecommend'
import MobileMenuRecommend from './fragments/MobileMenuRecommend'
import RecommendWrapper from './fragments/RecommendWrapper'

const RecommendMenu = ({ price }) => {
  const [location] = useState(() => {
    const saved = localStorage.getItem('dataAddress')
    const initialValue = JSON.parse(saved)
    return initialValue || ''
  })
  const menuRecommend = useFetchMenuRecommend(location.lat, location.lng, price)

  return (
    <>
      {!menuRecommend.isLoading && menuRecommend.data.result.length > 0 ? (
        <div className="bg-explore mt-5 md:mt-10">
          {!menuRecommend.isLoading && menuRecommend.data.result.length > 0 && (
            <RecommendWrapper price={price}>
              <DesktopMenuRecommend menuRecommend={menuRecommend} />
              <MobileMenuRecommend menuRecommend={menuRecommend} />
            </RecommendWrapper>
          )}
        </div>
      ) : null}
    </>
  )
}

export default RecommendMenu
