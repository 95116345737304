import { useMutation, useQuery } from 'react-query'

import {
  fetchDetailMitra,
  fetchListMenuAndalan,
  fetchListMenuHarian,
  fetchListMenuTambahan,
  fetchNearMitra,
} from './api'

export const useFetchDetailMitra = (lat, lon, partner) => {
  return useQuery(['detail-mitra', lat, lon, partner], () =>
    fetchDetailMitra(lat, lon, partner)
  )
}

export const useFetchListMenuAndalan = (lat, lon, partner, date) => {
  return useQuery(['list-menu-andalan', lat, lon, partner, date], () =>
    fetchListMenuAndalan(lat, lon, partner, date)
  )
}

export const useMenuAndalanMutations = () => {
  return useMutation(({ lat, lon, partner, date }) =>
    fetchListMenuAndalan(lat, lon, partner, date)
  )
}

export const useFetchListMenuHarian = (lat, lon, partner, date) => {
  return useQuery(['list-menu-harian', lat, lon, partner, date], () =>
    fetchListMenuHarian(lat, lon, partner, date)
  )
}

export const useMenuHarianMutations = () => {
  return useMutation(({ lat, lon, partner, date }) =>
    fetchListMenuHarian(lat, lon, partner, date)
  )
}

export const useFetchListMenuTambahan = (lat, lon, partner, date) => {
  return useQuery(['list-menu-tambahan', lat, lon, partner, date], () =>
    fetchListMenuTambahan(lat, lon, partner, date)
  )
}

export const useMenuTambahanMutations = () => {
  return useMutation(({ lat, lon, partner, date }) =>
    fetchListMenuAndalan(lat, lon, partner, date)
  )
}

export const useFetchDetailMutations = () => {
  return useMutation(({ lat, lon, partner }) =>
    fetchDetailMitra(lat, lon, partner)
  )
}

export const useFetchNearMitra = (lat, lon, partner) => {
  return useQuery(['near-mitra', lat, lon, partner], () =>
    fetchNearMitra(lat, lon, partner)
  )
}
