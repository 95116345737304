import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from 'components/button/LoadingButton'
import Form from 'components/forms/Form'
import PlainInput from 'components/forms/PlainInput'
import {
  useCreatePasswordUser,
  useRegisterUser,
  useValidateManualUser,
} from 'modules/Auth/hook'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import FooterHelp from './FooterHelp'

function OtpAlternativesInputContent({
  phoneNumber,
  fullName,
  password,
  setStep,
  scenario,
}) {
  const schema = yup.object().shape({
    code: yup.string().required('Kode Verifikasi Wajib diisi'),
  })

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  })

  const mutation = useValidateManualUser()
  const mutationRegister = useRegisterUser()
  const mutationCreatePassword = useCreatePasswordUser()

  const handleSubmitForm = (data) => {
    mutation.mutate(
      { phone: phoneNumber, code: data.code.toUpperCase() },
      {
        onSuccess: (result) => {
          if (result.length === 0 || Object.keys(result).length === 0) {
            setError('code', {
              type: 'server',
              message: 'Kode verifikasi salah, coba ulangi',
            })
          } else {
            if (result.data) {
              if (scenario === 'register') {
                registerUser()
              }
              if (scenario === 'create-password') {
                createUser()
              }
              if (scenario === 'forgot-password') {
                setStep('forgot-password-input')
              }
            } else {
              setError('code', {
                type: 'server',
                message: 'Kode verifikasi salah, coba ulangi',
              })
            }
          }
        },
      }
    )
  }

  const registerUser = () => {
    const replaceCountryCode = phoneNumber.replace(/^0/, '+62')
    mutationRegister.mutate(
      {
        phone: replaceCountryCode,
        password: password,
        fullname: fullName,
      },
      {
        onSuccess: (result) => {
          if (result.length === 0 || Object.keys(result).length === 0) {
            setError('code', {
              type: 'server',
              message: 'Server error, harap hubungi administrator',
            })
          } else {
            localStorage.setItem('token', result.token)
            setStep('success')
          }
        },
      }
    )
  }

  const createUser = () => {
    mutationCreatePassword.mutate(
      {
        phone: phoneNumber,
        password: password,
      },
      {
        onSuccess: (result) => {
          if (result.length === 0 || Object.keys(result).length === 0) {
            setError('code', {
              type: 'server',
              message: 'Server error, harap hubungi administrator',
            })
          } else {
            localStorage.setItem('token', result.token)
            setStep('success')
          }
        },
      }
    )
  }

  return (
    <div className="flex flex-col w-full">
      <h4 className="text-lg text-black font-semibold mb-1">
        Masukkan Kode Verifikasi
      </h4>
      <span className="text-sm text-black leading-6">
        Masukkan kode verifikasi yang sudah
        <br />
        diberikan admin kendixpress
      </span>

      <Form autoComplete="off">
        <div className="mt-12 mb-10 flex">
          <Controller
            name="code"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <PlainInput
                {...field}
                type="text"
                name="code"
                classInput={`w-full mx-2 text-center uppercase tracking-[1.25rem] !text-2xl ${
                  errors.code && errors.code.message
                    ? '!border-b-primary text-primary'
                    : ''
                }`}
              />
            )}
          />
        </div>
        <LoadingButton
          className="!px-2 md:!px-3 !py-3 font-semibold !w-full"
          isRounded={true}
          isLoading={
            mutation.isLoading ||
            mutationRegister.isLoading ||
            mutationCreatePassword.isLoading
          }
          onClick={handleSubmit(handleSubmitForm)}
        >
          Masuk
        </LoadingButton>
      </Form>

      <FooterHelp errorMessage={errors.code && errors.code.message} />
    </div>
  )
}

export default OtpAlternativesInputContent
