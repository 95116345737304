/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const PenyimpananData = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="penyimpanan-data">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        2. Penyimpanan Data Pemesan
      </div>
      <div className="my-2">
        Kami menyimpan Data Pribadi Pemesan hanya selama diperlukan untuk
        menyediakan layanan kepada Pemesan dan untuk tujuan bisnis yang sah dan
        penting, seperti meningkatkan kinerja layanan KendiXpress, membuat
        keputusan bisnis yang digerakkan oleh data tentang fitur dan penawaran
        baru, memenuhi kewajiban hukum kami, dan menyelesaikan perselisihan.
        Kami menyimpan sebagian Data Pribadi Pemesan selama Pemesan menjadi
        pengguna layanan KendiXpress.
      </div>
    </TabContent>
  )
})

export default PenyimpananData
