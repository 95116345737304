import PropTypes from 'prop-types'
import { memo, useState } from 'react'
import Loading from 'components/layouts/fragments/Loading'
import {
  useFetchDetailOrderConfirmPartner,
  useConfirmOrderPartner,
  useRejectOrderPartner,
} from 'modules/Order/hook'
import Card from 'pages/User/components/Card'
import RecipientDetail from 'pages/Partner/components/RecipientDetail'
import { Link, useParams } from 'react-router-dom'
import { formatDate } from 'utils/date'
import DetailOrderItem from './components/DetailOrderItem'

// Konstanta untuk status
const ORDER_STATUS = {
  REJECTED: 0,
  CONFIRMED: 1,
  NOTIFIED: 2,
  PLANNED: 3,
  ORDER_CREATED: 11,
  WAITING_PAYMENT: 12,
  UNPAID: 'U',
  PAID: 'P',
}

const LoadingScreen = memo(() => (
  <div className="w-screen h-screen flex items-center justify-center">
    <Loading />
  </div>
))

LoadingScreen.displayName = 'LoadingScreen'

const EmptyData = memo(() => (
  <div className="w-screen h-screen flex items-center justify-center">
    <h1>Maaf, Data Pesanan Tidak Ditemukan</h1>
  </div>
))

EmptyData.displayName = 'EmptyData'

const OrderRejected = memo(() => (
  <div className="w-screen h-screen flex items-center justify-center">
    <h1>Maaf, Data Pesanan Tidak Berlaku</h1>
  </div>
))

OrderRejected.displayName = 'OrderRejected'

const ErrorScreen = memo(({ message }) => (
  <div className="w-screen h-screen flex items-center justify-center">
    <div className="text-center">
      <h1 className="text-xl font-semibold text-red-500 mb-2">
        Terjadi Kesalahan
      </h1>
      <p className="text-gray-600">{message}</p>
    </div>
  </div>
))

ErrorScreen.propTypes = {
  message: PropTypes.string.isRequired,
}

ErrorScreen.displayName = 'ErrorScreen'

const FloatingFooter = memo(({ onAccept, onReject, isLoading = false }) => (
  <div>
    <div style={{ paddingBottom: '82px' }}></div>
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t p-4">
      <div className="text-center mb-4">
        <p className="text-sm text-gray-600">
          Silakan pilih untuk menerima atau menolak pesanan
        </p>
      </div>
      <div className="container mx-auto flex justify-between gap-4 p-0">
        <button
          className="flex-1 bg-white border-2 border-red-500 text-red-500 hover:bg-red-50 py-3 px-6 rounded-lg font-medium disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onReject}
          disabled={isLoading}
        >
          {isLoading ? 'Memproses...' : 'Tolak'}
        </button>
        <button
          className="flex-1 bg-primary hover:bg-primary/90 text-white py-3 px-6 rounded-lg font-medium disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onAccept}
          disabled={isLoading}
        >
          {isLoading ? 'Memproses...' : 'Terima'}
        </button>
      </div>
    </div>
  </div>
))

FloatingFooter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

FloatingFooter.displayName = 'FloatingFooter'

const OrderHeader = memo(({ orderNumber, transactionDate }) => (
  <div className="flex md:gap-2 flex-col">
    <p className="text-sm">Dipesan: {formatDate(transactionDate)}</p>
    <h3 className="text-lg lg:text-3xl font-semibold pb-4">
      Order ID: {orderNumber}
    </h3>
  </div>
))

OrderHeader.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  transactionDate: PropTypes.number.isRequired,
}

OrderHeader.displayName = 'OrderHeader'

const PartnerOrderConfirm = () => {
  const params = useParams()
  const detail = useFetchDetailOrderConfirmPartner(params.id)
  const confirmOrder = useConfirmOrderPartner()
  const rejectOrder = useRejectOrderPartner()
  const [isLoading, setIsLoading] = useState(false)
  const data = detail?.data?.result?.data

  if (data?.order && !data?.order?.partner_name) {
    data.order.partner_name = data.partner_name
  }

  if (detail.isLoading) {
    return <LoadingScreen />
  }

  if (detail.isError) {
    return (
      <ErrorScreen
        message={detail.error?.message || 'Terjadi kesalahan saat memuat data'}
      />
    )
  }

  if (!data) {
    return <EmptyData />
  }

  if (data?.status === ORDER_STATUS.REJECTED) {
    return <OrderRejected />
  }

  const handleAccept = async () => {
    try {
      setIsLoading(true)
      await confirmOrder.mutateAsync(data?.secure_id)
      alert('Pesanan berhasil dikonfirmasi')
      window.location.reload()
    } catch (error) {
      alert(error?.message || 'Terjadi kesalahan saat mengkonfirmasi pesanan')
    } finally {
      setIsLoading(false)
    }
  }

  const handleReject = async () => {
    try {
      setIsLoading(true)
      await rejectOrder.mutateAsync(data?.secure_id)
      alert('Pesanan berhasil ditolak')
      window.location.reload()
    } catch (error) {
      alert(error?.message || 'Terjadi kesalahan saat menolak pesanan')
    } finally {
      setIsLoading(false)
    }
  }

  const showInvoiceButton =
    [ORDER_STATUS.UNPAID, ORDER_STATUS.PAID].includes(
      data?.order?.invoice_status
    ) &&
    data?.order?.invoice_url &&
    data?.order?.invoice_url !== ''

  const showFloatingFooterButton = [
    ORDER_STATUS.NOTIFIED,
    ORDER_STATUS.PLANNED,
  ].includes(data?.status)
  console.log(showFloatingFooterButton)

  const showPaymentWaitingMessage = [
    ORDER_STATUS.ORDER_CREATED,
    ORDER_STATUS.WAITING_PAYMENT,
  ].includes(data?.order?.status)

  return (
    <div className="container pb-16">
      <div className="lg:container px-4">
        <div className="w-full p-0 pt-5 pb-2 bg-white flex justify-center items-center">
          <div className="mx-auto flex">
            <div className="">
              <Link to="/">
                <img
                  src="/images/kendixpress.svg"
                  className="w-44 cursor-pointer"
                  alt="Logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h1 className="text-center text-xl font-semibold mb-4 md:mb-6">
          Konfirmasi Pesanan
        </h1>
      </div>

      <Card
        className={`md:py-10 flex flex-col md:flex-row gap-3 justify-between md:px-12`}
      >
        <OrderHeader
          orderNumber={data?.order?.order_number}
          transactionDate={data?.order?.transaction_date}
        />
        {showInvoiceButton && (
          <div className="flex items-center">
            <a
              href={data?.order?.invoice_url}
              target="_blank"
              rel="noreferrer"
              className="text-primary items-center flex gap-1 text-sm md:text-lg font-semibold"
            ></a>
          </div>
        )}
      </Card>
      <div className="border-b md:hidden"></div>
      <DetailOrderItem data={data?.order} />
      <RecipientDetail data={data?.order} />
      <br />

      {showPaymentWaitingMessage && (
        <div className="text-sm text-gray-600">
          Pesanan ini sedang dalam menunggu Pembayaran Pelanggan, Setelah
          Pembayaran dilakukan oleh Pelanggan, anda akan mendapatkan informasi
          kembali H-1 hari jadwal pelayanan.
        </div>
      )}

      {showFloatingFooterButton && (
        <FloatingFooter
          onAccept={handleAccept}
          onReject={handleReject}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default memo(PartnerOrderConfirm)
