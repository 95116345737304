import React from 'react'

function Button({
  variant = 'primary',
  children,
  className = '',
  isRounded = true,
  ...rest
}) {
  const checkTypeButton = () => {
    let classList = ''

    if (variant == 'primary') {
      classList =
        'bg-primary border-2 border-primary text-white w-32 hover:bg-red-800 hover:border-red-800'
    }
    if (variant == 'white') {
      classList =
        'bg-white border-2 border-primary text-primary w-32 hover:bg-primary hover:text-white'
    }
    return classList
  }

  return (
    <button
      className={`px-2 py-[6px] ${className} ${checkTypeButton()} text-sm ${
        isRounded && 'rounded-full'
      }  font-semibold`}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
