import React from 'react'

import RecommendItem from './RecommendItem'

const MobileMenuRecommend = ({ menuRecommend }) => {
  return (
    <div className="flex flex-col md:hidden gap-2">
      {menuRecommend.data.result.map((item, index) => (
        <React.Fragment key={index}>
          {item.menus.map((menu, index) => (
            <RecommendItem
              key={index}
              id={item.partner_id}
              mitra={item.name}
              name={menu.name}
              img={menu.photo}
              cost={menu.shipping_cost_to}
              type={item.partner_type_id}
              minOrder={menu.min_order}
              unit={menu.qty_unit_name}
              orderInfo={item.info_min_order}
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

export default MobileMenuRecommend
