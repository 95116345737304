import React, { useEffect, useState } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { currency } from 'utils/formatNumber'

const MenuItem = (props) => {
  const {
    menu,
    date1,
    cart,
    addProduct,
    updateQuantity,
    time,
    removeProduct,
    popup,
  } = props
  const [index, setIndex] = useState(-1)
  const [item, setItem] = useState(-1)
  const [editQty, setEditQty] = useState(false)

  useEffect(() => {
    const dateItemIndex = cart?.findIndex(
      (item) => item?.date === date1 && item?.shipping_time === time
    )
    setIndex(dateItemIndex)
    if (dateItemIndex != undefined) {
      const cartItemIndex = cart[dateItemIndex]?.menus.findIndex(
        (item) => item?.name === menu?.name
      )
      setItem(cartItemIndex)
    }
  }, [addProduct, removeProduct])

  const [quantity, setQuantity] = useState(cart[index]?.menus[item]?.qty)

  const editQuantity = () => {
    console.log('edit quantity')
    setQuantity(cart[index]?.menus[item]?.qty)
    setEditQty(true)
  }

  const sendQuantity = (menu, date1, time, quantity) => {
    console.log('send quantity')
    updateQuantity(menu, date1, time, quantity)
    setEditQty(false)
  }

  return (
    <section className="flex flex-row gap-2 md:gap-6 pb-6 border-b">
      <img
        onClick={() => popup(menu)}
        src={menu?.photo}
        className="min-w-[80px] cursor-pointer md:min-w-[120px] h-20 md:h-[120px] rounded-xl"
      />
      <div className="flex flex-col w-full md:gap-1">
        <h4
          onClick={() => popup(menu)}
          className="font-medium md:font-semibold cursor-pointer flex-wrap text-sm md:text-base line-clamp-1"
        >
          {menu?.name}
        </h4>
        <div className="flex md:gap-12 justify-between">
          <div className="flex justify-between h-[60px] md:h-24 gap-2 md:gap-4 flex-col">
            <p
              onClick={() => popup(menu)}
              className="text-xs cursor-pointer md:text-sm w-full flex-wrap flex line-clamp-2 md:line-clamp-3 opacity-80 -z-1"
            >
              {menu?.content.replaceAll('\n', ', ')}
            </p>
            <div className="flex md:items-center items-end gap-2 md:gap-3">
              <p className="text-xs flex gap-0 md:gap-1 items-center md:text-sm font-semibold text-primary">
                {currency(menu?.price)}{' '}
                <span className="text-xs flex gap-0 md:gap-1 text-black md:text-xs font-normal">
                  <span className="hidden md:block">per</span>
                  <span className="md:hidden block">/</span>
                  {menu.qty_unit_name}
                </span>
              </p>
              {menu?.min_order !== 0 && (
                <p className="text-xs md:text-xs font-normal text-gray-400">
                  (min {menu?.min_order})
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-end">
            {item >= 0 && (
              <p className="text-[10px] hidden md:block text-center leading-loose">
                Jumlah Pesanan
              </p>
            )}

            {(item === -1 || item === undefined) && (
              <button
                onClick={() => addProduct(menu, date1, time)}
                className="bg-primary hover:opacity-90 w-20 md:w-40 h-6 md:h-9 text-white text-[10px] md:text-xs font-semibold rounded-full"
              >
                Pilih
              </button>
            )}

            {item >= 0 && (
              <div className="border-0 md:border-2 items-center px-0 md:px-1 gap-1 flex justify-between rounded-full w-20 md:w-40 h-6 md:h-9">
                <button
                  onClick={() => removeProduct(menu, date1, time)}
                  className="rounded-full border-2 hover:bg-primary hover:text-white text-primary border-primary"
                >
                  <BiMinus className="w-4" />
                </button>
                {editQty ? (
                  <input
                    autoFocus
                    value={quantity}
                    type="number"
                    onChange={(e) => setQuantity(e.target.value)}
                    onKeyPress={(e) =>
                      e.key === 'Enter' &&
                      sendQuantity(menu, date1, time, quantity)
                    }
                    onBlur={() => sendQuantity(menu, date1, time, quantity)}
                    className="w-full text-center text-[10px] md:text-xs font-semibold outline-none"
                  />
                ) : (
                  <div
                    onClick={() => editQuantity()}
                    className="text-[10px] text-center h-full justify-center grid w-3/4 items-center md:text-xs font-semibold"
                  >
                    {cart[index]?.menus[item]?.qty}
                  </div>
                )}
                <button
                  onClick={() => addProduct(menu, date1, time)}
                  className="rounded-full border-2 hover:bg-primary hover:text-white text-primary border-primary"
                >
                  <BiPlus className="w-4" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default MenuItem
