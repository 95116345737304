import React from 'react'

function SectionTitle({
  title,
  subtitle,
  classTitle = '',
  classSubtitle = '',
}) {
  return (
    <div className="w-full">
      <h3
        className={`text-black text-center text-sm md:text-xl mb-1 md:mb-4 ${classTitle}`}
      >
        {title}
      </h3>
      <h1
        className={`text-primary font-merienda text-center text-xl md:text-4xl font-semibold tracking-[-0.04em] ${classSubtitle}`}
      >
        {subtitle}
      </h1>
    </div>
  )
}

export default SectionTitle
