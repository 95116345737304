import React from 'react'

import Footer from './fragments/Footer'
import NavAddress from './fragments/Navbar/NavAddress'

const MitraLayout = ({ children }) => {
  return (
    <React.Fragment>
      <NavAddress />
      {children}
      <Footer />
    </React.Fragment>
  )
}

export default MitraLayout
