import WhatsappButton from 'components/WhatsappButton'
import React from 'react'

import BannerInfo from './fragments/BannerInfo'
import Footer from './fragments/Footer'
import NavbarInfo from './fragments/Navbar/NavbarInfo'
import Order from './fragments/Order'

function InfoLayout({ children, withContainer = true, isFaq = true }) {
  return (
    <>
      <NavbarInfo />
      <BannerInfo isFaq={isFaq} />
      <div className="bg-[#FAFAFA]">
        {withContainer && <div className="container">{children}</div>}
        {!withContainer && <>{children}</>}
      </div>
      <Order layout="info" />
      <Footer />
      <WhatsappButton />
    </>
  )
}

export default InfoLayout
