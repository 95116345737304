/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Kerahasiaan = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="kerahasiaan" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        K. Kerahasiaan Dan Hak Kekayaan Intelektual
      </div>
      <List>
        <ListItem>
          Para Pihak dengan ini setuju dan menyatakan bahwa atas semua data,
          informasi, dokumen atau data apapun yang berhubungan dengan
          KendiXpress dan dipertukarkan baik dalam bentuk lisan, tercetak,
          tertulis, grafik, magnetik, atau fotografik atau dalam bentuk apapun
          dari Pihak yang mengungkapkan kepada Pihak lainnya sehubungan dengan
          Kerja Sama ini maupun pelaksanaannya, termasuk data Pemesan, data
          Vendor RAM serta KendiXPress, atau aplikasi apapun milik RAM merupakan
          data dan/atau dokumen yang bersifat rahasia, baik selama Kerja Sama
          berlangsung maupun setelah Kerja Sama berakhir (”
          <strong>Informasi Rahasia</strong>”).
        </ListItem>
        <ListItem>
          Setiap Informasi Rahasia akan tetap menjadi milik Pihak yang
          mengungkapkan Informasi Rahasia dan pengungkapan Informasi Rahasia
          tersebut tidak memberikan hak apapun kepada Pihak yang menerima
          Informasi Rahasia (termasuk hak atas kekayaan intelektual) atas
          Informasi Rahasia apapun selain hak-hak yang terdapat dalam Kerja Sama
          ini.
        </ListItem>
        <ListItem>
          Pihak yang melakukan pelanggaran terhadap ketentuan pada Pasal ini
          akan dikenakan sanksi sesuai dengan hukum dan peraturan
          perundang-undangan tentang Hak atas Kekayaan Intelektual yang berlaku
          di Negara Republik Indonesia.
        </ListItem>
      </List>
    </TabContent>
  )
})

export default Kerahasiaan
