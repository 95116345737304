/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup'
import { Autocomplete, GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import Button from 'components/button/Index'
import LoadingButton from 'components/button/LoadingButton'
import Form from 'components/forms/Form'
import Input from 'components/forms/Input'
import Modal from 'components/modal/Index'
import LocationIcon from 'images/location.svg'
import { useGoogleMapGeocode } from 'modules/custom/useGoogleMapGeocode'
import React, { useCallback, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { checkIsMobile } from 'utils/checkIsMobile'
import * as yup from 'yup'

function ModalMaps({
  showModal,
  setShowModal,
  setLatLong,
  setPlace,
  place,
  setURL,
  setRegion,
  isPartnerExist,
  setIsPartnerExist,
  handleSubmitForm,
  isLoadingSave,
  hideModal,
}) {
  const autoCompleteRef = useRef()

  const [libraries, setLibraries] = useState(['places'])
  const [map, setMap] = useState(null)
  const [autoComplete, setAutoComplete] = useState(null)
  const [innerHeight, setinnerHeight] = useState(window.innerHeight)
  const [loadingPlace, setLoadingPlace] = useState(false)

  const dataAddress = JSON.parse(localStorage.getItem('dataAddress'))

  const schema = yup.object().shape({
    detailLocation: yup.string().required('Wajib diisi'),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      detailLocation: '',
    },
  })

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY_GMAPS,
    libraries: libraries,
  })

  const { getAddress } = useGoogleMapGeocode()

  const center = {
    lat: -6.225697155538032,
    lng: 106.80657330102655,
  }

  const onLoad = useCallback((map) => {
    if (dataAddress) {
      setValue('detailLocation', dataAddress.detailLocation)
    }
    const elementMap = document.getElementById('set-address-map')
    if (!document.querySelector('.centerMarker')) {
      const div = document.createElement('div')
      div.classList.add('centerMarker')
      elementMap.appendChild(div)
    }
    setLoadingPlace(true)
    getAddress(
      `${dataAddress ? dataAddress.lat : center.lat},${
        dataAddress ? dataAddress.lng : center.lng
      }`
    ).then((res) => {
      // console.log(res)
      if (dataAddress && dataAddress.formattedAddress) {
        setPlace && setPlace(dataAddress.formattedAddress)
      } else {
        setPlace && setPlace(res.formatted_address)
      }
      setLoadingPlace(false)
    })
    const bounds = new window.google.maps.LatLngBounds(
      dataAddress ? { lat: dataAddress.lat, lng: dataAddress.lng } : center
    )
    map.fitBounds(bounds)
    setMap(map)
    setLatLong &&
      setLatLong(
        dataAddress ? { lat: dataAddress.lat, lng: dataAddress.lng } : center
      )
  }, [])

  const onUnmount = useCallback((map) => {
    setMap(null)
    setLatLong && setLatLong(null)
    setPlace && setPlace(null)
    setIsPartnerExist && setIsPartnerExist(true)
    setAutoComplete(null)
    setValue('detailLocation', '')
    hideModal && hideModal()
  }, [])

  const onLoadAutoComplete = useCallback((autocomplete) => {
    setAutoComplete(autocomplete)
  })

  const onPlaceChange = useCallback(() => {
    if (autoComplete) {
      const place = autoComplete.getPlace()
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      setLatLong && setLatLong(location)
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport)
      } else {
        map.setCenter(place.geometry.location)
        map.setZoom(15)
      }
      setLoadingPlace(true)
      setValue('detailLocation', '')
      setPlace && setPlace(place.formatted_address)
      setURL(place.url)
      place.address_components.map((data) => {
        if (data.types[0] === 'administrative_area_level_2') {
          setRegion(data.long_name)
        }
      })
      setTimeout(() => {
        setLoadingPlace(false)
      }, 1000)
      setIsPartnerExist && setIsPartnerExist(true)
    }
  })

  const getLatLongCenter = useCallback(() => {
    const mapElem = document.querySelector('#set-address-map div')
    mapElem.classList.remove('opacity-20')
    if (map) {
      const tmpObj = {
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      }
      setLatLong && setLatLong(tmpObj)
      setLoadingPlace(true)
      getAddress(`${tmpObj.lat},${tmpObj.lng}`).then((res) => {
        const request = {
          placeId: res.place_id,
          fields: ['address_component', 'geometry', 'url'],
        }

        // eslint-disable-next-line no-undef
        const service = new google.maps.places.PlacesService(map)

        service.getDetails(request, (place) => {
          setURL(place.url)
          place.address_components.map((data) => {
            if (data.types[0] === 'administrative_area_level_2') {
              setRegion(data.long_name)
            }
          })
        })

        setPlace && setPlace(res.formatted_address)
        setValue('detailLocation', '')
        setLoadingPlace(false)
        autoCompleteRef.current.value = res.formatted_address
        setIsPartnerExist && setIsPartnerExist(true)
      })
    }
  })

  const blurMapOnDrag = useCallback(() => {
    const mapElem = document.querySelector('#set-address-map div')
    mapElem.classList.add('opacity-20')
  })

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      wrapperClass={``}
      modalClass={`sm:w-[576px]`}
      modalBodyClass={``}
      title={
        <>
          <h2 className="text-lg font-semibold block md:hidden">
            Mau dikirim kemana?
          </h2>
        </>
      }
    >
      <div
        className="w-full h-full md:h-auto md:!min-h-[auto] flex flex-col"
        style={{ minHeight: `calc(${innerHeight}px - 68px)` }}
      >
        <div className="mb-8 hidden md:block">
          <h2 className="text-2xl font-semibold text-black">
            Mau dikirim kemana?
          </h2>
          <span className="text-sm text-black">
            Sesuaikan pin lokasi dengan lokasi pengirimanmu
          </span>
        </div>
        <div>
          {isLoaded && (
            <GoogleMap
              id="set-address-map"
              mapContainerClassName="w-full md:w-full h-[334px] rounded-t-[20px] relative"
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onDragStart={blurMapOnDrag}
              onDragEnd={getLatLongCenter}
              options={{
                fullscreenControl: false,
                disableDefaultUI: true,
                maxZoom: 15,
                minZoom: 15,
                gestureHandling: 'greedy',
              }}
              clickableIcons={false}
            >
              <Autocomplete
                onLoad={onLoadAutoComplete}
                onPlaceChanged={onPlaceChange}
                className="absolute w-[stretch] top-0 left-0 mx-4 mt-4"
              >
                <div className={`w-full`}>
                  <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-white sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-5 md:pl-5 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-[#9D9D9D] z-[1]"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="default-search"
                      className="block shadow-search-homepage p-3 pl-16 pr-14 w-full text-sm text-black bg-white rounded-full border isolate border-white focus:outline-none google-maps-input"
                      placeholder={
                        checkIsMobile()
                          ? 'Cari lokasimu'
                          : 'Cari Nama Jalan, Perumahan atau Gedung'
                      }
                      ref={autoCompleteRef}
                    />
                    <div
                      className="absolute inset-y-0 right-0 items-center pr-5 md:pr-5 z-50 cursor-pointer"
                      onClick={() => {
                        autoCompleteRef.current.value = ''
                      }}
                    >
                      <MdClose className="w-6 h-6 text-[#9D9D9D]" />
                    </div>
                  </div>
                </div>
              </Autocomplete>
            </GoogleMap>
          )}
        </div>
        {isPartnerExist && (
          <div className="px-4 py-5 border-b border-l border-r border-[#EFEFEF] md:rounded-b-[20px] flex items-baseline flex-wrap">
            {loadingPlace && (
              <div role="status" className="space-y-2.5 animate-pulse w-full">
                <div className="flex items-center space-x-2 w-full">
                  <div className="h-2.5 bg-gray-200 rounded-full w-32"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
                </div>
                <div className="flex items-center w-full space-x-2 max-w-[480px]">
                  <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
                </div>
                <div className="flex items-center w-full space-x-2 max-w-[400px]">
                  <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full w-80"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
                </div>
                <div className="flex items-center w-full space-x-2 max-w-[480px]">
                  <div className="h-2.5 bg-gray-200 rounded-full w-full"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full w-full"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full w-24"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            )}
            {!loadingPlace && (
              <>
                <div className="w-2/5 md:w-1/5 text-sm font-semibold">
                  Dikirim ke
                </div>
                <div className="w-full md:w-4/5 text-sm">{place || '-'}</div>
              </>
            )}
          </div>
        )}
        {!isPartnerExist && (
          <>
            <div className="bg-[#fff4f4] px-4 py-2 border-b border-l border-r border-[#EFEFEF] flex items-center text-primary">
              <div className="w-full text-xs">
                <span className="font-semibold mr-2">Mohon Maaf</span>Lokasimu
                diluar jangkauan Pengiriman Kami
              </div>
            </div>
            <div className="px-4 py-[15px] border-b border-l border-r border-[#EFEFEF] md:rounded-b-[20px]">
              <div className="w-full text-xs flex items-center">
                <div className="text-black">
                  Kamu ingin pesan menu untuk lokasi ini?
                </div>
                <a
                  href="https://wa.me/6281119279900?text=Saya%20ingin%20pesan%20menu%20untuk%20lokasi%20di"
                  target="_blank"
                  className="ml-3 px-2 py-[6px] rounded-full text-center bg-primary border-2 border-primary text-white w-32 hover:bg-red-800 hover:border-red-800 text-xs"
                  rel="noreferrer"
                >
                  Hubungi Kami
                </a>
              </div>
            </div>
          </>
        )}
        <Form
          className="mt-9 mb-9 md:mb-0"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <div className="mx-3 md:mx-0 flex">
            <div className="w-1/12">
              <img src={LocationIcon} className="w-6 h-6 mx-auto" />
            </div>
            <div className="w-11/12">
              <Controller
                name="detailLocation"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    label="Detail Lokasi (Cth: Patokan)"
                    name="detailLocation"
                    onKeyUp={(event) => {
                      if (event.keyCode === 13) {
                        handleSubmit(handleSubmitForm)(event)
                      }
                    }}
                    error={
                      errors.detailLocation && errors.detailLocation.message
                    }
                  />
                )}
              ></Controller>
            </div>
          </div>
        </Form>
        <div className="mx-3 md:mx-0 mt-auto md:mt-9 mb-3 md:mb-0 flex justify-end relative w-[stretch] md:w-auto">
          <Button
            className="mr-3 w-1/2 md:w-[128px]"
            variant="white"
            isRounded={true}
            onClick={() => setShowModal(false)}
          >
            Batal
          </Button>
          <LoadingButton
            className="w-1/2 md:w-[128px]"
            variant="primary"
            isRounded={true}
            isLoading={isLoadingSave}
            onClick={handleSubmit(handleSubmitForm)}
          >
            Simpan
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export default ModalMaps
