import React from 'react'

const MenuWrapper = ({
  menuTitle,
  id,
  description,
  children,
  isPartner,
  menu,
}) => {
  return (
    <section id={id}>
      <div className="flex flex-col pt-6 gap-3 md:gap-6 px-4 md:px-0">
        {menu?.data?.length > 0 && (
          <div className="flex flex-col gap-1 md:gap-2">
            <h3 className="font-semibold text-sm md:text-xl">{menuTitle}</h3>
            {description && isPartner.partner_type_id === 1 && (
              <p className="text-xs md:text-sm text-gray-400">{description}</p>
            )}
          </div>
        )}
        {children}
      </div>
    </section>
  )
}

export default MenuWrapper
