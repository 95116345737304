import Card from 'pages/User/components/Card'
import InfoWrapper from 'pages/User/components/fragments/InfoWrapper'
import React from 'react'
import { currency } from 'utils/formatNumber'
import CartList from './CartList'

// import Card from './Card'
// import CartList from './fragments/CartList'
// import InfoWrapper from './fragments/InfoWrapper'

const DetailOrderItem = ({ data }) => {
  // CONVERT TANGGAL KE GMT
  const handleDateGMT = (date) => {
    let dateConvert = new Date(date * 1000)
    dateConvert.setHours(dateConvert.getHours() + 7)
    return Date.parse(dateConvert)
  }
  return (
    <Card className={`md:py-10 flex flex-col md:px-12`}>
      <h3 className="text-xl font-semibold mt-4 md:mb-6">Detail Pesanan</h3>
      <InfoWrapper border title="Nama Mitra" value={data?.partner_name} />
      <div className="">
        {data.order_detail.map((item, key) => {
          return (
            <CartList key={key} data={item} date={handleDateGMT(item.date)} />
          )
        })}
        {data?.payment_unique_code !== 0 && (
          <div className="flex justify-between py-2 md:py-4 border-b md:pr-2 text-sm md:text-base font-semibold">
            <h5>Kode Pembayaran</h5>
            <p>{currency(data?.payment_unique_code)}</p>
          </div>
        )}
        {data?.pph !== 0 && (
          <div className="flex justify-between py-2 md:py-4 border-b md:pr-2 text-sm md:text-base text-primary font-semibold">
            <h5>Pajak Penghasilan</h5>
            <p>{currency(data?.pph)}</p>
          </div>
        )}
        <div className="flex justify-end py-4 md:py-6 border-b md:border-b-0 md:pr-2 text-sm md:text-base font-semibold">
          <h5 className="mr-4">Total Pembelian</h5>
          <p>{currency(data?.grand_total)}</p>
        </div>
      </div>
    </Card>
  )
}

export default DetailOrderItem
