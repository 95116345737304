/* eslint-disable no-unused-vars */
import NavAddress from 'components/layouts/fragments/Navbar/NavAddress'
import Tooltip from 'components/layouts/fragments/Tooltip'
import AuthModal from 'components/modal/Auth'
import orderIcon from 'images/menu/min-order.svg'
import shiping from 'images/menu/shipping.svg'
import { useCartSystem } from 'modules/custom/useCartSystem'
import { useFirebaseHook } from 'modules/custom/useFirebaseHook'
import { useOrder } from 'modules/custom/useOrder'
import { useFetchDetailMitra } from 'modules/Mitra/hooks'
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { dateGMT, MONTH_NAMES } from 'utils/date'
import { currency } from 'utils/formatNumber'

import DateTimePicker from './components/DateTimePicker'
import DateCart from './components/fragments/DateCart'
import EmptyCart from './components/fragments/EmptyCart'
import MenuCart from './components/fragments/MenuCart'

const CartMobile = () => {
  const params = useParams()
  const {
    cart,
    partner,
    grandTotal,
    setDateParams,
    setDatePick,
    time,
    setTime,
    addProduct,
    updateQuantity,
    removeByDate,
    removeProduct,
    changeDateCart,
    changeTimeCart,
    datePick,
  } = useCartSystem()
  const [location] = useState(
    JSON.parse(localStorage.getItem('dataAddress')) || ''
  )

  const [paramsFrom, setParamsFrom] = useSearchParams()
  const dataparam = paramsFrom.get('ref')
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  const { handlePayment } = useCartSystem()
  const { differentDate } = useOrder()
  const { setClickPayment } = useFirebaseHook()
  const [showModalAuth, setShowModalAuth] = useState(false)
  const detailMitra = useFetchDetailMitra(location.lat, location.lng, params.id)

  const [alertMenuUtama, setAlertMenuUtama] = useState(false)
  const [alertMinOrder, setAlertMinOrder] = useState(false)
  const [alertMenuNotAvail, setAlertMenuNotAvail] = useState(false)

  const [lateOrder, setLateOrder] = useState(false)
  const [dateLate, setDateLate] = useState('')
  const [checkMenuAndalan, setCheckMenuAndalan] = useState(false)

  const minOrder = partner.info_min_order
  const findMenu = cart.flatMap((menu) => menu.menus)

  const cekData = cart.map((data) => data.menus)

  const checkNotAvail = findMenu.findIndex((x) => x.available == false)
  const [dateMinimum, setDateMinimum] = useState(Date.now())
  const dateDay = new Date(dateMinimum).getDate()
  const dateMonth = new Date(dateMinimum).getMonth()
  const [minimumPrice, setMinimumPrice] = useState(0)

  const order = parseInt(
    minOrder?.replaceAll('Min order per pengiriman Rp ', '').replaceAll('.', '')
  )
  const checkTotalMinimum = cart.filter((data) => {
    if (data.sub_total < order) {
      return data
    }
  })

  const handleCheckSubmit = () => {
    if (partner.partner_type_id === 2) {
      if (checkTotalMinimum.length == 0) {
        handlePayment(cart)
        if (!token) {
          setClickPayment(true)
          localStorage.setItem('click', true)
          setShowModalAuth(true)
        } else {
          dataparam == 'confirm'
            ? navigate('/payment-confirmation')
            : navigate(`/payment-info`)
        }
      } else {
        setMinimumPrice(parseInt(order - checkTotalMinimum[0].sub_total))
        setDateMinimum(checkTotalMinimum[0].date)
        setAlertMenuNotAvail(false)
        setAlertMinOrder(true)
      }
    } else {
      if (checkMenuAndalan) {
        setAlertMenuNotAvail(false)
        setAlertMenuUtama(true)
      } else {
        handlePayment(cart)
        if (!token) {
          setClickPayment(true)
          localStorage.setItem('click', true)
          setShowModalAuth(true)
        } else {
          dataparam == 'confirm'
            ? navigate('/payment-confirmation')
            : navigate(`/payment-info`)
        }
      }
    }
  }

  // add menu in same time & date
  const addMenu = (date, time) => {
    setDatePick(date)
    sessionStorage.setItem('dateparams', JSON.stringify(dateGMT(date)))
    setDateParams(dateGMT(date))
    setTime(time)
    sessionStorage.setItem('date', JSON.stringify(date))
    sessionStorage.setItem('time', JSON.stringify(time))
    navigate(`/detail-mitra/${params.id}`)
  }

  const [isOpen, setIsOpen] = useState(false)
  const [quantity, setQuantity] = useState(0)

  const dateIndex = new Date(datePick)
  let maxDate = new Date()
  maxDate.setMonth(maxDate.getMonth() + 3)
  let minDate = new Date()

  useEffect(() => {
    if (new Date().getHours() >= 14 && new Date().getMinutes() >= 1) {
      minDate.setDate(minDate.getDate() + 1)
    }
  }, [])

  const handleSave = () => {
    sessionStorage.setItem('time', JSON.stringify(time))
    sessionStorage.setItem('date', JSON.stringify(datePick))

    sessionStorage.setItem('dateparams', JSON.stringify(dateGMT(dateIndex)))
    setDateParams(dateGMT(dateIndex))
    setIsOpen(false)
    navigate(`/detail-mitra/${params.id}`)
  }

  useEffect(() => {
    let dataTmp = []
    for (let a = 0; a < cekData.length; a++) {
      const cek = cekData[a].findIndex(
        (x) => (x.is_main_menu == true && x.type == 2) || x.type == 1
      )
      if (cek == -1) {
        dataTmp.push({ data: true })
      }
    }
    const cekDataTmp = dataTmp.findIndex((a) => a.data == true)
    if (cekDataTmp >= 0) {
      setCheckMenuAndalan(true)
    } else {
      setCheckMenuAndalan(false)
      setAlertMenuUtama(false)
    }

    const dataLate = cart.find((data) => {
      if (new Date().getHours() >= 14 && new Date().getMinutes() >= 1) {
        if (differentDate(data.date) < 2) return data
      } else {
        if (differentDate(data.date) < 1) return data
      }
    })

    if (dataLate) {
      setLateOrder(true)
      setDateLate(dataLate)
    } else {
      setLateOrder(false)
    }

    if (cart.length > 0) {
      setMinimumPrice(parseInt(order - checkTotalMinimum[0]?.sub_total))
    }
    setTimeout(() => {
      checkNotAvail >= 0 && setAlertMenuNotAvail(true)
    }, 500)
    checkTotalMinimum.length == 0 && setAlertMinOrder(false)
  }, [cart, checkNotAvail, checkTotalMinimum])

  return (
    <React.Fragment>
      <NavAddress>
        <div className=" -ml-12 md:hidden flex items-center gap-1">
          <h3 className="text-lg lg:text-xl font-semibold">
            Keranjang Pesanan
          </h3>
          <Tooltip>
            Kamu bisa pesan menu makan
            <br />
            lebih dari satu tanggal dan waktu
            <br /> dalam satu keranjang pesanan
          </Tooltip>
        </div>
      </NavAddress>
      <hr />
      {cart.length > 0 && (
        <div className="pb-32 ">
          <div className="p-4 max-w-sm mx-auto">
            {cart.map((data, key) => {
              // free ongkir hidden if any one is true
              let fulfilled = false
              const filterFreeOngkir = data.menus.filter(
                (menu) =>
                  menu.qty >= menu.free_shipping_cost &&
                  menu.free_shipping_cost !== 0
              )
              const flat = filterFreeOngkir.flatMap((data) => data)
              if (flat.length > 0) {
                fulfilled = true
              } else {
                fulfilled = false
              }
              return (
                <section key={key}>
                  <DateCart
                    cart={cart}
                    date={data.date}
                    time={data.shipping_time}
                    shipping={data.shipping_cost}
                    removeByDate={removeByDate}
                    changeDateCart={changeDateCart}
                    changeTimeCart={changeTimeCart}
                  />
                  {data.menus.map((menu, key) => (
                    <MenuCart
                      key={key}
                      name={menu.name}
                      qty={menu.qty}
                      available={menu.available}
                      price={menu.price}
                      unit={menu.qty_unit_name}
                      min_free={menu.free_shipping_cost}
                      disabled_up={menu.disable_up}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      maxOrder={menu.qty < menu.max_order ? true : false}
                      addProduct={() =>
                        addProduct(menu, partner, data.date, data.shipping_time)
                      }
                      updateQuantity={() =>
                        updateQuantity(
                          menu,
                          data.date,
                          data.shipping_time,
                          quantity
                        )
                      }
                      removeProduct={() =>
                        removeProduct(menu, data.date, data.shipping_time)
                      }
                      fulfilled={fulfilled}
                    />
                  ))}
                  {new Date(datePick).getDate() ==
                    new Date(data.date).getDate() &&
                  new Date(datePick).getMonth() ==
                    new Date(data.date).getMonth() &&
                  time == data.shipping_time ? null : (
                    <div className="border-t my-4 flex justify-between items-center w-full">
                      <button
                        onClick={() => addMenu(data.date, data.shipping_time)}
                        className="flex gap-1 items-center  py-3 text-primary font-semibold text-[10px]"
                      >
                        <AiOutlinePlus size={12} /> Tambah Menu
                      </button>
                    </div>
                  )}
                  <div className="h-3 mb-5 bg-primary opacity-5"></div>
                </section>
              )
            })}
            <button
              onClick={() => setIsOpen(true)}
              className="flex w-full border-2 py-1 justify-center text-primary rounded-full border-primary font-semibold text-[10px]"
            >
              Tambah Tanggal Pengiriman
            </button>
          </div>
          <div className="fixed bg-white flex flex-col w-full bottom-0">
            <div className="flex px-4 flex-col justify-center mb-2 gap-2 text-[11px]">
              <div className="flex justify-center gap-2">
                <img src={shiping} alt="shipping" />
                <p>Gratis Ongkir, minimal order terpenuhi</p>
              </div>
              {detailMitra?.data?.result[0]?.partner_type_id === 2 && (
                <div className="flex justify-center gap-2">
                  <img src={orderIcon} alt="order" />
                  <p>{detailMitra?.data?.result[0]?.info_min_order}</p>
                </div>
              )}
            </div>
            <div className="pt-3 px-4 flex flex-col justify-center mx-auto max-w-sm gap-1 w-full shadow-top-only bg-white">
              <button
                disabled={lateOrder}
                onClick={() => handleCheckSubmit()}
                className="py-3 w-full rounded-full z-10 disabled:bg-gray-200 disabled:text-gray-400 text-white hover:opacity-80 bg-primary text-sm font-semibold flex justify-between px-5"
              >
                <p>Pembayaran</p>
                <p>{currency(grandTotal)}</p>
              </button>
              <div className="flex justify-center h-5 mb-1 w-full">
                {alertMenuUtama && (
                  <p className="text-[11px] text-center text-primary">
                    Harap pesan menu utama, agar bisa proses selanjutnya
                  </p>
                )}
                {alertMenuNotAvail && (
                  <p className="text-[11px] text-center text-primary">
                    Terdapat menu yang tak tersedia, harap cek menu
                  </p>
                )}
                {alertMinOrder && (
                  <p className="text-[10px] text-center text-primary">
                    Minimal Pesanan {dateDay}{' '}
                    {MONTH_NAMES[dateMonth].slice(0, 3)},{' '}
                    {cart[0].shipping_time.replaceAll('.', ':')} Kurang{' '}
                    {currency(minimumPrice)}
                  </p>
                )}
                {lateOrder && (
                  <p className="text-[10px] tracking-tight text-center text-primary">
                    Pesanan {new Date(dateLate.date).getDate()}{' '}
                    {MONTH_NAMES[new Date(dateLate.date).getMonth()].slice(
                      0,
                      3
                    )}{' '}
                    {new Date(dateLate.date).getFullYear()} sudah tidak berlaku,
                    harap ganti tanggal
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {cart.length === 0 && (
        <div className="flex h-full items-center justify-center">
          <EmptyCart />
        </div>
      )}

      {/* Popup Auth */}
      <AuthModal showModal={showModalAuth} setShowModal={setShowModalAuth} />

      {/* Popup DatePicker */}
      <DateTimePicker
        show={isOpen}
        setShow={setIsOpen}
        dateTmp={dateIndex}
        minDate={minDate}
        maxDate={maxDate}
        setDateTmp={setDatePick}
        time={time}
        setTime={setTime}
        setSave={handleSave}
      />
    </React.Fragment>
  )
}

export default CartMobile
