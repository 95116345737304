import { verifyWhatsappCustomer } from 'modules/Microsite/api'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Loading from './layouts/fragments/Loading'
import TokenExpiredModal from './modal/TokenExpired'

const WhatsappRoute = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [tokenExpired, setTokenExpired] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [searchParams] = useSearchParams()
  const [title, setTitle] = useState('')
  useEffect(() => {
    const uuid = searchParams.get('secure_id')
    const token = searchParams.get('token')

    if (uuid || token) {
      const fetchData = async () => {
        try {
          let result
          if (uuid) {
            result = await verifyWhatsappCustomer({ secure_id: uuid })
          } else {
            result = await verifyWhatsappCustomer({ token: token })
          }

          if (
            result.result &&
            result.status.message_client === 'Success' &&
            result.result.data
          ) {
            localStorage.setItem('token', result.result.data.token)
            localStorage.setItem(
              'data-user',
              JSON.stringify({
                delivery_address: result.result.data.address,
                lat: result.result.data.lat,
                long: result.result.data.long,
                location_pin: result.result.data.location_pin,
                region_name: result.result.data.region_name,
              })
            )
            localStorage.setItem(
              'wa-customer',
              JSON.stringify({
                name: result.result.data.name,
                phone: result.result.data.phone,
              })
            )
          } else if (result.status.status_code !== 20) {
            result.status.message_client === 'Token is Expired'
              ? setTitle('Sesi Anda telah berakhir')
              : setTitle('Token Anda tidak valid')
            setTokenExpired(true)
            setShowModal(true)
          }
        } catch (error) {
          setTokenExpired(true)
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    } else {
      setLoading(false)
    }
  }, [searchParams])
  if (loading) {
    return <Loading />
  }
  if (tokenExpired) {
    return (
      <TokenExpiredModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
      />
    )
  }

  return children
}

export default WhatsappRoute
