import LogoMenu from 'images/menu/menu-utama.svg'
import React from 'react'
import { currency } from 'utils/formatNumber'

const MenuItem = ({ photo, title, price, unit, type, minOrder, click }) => {
  return (
    <section
      onClick={click}
      className="flex md:last:flex cursor-pointer items-center flex-row-reverse md:flex-row w-full gap-3 rounded-xl shadow-none md:shadow-menu-item"
    >
      <img
        src={photo === '' ? LogoMenu : photo}
        alt={title}
        height="100%"
        className=" md:w-[140px] md:h-[140px] w-20 h-20 object-cover rounded-xl"
        loading="lazy"
      />
      <div className="grid grid-cols-1 pr-1 md:h-full w-full  justify-around py-2 md:py-4">
        <h4 className="w-full text-base flex items-center">{title}</h4>
        <div className="flex md:justify-between gap-2 pr-4">
          <p className="text-sm flex font-semibold text-primary items-end">
            {currency(Number(price))}{' '}
            <span className="text-[10px] font-normal flex items-end text-black ml-1">
              per {unit}
            </span>
          </p>
          {type == 1 && (
            <p className="text-[10px] flex text-gray-400 py-[3px] items-end ">
              (min {minOrder})
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

export default MenuItem
