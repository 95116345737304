import LogoutEmoticonIcon from 'images/logout_emoticon.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from './Index'

function Logout({ showLogout, setShowLogout }) {
  const navigate = useNavigate()

  const userLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('cart')
    localStorage.removeItem('partner')
    localStorage.removeItem('data-user')
    localStorage.removeItem('ongkir')
    localStorage.removeItem('price')
    sessionStorage.clear()
    navigate('/')
  }

  return (
    <Modal
      showModal={showLogout}
      setShowModal={setShowLogout}
      isModalResponsiveFullPage={false}
      isModalResponsiveRounded={true}
      modalClass={`sm:!w-[380px]`}
      modalBodyClass={`p-[32px] pt-0 sm:!p-[40px] sm:!pt-[40px]`}
    >
      <div className="flex flex-col justify-center items-center">
        {
          <img
            src={LogoutEmoticonIcon}
            className="w-[140px] h-[140px] md:w-[180px] md:h-[180px]"
          />
        }
        <div className="text-center">
          <h4 className="text-lg font-semibold text-black mb-1">
            Kamu Ingin Keluar
          </h4>
          <span className="text-sm text-black">
            Apakah bisa beri tahu Kami alasan Kamu keluar?
          </span>
        </div>
        <div className="mt-[22px] w-full">
          <button
            className="mt-[12px] py-[12px] text-sm rounded-full bg-white border-2 border-primary text-primary w-full hover:bg-primary hover:text-white font-semibold"
            onClick={() => userLogout()}
          >
            Iya, Saya Keluar
          </button>
          <div className="mt-[12px] flex w-full">
            <a
              className="py-[12px] text-sm rounded-full bg-primary border-2 border-primary text-white w-full hover:bg-red-800 hover:border-red-800 font-semibold text-center"
              href="https://api.whatsapp.com/send/?phone=6281119279900&text=Alasan+Saya+keluar+dari++website+karena&type=phone_number&app_absent=0"
              target={'_blank'}
              rel="noreferrer"
            >
              Kasih Alasan Keluar
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Logout
