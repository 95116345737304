import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { times } from 'utils/date'

const TimeChange = ({ cart, show, setShow, date, time, action }) => {
  const findDate = cart.findIndex(
    (item) => item.date === date && item.shipping_time === time
  )
  return (
    <React.Fragment>
      {show && (
        <>
          <div
            onClick={() => setShow(false)}
            className="fixed inset-0 w-screen h-screen"
          ></div>
          <div className="overflow-y-scroll no-scrollbar gap-2 bg-white z-50 rounded-xl shadow-menu-item p-2 absolute top-[50px] right-1 h-[172px] w-[90px] flex flex-col">
            {times.map((currentTime, key) => (
              <button
                key={key}
                className="text-xs font-medium gap-1 flex justify-between pl-1"
              >
                <p onClick={() => action(date, time, currentTime)}>
                  {currentTime}
                </p>
                {
                  <AiOutlineCheck
                    size={18}
                    className={`text-primary ${
                      cart[findDate].shipping_time === currentTime
                        ? 'visible'
                        : 'invisible'
                    }
             `}
                  />
                }
              </button>
            ))}
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default TimeChange
