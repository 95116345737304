import DropdownNavbarResponsive from 'components/layouts/fragments/Navbar/DropdownNavbarResponsive'
import AuthModal from 'components/modal/Auth'
import UserIcon from 'images/icons/user-white.svg'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Navbar() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  const [showModalAuth, setShowModalAuth] = useState(false)

  return (
    <div className="container absolute inset-0 z-10">
      <div className="w-full p-0 py-5 bg-transparent flex justify-center items-center">
        <div className="mx-auto flex w-full justify-between items-center font-medium">
          <div className="w-auto h-auto relative">
            <Link to="/">
              <img
                src="/images/kendixpress-white.svg"
                className="w-40 md:w-44"
                alt="Logo"
              />
            </Link>
          </div>
          {!token && (
            <button
              className="px-2 md:px-3 py-[6px] text-sm rounded-full font-semibold bg-white border-2 border-white text-primary w-44 hover:bg-[#EFEFEF] hidden md:block"
              onClick={() => setShowModalAuth(true)}
            >
              Masuk / Daftar
            </button>
          )}
          {token && (
            <div className="hidden md:flex">
              <Link to="/user/profile" className="md:mr-5">
                <img src={UserIcon} className="w-9 h-9" />
              </Link>
              <button
                onClick={() => navigate('/user/order')}
                className="px-2 md:px-3 py-[6px] text-sm rounded-full font-semibold bg-white border-2 border-white text-primary w-28 hover:bg-[#EFEFEF] hidden md:block"
              >
                Pesanan
              </button>
            </div>
          )}
          <DropdownNavbarResponsive
            onClick={() => setShowModalAuth(true)}
            classIcon={`!text-white`}
          />
        </div>
      </div>
      <AuthModal showModal={showModalAuth} setShowModal={setShowModalAuth} />
    </div>
  )
}

export default Navbar
