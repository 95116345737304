import Input from 'components/forms/Input'
import noteIcon from 'images/icons/noteIcon.png'
import React from 'react'

import CustomForm from '../../../components/forms/CustomForm'

const RequestForm = ({ user, setUser }) => {
  return (
    <CustomForm title="Permintaan Khusus">
      <div className="py-1 flex gap-2">
        <img src={noteIcon} alt="note" className="w-5 h-6 mr-1" />
        <div className="flex w-full flex-col">
          <Input
            type="text"
            name="Catatan Pesanan atau Pengiriman"
            label="Catatan Pesanan atau Pengiriman"
            onChange={(e) => setUser({ ...user, notes: e.target.value })}
            maxLength={250}
            value={user.notes}
            contentBottom={
              <span className="text-[10px] pt-1 text-end text-gray-400">
                {user?.notes?.length || 0} / 250
              </span>
            }
          />
        </div>
      </div>
    </CustomForm>
  )
}

export default RequestForm
