/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Pajak = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="pajak" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        H. Pajak
      </div>
      <div className="my-2">
        <em>Vendor</em> dan Pemesan berkewajiban untuk menanggung segala beban
        pajak atas Penjualan Makanan sesuai dengan peraturan perundang-undangan
        yang berlaku. Prosedur atas pengenaan pajak tersebut dilakukan sesuai
        dengan peraturan perundang-undangan yang berlaku.
      </div>
    </TabContent>
  )
})

export default Pajak
