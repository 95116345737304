/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'

export const TabContent = forwardRef(
  ({ children, className, ...rest }, ref) => {
    return (
      <div
        className={`md:px-10 text-sm text-black ${className}`}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    )
  }
)

export default TabContent
