import $axios from 'utils/axios'

export const fetchDetailMitra = async (lat, lon, partner) => {
  const result = await $axios(
    `/partner/detail?lat=${lat}&lon=${lon}&partner_id=${partner}`
  )
  return result.data
}

export const fetchListMenuAndalan = async (lat, lon, partner, date) => {
  const result = await $axios(
    `/menu/v2/list?lat=${lat}&lon=${lon}&partner_id=${partner}&type=2&is_main_menu=1&menu_date=${date}`
  )
  return result.data
}

export const fetchListMenuHarian = async (lat, lon, partner, date) => {
  const result = await $axios(
    `/menu/v2/list?lat=${lat}&lon=${lon}&partner_id=${partner}&type=1&menu_date=${date}`
  )
  return result.data
}

export const fetchListMenuTambahan = async (lat, lon, partner, date) => {
  const result = await $axios(
    `/menu/v2/list?lat=${lat}&lon=${lon}&partner_id=${partner}&type=2&is_main_menu=0&menu_date=${date}`
  )
  return result.data
}

export const fetchNearMitra = async (lat, lon, partner) => {
  const result = await $axios(
    `/partner/near-me/list?lat=${lat}&lon=${lon}&exclude_partner_id=${partner}`
  )
  return result.data
}

export const fetchMenuExist = async (menu, date) => {
  const result = await $axios(
    `/menu/v2/date-exists?menu_id=${menu}&date=${date}`
  )
  return result.data
}
