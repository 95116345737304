import Breadcrumb from 'components/layouts/fragments/breadcrumb'
import React from 'react'

import NavMicrosite from './fragments/Navbar/NavMicrosite'

const MicrositePaymentLayout = ({
  header,
  headForm,
  descriptionForm,
  children,
}) => {
  return (
    <React.Fragment>
      <NavMicrosite>
        <div className="block md:invisible w-full md:w-fit ml-2 text-lg font-semibold">
          {header}
        </div>
      </NavMicrosite>

      <Breadcrumb heading={headForm} description={descriptionForm} />
      <div className="md:bg-slate-50 bg-white bg-opacity-70">{children}</div>
    </React.Fragment>
  )
}

export default MicrositePaymentLayout
