import Dropdown from 'components/dropdown/Index'
import Logout from 'components/modal/Logout'
import LocationIcon from 'images/icons/location.svg'
import LogoutIcon from 'images/icons/logout.svg'
import OrderIcon from 'images/icons/order.svg'
import ProfileIcon from 'images/icons/profile.svg'
import React, { useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Link } from 'react-router-dom'

function DropdownNavbarResponsive({ onClick, classIcon, classButton = '' }) {
  const token = localStorage.getItem('token')

  const [showLogout, setShowLogout] = useState(false)

  return (
    <>
      <button className={`block md:hidden ${classButton}`}>
        {!token && (
          <BsThreeDotsVertical
            size={24}
            className={`text-primary ${classIcon}`}
            onClick={onClick}
          />
        )}
        {token && (
          <Dropdown
            classButton={`bg-transparent`}
            buttonContent={
              <BsThreeDotsVertical
                size={24}
                className={`text-primary ${classIcon}`}
              />
            }
            classDropdown={`h-auto w-[150px]`}
            dropdownContent={
              <ul className="text-left p-[15px]">
                <li className="py-[10px] flex items-center">
                  <img src={ProfileIcon} className="w-5 h-5 mr-4" />
                  <Link to="/user/profile" className="text-sm text-black">
                    Profil
                  </Link>
                </li>
                <li className="py-[10px] flex items-center">
                  <img src={OrderIcon} className="w-5 h-5 mr-4" />
                  <Link to="/user/order" className="text-sm text-black">
                    Pesanan
                  </Link>
                </li>
                <li className="py-[10px] flex items-center">
                  <img src={LocationIcon} className="w-5 h-5 mr-4" />
                  <Link to="/user/address" className="text-sm text-black">
                    Alamat
                  </Link>
                </li>
                <li className="py-[10px] flex items-center">
                  <img src={LogoutIcon} className="w-5 h-5 mr-4" />
                  <div
                    className="text-sm text-primary cursor-pointer"
                    onClick={() => setShowLogout(true)}
                  >
                    Keluar
                  </div>
                </li>
              </ul>
            }
          />
        )}
      </button>
      {/* MODAL LOGOUT */}
      <Logout showLogout={showLogout} setShowLogout={setShowLogout} />
    </>
  )
}

export default DropdownNavbarResponsive
