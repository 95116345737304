import React from 'react'
import { currency } from 'utils/formatNumber'

const RecommendWrapper = ({ price, children }) => {
  return (
    <section className="md:px-0 flex flex-col py-5 md:py-16">
      <h4 className="text-2xl flex gap-2 flex-col mb-5 md:flex-row font-medium items-center justify-center">
        Rekomendasi Menu{' '}
        <span className="text-primary">Harga {currency(Number(price))}</span>
      </h4>
      {children}
    </section>
  )
}

export default RecommendWrapper
