import React, { useRef, useState } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import OtherItem from './fragments/OtherItem'

const NearMitra = ({ mitra }) => {
  const scrl = useRef(null)
  const [scrollX, setscrollX] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)
  const dataLength = mitra.map((x) => x)

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift
    setscrollX(scrollX + shift)

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  // check scroll
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft)
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }
  return (
    <div className="flex flex-nowrap p-4 pb-0 lg:container lg:mt-10 flex-col gap-5 lg:gap-12">
      <div className="flex flex-row justify-between">
        <h4 className="lg:text-2xl">
          Catering & Restoran lain yang terdekat dengan lokasi pengirimanmu
        </h4>
        <div className="md:flex hidden gap-5">
          <button
            onClick={() => slide(-920)}
            className={`${
              scrollX === 0
                ? 'text-gray-400 pointer-events-none'
                : 'text-primary'
            }`}
          >
            <IoIosArrowBack size={24} />
          </button>
          <button
            onClick={() => slide(+920)}
            className={`${
              scrolEnd || dataLength.length < 6
                ? 'text-gray-400 pointer-events-none'
                : 'text-primary'
            }`}
          >
            <IoIosArrowForward size={24} />
          </button>
        </div>
      </div>
      <div
        ref={scrl}
        onScroll={scrollCheck}
        className="flex flex-row py-2 pb-10 scroll-smooth no-scrollbar border-b h-full flex-nowrap overflow-x-scroll gap-9"
      >
        {mitra.map((item, key) => (
          <OtherItem key={key} data={item} />
        ))}
      </div>
    </div>
  )
}

export default NearMitra
