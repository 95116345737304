import Datepicker from 'components/datepicker'
import Timepicker from 'components/timepicker'
import React, { useEffect } from 'react'
import { IoCloseSharp } from 'react-icons/io5'

const DateTimePicker = ({
  show,
  setShow,
  dateTmp,
  minDate,
  maxDate,
  setDateTmp,
  time,
  setTime,
  setSave,
}) => {
  useEffect(() => {
    let body = document.querySelector('body')
    let modal = document.querySelector('.modal')

    if (modal) {
      body.classList.add('overflow-hidden')
    } else {
      body.classList.remove('overflow-hidden')
    }
  }, [show])
  return (
    <React.Fragment>
      {show ? (
        <div className="inset-0 z-50 p-4 md:p-10 w-full h-full flex fixed bg-black bg-opacity-40 modal">
          <div className="flex flex-col relative gap-5 mx-auto my-auto bg-white p-5 md:p-9 rounded-3xl">
            <button
              onClick={() => setShow(false)}
              className="absolute text-primary top-5 right-5"
            >
              <IoCloseSharp size={28} />
            </button>
            <h6 className="font-lg font-semibold">Pilih Tanggal dan Waktu</h6>
            <div className="flex h-fit flex-col md:flex-row">
              <Datepicker
                isOpen
                defaultValue={dateTmp}
                minDate={minDate}
                maxDate={maxDate}
                onChange={setDateTmp}
              />
              <div className="border-l-none md:border-l">
                <Timepicker shippingTime={time} setShippingTime={setTime} />
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between md:pr-3 items-center gap-2">
              <p className="text-[10px] md:text-xs">
                Tanggal dan waktu yang kamu inginkan
                <br />
                tidak tersedia, Jangan khawatir{' '}
                <a
                  href="https://wa.me/6281119279900?text=Saya%20ingin%20pesan%20Untuk%20tanggal%20"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary cursor-pointer"
                >
                  pesan disini
                </a>
              </p>
              <div
                onClick={setSave}
                className="w-full justify-center z-10 md:w-fit px-12 flex max-w-xs text-sm cursor-pointer rounded-full py-2 bg-primary text-white"
              >
                Simpan
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default DateTimePicker
