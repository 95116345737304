import React from 'react'

const LoadMore = ({ title, click }) => {
  return (
    <button
      onClick={click}
      className="border-2 flex w-full justify-center font-semibold rounded-full py-2 border-primary text-primary text-sm"
    >
      Lihat Lebih Banyak Menu {title}
    </button>
  )
}

export default LoadMore
