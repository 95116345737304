import Modal from 'components/modal/Index'
import React from 'react'

import Accordion from './fragments/Accordion'

const MethodModal = ({ show, setShow, data }) => {
  return (
    <Modal
      showModal={show}
      setShowModal={setShow}
      isModalResponsiveFullPage={false}
      isModalResponsiveRounded={true}
      modalClass={`sm:!w-[380px] z-50`}
      modalBodyClass={`p-[32px] pt-0 sm:!p-[40px] sm:!pt-[40px]`}
      hideTitleSection
    >
      <div className="flex flex-col gap-3">
        <h4 className="text-sm md:text-lg font-semibold">Cara Pembayaran</h4>
        <p className="text-xs md:text-sm">
          Harap perhatikan nomor VA dan jumlah pembayaran ketika transfer
        </p>
        <div className="flex flex-col gap-2">
          <Accordion
            title="Transfer Melalui ATM"
            description={
              <ol className="!list-decimal pl-3.5 flex flex-col gap-1">
                {data?.atm.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ol>
            }
          />
          <Accordion
            title="Transfer Melalui Internet Banking"
            description={
              <ol className="!list-decimal pl-3.5 flex flex-col gap-1">
                {data?.internet_banking.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ol>
            }
          />
          <Accordion
            title="Transfer Melalui Mobile Banking"
            description={
              <ol className="!list-decimal pl-3.5 flex flex-col gap-1">
                {data?.m_banking.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ol>
            }
          />
        </div>
        <div className="flex justify-center py-4">
          <button
            onClick={() => setShow(false)}
            className="text-white bg-primary rounded-full w-full py-2 text-sm font-semibold"
          >
            Saya Mengerti
          </button>
        </div>
        <div className="items-center justify-center flex text-[10px]">
          Butuh Bantuan?{' '}
          <a
            href="https://wa.me/6281119279900?text=Saya%20terdapat%20kendala%20dalam%20pembayaran%20memakai%20Virtual%20Account%20Bank"
            target="_blank"
            rel="noreferrer"
            className="text-primary text-[10px] ml-1 font-medium"
          >
            Klik disini
          </a>
        </div>
      </div>
    </Modal>
  )
}

export default MethodModal
