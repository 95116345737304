import Quote from 'images/tentang_kami_quotes.svg'
import React from 'react'

function TestimonialItem({
  imgPerson,
  descTestimonial,
  endTestimonial,
  namePerson,
  jobPerson,
}) {
  return (
    <div className="w-full flex flex-wrap mb-10 md:mb-0">
      <div className="w-full md:w-1/12 my-4 md:my-0">
        <img src={Quote} className="max-w-[40px] md:max-w-[60px]" />
      </div>
      <div className="w-full md:w-2/12 hidden md:flex justify-center mt-10">
        <div className="md:w-[153px]">
          <img src={imgPerson} className="rounded-[20px]" alt="Person" />
        </div>
      </div>
      <div className="w-full flex flex-col md:block md:w-9/12 md:mt-10 md:pl-12">
        {descTestimonial}
        <div className="w-full md:w-2/12 flex md:hidden justify-center mt-10 order-2">
          <div className="w-[110px]">
            <img src={imgPerson} className="rounded-[20px]" alt="Person" />
          </div>
        </div>
        {endTestimonial}
        <p className="text-lg mt-6 font-bold text-center md:text-left order-3">
          {namePerson}
        </p>
        {jobPerson && (
          <p className="text-sm mt-1 text-center md:text-left order-4">
            {jobPerson}
          </p>
        )}
      </div>
    </div>
  )
}

export default TestimonialItem
