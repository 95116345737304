import Tooltip from 'components/layouts/fragments/Tooltip'
// import shipping from 'images/menu/shipping.svg'
import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'

import CartItem from './CartItem'
// import ChangeCart from './fragments/ChangeCart'
import EmptyCart from './fragments/EmptyCart'

const Cart = ({
  cart,
  addProduct,
  updateQuantity,
  removeProduct,
  removeByDate,
  setDatePick,
  defaultDate,
  time,
  setTime,
  grandTotal,
  datePick,
  setDateParam,
  // partner,
  changeDateCart,
  changeTimeCart,
}) => {
  return (
    <aside className="my-5 sticky top-2 ">
      <div className="lg:shadow-cart px-1 rounded-2xl">
        <div className=" flex gap-1 px-4 lg:px-5 pt-5 mb-5">
          <AiOutlineShoppingCart className="w-6 h-6 text-primary" />
          <h3 className="text-xl font-semibold">Keranjang Pesanan</h3>
          <Tooltip>
            Kamu bisa pesan menu makan
            <br />
            lebih dari satu tanggal dan waktu
            <br /> dalam satu keranjang pesanan
          </Tooltip>
        </div>
        <div className="border-b mx-4 lg:mx-5"></div>
        {cart.length === 0 && <EmptyCart />}
        {cart.length > 0 && (
          <CartItem
            cart={cart}
            addProduct={addProduct}
            updateQuantity={updateQuantity}
            removeProduct={removeProduct}
            datePick={datePick}
            setDatePick={setDatePick}
            setDateParam={setDateParam}
            time={time}
            defaultDate={defaultDate}
            setTime={setTime}
            removeByDate={removeByDate}
            grandTotal={grandTotal}
            changeDateCart={changeDateCart}
            changeTimeCart={changeTimeCart}
          />
        )}
      </div>
    </aside>
  )
}

export default Cart
