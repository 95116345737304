import React from 'react'
import { BiPlus } from 'react-icons/bi'
import { currency } from 'utils/formatNumber'

const MenuList = ({ menus, date, time, add }) => {
  return (
    <React.Fragment>
      <div className="grid grid-cols-4 mb-2 gap-3">
        <div className="col-span-3 text-xs text-gray-400">
          Nama Menu dan Harga
        </div>
        <div className="col-span-1 text-xs text-gray-400">Jumlah</div>
      </div>
      <div className="flex flex-col gap-3">
        {menus.map((menu, key) => (
          <div key={key} className="grid grid-cols-4 gap-3">
            <div className="col-span-3 flex flex-col">
              <h4 className="text-sm truncate">{menu.name}</h4>
              <p className="text-[11px]">
                {currency(menu.price)}{' '}
                <span className="text-gray-400">per {menu.qty_unit_name}</span>
              </p>
            </div>
            <div className="col-span-1">
              {menu.qty + ' ' + menu.qty_unit_name}
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={() => add(time, date)}
        className="text-[10px] font-semibold flex gap-1 items-center my-2 text-primary"
      >
        <BiPlus /> Tambah Pesanan
      </button>
    </React.Fragment>
  )
}

export default MenuList
