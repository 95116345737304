import React from 'react'
import { MONTH_NAMES } from 'utils/date'

const DateCart = ({ date, time, qty }) => {
  const dateDay = date.getDate()
  const dateMonth = date.getMonth()
  const dateYear = date.getFullYear()
  return (
    <div className="grid grid-cols-4 gap-3">
      <div className="col-span-2 flex flex-col gap-2">
        <h4 className="text-xs text-gray-400">Tanggal Pengiriman</h4>
        <h5 className="text-sm font-semibold">
          {dateDay + ' ' + MONTH_NAMES[dateMonth].slice(0, 3) + ' ' + dateYear}
        </h5>
      </div>
      <div className="col-span-1 flex flex-col gap-2">
        <h4 className="text-xs text-gray-400">Waktu</h4>
        <h5 className="text-sm font-semibold">{time.replace('.', ':')}</h5>
      </div>
      <div className="col-span-1 flex flex-col gap-2">
        <h4 className="text-xs text-gray-400">Jumlah</h4>
        <h5 className="text-sm font-semibold">{qty}</h5>
      </div>
    </div>
  )
}

export default DateCart
