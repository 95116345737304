import React from 'react'

const RadioButton = ({ value, name, buttonClick, ...rest }) => {
  return (
    <React.Fragment>
      <div onClick={buttonClick} className="flex gap-2 cursor-pointer text-sm">
        <input
          {...rest}
          type="radio"
          name={name}
          value={value}
          className="accent-primary cursor-pointer border-primary outline-primary w-5"
        />
        {value}
      </div>
    </React.Fragment>
  )
}

export default RadioButton
