import ExploreLayout from 'components/layouts/ExploreLayout'
import Loading from 'components/layouts/fragments/Loading'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import { fetchLatestPrice } from 'modules/Explore/api'
import { useListMitraMutation } from 'modules/Explore/hook'
import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

import MoreButton from '../../components/layouts/fragments/MoreButton'
import EmptyAddress from './components/EmptyAddress'
import EmptyMitra from './components/EmptyMitra'
import EmptySearchMenu from './components/EmptySearchMenu'
import MitraItem from './components/MitraItem'
import RecommendMenu from './components/RecommendMenu'
import TabSearch from './components/TabSearch'

const Explore = () => {
  const listMenu = ['Ayam', 'Ikan', 'Ricebowl', 'Tumpeng', 'Snack', 'Harian']
  const [load, setLoad] = useState(
    JSON.parse(sessionStorage.getItem('loadMore')) || 10
  )
  const [menu, setMenu] = useState(0)
  const [loading, setLoading] = useState(false)
  const [buttonMenu, setButtonMenu] = useState(true)
  const focus = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useState(
    JSON.parse(localStorage.getItem('dataAddress')) || ''
  )
  const [partner] = useState(JSON.parse(localStorage.getItem('partner')) || '')
  const { searchValue, setSearchValue, partnerTypeId, setPartnerTypeId } =
    useGoogleMapHook()
  const [input, setInput] = useState(searchValue)
  const [price, setPrice] = useState(null)

  // eslint-disable-next-line no-unused-vars
  const [listMitra, setList] = useState(
    JSON.parse(sessionStorage.getItem('listData')) || ''
  )

  const listMutation = useListMitraMutation()

  // clear search value
  const clearSearch = () => {
    setList('')
    setInput('')
    setSearchValue('')
    sessionStorage.setItem('searchValue', JSON.stringify(''))
    if (focus.current) focus.current.focus()
  }

  // handle search menu
  const handleSearch = async (e) => {
    setList('')
    e.preventDefault()
    setSearchValue(input)
    sessionStorage.setItem('searchValue', JSON.stringify(input))
    setLoad(5)
  }

  // handle tabs
  const handleTab = (e) => {
    setList('')
    setLoad(10)
    setPartnerTypeId(e)
  }

  useEffect(() => {
    listMitra.length == 0 && setLoading(true)
    listMitra.length > 0 && setLoading(false)
    const dataTmp = JSON.parse(localStorage.getItem('dataPartners'))
    if (dataTmp) {
      setList(dataTmp)
      setLoading(false)
      sessionStorage.setItem('listData', JSON.stringify(dataTmp))
    } else {
      if (listMitra.length === 0) {
        listMutation.mutate(
          {
            lat: location.lat,
            long: location.lng,
            type: partnerTypeId,
            search: searchValue,
          },
          {
            onSuccess: (data) => {
              if (data.status.status_code !== 20) {
                setList('error')
                sessionStorage.setItem('listData', JSON.stringify('error'))
                return
              }
              if (
                data.result == null ||
                data?.result.length === 0 ||
                Object.keys(data?.result).length === 0
              ) {
                setList(data.result)
                sessionStorage.setItem('listData', JSON.stringify(data.result))
                setLoading(false)
              } else {
                setList(data.result)
                sessionStorage.setItem('listData', JSON.stringify(data.result))
                setLoading(false)
              }
            },
          }
        )
      }
    }
    localStorage.removeItem('dataPartners')
  }, [searchValue, partnerTypeId])

  // dynamic placeholder
  useEffect(() => {
    const interval = setInterval(() => {
      menu == listMenu.length - 1 ? setMenu(0) : setMenu(menu + 1)
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [menu])

  useEffect(() => {
    setTimeout(() => {
      if (partner !== '' && listMitra) {
        const checkCartAvailable = () => {
          const checkPartner = listMitra?.findIndex(
            (data) => data.partner_id === partner.partner_id
          )
          return checkPartner
        }
        const resultCheck = checkCartAvailable()
        localStorage.setItem('cart-available', resultCheck)
      }
    }, 6000)
  }, [])

  // check button load more menu
  useEffect(() => {
    if (listMitra) {
      listMitra.length > load ? setButtonMenu(false) : setButtonMenu(true)
    }
    sessionStorage.setItem('loadMore', JSON.stringify(load))
  }, [load, partnerTypeId, listMitra])

  useEffect(() => {
    fetchLatestPrice().then((res) => setPrice(res))
  }, [])

  // HANDLE ERROR RESPONSE
  // if (listMitra == 'error') {
  //   return (
  //     <div className="text-sm md:text-3xl lg:text-5xl text-red-600 font-bold flex justify-center items-center h-screen">
  //       Server Error
  //     </div>
  //   )
  // }

  return (
    <ExploreLayout>
      <>
        {location === '' ? (
          <EmptyAddress />
        ) : (
          <>
            <section className="md:pt-11 pt-5 w-full bg-explore">
              <TabSearch
                searchValue={input}
                focus={focus}
                searchChange={(e) => setInput(e.target.value)}
                clearValue={() => clearSearch()}
                placeholder={listMenu[menu]}
                submit={handleSearch}
                index={partnerTypeId}
                setIndex={handleTab}
              />
            </section>

            {/* Loading  */}
            {loading && <Loading />}

            {/* Empty Mitra */}
            {!loading && searchValue === '' && listMitra.length === 0 && (
              <EmptyMitra />
            )}

            {/* Empty Search Menu */}
            {!loading && searchValue !== '' && listMitra.length === 0 && (
              <EmptySearchMenu />
            )}

            {/* List Mitra */}
            {listMitra.length > 0 &&
              !loading &&
              listMitra
                .slice(0, load)
                .map((data, index) => <MitraItem key={index} data={data} />)}

            {/* Button LoadMore Menu */}
            {!loading && !buttonMenu && (
              <MoreButton
                disabled={buttonMenu}
                click={() => setLoad(load + 10)}
              />
            )}

            {!loading && listMitra.length > 0 && price !== null && (
              <RecommendMenu price={price?.result?.price || 25000} />
            )}
          </>
        )}
      </>
    </ExploreLayout>
  )
}

export default Explore
