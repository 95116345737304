import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from 'components/button/LoadingButton'
import Form from 'components/forms/Form'
import Input from 'components/forms/Input'
import React from 'react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'
import * as yup from 'yup'

import FooterHelp from './FooterHelp'

function CreatePasswordContent({
  phoneNumber,
  goNextStep,
  loadingButton,
  errorMessage,
}) {
  const [showPassword, setShowPassword] = useState(false)
  const [showReenterPassword, setShowReenterPassword] = useState(false)

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Wajib diisi')
      .test('len', 'Minimal 8 karakter', (val) => {
        if (val == undefined) {
          return true
        }
        return val.length == 0 || val.length >= 8
      }),
    reenterPassword: yup
      .string()
      .required('Wajib diisi')
      .oneOf([yup.ref('password'), null], 'Kata sandi tidak sama'),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      reenterPassword: '',
    },
  })

  const handleSubmitForm = (data) => {
    goNextStep && goNextStep(data)
  }

  return (
    <div className="flex flex-col w-full">
      <h4 className="text-lg text-black font-semibold mb-1">
        Masukkan Kata Sandi
      </h4>
      <span className="text-sm text-black leading-6">
        Untuk Kamu gunakan dikemudian hari
        <br />
        ketika masuk kendiXpress
      </span>

      <Form>
        <div className="mt-12 mb-9">
          <div className="hidden">
            <Input type="text" name="username" defaultValue={phoneNumber} />
          </div>
          <Controller
            name="password"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                {...field}
                type={!showPassword ? 'password' : 'text'}
                label="Buat Kata Sandi"
                name="password"
                autoComplete="off"
                iconAppend={
                  <div
                    className="cursor-pointer"
                    onClick={() => setShowPassword((prevValue) => !prevValue)}
                  >
                    {!showPassword ? (
                      <IoEyeOffOutline className="w-full h-full text-[#9D9D9D]" />
                    ) : (
                      <IoEyeOutline className="w-full h-full text-[#9D9D9D]" />
                    )}
                  </div>
                }
                error={errors.password && errors.password.message}
              />
            )}
          />
        </div>
        <div className="mt-12 mb-9">
          <Controller
            name="reenterPassword"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                {...field}
                type={!showReenterPassword ? 'password' : 'text'}
                label="Ulangi Kata Sandi"
                name="reenterPassword"
                autoComplete="off"
                iconAppend={
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      setShowReenterPassword((prevValue) => !prevValue)
                    }
                  >
                    {!showReenterPassword ? (
                      <IoEyeOffOutline className="w-full h-full text-[#9D9D9D]" />
                    ) : (
                      <IoEyeOutline className="w-full h-full text-[#9D9D9D]" />
                    )}
                  </div>
                }
                error={errors.reenterPassword && errors.reenterPassword.message}
              />
            )}
          />
        </div>
        <LoadingButton
          className="!px-2 md:!px-3 !py-3 font-semibold !w-full"
          isRounded={true}
          isLoading={loadingButton}
          onClick={handleSubmit(handleSubmitForm)}
        >
          Selanjutnya
        </LoadingButton>
      </Form>

      <FooterHelp errorMessage={errorMessage} />
    </div>
  )
}

export default CreatePasswordContent
