import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const KebijakanCookie = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="kebijakan-cookie">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        3. Kebijakan Cookie
      </div>
      <div className="my-2">
        Kami menggunakan cookie Google Analytics untuk membantu kami mengerti
        halaman atau bagian mana dari Situs yang Pemesan sering kunjungi. Cookie
        ini memberikan kami data yang menjadi dasar pengukuran terhadap performa
        Situs dan bagaimana kami bisa meningkatkannya.
      </div>
    </TabContent>
  )
})

export default KebijakanCookie
