/* eslint-disable no-unused-vars */
import ModalMaps from 'components/modal/ModalMaps'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import React from 'react'

function SearchBanner() {
  const {
    showModal,
    setShowModal,
    latLong,
    setLatLong,
    place,
    setPlace,
    setURL,
    setRegion,
    isPartnerExist,
    setIsPartnerExist,
    handleSubmitForm,
    saveLocationMutation,
    redirectToExploreIfAddressFilled,
  } = useGoogleMapHook()

  const dataAddress = JSON.parse(localStorage.getItem('dataAddress'))

  return (
    <>
      <div className="relative md:absolute w-full md:w-8/12 bottom-auto md:-bottom-9 right-auto md:right-1/2 md:translate-x-1/2 order-1 mb-4 md:mb-0">
        <div
          className="flex shadow-search-homepage p-3 md:p-6 pl-12 md:pl-16 pr-14 md:pr-20 w-full text-sm md:text-lg text-black bg-white rounded-full isolate border border-white focus:outline-none cursor-pointer relative"
          onClick={() => setShowModal(true)}
        >
          <div className="flex absolute inset-y-0 left-0 items-center pl-5 md:pl-5 pointer-events-none">
            <svg
              className="w-[16px] md:w-8 h-[16px] md:h-8 text-primary"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
          </div>
          {!dataAddress && (
            <div className="text-sm md:text-lg text-[#9D9D9D] w-full">
              Mau dikirim kemana?
            </div>
          )}
          {dataAddress && (
            <div className="w-full flex flex-nowrap text-sm md:text-lg">
              <div className="text-[#9D9D9D] w-auto md:w-[15%] whitespace-nowrap">
                Dikirim ke
              </div>
              <div className="text-black font-semibold w-auto md:w-[85%] px-2 md:px-3 overflow-hidden overflow-ellipsis whitespace-nowrap">
                {dataAddress.formattedAddress}
              </div>
            </div>
          )}
          <button
            onClick={() => redirectToExploreIfAddressFilled()}
            className="text-white absolute right-[5px] md:right-[10px] bottom-[5px] md:bottom-[10px] bg-primary hover:bg-red-800 focus:outline-none font-medium rounded-full text-sm px-3 md:px-5 py-3 md:py-5"
          >
            <svg
              aria-hidden="true"
              className="w-3 md:w-5 h-3 md:h-5 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {/* MODAL SET LOCATION */}
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        place={place}
        setURL={setURL}
        setRegion={setRegion}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={saveLocationMutation.isLoading}
        hideModal={() => {
          sessionStorage.setItem('searchValue', JSON.stringify(''))
          sessionStorage.setItem('partnerType', JSON.stringify(''))
        }}
      />
    </>
  )
}

export default SearchBanner
