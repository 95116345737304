/* eslint-disable react/display-name */
import List from 'components/list/Index'
import ListItem from 'components/list/ListItem'
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const BatasTanggungJawab = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="batas-tanggung-jawab" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        I. Batas Tanggung Jawab
      </div>
      <List>
        <ListItem>
          Kami tidak bertanggung jawab kepada Pemesan atas terjadinya kerugian
          insidentil atau akibat yang timbul dari:
          <List type="none">
            <ListItem className="ml-0 !pl-0">
              1.1. Keterlambatan atau gangguan dalam KendiXpress;
            </ListItem>
            <ListItem className="ml-0 !pl-0">
              1.2. Kejadian atau situasi yang berada diluar kendali Kami,
              termasuk namun tidak terbatas pada kualitas dan kemungkinan
              terjadinya kontaminasi pada produk hasil <em>Vendor</em>;
            </ListItem>
            <ListItem className="ml-0 !pl-0">
              1.3. Persiapan produksi dan pengiriman makanan;
            </ListItem>
            <ListItem className="ml-0 !pl-0">
              1.4. Pelanggaran atas pembayaran atau perjanjian;
            </ListItem>
            <ListItem className="ml-0 !pl-0">
              1.5. Pelanggaran hukum dan/atau peraturan yang dilakukan oleh
              <em>Vendor</em>;
            </ListItem>
            <ListItem className="ml-0 !pl-0">
              1.6. Pembayaran tidak resmi kepada pihak lain selain ke Rekening{' '}
              <em>Virtual Account</em>; dan
            </ListItem>
            <ListItem className="ml-0 !pl-0">
              1.7. Pesanan, tindakan, atau adanya tindakan dari pihak ketiga,
              termasuk terkait dengan Pesanan yang ada dalam KendiXpress yang
              diduga palsu.
            </ListItem>
          </List>
          Pemesan dan Vendor dengan ini melepaskan KendiXpress dari segala
          tuntutan dan/atau gugatan ganti rugi yang dapat timbul dikemudian hari
          yang disebabkan oleh hal-hal tersebut diatas.
        </ListItem>
        <ListItem>
          RAM tidak menjamin kesempurnaan dalam penggunaan <em>website</em>{' '}
          dan/atau aplikasi KendiXpress, dan akan berusaha semaksimal mungkin
          untuk menyelesaikan semua masalah yang disebabkan oleh{' '}
          <em>website</em> dan/atau aplikasi KendiXpress serta menyediakan{' '}
          <em>Customer Service</em> untuk Pemesan dalam mengatasi masalah di
          <em>website</em> dan/atau aplikasi KendiXpress.
        </ListItem>
        <ListItem>
          RAM hanya bertanggungjawab sebatas nilai kekurangan Pesanan, apabila
          terbukti terdapat ketidaksesuaian menu dan/atau jumlah Pesanan.
        </ListItem>
        <ListItem>
          Pengembalian atas ketidaksesuaian Pesanan akan dilakukan oleh RAM
          senilai kekurangan Pesanan yang telah dikonfirmasi oleh Vendor ke
          Rekening Pemesan selambat-lambatnya 1 (satu) hari kerja.
        </ListItem>
        <ListItem>
          Pemesan membebaskan RAM, dan <em>Vendor</em> dari tanggung jawab atas
          kegagalan memberikan Pesanan akibat adanya kejadian memaksa (
          <em>force majeure</em>).
        </ListItem>
      </List>
    </TabContent>
  )
})

export default BatasTanggungJawab
