import WhatsappButton from 'components/WhatsappButton'
import React from 'react'

import Alert from './fragments/Alert'
import Footer from './fragments/Footer'
import Navbar from './fragments/Navbar/Navbar'
import Order from './fragments/Order'

function HomeLayout({ children, bottomContent }) {
  return (
    <>
      <Alert />
      <Navbar />
      <div className="container">{children}</div>
      {bottomContent}
      <Order />
      <Footer />
      <WhatsappButton />
    </>
  )
}

export default HomeLayout
