/* eslint-disable no-unused-vars */
import clockIcon from 'images/mitra/clock-icon.svg'
import dateIcon from 'images/mitra/date-icon.svg'
import React, { useState } from 'react'

import DateChange from './DateChange'
import TimeChange from './TimeChange'

const MONTH_NAMES = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
]

const DateCart = ({
  cart,
  date,
  time,
  qty,
  changeTimeCart,
  changeDateCart,
}) => {
  const dateConvert = new Date(date)
  const [datePopup, setDatePopup] = useState(false)
  const [timePopup, setTimePopup] = useState(false)

  const changeDate = (value) => {
    changeDateCart(date, time, value)
    setDatePopup(false)
  }

  const changeTime = (date, time, value) => {
    changeTimeCart(date, time, value)
    setTimePopup(false)
  }

  return (
    <React.Fragment>
      <div className="flex relative mt-5 flex-row gap-3 items-center justify-between">
        <DateChange
          cart={cart}
          show={datePopup}
          setShow={setDatePopup}
          dateCart={date}
          time={time}
          action={changeDate}
        />
        <TimeChange
          cart={cart}
          show={timePopup}
          setShow={setTimePopup}
          date={date}
          time={time}
          action={changeTime}
        />
        <div
          onClick={() => setDatePopup(true)}
          className="flex justify-between  cursor-pointer gap-4 items-end pb-3 border-b"
        >
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Tanggal</p>
            <p className="font-medium text-sm">
              {dateConvert.getDate()}{' '}
              {MONTH_NAMES[dateConvert.getMonth()].slice(0, 3)}{' '}
              {dateConvert.getFullYear()}
            </p>
          </div>
          <img src={dateIcon} alt="date-icon" className="h-5 w-5" />
        </div>
        <div
          onClick={() => setTimePopup(true)}
          onBlur={() => setTimePopup(false)}
          className="flex justify-between cursor-pointer gap-4 items-end pb-3 border-b"
        >
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Waktu</p>
            <p className="font-medium text-sm">{time.replaceAll('.', ':')}</p>
          </div>
          <img src={clockIcon} alt="clock-icon" className="h-5 w-5" />
        </div>
        <div className="col-span-1 flex flex-col gap-2">
          <h4 className="text-xs text-gray-400">Jumlah</h4>
          <h5 className="text-sm font-semibold">{qty}</h5>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DateCart
