/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Hukum = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent id="hukum" ref={ref} {...rest}>
      <div className="text-sm leading-6 font-bold text-black uppercase">
        J. Hukum, Jurisdiksi Dan Bahasa Yang Berlaku
      </div>
      <div className="my-2">
        Ketentuan ini dibuat dalam Bahasa Indonesia dan dapat diterjemahkan ke
        bahasa lain. Dalam hal terdapat perbedaan penafsiran antara Bahasa
        Indonesia dengan versi terjemahannya tersebut, maka versi Bahasa
        Indonesia akan berlaku dan versi terjemahannya tersebut akan dianggap
        secara otomatis diubah agar bagian terkait dari versi terjemahannya
        konsisten dengan bagian terkait dari versi Bahasa Indonesia. Ketentuan
        ini diatur dan ditafsirkan berdasarkan hukum Negara Republik Indonesia.
        Kami dan Anda sepakat untuk memilih tempat kedudukan hukum yang umum dan
        tetap pada Badan Arbitrase Nasional Indonesia (BANI).
      </div>
    </TabContent>
  )
})

export default Hukum
