import React from 'react'

import Card from './Card'
import InfoWrapper from './fragments/InfoWrapper'

const RecipientDetail = ({ data }) => {
  return (
    <Card className={`md:py-10 flex flex-col md:px-12`}>
      <h3 className="text-xl font-semibold mb-6">Detail Pemesan</h3>
      <div className="flex flex-col gap-3">
        <InfoWrapper title="Nama Pemesan" value={data?.customer_name} />
        <InfoWrapper
          title="Nomor Handphone Pemesan"
          value={data?.pic_phone_number}
          border={
            data?.pic_email.length > 0 || data?.company_name.length > 0
              ? false
              : true
          }
        />
        {data?.pic_email.length > 0 && (
          <InfoWrapper
            title="Email Pemesan"
            value={data?.pic_email}
            border={data?.company_name.length > 0 ? false : true}
          />
        )}
        {data?.company_name.length > 0 && (
          <InfoWrapper
            title="Nama Perusahaan"
            value={data?.company_name}
            border
          />
        )}
      </div>

      <h3 className="text-xl font-semibold my-6">Detail Penerima</h3>
      <div className="flex flex-col gap-3">
        <InfoWrapper title="Nama Penerima" value={data?.recipient_name} />
        <InfoWrapper
          title="Nomor Handphone Penerima"
          value={data?.recipient_phone}
        />
        {data?.delivery_to.length > 0 && (
          <InfoWrapper title="Pemesanan dikirim ke" value={data?.delivery_to} />
        )}
        <InfoWrapper title="Alamat Pengiriman" value={data?.delivery_address} />
        <InfoWrapper
          title="Detail Lokasi"
          value={data?.detail_location}
          border
        />
      </div>

      {data?.notes.length > 0 && (
        <>
          <h3 className="text-xl font-semibold my-6">Permintaan khusus</h3>
          <div className="flex flex-col gap-3">
            <InfoWrapper
              title="Catatan Pesanan atau Pengiriman"
              value={data?.notes}
              border
            />
          </div>
        </>
      )}

      <h3 className="text-xl font-semibold my-6">Detail Pembayaran</h3>
      <div className="flex flex-col md:flex-row gap-3 justify-between">
        <div className="flex flex-col gap-3">
          {data?.payment_type !== 3 ? (
            <InfoWrapper
              title="Metode Pembayaran"
              value={
                <div className="flex flex-col">
                  <p>
                    {data?.payment_type == 2
                      ? `Bank Transfer (${data?.bank_account_bank})`
                      : `Virtual Account (${data?.bank_account_bank})`}
                  </p>
                  <p>
                    No {data?.payment_type == 2 ? 'Rek' : 'VA'}{' '}
                    {data?.bank_account_bank} {data?.bank_account_norek}
                  </p>
                  <p>{data?.bank_account_name}</p>
                </div>
              }
            />
          ) : (
            <InfoWrapper
              title="Metode Pembayaran"
              value={
                <div className="flex flex-col">
                  <p>Cash</p>
                  <p>{data?.payment_description}</p>
                </div>
              }
            />
          )}
          <InfoWrapper
            title="Tipe Pembayaran"
            value={`${data?.payment_scheme_code} ${
              data?.payment_scheme_code == 'TOP'
                ? data?.payment_scheme_period
                : ''
            }`}
          />
        </div>
        <div className="flex md:pt-7 text-sm text-primary">
          {data?.invoice_status == 'P' ||
          (data?.status == 13 && data?.flag == 3)
            ? 'SUDAH DIBAYAR'
            : 'BELUM DIBAYAR'}
        </div>
      </div>
    </Card>
  )
}

export default RecipientDetail
