import {
  useMenuAndalanMutations,
  useMenuHarianMutations,
  useMenuTambahanMutations,
} from 'modules/Mitra/hooks'
import { useFetchPartnerListMutation } from 'modules/partner/hook'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useCartSystem } from './useCartSystem'

export const useGoogleMapHook = () => {
  const { dateParams } = useCartSystem()
  const navigate = useNavigate()
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [latLong, setLatLong] = useState(null)
  const [place, setPlace] = useState(null)
  const [isPartnerExist, setIsPartnerExist] = useState(true)

  const [URL, setURL] = useState(null)
  const [region, setRegion] = useState(null)

  const [menuKeyword, setMenuKeyword] = useState(null)
  const [menuPrice, setMenuPrice] = useState(null)
  const [searchValue, setSearchValue] = useState(
    JSON.parse(sessionStorage.getItem('searchValue'))
  )
  const [partner] = useState(
    JSON.parse(localStorage.getItem('partner')) || null
  )
  const [change, setChange] = useState(false)
  const [loading, setLoading] = useState(false)

  const [partnerId, setPartnerId] = useState(null)
  const [partnerTypeId, setPartnerTypeId] = useState(
    JSON.parse(sessionStorage.getItem('partnerType'))
  )

  const saveLocationMutation = useFetchPartnerListMutation()
  const andalan = useMenuAndalanMutations()
  const harian = useMenuHarianMutations()
  const tambahan = useMenuTambahanMutations()

  const dataAddress = JSON.parse(localStorage.getItem('dataAddress'))

  const handleSubmitForm = (data) => {
    let tmpObj = {
      ...latLong,
      detailLocation: data.detailLocation,
      formattedAddress: place,
      city: region,
      locationPin: URL,
    }

    andalan.mutate(
      {
        lat: latLong.lat,
        lon: latLong.lng,
        partner: partner?.partner_id || null,
        date: dateParams / 1000,
      },
      {
        onSuccess: (data) => {
          if (data.result.length > 0 || Object.keys(data.result).length > 0) {
            sessionStorage.setItem(
              'andalanTmp',
              JSON.stringify(data.result.data)
            )
          }
          harian.mutate(
            {
              lat: latLong.lat,
              lon: latLong.lng,
              partner: partner?.partner_id || null,
              date: dateParams / 1000,
            },
            {
              onSuccess: (data) => {
                if (
                  data.result.length > 0 ||
                  Object.keys(data.result).length > 0
                ) {
                  sessionStorage.setItem(
                    'harianTmp',
                    JSON.stringify(data.result.data)
                  )
                }
                tambahan.mutate(
                  {
                    lat: latLong.lat,
                    lon: latLong.lng,
                    partner: partner?.partner_id || null,
                    date: dateParams / 1000,
                  },
                  {
                    onSuccess: (data) => {
                      if (
                        data.result.length > 0 ||
                        Object.keys(data.result).length > 0
                      ) {
                        sessionStorage.setItem(
                          'tambahanTmp',
                          JSON.stringify(data.result.data)
                        )
                      }
                      saveLocationMutation.mutate(
                        {
                          lat: latLong.lat,
                          lon: latLong.lng,
                          menu_keyword: menuKeyword,
                          menu_price: menuPrice,
                          partner_id: partnerId,
                          partner_type_id: partnerTypeId,
                        },
                        {
                          onSuccess: (data) => {
                            if (
                              data.length === 0 ||
                              Object.keys(data).length === 0
                            ) {
                              setIsPartnerExist(false)
                            } else {
                              setShowModal(false)
                              setIsPartnerExist(true)
                              localStorage.setItem(
                                'dataAddress',
                                JSON.stringify(tmpObj)
                              )
                              if (location.pathname == '/payment-info') {
                                navigate(0)
                              } else {
                                if (change) {
                                  setLoading(true)
                                  navigate(0)
                                } else {
                                  if (location.pathname == '/explore') {
                                    localStorage.setItem(
                                      'dataPartners',
                                      JSON.stringify(data)
                                    )
                                    navigate(0)
                                  } else {
                                    if (
                                      partnerId === '' ||
                                      partnerId === null
                                    ) {
                                      localStorage.setItem(
                                        'dataPartners',
                                        JSON.stringify(data)
                                      )
                                      setTimeout(() => {
                                        navigate(`/explore`)
                                      }, 100)
                                    } else {
                                      setTimeout(() => {
                                        navigate(`detail-mitra/${partnerId}`)
                                      }, 100)
                                    }
                                  }
                                }
                              }
                            }
                          },
                        }
                      )
                    },
                  }
                )
              },
            }
          )
        },
      }
    )
  }

  const redirectToExploreIfAddressFilled = () => {
    sessionStorage.setItem('searchValue', JSON.stringify(''))
    sessionStorage.setItem('partnerType', JSON.stringify(''))
    if (dataAddress) {
      navigate(`/explore`)
    } else {
      setShowModal(true)
    }
  }

  const handlePartner = (id) => {
    sessionStorage.setItem('searchValue', JSON.stringify(''))
    if (dataAddress) {
      navigate(`/detail-mitra/${id}`)
    } else {
      setPartnerId(id)
      setShowModal(true)
    }
  }

  return {
    showModal,
    setShowModal,
    latLong,
    setLatLong,
    place,
    setPlace,
    isPartnerExist,
    setIsPartnerExist,
    searchValue,
    setSearchValue,
    menuKeyword,
    setMenuKeyword,
    menuPrice,
    setMenuPrice,
    partnerId,
    setPartnerId,
    partnerTypeId,
    setPartnerTypeId,
    handleSubmitForm,
    saveLocationMutation,
    redirectToExploreIfAddressFilled,
    handlePartner,
    change,
    setChange,
    loading,
    URL,
    setURL,
    region,
    setRegion,
    andalan,
    harian,
    tambahan,
  }
}
