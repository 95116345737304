/* eslint-disable no-unused-vars */
import CreatePasswordContent from 'components/modal/fragments/Auth/CreatePassword'
import ForgotPasswordInputContent from 'components/modal/fragments/Auth/ForgotPasswordInput'
import ForgotPasswordPhoneContent from 'components/modal/fragments/Auth/ForgotPasswordPhone'
import LoginContent from 'components/modal/fragments/Auth/Login'
import OtpAlternativesContent from 'components/modal/fragments/Auth/OtpAlternatives'
import OtpAlternativesInputContent from 'components/modal/fragments/Auth/OtpAlternativesInput'
import OtpInputContent from 'components/modal/fragments/Auth/OtpInput'
import PhoneValidatorContent from 'components/modal/fragments/Auth/PhoneValidator'
import RegisterContent from 'components/modal/fragments/Auth/Register'
import SuccessAuth from 'components/modal/fragments/Auth/Success'
import {
  useCreatePasswordUser,
  useLoginUser,
  useRegisterUser,
} from 'modules/Auth/hook'
import { useFirebaseHook } from 'modules/custom/useFirebaseHook'
import React, { useRef, useState } from 'react'
import { IoChevronBackOutline } from 'react-icons/io5'

import Modal from './Index'

function AuthModal({ showModal, setShowModal }) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [step, setStep] = useState('phone')
  const [fullName, setFullName] = useState('')
  const [password, setPassword] = useState('')
  const [loadingButton, setLoadingButton] = useState('')
  const [scenario, setScenario] = useState('')

  const mutationRegister = useRegisterUser()
  const mutationCreatePassword = useCreatePasswordUser()
  const mutationLogin = useLoginUser()

  const recaptchaRef = useRef(null)

  const {
    result,
    setResult,
    getOtp,
    verifyOtp,
    errorMessage,
    setErrorMessage,
    retryOtp,
    redirectSuccessAuth,
  } = useFirebaseHook()

  const phoneValidation = (result) => {
    if (!result.is_verified && !result.is_phone_registered) {
      setStep('register')
      setScenario('register')
    }
    if (!result.is_verified && result.is_phone_registered) {
      setStep('create-password')
      setScenario('create-password')
    }
    if (result.is_verified && result.is_phone_registered) {
      setStep('login')
      setScenario('login')
    }
  }

  const registerValidation = async (data) => {
    setLoadingButton(true)
    setFullName(data.fullname)
    setPassword(data.password)
    if (!window.recaptchaVerifier) {
      await getOtp(phoneNumber)
        .then((resp) => {
          setLoadingButton(false)
          setStep('otp')
        })
        .catch((err) => {
          setLoadingButton(false)
        })
    } else {
      await retryOtp(phoneNumber)
        .then((resp) => {
          setLoadingButton(false)
          setStep('otp')
        })
        .catch((err) => {
          setLoadingButton(false)
        })
    }
  }

  const createPasswordValidation = async (data) => {
    setLoadingButton(true)
    setPassword(data.password)
    if (!window.recaptchaVerifier) {
      await getOtp(phoneNumber)
        .then((resp) => {
          setLoadingButton(false)
          setStep('otp')
        })
        .catch((err) => {
          setLoadingButton(false)
        })
    } else {
      await retryOtp(phoneNumber)
        .then((resp) => {
          setLoadingButton(false)
          setStep('otp')
        })
        .catch((err) => {
          setLoadingButton(false)
        })
    }
  }

  const forgotPasswordValidation = async (data) => {
    setLoadingButton(true)
    setPhoneNumber(data.phone)
    if (!window.recaptchaVerifier) {
      await getOtp(phoneNumber)
        .then((resp) => {
          setLoadingButton(false)
          setStep('otp')
        })
        .catch((err) => {
          setLoadingButton(false)
        })
    } else {
      await retryOtp(phoneNumber)
        .then((resp) => {
          setLoadingButton(false)
          setStep('otp')
        })
        .catch((err) => {
          setLoadingButton(false)
        })
    }
  }

  const otpValidation = (data) => {
    const replaceCountryCode = phoneNumber.replace(/^0/, '+62')
    setLoadingButton(true)
    verifyOtp(data)
      .then((resp) => {
        if (scenario === 'register') {
          mutationRegister.mutate(
            {
              phone: replaceCountryCode,
              password: password,
              fullname: fullName,
            },
            {
              onSuccess: (result) => {
                if (result.length === 0 || Object.keys(result).length === 0) {
                  setErrorMessage('Server error, harap hubungi administrator')
                } else {
                  setLoadingButton(false)
                  localStorage.setItem('token', result.token)
                  setStep('success')
                }
              },
            }
          )
        }
        if (scenario === 'create-password') {
          mutationCreatePassword.mutate(
            {
              phone: phoneNumber,
              password: password,
            },
            {
              onSuccess: (result) => {
                if (result.length === 0 || Object.keys(result).length === 0) {
                  setErrorMessage('Server error, harap hubungi administrator')
                } else {
                  setLoadingButton(false)
                  localStorage.setItem('token', result.token)
                  setStep('success')
                }
              },
            }
          )
        }
        if (scenario === 'forgot-password') {
          setLoadingButton(false)
          setStep('forgot-password-input')
        }
      })
      .catch((err) => {
        setLoadingButton(false)
      })
  }

  const resetDataModal = (isModalOpen) => {
    if (isModalOpen === true) {
      setShowModal && setShowModal(true)
    }
    if (isModalOpen === false) {
      setShowModal && setShowModal(false)
      setPhoneNumber('')
      setStep('phone')
      setScenario('')
      setFullName('')
      setPassword('')
      setResult('')
      setLoadingButton('')
      setErrorMessage('')
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier = undefined
        recaptchaRef.current.innerHTML = `<div id="recaptcha-container"></div>`
      }
    }
  }

  const backToPreviousStep = () => {
    setErrorMessage('')
    if (scenario === 'login') {
      if (step === 'login') {
        setPhoneNumber('')
        setStep('phone')
      }
    }
    if (scenario === 'register') {
      if (step === 'register') {
        setPhoneNumber('')
        setStep('phone')
      }
      if (step === 'otp') {
        setFullName('')
        setPassword('')
        setStep('register')
      }
      if (step === 'alternative-otp') {
        retryOtp(phoneNumber)
        setStep('otp')
      }
      if (step === 'input-alternative-otp') {
        setStep('alternative-otp')
      }
    }
    if (scenario === 'create-password') {
      if (step === 'create-password') {
        setPhoneNumber('')
        setStep('phone')
      }
      if (step === 'otp') {
        setPassword('')
        setStep('create-password')
      }
      if (step === 'alternative-otp') {
        retryOtp(phoneNumber)
        setStep('otp')
      }
      if (step === 'input-alternative-otp') {
        setStep('alternative-otp')
      }
    }
    if (scenario === 'forgot-password') {
      if (step === 'forgot-password') {
        setPhoneNumber('')
        setStep('phone')
      }
      if (step === 'otp') {
        setPhoneNumber('')
        setStep('forgot-password')
      }
      if (step === 'alternative-otp') {
        retryOtp(phoneNumber)
        setStep('otp')
      }
      if (step === 'input-alternative-otp') {
        setStep('alternative-otp')
      }
      if (step === 'forgot-password-input') {
        setPhoneNumber('')
        setStep('phone')
      }
    }
  }

  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={resetDataModal}
        isModalResponsiveFullPage={false}
        isModalResponsiveRounded={true}
        modalClass={`sm:!w-[380px]`}
        modalBodyClass={`p-[32px] pt-0 sm:!p-[40px] sm:!pt-[32px]`}
        hideTitleSection={step == 'success'}
        titleContent={
          step !== 'phone' &&
          step !== 'success' && (
            <div
              className="cursor-pointer"
              onClick={() => backToPreviousStep()}
            >
              <IoChevronBackOutline className="text-[#9D9D9D] w-[32px] h-[32px]" />
            </div>
          )
        }
      >
        {step === 'phone' && (
          <PhoneValidatorContent
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            goNextStep={phoneValidation}
          />
        )}
        {step === 'register' && (
          <RegisterContent
            phoneNumber={phoneNumber}
            goNextStep={registerValidation}
            loadingButton={loadingButton}
            errorMessage={errorMessage}
          />
        )}
        {step === 'otp' && (
          <OtpInputContent
            phoneNumber={phoneNumber}
            errorMessage={errorMessage}
            loadingOtp={loadingButton}
            retryOtp={() => retryOtp(phoneNumber)}
            anotherOptionOtp={setStep}
            setErrorMessage={setErrorMessage}
            handleSubmitOtp={otpValidation}
          />
        )}
        {step === 'alternative-otp' && (
          <OtpAlternativesContent setStep={setStep} />
        )}
        {step === 'input-alternative-otp' && (
          <OtpAlternativesInputContent
            setStep={setStep}
            phoneNumber={phoneNumber}
            fullName={fullName}
            password={password}
            scenario={scenario}
          />
        )}
        {step === 'login' && (
          <LoginContent
            phoneNumber={phoneNumber}
            setStep={setStep}
            setScenario={setScenario}
            setShowModal={resetDataModal}
          />
        )}
        {step === 'forgot-password' && (
          <ForgotPasswordPhoneContent
            goNextStep={forgotPasswordValidation}
            loadingButton={loadingButton}
            errorMessage={errorMessage}
            phoneNumber={phoneNumber}
          />
        )}
        {step === 'forgot-password-input' && (
          <ForgotPasswordInputContent
            phoneNumber={phoneNumber}
            setStep={setStep}
          />
        )}
        {step === 'create-password' && (
          <CreatePasswordContent
            phoneNumber={phoneNumber}
            goNextStep={createPasswordValidation}
            loadingButton={loadingButton}
            errorMessage={errorMessage}
          />
        )}
        {step === 'success' && (
          <SuccessAuth
            scenario={scenario}
            redirectPage={() => {
              resetDataModal(false)
              redirectSuccessAuth()
            }}
          />
        )}
      </Modal>
      <div ref={recaptchaRef}>
        <div id="recaptcha-container"></div>
      </div>
    </>
  )
}

export default AuthModal
