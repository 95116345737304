import Loading from 'components/layouts/fragments/Loading'
import { fetchListOrder } from 'modules/Order/api'
import React from 'react'
import { useInView } from 'react-intersection-observer'

import DataOrder from './fragments/DataOrder'
import DataTop from './fragments/DataTop'

const ListOrder = ({ data, setDataList, visible, setVisible, totalPage }) => {
  const { ref, inView } = useInView()
  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState(1)

  const refetchListOrder = async (page) => {
    const result = await fetchListOrder(page)
    setDataList([...data, result?.result?.data])
    sessionStorage.setItem(
      'dataList',
      JSON.stringify([...data, result?.result?.data])
    )
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  // CONVERT TANGGAL KE GMT
  const handleDateGMT = (date) => {
    let dateConvert = new Date(date * 1000)
    dateConvert.setHours(dateConvert.getHours() + 7)
    return Date.parse(dateConvert)
  }

  React.useEffect(() => {
    const check = JSON.parse(sessionStorage.getItem('page'))
    !check ? setPage(1) : setPage(check)
  }, [])

  React.useEffect(() => {
    if (inView && page < totalPage) {
      setLoading(true)
      refetchListOrder(page)
      setPage(page + 1)
      sessionStorage.setItem('page', JSON.stringify(page + 1))
    }
  }, [inView])

  return (
    <div className="flex flex-col gap-5">
      {data?.map((list, index) => (
        <React.Fragment key={index}>
          {list?.map((item, key) => {
            let dateTmp = []
            for (let i = 0; i < item.order_dates.length; i++) {
              dateTmp.push(handleDateGMT(item.order_dates[i]))
            }
            for (let i = 1; i < dateTmp.length; i++) {
              if (
                new Date(dateTmp[0]).getDate() ==
                  new Date(dateTmp[i]).getDate() &&
                new Date(dateTmp[0]).getMonth() ==
                  new Date(dateTmp[i]).getMonth()
              ) {
                dateTmp.splice(i, 1)
              }
            }
            let dateUnique = [...new Set(dateTmp)]
            let result = []
            dateUnique.map((arr) => {
              const dept = result.findIndex(
                (res) =>
                  new Date(res[0]).getMonth() === new Date(arr).getMonth()
              )

              if (dept == -1) {
                result.push([arr])
              } else {
                result[dept].push(arr)
              }
            })

            return (
              <div key={key}>
                <DataTop item={item} />
                <DataOrder
                  index={key}
                  data={item}
                  date={result}
                  visible={visible}
                  setVisible={setVisible}
                />
              </div>
            )
          })}
        </React.Fragment>
      ))}
      <div ref={ref}>
        {loading && (
          <div className="flex justify-center items-center h-40">
            <Loading />
          </div>
        )}
      </div>
    </div>
  )
}

export default ListOrder
