import React from 'react'

function ServiceItem({
  imgSrc,
  imgAlt,
  title,
  descPrice,
  classWrapper,
  onClick,
}) {
  return (
    <div className={classWrapper} onClick={onClick}>
      <figure className="relative inline-block max-w-[160px] md:max-w-[264px] max-h-[380px]">
        <div className="overflow-hidden rounded-[20px]">
          <img
            src={imgSrc}
            className="relative float-right transition-transform duration-200 ease-in hover:scale-[1.2] block"
            alt={imgAlt}
          />
        </div>
        <figcaption className="absolute bottom-1 left-0 w-full text-center flex flex-col justify-center pb-[10px] md:pb-[24px] pt-11 after:absolute after:-bottom-3 after:top-0 after:left-0 after:right-0 after:content-[''] after:rounded-b-[20px] after:opacity-100 after:bg-service-pattern">
          <h5 className="text-sm md:text-lg font-semibold text-white mb-1 z-10">
            {title}
          </h5>
          <p className="text-xs md:text-sm font-normal text-white z-10">
            Mulai dari Rp {descPrice}
          </p>
        </figcaption>
      </figure>
    </div>
  )
}

export default ServiceItem
