import LoadingButton from 'components/button/LoadingButton'
import SuccessIcon from 'images/success-icon.svg'
import React from 'react'

function SuccessAuth({ scenario, redirectPage }) {
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <img src={SuccessIcon} className="w-[180px] h-[180px]" />
      <div className="mb-7">
        <h4 className="text-lg font-semibold text-black my-1">
          {scenario === 'register' ||
          scenario === 'login' ||
          scenario === 'create-password'
            ? 'Kamu Berhasil Masuk'
            : scenario === 'forgot-password'
            ? 'Kata Sandi Baru Berhasil'
            : ''}
        </h4>
        <h5 className="text-sm text-black my-1 text-center">
          {(scenario === 'register' ||
            scenario === 'login' ||
            scenario === 'create-password') && (
            <>
              Terimakasih sudah
              <br />
              mempercayai kendiXpress
            </>
          )}
          {scenario === 'forgot-password' && (
            <>
              Sekarang Kamu bisa melanjutkan
              <br />
              untuk melakukan pesanan
            </>
          )}
        </h5>
      </div>
      <LoadingButton
        className="!px-2 md:!px-3 !py-3 font-semibold !w-full"
        isRounded={true}
        onClick={redirectPage}
      >
        Lanjut Masuk
      </LoadingButton>
    </div>
  )
}

export default SuccessAuth
