/* eslint-disable no-unused-vars */
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import Loading from 'components/layouts/fragments/Loading'
import UserLayout from 'components/layouts/UserLayout'
import NotAddressFoundIcon from 'images/belum_ada_alamat.svg'
import BuildingIcon from 'images/icons/building.svg'
import GuestIcon from 'images/icons/guest.svg'
import LocationBlackIcon from 'images/icons/location-black.svg'
import PhoneIcon from 'images/icons/phone.svg'
import { useFetchUserProfile } from 'modules/User/hook'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import Card from './components/Card'
import InfoItems from './components/InfoItems'
import Sidebar from './components/Sidebar'

function Address() {
  const [map, setMap] = useState(null)
  const [mapRes, setMapRes] = useState(null)
  const [libraries] = useState(['places'])

  const dataHook = useFetchUserProfile()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY_GMAPS,
    libraries: libraries,
  })

  const onLoad = useCallback((map) => {
    const elementMap = document.getElementById('set-address-map')
    if (!document.querySelector('.centerMarker')) {
      const div = document.createElement('div')
      div.classList.add('centerMarker')
      elementMap.appendChild(div)
    }
    const bounds = new window.google.maps.LatLngBounds({
      lat: dataHook.data && dataHook.data.lat,
      lng: dataHook.data && dataHook.data.lon,
    })
    map.fitBounds(bounds)
    setMap(map)
  })

  const onUnmount = useCallback((map) => {
    setMap(null)
    setMapRes(null)
  })

  const onLoadRes = useCallback((map) => {
    const elementMapRes = document.getElementById('set-address-map-res')
    if (!document.querySelector('.centerMarkerRes')) {
      const div = document.createElement('div')
      div.classList.add('centerMarkerRes')
      elementMapRes.appendChild(div)
    }
    const bounds = new window.google.maps.LatLngBounds({
      lat: dataHook.data && dataHook.data.lat,
      lng: dataHook.data && dataHook.data.lon,
    })
    map.fitBounds(bounds)
    setMapRes(map)
  })

  const onUnmountRes = useCallback((map) => {
    setMapRes(null)
  })

  return (
    <UserLayout>
      <div className="flex flex-wrap py-6 pt-0 md:pt-10 md:py-10">
        {/* DESKTOP */}
        <div className="w-4/12 pr-11 hidden md:block">
          {/* SIDEBAR */}
          <Sidebar
            name={dataHook.data && dataHook.data.name}
            isLoading={dataHook.isFetching}
          />
          {/* END SIDEBAR */}
        </div>
        <div className="w-8/12 hidden md:block">
          {/* INFO SECTION */}
          <Card
            className={`py-10 px-12 ${
              dataHook.isFetching ||
              (dataHook.data &&
                dataHook.data.lat === 0 &&
                dataHook.data.lon === 0)
                ? 'h-[650px]'
                : ''
            }`}
          >
            {/* IF DATA EXIST */}
            {/* LOADING */}
            {dataHook.isFetching && <Loading className="!h-full" />}
            {/* END LOADING */}
            {!dataHook.isFetching &&
              dataHook.data &&
              dataHook.data.lat !== 0 &&
              dataHook.data.lon !== 0 && (
                <>
                  <div className="mb-[32px] border-b pb-6">
                    <h1 className="text-[26px] leading-9 text-black font-semibold">
                      Alamat Pengiriman
                    </h1>
                    <div className="text-sm text-black mt-1">
                      Alamat pengiriman akan terupdate ketika terjadi perubahan
                      pada pesanan terakhir
                    </div>
                  </div>
                  <div className="mb-[60px]">
                    <InfoItems
                      title={`Nama Penerima`}
                      desc={dataHook.data && dataHook.data.recipient_name}
                      icon={GuestIcon}
                    />
                    <InfoItems
                      title={`Nomor Handphone`}
                      desc={dataHook.data && dataHook.data.recipient_phone}
                      icon={PhoneIcon}
                    />
                    {dataHook.data && dataHook.data.delivery_to && (
                      <InfoItems
                        title={`Pemesanan dikirim ke`}
                        desc={dataHook.data && dataHook.data.delivery_to}
                        icon={BuildingIcon}
                      />
                    )}
                    {dataHook.data && dataHook.data.delivery_address && (
                      <InfoItems
                        title={`Alamat Pengiriman`}
                        desc={dataHook.data && dataHook.data.delivery_address}
                        descBottom={
                          dataHook.data && dataHook.data.detail_location
                        }
                        descBottomClass={`mt-5`}
                        icon={LocationBlackIcon}
                      />
                    )}
                    {!dataHook.isFetching && isLoaded && (
                      <GoogleMap
                        id="set-address-map"
                        mapContainerClassName="w-full rounded-[20px] h-[200px] relative"
                        zoom={10}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                          zoomControl: false,
                          fullscreenControl: false,
                          disableDefaultUI: true,
                          maxZoom: 15,
                          minZoom: 15,
                          gestureHandling: 'none',
                        }}
                        clickableIcons={false}
                      ></GoogleMap>
                    )}
                  </div>
                </>
              )}
            {/* END IF DATA EXIST */}
            {/* IF DATA NOT EXIST */}
            {!dataHook.isFetching &&
              dataHook.data.lat === 0 &&
              dataHook.data.lon === 0 && (
                <div className="w-full h-full flex flex-col justify-center items-center">
                  <img
                    src={NotAddressFoundIcon}
                    className="w-[228px] h-[200px]"
                  />
                  <div className="mt-5 text-center">
                    <h4 className="text-lg font-semibold text-black mb-1">
                      Belum ada
                      <br />
                      alamat pengiriman
                    </h4>
                    <span className="text-sm text-black">
                      Silahkan buat pesanan,
                      <br />
                      agar alamat pengirimanmu ada disini
                    </span>
                  </div>
                  <div className="mt-6 text-center flex">
                    <Link
                      to="/explore"
                      className="px-2 md:px-3 py-[6px] text-sm font-semibold rounded-full bg-primary border-2 border-primary text-white w-44 hover:bg-red-800 hover:border-red-800"
                    >
                      Buat Pesanan
                    </Link>
                  </div>
                </div>
              )}
            {/* END IF DATA NOT EXIST */}
          </Card>
          {/* END SECTION */}
        </div>
        {/* END DESKTOP */}
        {/* MOBILE */}
        <div className="w-full block md:hidden">
          {/* LOADING */}
          {dataHook.isFetching && <Loading />}
          {/* END LOADING */}
          {/* IF DATA EXIST */}
          {!dataHook.isFetching &&
            dataHook.data &&
            dataHook.data.lat !== 0 &&
            dataHook.data.lon !== 0 && (
              <>
                <InfoItems
                  title={`Nama Penerima`}
                  desc={dataHook.data && dataHook.data.recipient_name}
                  icon={GuestIcon}
                />
                <InfoItems
                  title={`Nomor Handphone`}
                  desc={dataHook.data && dataHook.data.recipient_phone}
                  icon={PhoneIcon}
                />
                {dataHook.data && dataHook.data.delivery_to && (
                  <InfoItems
                    title={`Pemesanan dikirim ke`}
                    desc={dataHook.data && dataHook.data.delivery_to}
                    icon={BuildingIcon}
                  />
                )}
                {dataHook.data && dataHook.data.delivery_address && (
                  <InfoItems
                    title={`Alamat Pengiriman`}
                    desc={dataHook.data && dataHook.data.delivery_address}
                    descBottom={dataHook.data && dataHook.data.detail_location}
                    descBottomClass={`mt-5`}
                    icon={LocationBlackIcon}
                  />
                )}
                {!dataHook.isFetching && isLoaded && (
                  <GoogleMap
                    id="set-address-map-res"
                    mapContainerClassName="w-full rounded-[20px] h-[117px] relative mb-[36px] mt-[10px]"
                    zoom={10}
                    onLoad={onLoadRes}
                    onUnmount={onUnmountRes}
                    options={{
                      zoomControl: false,
                      fullscreenControl: false,
                      disableDefaultUI: true,
                      maxZoom: 15,
                      minZoom: 15,
                      gestureHandling: 'none',
                    }}
                    clickableIcons={false}
                  ></GoogleMap>
                )}
              </>
            )}
          {/* END IF DATA EXIST */}
          {/* IF DATA NOT EXIST */}
          {!dataHook.isFetching &&
            dataHook.data &&
            dataHook.data.lat === 0 &&
            dataHook.data.lon === 0 && (
              <div className="w-full h-full flex flex-col justify-center items-center py-[60px]">
                <img
                  src={NotAddressFoundIcon}
                  className="w-[200px] h-[200px]"
                />
                <div className="mt-5 text-center">
                  <h4 className="text-lg font-semibold text-black mb-1">
                    Belum ada
                    <br />
                    alamat pengiriman
                  </h4>
                  <span className="text-sm text-black">
                    Silahkan buat pesanan,
                    <br />
                    agar alamat pengirimanmu ada disini
                  </span>
                </div>
                <div className="mt-6 text-center flex">
                  <Link
                    to="/explore"
                    className="px-2 md:px-3 py-[6px] text-sm font-semibold rounded-full bg-primary border-2 border-primary text-white w-44 hover:bg-red-800 hover:border-red-800"
                  >
                    Buat Pesanan
                  </Link>
                </div>
              </div>
            )}
          {/* END IF DATA NOT EXIST */}
        </div>
        {/* END MOBILE */}
      </div>
    </UserLayout>
  )
}

export default Address
