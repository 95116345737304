import React from 'react'

function ServiceWrapper({ children }) {
  return (
    <div className="w-full mt-5 md:mt-10 mb-4 md:mb-3 pb-1 md:pb-0 overflow-x-scroll md:overflow-hidden">
      <div className="w-[700px] md:w-full">{children}</div>
    </div>
  )
}

export default ServiceWrapper
