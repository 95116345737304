import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { auth } from 'utils/firebase'

export const useFirebaseHook = () => {
  const [result, setResult] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const location = useLocation()
  const navigate = useNavigate()

  const idPartner = useParams()
  const [clickPayment, setClickPayment] = useState(
    localStorage.getItem('click')
  )

  const setUpRecaptcha = (phoneNum) => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        auth
      )
      window.recaptchaVerifier.render()
    }
    return signInWithPhoneNumber(auth, phoneNum, window.recaptchaVerifier)
  }

  const getOtp = (phoneNum) => {
    setErrorMessage('')
    return new Promise((resolve, reject) => {
      const replaceCountryCode = phoneNum.replace(/^0/, '+62')
      setUpRecaptcha(replaceCountryCode)
        .then((response) => {
          setResult(response)
          resolve(response)
        })
        .catch((err) => {
          console.log(err.message)
          let errMsg = ''
          if (err.message.includes('auth/invalid-phone-number')) {
            errMsg = 'Harap cek nomor handphonemu'
          }
          setErrorMessage(errMsg)
          reject(errMsg)
        })
    })
  }

  const verifyOtp = async (data) => {
    setErrorMessage('')
    let code = ''
    for (const key in data) {
      code += data[key]
    }

    if (code === '' || code === null) return
    return new Promise((resolve, reject) => {
      result
        .confirm(code)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => {
          console.log(err.message)
          setErrorMessage(err.message)
          reject(err.message)
        })
    })
  }

  const retryOtp = (phoneNum) => {
    setErrorMessage('')
    const replaceCountryCode = phoneNum.replace(/^0/, '+62')
    return new Promise((resolve, reject) => {
      signInWithPhoneNumber(auth, replaceCountryCode, window.recaptchaVerifier)
        .then((response) => {
          setResult(response)
          resolve(response)
        })
        .catch((err) => {
          console.log(err.message)
          let errMsg = ''
          if (err.message.includes('auth/invalid-phone-number')) {
            errMsg = 'Harap cek nomor handphonemu'
          }
          setErrorMessage(errMsg)
          reject(errMsg)
        })
    })
  }

  const redirectSuccessAuth = () => {
    sessionStorage.setItem('searchValue', JSON.stringify(''))
    sessionStorage.setItem('partnerType', JSON.stringify(''))
    if (clickPayment) {
      navigate(`/payment-info`)
    } else {
      if (
        location.pathname === '/' ||
        location.pathname === '/explore' ||
        location.pathname === '/about-us' ||
        location.pathname === '/faq' ||
        location.pathname === '/tnc' ||
        location.pathname === '/privacy-policy'
      ) {
        navigate('/explore')
      }
      if (location.pathname === '/detail-mitra/:id') {
        navigate(`/detail-mitra/${idPartner.id}`)
      }
    }
  }

  return {
    setUpRecaptcha,
    result,
    setResult,
    getOtp,
    errorMessage,
    setErrorMessage,
    clickPayment,
    setClickPayment,
    verifyOtp,
    retryOtp,
    redirectSuccessAuth,
  }
}
