import React from 'react'

const EmptyWrapper = ({ image, children, text, click, link }) => {
  return (
    <div className="container py-20 flex gap-5 flex-col text-center items-center border-b">
      <img src={image} alt="empty menu" width={240} height={240} />
      <div className="flex flex-col gap-1">{children}</div>
      <a
        className="py-3 bg-primary cursor-pointer px-8 rounded-full text-white text-sm font-semibold"
        href={link}
        onClick={click}
        target="_blank"
        rel="noreferrer"
      >
        <button name={text}>{text}</button>
      </a>
    </div>
  )
}

export default EmptyWrapper
