import Tooltip from 'components/layouts/fragments/Tooltip'
import shipping from 'images/menu/shipping.svg'
import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'

import CartItem from './CartItem'
import ChangeCart from './fragments/ChangeCart'
import EmptyCart from './fragments/EmptyCart'

const Cart = ({
  cart,
  addProduct,
  updateQuantity,
  removeProduct,
  removeByDate,
  setDatePick,
  defaultDate,
  time,
  setTime,
  grandTotal,
  datePick,
  setDateParam,
  partner,
  partnerParam,
  changeDateCart,
  changeTimeCart,
}) => {
  return (
    <aside className="my-5 sticky top-2 ">
      <div className="lg:shadow-cart px-1 rounded-2xl">
        <div className=" flex gap-1 px-4 lg:px-5 pt-5 mb-5">
          <AiOutlineShoppingCart className="w-6 h-6 text-primary" />
          <h3 className="text-xl font-semibold">Keranjang Pesanan</h3>
          <Tooltip>
            Kamu bisa pesan menu makan
            <br />
            lebih dari satu tanggal dan waktu
            <br /> dalam satu keranjang pesanan
          </Tooltip>
        </div>
        <div className="border-b mx-4 lg:mx-5"></div>
        {cart.length === 0 && <EmptyCart />}
        {cart.length > 0 && partnerParam !== partner.partner_id && (
          <ChangeCart />
        )}
        {cart.length > 0 && partnerParam == partner.partner_id && (
          <CartItem
            cart={cart}
            addProduct={addProduct}
            updateQuantity={updateQuantity}
            removeProduct={removeProduct}
            partner={partner}
            datePick={datePick}
            setDatePick={setDatePick}
            setDateParam={setDateParam}
            time={time}
            defaultDate={defaultDate}
            setTime={setTime}
            removeByDate={removeByDate}
            grandTotal={grandTotal}
            changeDateCart={changeDateCart}
            changeTimeCart={changeTimeCart}
          />
        )}
      </div>
      <div className="flex gap-2 opacity-80 mt-5 justify-center items-center">
        <img src={shipping} alt="shipping" className="w-5" />
        <p className="text-sm text-center">
          Gratis Ongkir, minimal order terpenuhi
        </p>
      </div>
    </aside>
  )
}

export default Cart
