/* eslint-disable react/display-name */
import React from 'react'
import { forwardRef } from 'react'

export const PlainInput = forwardRef(
  ({ type, name, classInput, ...rest }, ref) => {
    return (
      <input
        ref={ref}
        type={type}
        name={name}
        {...rest}
        className={`block w-full appearance-none focus:outline-none bg-transparent pb-3 border-b-[1px] border-b-[#C3C3C3] focus:border-b-primary z-[51] ${classInput}`}
      />
    )
  }
)

export default PlainInput
