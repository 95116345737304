import React from 'react'

import Card from 'pages/User/components/Card'
import InfoWrapper from 'pages/User/components/fragments/InfoWrapper'

const RecipientDetail = ({ data }) => {
  return (
    <Card className={`md:py-10 flex flex-col md:px-12`}>
      {/* <h3 className="text-xl font-semibold mb-6">Detail Pemesan</h3>
      <div className="flex flex-col gap-3">
        <InfoWrapper title="Nama Pemesan" value={data?.customer_name} />
        <InfoWrapper
          title="Nomor Handphone Pemesan"
          value={data?.pic_phone_number}
          border={
            data?.pic_email.length > 0 || data?.company_name.length > 0
              ? false
              : true
          }
        />
        {data?.pic_email.length > 0 && (
          <InfoWrapper
            title="Email Pemesan"
            value={data?.pic_email}
            border={data?.company_name.length > 0 ? false : true}
          />
        )}
        {data?.company_name.length > 0 && (
          <InfoWrapper
            title="Nama Perusahaan"
            value={data?.company_name}
            border
          />
        )}
      </div> */}

      <h3 className="text-xl font-semibold my-6">Detail Penerima</h3>
      <div className="flex flex-col gap-3">
        <InfoWrapper title="Nama Penerima" value={data?.recipient_name} />
        <InfoWrapper
          title="Nomor Handphone Penerima"
          value={data?.recipient_phone}
        />
        {data?.delivery_to.length > 0 && (
          <InfoWrapper title="Pemesanan dikirim ke" value={data?.delivery_to} />
        )}
        <InfoWrapper title="Alamat Pengiriman" value={data?.delivery_address} />
        <InfoWrapper
          title="Detail Lokasi"
          value={data?.detail_location}
          border
        />
      </div>

      {data?.notes.length > 0 && (
        <>
          <h3 className="text-xl font-semibold my-6">Permintaan khusus</h3>
          <div className="flex flex-col gap-3">
            <InfoWrapper
              title="Catatan Pesanan atau Pengiriman"
              value={data?.notes}
              border
            />
          </div>
        </>
      )}
    </Card>
  )
}

export default RecipientDetail
