import { useCartSystem } from 'modules/custom/useCartSystem'
import React from 'react'
import { DAY_NAMES, MONTH_NAMES } from 'utils/date'
import { currency } from 'utils/formatNumber'

import Accordion from './Accordion'

const CartResponsive = () => {
  const { cart, grandTotal } = useCartSystem()
  const [active, setActive] = React.useState(false)
  return (
    <React.Fragment>
      <h5 className="text-lg -mb-2 font-semibold">Total Pembayaran</h5>
      <Accordion
        noBorder
        setTitle={() => setActive(!active)}
        title={
          <div
            onClick={() => setActive(!active)}
            className="flex justify-between"
          >
            <div className="">
              <p className="text-sm font-semibold">{currency(grandTotal)}</p>
            </div>
            <div className="text-primary text-sm font-semibold">
              {active ? 'Tutup' : 'Detail'}
            </div>
          </div>
        }
        description={
          <div className="flex w-full h-full">
            <div className="flex w-full flex-col">
              {cart.map((data, key) => (
                <div
                  key={key}
                  className="first:border-t border-t-0 py-2 border-b last:border-b-0"
                >
                  <div className="py-4 border-b">
                    <p className="text-sm font-semibold">{`${
                      DAY_NAMES[new Date(data.date).getDay()]
                    }, ${new Date(data.date).getDate()} ${
                      MONTH_NAMES[new Date(data.date).getMonth()]
                    } ${new Date(data.date).getFullYear()}`}</p>
                    <p className="text-sm text-primary">
                      Waktu Tiba {data.shipping_time.replaceAll('.', ':')}
                    </p>
                  </div>
                  <div className="flex justify-between py-2 gap-2 text-sm text-gray-400">
                    <div className="">Nama</div>
                    <div className=" text-end ">Harga</div>
                  </div>
                  <div className="flex flex-col py-2 gap-2">
                    {data.menus.map((menu, key) => (
                      <div className="flex justify-between gap-2" key={key}>
                        <div className="col-span-3 text-sm flex flex-col font-medium">
                          <h6 className="capitalize">{menu.name}</h6>
                          <p>{`${currency(menu.price)} x ${menu.qty} ${
                            menu.qty_unit_name
                          }`}</p>
                        </div>
                        <div className="col-span-1 text-end text-sm font-semibold">
                          {currency(menu.sub_total)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-between py-2 gap-2 text-sm font-medium">
                    <div className="">Ongkos Kirim</div>
                    <div className=" text-end ">
                      {currency(data.shipping_cost)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      />
    </React.Fragment>
  )
}

export default CartResponsive
