import WhatsappButton from 'components/WhatsappButton'
import React from 'react'
import { useLocation } from 'react-router-dom'

import Footer from './fragments/Footer'
import NavbarUser from './fragments/Navbar/NavUser'

function UserLayout({ children }) {
  const location = useLocation()

  const setMenuNameResponsive = () => {
    if (location.pathname === '/user/profile') {
      return 'Detail Profil'
    }
    if (location.pathname === '/user/address') {
      return 'Alamat Pengiriman'
    }
    if (location.pathname === '/user/order') {
      return 'Daftar Pesanan'
    }
    if (location.pathname.includes('/user/order/')) {
      return 'Detail Pesanan'
    }
  }

  return (
    <>
      <NavbarUser menuNameResponsive={setMenuNameResponsive()} />
      <div
        className={`${
          location.pathname == '/user/order' ? 'bg-[#FAFAFA]' : ' bg-white'
        } md:bg-[#FAFAFA] min-h-[calc(100vh-308px)]`}
      >
        <div className="container">{children}</div>
      </div>
      <Footer classWrapper={`border-t`} />
      <WhatsappButton />
    </>
  )
}

export default UserLayout
