import React from 'react'
import { IoCloseSharp } from 'react-icons/io5'

const PopupConfirm = ({ setShow, show, continues }) => {
  return (
    <React.Fragment>
      {show && (
        <div className="inset-0 z-50 p-4 md:p-10 w-screen h-screen flex fixed bg-black bg-opacity-40 modal">
          <div className="bg-white relative md:justify-between w-full max-w-lg mx-auto h-fit my-auto flex flex-col transition-all duration-200 gap-5 md:gap-8 p-4 md:p-7 rounded-2xl">
            <div className="flex flex-col mb-3 mt-10 gap-4">
              <h3 className="font-semibold text-bse md:text-lg">
                Ingin Ganti Catering atau Resto?
              </h3>
              <p className="font-medium leading-loose text-xs md:text-sm">
                Pesanan kepada catering atau resto yang sudah terpilih akan
                terhapus, Kamu tetap melakukan perubahan?
              </p>
              <div className="flex mr-5 gap-5 justify-center md:justify-end">
                <button
                  onClick={() => setShow(false)}
                  className="border-2 border-primary w-28 rounded-full text-primary hover:text-white hover:bg-primary hover:opacity-90 text-xs font-semibold py-2"
                >
                  Batal
                </button>
                <button
                  onClick={continues}
                  className="border-2 border-primary w-28 rounded-full text-white bg-primary hover:opacity-90 text-xs font-semibold py-2"
                >
                  Ya
                </button>
              </div>
            </div>
            <div className="absolute right-4 top-4">
              <button onClick={() => setShow(false)} className="h-fit">
                <IoCloseSharp size={28} className="text-primary font-bold" />
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default PopupConfirm
