import React from 'react'

function ListItem({ children, className = '' }) {
  return (
    <li className={`ml-4 my-2 pl-3 text-sm leading-6 ${className}`}>
      {children}
    </li>
  )
}

export default ListItem
