/* eslint-disable no-unused-vars */
import React from 'react'
import { HiCheck } from 'react-icons/hi'
import { IoChevronDown } from 'react-icons/io5'

const Dropdown = ({ user, setUser }) => {
  const [open, setOpen] = React.useState(false)

  const checkActive = (user, data) => {
    return user.payment_bank === data ? 'text-primary' : ''
  }

  const handleVA = (data) => {
    setUser({ ...user, payment_bank: data })
    setOpen(false)
  }
  return (
    <React.Fragment>
      <div className="relative z-[99]">
        <div
          onClick={() => setOpen(true)}
          className="flex justify-between cursor-pointer px-5 text-sm items-center"
        >
          <p>
            {user.payment_bank !== 'no account'
              ? user.payment_bank
              : 'Silahkan pilih'}
          </p>
          <IoChevronDown
            className={`text-primary w-5 h-5 transition-all duration-500 ${
              open ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </div>
        {open && (
          <section className="bg-white absolute w-full shadow-search-homepage rounded-lg top-9 bottom-auto px-5 py-3 grid grid-cols-6">
            <div className="col-span-2 z-40 flex flex-col gap-1">
              <div
                onClick={() => handleVA('BCA')}
                className={`flex z-40 justify-between cursor-pointer hover:text-primary hover:font-semibold ${checkActive(
                  user,
                  'BCA'
                )}`}
              >
                <p>BCA</p>
                {user.payment_bank == 'BCA' && (
                  <HiCheck className="text-primary" />
                )}
              </div>
              <div
                onClick={() => handleVA('BNI')}
                className={`flex z-40 justify-between cursor-pointer hover:text-primary hover:font-semibold ${checkActive(
                  user,
                  'BNI'
                )}`}
              >
                <p>BNI</p>
                {user.payment_bank == 'BNI' && (
                  <HiCheck className="text-primary" />
                )}
              </div>
              <div
                onClick={() => handleVA('MANDIRI')}
                className={`flex z-40 justify-between cursor-pointer hover:text-primary hover:font-semibold ${checkActive(
                  user,
                  'MANDIRI'
                )}`}
              >
                <p>Mandiri</p>
                {user.payment_bank == 'MANDIRI' && (
                  <HiCheck className="text-primary" />
                )}
              </div>
              <div
                onClick={() => handleVA('BRI')}
                className={`flex z-40 justify-between cursor-pointer hover:text-primary hover:font-semibold ${checkActive(
                  user,
                  'BRI'
                )}`}
              >
                <p>BRI</p>
                {user.payment_bank == 'BRI' && (
                  <HiCheck className="text-primary" />
                )}
              </div>
            </div>
            <div
              onClick={() => setOpen(false)}
              className="fixed inset-0 z-0 w-screen h-screen"
            ></div>
          </section>
        )}
      </div>
    </React.Fragment>
  )
}

export default Dropdown
