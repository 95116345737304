import React, { useRef, useState } from 'react'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'

const MobileVersion = ({ times, setShippingTime, shippingTime }) => {
  const scrl = useRef(null)
  const [scrollX, setscrollX] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)
  const [id, setId] = useState(sessionStorage.getItem('scroll-re') || 50 * 7)

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift
    setscrollX(scrollX + shift)

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  // check scroll
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft)
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  React.useEffect(() => {
    scrl.current.scrollLeft = id
  }, [])

  return (
    <div className="block md:hidden">
      <div className="w-fit flex-col flex m-auto">
        <div className="border-t border-b text-center text-base font-medium p-2 md:p-4 px-10">
          Waktu Tiba
        </div>
        <div className="flex justify-center gap-4 w-full max-w-[240px] items-center py-2 flex-row md:flex-col">
          <button
            onClick={() => slide(-100)}
            className={`${
              scrollX === 0
                ? 'text-gray-400 pointer-events-none cursor-not-allowed'
                : 'text-primary'
            }`}
          >
            <MdArrowBackIos size={24} />
          </button>
          <div
            ref={scrl}
            onScroll={scrollCheck}
            className="w-full overflow-x-scroll scroll-smooth no-scrollbar flex flex-row gap-1"
          >
            {times.map((time, index) => (
              <div
                id={time}
                key={index}
                onClick={() => {
                  setShippingTime(time)
                  setId(64 * index)
                  sessionStorage.setItem('scroll-re', 64 * index)
                }}
                className={`md:py-3 rounded-lg md:px-6 px-4 py-2 cursor-pointer text-xs md:text-sm ${
                  shippingTime == time
                    ? 'bg-primary text-white'
                    : 'opacity-80 text-black'
                }`}
              >
                {time}
              </div>
            ))}
          </div>
          <button
            onClick={() => slide(+100)}
            className={`${
              scrolEnd ? 'text-gray-400 pointer-events-none' : 'text-primary'
            }`}
          >
            <MdArrowForwardIos size={24} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default MobileVersion
