/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const MelindungiData = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="melindungi-data">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        7. Melindungi Data Pribadi Pemesan
      </div>
      <div className="my-2">
        <div>
          Kami berkomitmen untuk melindungi Data Pribadi di bawah kepemilikan
          atau kendali kami dengan mempertahankan pengaturan keamanan yang
          wajar, termasuk prosedur fisik, teknis dan organisasi, untuk mencegah
          akses pengumpulan, penggunaan, penyajian, penyalinan, modifikasi,
          penghapusan yang tidak sah atau risiko yang sama.
        </div>
      </div>
    </TabContent>
  )
})

export default MelindungiData
