import React from 'react'
import { CgSpinner } from 'react-icons/cg'

import Button from './Index'

function LoadingButton({ children, isLoading, className, ...rest }) {
  return (
    <Button
      {...rest}
      disabled={isLoading}
      className={`${
        isLoading && 'cursor-not-allowed'
      } ${className} flex justify-center`}
    >
      {children}
      {isLoading && <CgSpinner className="ml-2 h-5 w-5 animate-spin" />}
    </Button>
  )
}

export default LoadingButton
