/* eslint-disable react/display-name */
import TabContent from 'components/tabs/TabContent'
import React, { forwardRef } from 'react'

export const Disclaimer = forwardRef(({ ...rest }, ref) => {
  return (
    <TabContent {...rest} ref={ref} id="disclaimer">
      <div className="text-sm leading-6 font-bold text-black uppercase">
        9. Disclaimer
      </div>
      <div className="my-2">
        Kebijakan Privasi ini mematuhi hukum yang berlaku di Indonesia. Kami
        tidak mewakili atau menjamin bahwa Kebijakan Privasi ini mematuhi
        undang-undang privasi dari setiap yuridiksi di negara lainnya.
      </div>
    </TabContent>
  )
})

export default Disclaimer
