import React from 'react'
import { times } from 'utils/date'

import DesktopVersion from './DesktopVersion'
import MobileVersion from './MobileVersion'

const Timepicker = ({ shippingTime, setShippingTime }) => {
  return (
    <React.Fragment>
      <DesktopVersion
        times={times}
        shippingTime={shippingTime}
        setShippingTime={setShippingTime}
      />
      <MobileVersion
        times={times}
        shippingTime={shippingTime}
        setShippingTime={setShippingTime}
      />
    </React.Fragment>
  )
}

export default Timepicker
