/* eslint-disable no-unused-vars */
import SectionTitle from 'components/layouts/fragments/SectionTitle'
import SectionLayout from 'components/layouts/SectionLayout'
import logoMitra from 'images/menu/logo-mitra.svg'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import { useFetchPartners } from 'modules/Home/hook'
import { useFetchDetailMutations } from 'modules/Mitra/hooks'
import React, { useState } from 'react'

import ModalMaps from '../../../components/modal/ModalMaps'

function Partner() {
  const {
    showModal,
    setShowModal,
    latLong,
    setLatLong,
    place,
    setPlace,
    setURL,
    setRegion,
    isPartnerExist,
    setIsPartnerExist,
    partnerId,
    setPartnerId,
    partnerTypeId,
    setPartnerTypeId,
    handleSubmitForm,
    saveLocationMutation,
    handlePartner,
  } = useGoogleMapHook()

  const dataHook = useFetchPartners()
  const [location, setLocation] = useState(() => {
    const saved = localStorage.getItem('dataAddress')
    const initialValue = JSON.parse(saved)
    return initialValue || ''
  })

  const saveDataMutations = useFetchDetailMutations()
  const handleCheck = (partnerId) => {
    saveDataMutations.mutate(
      {
        lat: location.lat,
        lon: location.lng,
        partner: partnerId,
      },
      {
        onSuccess: (data) => {
          if (data.result.length === 0) {
            setShowModal(true)
          } else {
            handlePartner(partnerId)
          }
        },
      }
    )
  }
  return (
    <SectionLayout className="my-14 md:my-20" id="partner">
      <SectionTitle
        title={`Catering dan Restoran Terbaik`}
        subtitle={`Tersedia untuk Momenmu`}
      />
      <div className="w-full mt-4 md:mt-16 mb-6">
        <div className="w-full flex flex-wrap justify-around md:justify-center items-center mt-5 md:mt-20">
          {/* LOOPING ITEM PARTNER FROM API */}
          {dataHook.data &&
            dataHook.data.map((partner, index) => (
              <div
                className="mx-1 md:mx-6 my-4 md:my-6 w-[96px] md:w-[110px] cursor-pointer"
                key={index}
                onClick={() => {
                  setPartnerId(partner.partner_id)
                  handleCheck(partner.partner_id)
                }}
              >
                <img
                  src={partner.logo}
                  alt={partner.name}
                  onError={(e) => {
                    e.target.src = logoMitra
                  }}
                  loading="lazy"
                />
              </div>
            ))}
          {/* END LOOPING ITEM PARTNER FROM API */}
        </div>
        <div className="w-full mt-10 md:mt-8">
          <h3 className="text-orange-400 text-center text-sm">
            Masih banyak catering dan restoran yang bisa Kamu pesan di
            kendiXpress
          </h3>
        </div>
      </div>
      {/* MODAL SET LOCATION */}
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        place={place}
        setURL={setURL}
        setRegion={setRegion}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={saveLocationMutation.isLoading}
        hideModal={() => {
          setPartnerId(null)
          setPartnerTypeId(null)
        }}
      />
    </SectionLayout>
  )
}

export default Partner
