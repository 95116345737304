import React from 'react'
import { Link } from 'react-router-dom'

function Tabs({
  tabsItem,
  activeTab,
  onClick,
  classItems = '',
  classItem = '',
}) {
  return (
    <div className="text-sm font-medium text-center text-[#9D9D9D]">
      <ul className="flex flex-wrap -mb-px">
        {tabsItem.map((tab, index) => (
          <li key={index} className={classItems}>
            <Link
              to={tab.redirectTo}
              className={`inline-block text-sm md:text-lg font-semibold p-4 rounded-t-lg border-b-4 ${
                activeTab == tab.id
                  ? 'text-black border-primary'
                  : 'border-transparent'
              } ${
                activeTab == tab.id
                  ? `hover:text-black hover:border-primary`
                  : `hover:text-black hover:border-primary`
              } cursor-pointer ${classItem}`}
              onClick={() => onClick(tab.id)}
            >
              {tab.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Tabs
