export const dataTransfer = (name, number) => {
  const MANDIRI = {
    atm: [
      `Masukkan ATM dan tekan "Bahasa Indonesia"`,
      `Masukkan PIN, lalu tekan "Benar"`,
      `Pilih "Pembayaran", lalu pilih "Multi Payment"`,
      `Masukkan kode perusahaan '88608', lalu tekan 'BENAR'`,
      `Masukkan Nomor Virtual Account ${number}, lalu tekan 'BENAR'`,
      `Masukkan nominal yang ingin di transfer, lalu tekan "BENAR"`,
      `Informasi pelanggan akan ditampilkan, pilih nomor 1 sesuai dengan nominal pembayaran kemudian tekan "YA"`,
      `Konfirmasi pembayaran akan muncul, tekan "YES", untuk melanjutkan`,
      `Simpan bukti transaksi anda`,
    ],
    internet_banking: [
      `Buka situs Mandiri Internet Banking https://ibank.bankmandiri.co.id`,
      `Masuk menggunakan USER ID dan PASSWORD anda`,
      `Buka halaman beranda, kemudian pilih "Pembayaran"`,
      `Pilih "Multi Payment"`,
      `Pilih 88608, Masukkan Nomor Virtual Account ${number}, lalu pilih Lanjut`,
      `Apabila semua detail benar tekan "KONFIRMASI"`,
      `Masukkan PIN / Challenge Code Token`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
    m_banking: [
      `Buka aplikasi Mandiri Online, masukkan USERNAME dan PASSWORD anda`,
      `Pilih "Bayar" dan Pilih "Multipayment"`,
      `Pilih 88608, Masukkan Nomor Virtual Account ${number}, lalu pilih Lanjut`,
      `Apabila semua detail benar tekan "KONFIRMASI"`,
      `Masukkan PIN / Challenge Code Token`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
  }

  const BRI = {
    atm: [
      `Masukkan kartu, kemudian pilih bahasa dan masukkan PIN anda`,
      `Pilih "Transaksi Lain" dan pilih "Pembayaran"`,
      `Pilih menu "Lainnya" dan pilih "Briva"`,
      `Masukkan Nomor Virtual Account ${number} dan jumlah yang ingin anda bayarkan`,
      `Periksa data transaksi dan tekan "YA"`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
    internet_banking: [
      `Buka situs https://ib.bri.co.id/ib-bri/, dan masukkan USER ID dan PASSWORD anda`,
      `Pilih "Pembayaran" dan pilih "Briva"`,
      `Masukkan Nomor Virtual Account ${number} dan jumlah yang ingin anda bayarkan`,
      `Masukkan password anda kemudian masukkan mToken internet banking`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
    m_banking: [
      `Buka aplikasi BRI Mobile Banking, masukkan USER ID dan PIN anda`,
      `Pilih "Pembayaran" dan pilih "Briva"`,
      `Masukkan Nomor Virtual Account anda ${number} dan jumlah yang ingin anda bayarkan`,
      `Masukkan PIN Mobile Banking BRI`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
  }

  const BNI = {
    atm: [
      `Masukkan kartu ATM anda, Pilih bahasa, Masukkan PIN ATM anda`,
      `Pilih "Menu Lainnya", Pilih "Transfer"`,
      `Pilih jenis rekening yang akan anda gunakan (contoh: "Dari Rekening Tabungan")`,
      `Pilih "Virtual Account Billing"`,
      `Masukkan Nomor Virtual Account anda ${number}`,
      `Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi`,
      `Konfirmasi, apabila telah sesuai, lanjutkan`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
    internet_banking: [
      `Buka situs https://ibank.bni.co.id dan Masukkan User ID dan Password`,
      `Pilih menu "Transfer" dan Pilih menu "Virtual Account Billing"`,
      `Masukkan Nomor Virtual Account ${number}`,
      `Lalu pilih rekening debet yang akan digunakan. Kemudian tekan "Lanjut"`,
      `Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi`,
      `Masukkan Kode Otentikasi Token`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
    m_banking: [
      `Akses BNI Mobile Banking melalui handphone`,
      `Masukkan User ID dan Password`,
      `Pilih menu "Transfer"`,
      `Pilih menu "Virtual Account Billing", lalu pilih rekening debet`,
      `Masukkan Nomor Virtual Account anda ${number} pada menu "Input Baru"`,
      `Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi`,
      `Konfirmasi transaksi dan masukkan Password Transaksi`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
  }
  const BCA = {
    atm: [
      `Masukkan Kartu ATM BCA lalu Masukkan PIN`,
      `Pilih menu "Transaksi Lainnya"`,
      `Pilih menu "Transfer"`,
      `Pilih menu "ke Rekening BCA Virtual Account"`,
      `Masukkan Nomor Virtual Account Anda ${number}. Tekan "Benar" untuk melanjutkan`,
      `Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti No VA, Nama, Perus/Produk dan Total Tagihan, tekan "Benar" untuk melanjutkan`,
      `Tekan "Ya" jika sudah benar`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
    internet_banking: [
      `Lakukan log in pada aplikasi KlikBCA Individual https://ibank.klikbca.com`,
      `Masukkan User ID dan PIN`,
      `Pilih "Transfer Dana", kemudian pilih "Transfer ke BCA Virtual Account"`,
      `Masukkan Nomor Virtual Account ${number}, Pilih "Lanjutkan"`,
      `Masukkan "RESPON KEYBCA APPLI 1" yang muncul pada Token BCA anda, kemudian tekan tombol "Kirim"`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
    m_banking: [
      `Buka aplikasi BCA Mobile`,
      `Pilih menu "m-BCA", kemudian masukkan kode akses m-BCA`,
      `Pilih “Transaction” lalu pilih "m-Transfer", kemudian pilih "BCA Virtual Account"`,
      `Masukkan Nomor Virtual Account ${number}, kemudian tekan "OK"`,
      `Tekan tombol "Kirim" yang berada di sudut kanan atas aplikasi untuk melakukan transfer`,
      `Tekan "OK" untuk melanjutkan pembayaran`,
      `Masukkan PIN Anda untuk meng-otorisasi transaksi`,
      `Setelah transaksi pembayaran Anda selesai, simpan bukti pembayaran`,
    ],
  }

  switch (name) {
    case 'MANDIRI':
      return MANDIRI
    case 'BCA':
      return BCA
    case 'BNI':
      return BNI
    default:
      return BRI
  }
}
