import React, { useEffect } from 'react'
import { useState } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { currency } from 'utils/formatNumber'

import PopupConfirm from './PopupConfirm'

const MenuHarian = (props) => {
  const {
    menus,
    cart,
    setCart,
    partnerActive,
    changeMitra,
    updateQuantity,
    addProduct,
    time,
    isPartner,
    removeProduct,
    popup,
  } = props
  const date = new Date(menus.date_on * 1000)
  const [index, setIndex] = useState(-1)
  const [item, setItem] = useState(-1)
  const [show, setShow] = useState(false)
  const [editQty, setEditQty] = useState(false)

  const ischangeMitra = async () => {
    setCart([])
    changeMitra(menus, partnerActive, daysMenu, time)
    setShow(false)
  }

  const daysMenu = new Date(date).setHours(date.getHours() - 7)

  useEffect(() => {
    const dateItemIndex = cart?.findIndex(
      (item) => item?.date == daysMenu && item?.shipping_time === time
    )
    setIndex(dateItemIndex)
    if (dateItemIndex != undefined) {
      const cartItemIndex = cart[dateItemIndex]?.menus.findIndex(
        (item) => item?.menu_id === menus.menu_id
      )
      setItem(cartItemIndex)
    }
  }, [addProduct, removeProduct])

  const [quantity, setQuantity] = useState(cart[index]?.menus[item]?.qty)

  const editQuantity = () => {
    setQuantity(cart[index]?.menus[item]?.qty)
    setEditQty(true)
  }

  const sendQuantity = (menu, daysMenu, time, quantity) => {
    updateQuantity(menu, daysMenu, time, quantity)
    setEditQty(false)
  }

  const addProductDaily = (menus, partnerActive, daysMenu, time) => {
    addProduct(menus, partnerActive, daysMenu, time)
  }

  return (
    <section className="w-full border flex flex-col justify-between rounded-xl px-2 py-4 md:p-5">
      <div className="">
        <div className="flex gap-2">
          <img
            onClick={() => popup(menus)}
            src={menus.photo}
            alt={menus.name}
            className="hidden md:block cursor-pointer rounded-xl w-[50px] h-[50px]"
          />
          <div className="">
            <h3
              onClick={() => popup(menus)}
              className="text-sm md:text-base cursor-pointer font-semibold"
            >
              {menus.name}
            </h3>
            <h4 className="text-primary text-xs md:text-sm">
              {date.toGMTString().slice(5, 16)}
            </h4>
          </div>
        </div>
        <div
          onClick={() => popup(menus)}
          className="pt-2 cursor-pointer md:pt-4"
        >
          {menus.content.split('\n').map((desc, i) => (
            <p key={i} className="text-xs truncate md:text-sm">
              {desc}
            </p>
          ))}
        </div>
      </div>
      <div className="flex mt-5 md:mt-10 flex-col items-center gap-4 md:gap-1 md:flex-row justify-between">
        <div className="flex items-end md:gap-1 text-[8px]">
          <p className="text-xs md:text-xs text-primary font-semibold">
            {currency(menus.price)}
          </p>
          <p className="hidden md:block md:text-[9px]">
            per {menus.qty_unit_name}
          </p>
          <p className="block md:hidden md:text-[9px]">
            /{menus.qty_unit_name}
          </p>
          {menus?.min_order !== 0 && (
            <p className="md:text-[9px] ml-1 md:ml-0 text-gray-400">
              (min {menus.min_order})
            </p>
          )}
        </div>
        {(item === -1 || item == undefined) && (
          <button
            onClick={() =>
              cart.length > 0 &&
              isPartner.partner_id != partnerActive.partner_id
                ? setShow(true)
                : addProductDaily(menus, partnerActive, daysMenu, time)
            }
            className="bg-primary w-full md:w-[100px] hover:opacity-90 py-2 rounded-full text-[10px] md:text-xs font-semibold text-white"
          >
            Pilih
          </button>
        )}
        {item >= 0 && (
          <div className="border-2 w-full flex items-center justify-between p-1 md:w-[100px] hover:opacity-90 py-1 rounded-full text-[10px] md:text-xs font-semibold text-white">
            <button
              onClick={() => removeProduct(menus, daysMenu, time)}
              className="rounded-full border-2 hover:bg-primary hover:text-white text-primary border-primary"
            >
              <BiMinus className="w-4 h-4" />
            </button>
            {editQty ? (
              <input
                autoFocus
                value={quantity}
                type="number"
                onChange={(e) => setQuantity(e.target.value)}
                onKeyPress={(e) =>
                  e.key === 'Enter' &&
                  sendQuantity(menus, daysMenu, time, quantity)
                }
                onBlur={() => sendQuantity(menus, daysMenu, time, quantity)}
                className="w-full text-center text-black text-[10px] md:text-xs font-semibold outline-none"
              />
            ) : (
              <div
                onClick={() => editQuantity()}
                className="text-black w-3/4 grid h-full items-center align-middle text-center"
              >
                {cart[index]?.menus[item]?.qty}
              </div>
            )}
            <button
              onClick={() => addProduct(menus, partnerActive, daysMenu, time)}
              className={`rounded-full border-2 ${
                cart[index]?.menus[item]?.qty < menus.max_order &&
                (cart[index]?.menus[item]?.disable_up?.length > 0 ||
                  cart[index]?.menus[item]?.disable_up == undefined)
                  ? 'hover:bg-primary hover:text-white text-primary border-primary'
                  : 'text-gray-400 border-gray-400'
              }`}
            >
              <BiPlus className="w-4 h-4" />
            </button>
          </div>
        )}
      </div>
      <PopupConfirm
        show={show}
        setShow={setShow}
        continues={() => ischangeMitra()}
      />
    </section>
  )
}

export default MenuHarian
