import React from 'react'

import NavMicrosite from './fragments/Navbar/NavMicrosite'

const MicrositeLayout = ({ children }) => {
  return (
    <React.Fragment>
      <NavMicrosite />
      {children}
    </React.Fragment>
  )
}

export default MicrositeLayout
