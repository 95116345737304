/* eslint-disable no-unused-vars */
import CloseIcon from 'images/close.svg'
import React, { useEffect } from 'react'

function Modal({
  showModal,
  setShowModal,
  title = '',
  children,
  modalClass = '',
  modalBodyClass = 'p-10',
  wrapperClass = '',
  isModalResponsiveFullPage = true,
  isModalResponsiveRounded = false,
  hideTitleSection = false,
  titleContent,
}) {
  useEffect(() => {
    let body = document.querySelector('body')
    let modal = document.querySelector('.modal')

    if (modal) {
      body.classList.add('overflow-hidden')
    } else {
      body.classList.remove('overflow-hidden')
    }
  }, [showModal])

  return (
    <>
      {showModal ? (
        <>
          <div
            className={`fixed inset-0 z-[55] overflow-y-auto modal ${wrapperClass}`}
          >
            <div
              className={`flex min-h-full justify-center ${
                isModalResponsiveFullPage ? 'p-0' : 'p-4'
              }  text-center items-center sm:p-0`}
            >
              <div
                className={`relative overflow-hidden ${
                  isModalResponsiveRounded ? 'rounded-[20px]' : 'rounded-none'
                } sm:rounded-[20px] bg-white text-left shadow-xl transition-all sm:my-8 w-screen sm:w-full sm:max-w-2xl ${modalClass}`}
              >
                <div className="bg-white flex items-start justify-between p-5 pb-5 md:pb-0">
                  {!hideTitleSection && (
                    <>
                      {titleContent}
                      <div className="text-black">{title}</div>
                      <button
                        className="p-1 ml-auto bg-transparent float-right leading-none outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <img src={CloseIcon} className="w-5 h-5" />
                      </button>
                    </>
                  )}
                </div>
                <div className={`bg-white sm:px-10 sm:pb-6 ${modalBodyClass}`}>
                  {children}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-[#303030]"></div>
        </>
      ) : null}
    </>
  )
}

export default Modal
