import React from 'react'

function FeatureItem({ imgSrc, title, subtitle }) {
  return (
    <div className="w-1/2 md:1/3 flex flex-col md:flex-row justify-center items-center my-6 md:my-0">
      <div className="h-[50px] md:h-auto">
        <div
          className={`${
            title == 'Harga Terjangkau'
              ? 'w-[33px]'
              : title == 'Gratis Ongkir'
              ? 'w-[45px]'
              : 'w-[40px]'
          } md:w-full`}
        >
          <img src={imgSrc} className="m-auto" />
        </div>
      </div>
      <div className="w-full md:w-3/4 pl-0 md:pl-4 pr-0 md:pr-3">
        <h5 className="text-primary text-base font-semibold text-center md:text-left">
          {title}
        </h5>
        <p className="text-black text-[0.715rem] font-normal pt-1 text-center md:text-left">
          {subtitle}
        </p>
      </div>
    </div>
  )
}

export default FeatureItem
